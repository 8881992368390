/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum EnrollmentActivity {
  SUBMITTED = 'SUBMITTED',
  WAIVED = 'WAIVED',
  WAIVER_UPDATE = 'WAIVER_UPDATE',
  RESET = 'RESET',
  APPLICATION_SENT = 'APPLICATION_SENT',
  ENROLLMENT_CONFIRMED = 'ENROLLMENT_CONFIRMED',
  ENROLLMNET_DETAILS_UPDATE = 'ENROLLMNET_DETAILS_UPDATE',
  PREMIUM_ADJUSTMENT = 'PREMIUM_ADJUSTMENT',
  ELECTION_DETAILS_UPDATE = 'ELECTION_DETAILS_UPDATE',
  APPLICATION_REVERTED = 'APPLICATION_REVERTED',
  ENROLLMENT_CONFIRMATION_REVERTED = 'ENROLLMENT_CONFIRMATION_REVERTED',
}
