import { EMPLOYEE_DYNAMIC_ROUTES, useDynamicRouter } from '@zorro/shared/utils';
import { STEPPER_BODY_MAX_WIDTH } from '@zorro/types';
import { Box, Stack, Title } from '@zorro/zorro-ui-design';
import { ReactNode } from 'react';

import { FormFooter } from '../../FormFooter';
import { ManualSubmissionBreadcrumbs } from './ManualSubmissionBreadcrumbs';
import { ManualSubmissionStepper } from './ManualSubmissionStepper';
import {
  ManualSubmissionStepSubmit,
  ManualSubmissionStepType,
  getManualSubmissionStepConfig,
} from './ManualSubmissionUtils';

type Props = {
  step: ManualSubmissionStepType;
  title: string;
  children: ReactNode;
  onSubmit: ManualSubmissionStepSubmit;
  submitLabel?: string;
  isSubmitDisabled?: boolean;
  employerId: string;
  employeeId: string;
  onboardingPeriodId: string;
  isDon?: boolean;
};

export const ManualSubmissionStep = ({
  step,
  title,
  children,
  onSubmit,
  isSubmitDisabled,
  submitLabel = 'Continue',
  employerId,
  employeeId,
  onboardingPeriodId,
  isDon = false,
}: Props) => {
  const { navigateToRoute } = useDynamicRouter(EMPLOYEE_DYNAMIC_ROUTES);
  const { nextRoute } = getManualSubmissionStepConfig(step);

  const handleSubmit = async () => {
    const { navigate } = await onSubmit();
    if (navigate) {
      navigateToRoute(
        nextRoute,
        [{ employerId, employeeId, onboardingPeriodId }],
        { initialOPId: onboardingPeriodId }
      );
    }
  };

  return (
    <>
      <ManualSubmissionBreadcrumbs
        onboardingPeriodId={onboardingPeriodId}
        employerId={employerId}
        employeeId={employeeId}
        isDon={isDon}
      />
      <ManualSubmissionStepper
        onboardingPeriodId={onboardingPeriodId}
        employerId={employerId}
        employeeId={employeeId}
        step={step}
      />
      <Box mx="24" mt="xl" pb={100}>
        <Stack maw={STEPPER_BODY_MAX_WIDTH}>
          <Title size="sm" fw={600}>
            {title}
          </Title>
          {children}
        </Stack>
      </Box>
      <FormFooter
        primaryLabel={submitLabel}
        primaryButtonProps={{
          onClick: handleSubmit,
          disabled: isSubmitDisabled,
        }}
      />
    </>
  );
};
