import { IconExclamationCircleFilled } from '@tabler/icons-react';
import { EmployeeDto } from '@zorro/clients';
import { getNow, parseDateISO } from '@zorro/shared/formatters';
import { useMonolithQuery, useRoles } from '@zorro/shared/utils';
import {
  Button,
  Drawer,
  Group,
  Icon,
  Text,
  brand,
} from '@zorro/zorro-ui-design';
import { useState } from 'react';

import { ChangeElectionWindowForm } from '../../EmployeeActions/EnrollmentActions/ChangeElectionWindowForm';
import { EnrollmentTabAction } from '../EnrollmentTabMenuActions';

type Props = {
  employee: EmployeeDto;
  onboardingPeriodId: string;
};

export const DeadlinePassedTabContent = ({
  employee,
  onboardingPeriodId,
}: Props) => {
  const { isEmployerAdmin } = useRoles();
  const { data: onboardingPeriod, isLoading } = useMonolithQuery({
    method: 'onboardingPeriodsControllerFindOne',
    params: [employee.id, onboardingPeriodId],
  });

  const [isModalOpenByName, setIsModalOpenByName] = useState<string | null>(
    null
  );

  if (!onboardingPeriod || isLoading) {
    return null;
  }

  const daysAgo = getNow().diff(
    parseDateISO(onboardingPeriod.onboardingUntil),
    'day'
  );

  return (
    <>
      <Group>
        {daysAgo > 0 && (
          <Group gap="0.25rem">
            <Icon
              icon={IconExclamationCircleFilled}
              color={brand.zorroFire700}
            />
            <Text size="sm" c="zorroGray.4">
              Deadline passed {daysAgo} days ago
            </Text>
          </Group>
        )}
        {!isEmployerAdmin && (
          <Button
            size="sm"
            fw={300}
            onClick={() =>
              setIsModalOpenByName(EnrollmentTabAction.CHANGE_ELECTION_WINDOW)
            }
          >
            Change election window
          </Button>
        )}
      </Group>
      <Drawer
        opened={
          isModalOpenByName === EnrollmentTabAction.CHANGE_ELECTION_WINDOW
        }
        title="Change election window"
        onClose={() => setIsModalOpenByName(null)}
      >
        <ChangeElectionWindowForm
          planYearId={onboardingPeriod.enrollmentPeriodId}
          selectedEmployees={[
            {
              id: onboardingPeriod.employeeId,
              firstName: employee.firstName,
              lastName: employee.lastName,
              onboardingPeriodId,
            },
          ]}
          onSuccess={() => setIsModalOpenByName(null)}
        />
      </Drawer>
    </>
  );
};
