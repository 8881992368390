import {
  CreateEmployeeDto,
  EmployeeDto,
  FamilyUnit,
  Gender,
  SelfReportType,
  UpdateMajorMedicalPlanDto,
} from '@zorro/clients';
import {
  formatDateISO,
  parseDateEnLocalePermissive,
  parseDateISO,
  parseStringToFloat,
} from '@zorro/shared/formatters';
import {
  assertOrThrow,
  callEndpoint,
  logger,
  parsePhoneNumber,
} from '@zorro/shared/utils';
import { ZorroError } from '@zorro/types';
import { HttpStatusCode } from 'axios';
import { $enum } from 'ts-enum-util';

import { RosterInput, RosterUploadResult } from './uploadRoster.types';

export const INCORRECT_FILE_FORMAT_MESSAGE =
  'Incorrect roster format. Download the sample for the correct format.';
function throwInvalidField(
  field: string,
  value: string,
  errorMessage = 'See sample roster file for details.'
) {
  throw new Error(
    `Invalid "${
      friendlyFieldName.get(field) || field
    }" : "${value}". ${errorMessage}`
  );
}

function throwRequiredField(
  field: string,
  errorMessage = 'Please include it in your input.'
) {
  throw new Error(
    `${
      friendlyFieldName.get(field) || field
    } is a required field. ${errorMessage}`
  );
}

/**
 * Since we can't iterate over fields of interfaces I've declared an object so I can use it's keys
 * with `Object.keys` and it's shape with `typeof EmployeeInput`, for more info:
 * https://stackoverflow.com/questions/45670705/iterate-over-interface-properties-in-typescript
 *
 * Note that these fields will always be strings since they are parsed from CSV input
 */
const EmployeeInput = {
  company_email: 'Company Email',
  personal_email: 'Personal Email',
  first_name: 'First Name',
  last_name: 'Last Name',
  id_from_employer: 'Id From Employer',
  phone: 'Phone',
  address: 'Address',
  date_of_birth: 'Date Of Birth',
  gender: 'Gender',
  plan_id: 'Plan Id',
  class: 'Class',
  salary: 'Salary',
  hire_date: 'Hire Date',
  eligibility_start_date: 'Eligibility Start Date',
};

const friendlyFieldName = new Map<string, string>([
  ['firstName', 'First Name'],
  ['lastName', 'Last Name'],
  ['idFromEmployer', 'Employee HRIS ID'],
  ['email', 'Company Email'],
  ['personalEmail', 'Personal Email'],
  ['phone', 'Phone Number'],
  ['address', 'Address'],
  ['dateOfBirth', 'Date of Birth'],
  ['gender', 'Gender'],
  ['existingPlanID', 'Current Plan ID'],
  ['class', 'Class'],
  ['salary', 'Yearly Salary'],
  ['hireDate', 'Hire Date'],
  ['eligibleFrom', 'ICHRA Eligibility Start Date'],
]);

type EmployeeInputType = typeof EmployeeInput;
const requiredFields: string[] = [
  'firstName',
  'lastName',
  'dateOfBirth',
  'class',
];

function parseByCsvFormatColumns(object: object): object is EmployeeInputType {
  return Object.keys(EmployeeInput).every((key: string) => {
    return key in object;
  });
}

function isValidEmail(email: string) {
  const expression = /^[^\s@]+@[^\s@][^\s.@]*\.[^\s@]+$/u;
  return expression.test(email);
}

function validateRequiredFields(createEmployeeDto: CreateEmployeeDto) {
  for (const field of requiredFields) {
    if (field in createEmployeeDto) {
      const typedField = field as keyof CreateEmployeeDto;
      if (
        !createEmployeeDto[typedField] ||
        createEmployeeDto[typedField]?.toString().length === 0
      ) {
        throwRequiredField(field);
      }
    }
  }
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function validateFieldsFormat(createEmployeeDto: CreateEmployeeDto) {
  // validate fields format
  const personalEmail = createEmployeeDto.personalEmail;
  if (
    personalEmail &&
    personalEmail.length > 0 &&
    !isValidEmail(personalEmail)
  ) {
    throwInvalidField(
      'personal_email',
      `Can't parse email address: ${personalEmail}`
    );
  }

  const companyEmail = createEmployeeDto.email;
  if (!companyEmail) {
    throwRequiredField('company_email');
  }
  if (!isValidEmail(companyEmail)) {
    throwInvalidField('company_email', companyEmail);
  }

  if (
    createEmployeeDto.phone &&
    parsePhoneNumber(createEmployeeDto.phone) === undefined
  ) {
    throwInvalidField('phone', createEmployeeDto.phone);
  }

  if (createEmployeeDto.salary && Number.isNaN(createEmployeeDto.salary)) {
    throwInvalidField('salary', createEmployeeDto.salary.toString());
  }

  if (
    createEmployeeDto.dateOfBirth &&
    !parseDateISO(createEmployeeDto.dateOfBirth).isValid()
  ) {
    throwInvalidField('date_of_birth', createEmployeeDto.dateOfBirth);
  }

  if (
    createEmployeeDto.hireDate &&
    !parseDateISO(createEmployeeDto.hireDate).isValid()
  ) {
    throwInvalidField('hire_date', createEmployeeDto.hireDate);
  }

  if (
    createEmployeeDto.eligibleFrom &&
    !parseDateISO(createEmployeeDto.eligibleFrom).isValid()
  ) {
    throwInvalidField('eligibility_start_date', createEmployeeDto.eligibleFrom);
  }

  if (createEmployeeDto.gender) {
    const upperCaseGender = createEmployeeDto.gender.toUpperCase();

    const genderValues = Object.values(Gender) as string[];
    if (!genderValues.includes(upperCaseGender)) {
      throwInvalidField(
        'gender',
        createEmployeeDto.gender,
        `It must be one of : ${genderValues.join(',')}`
      );
    }
  }

  if (createEmployeeDto.idFromEmployer?.includes(' ')) {
    throwInvalidField(
      'id_from_employer',
      createEmployeeDto.idFromEmployer,
      'Should not contain spaces.'
    );
  }
}

function processEmployeeRosterEntry(
  employerId: string,
  employeeInput: EmployeeInputType,
  employeeClasses: string[],
  existingEmployee?: EmployeeDto
): CreateEmployeeDto {
  const employeeDataForProcessing = existingEmployee
    ? mergeWithExistingEmployee(employerId, employeeInput, existingEmployee)
    : createNewEmployee(employerId, employeeInput);
  validateMergedEmployee(employeeDataForProcessing, employeeClasses);

  return employeeDataForProcessing;
}

function validateClass(employeeClass: string, validClasses: string[]) {
  if (!validClasses.includes(employeeClass)) {
    throw new Error(
      `Invalid class: ${employeeClass}. It must be one of: ${validClasses.join(
        ', '
      )}`
    );
  }
}

function validateMergedEmployee(
  employeeInput: CreateEmployeeDto,
  employeeClasses: string[]
) {
  validateRequiredFields(employeeInput);
  validateFieldsFormat(employeeInput);
  validateClass(employeeInput.class, employeeClasses);
}

function mergeWithExistingEmployee(
  employerId: string,
  employeeInput: EmployeeInputType,
  existingEmployee: EmployeeDto
): CreateEmployeeDto {
  return {
    employerId,
    firstName: employeeInput.first_name || existingEmployee.firstName,
    lastName: employeeInput.last_name || existingEmployee.lastName,
    idFromEmployer:
      employeeInput.id_from_employer || existingEmployee.idFromEmployer,
    email: existingEmployee.email,
    personalEmail:
      employeeInput.personal_email || existingEmployee.personalEmail,
    address: employeeInput.address || existingEmployee.address,
    phone: employeeInput.phone || existingEmployee.phone,
    dateOfBirth: employeeInput.date_of_birth
      ? formatDateISO(parseDateEnLocalePermissive(employeeInput.date_of_birth))
      : existingEmployee.dateOfBirth,
    gender: employeeInput.gender
      ? (employeeInput.gender.toUpperCase() as Gender)
      : existingEmployee.gender,
    existingPlanID:
      employeeInput.plan_id === ''
        ? (existingEmployee.existingPlan as unknown as string)
        : employeeInput.plan_id,
    class: employeeInput.class || existingEmployee.class,
    salary: employeeInput.salary
      ? parseStringToFloat(employeeInput.salary)
      : existingEmployee.salary,
    hireDate: employeeInput.hire_date
      ? formatDateISO(parseDateEnLocalePermissive(employeeInput.hire_date))
      : existingEmployee.hireDate,
    eligibleFrom: employeeInput.eligibility_start_date
      ? formatDateISO(
          parseDateEnLocalePermissive(employeeInput.eligibility_start_date)
        )
      : existingEmployee.eligibleFrom,
  };
}

function createNewEmployee(
  employerId: string,
  employeeInput: EmployeeInputType
): CreateEmployeeDto {
  return {
    employerId,
    firstName: employeeInput.first_name,
    lastName: employeeInput.last_name,
    idFromEmployer: employeeInput.id_from_employer,
    email: employeeInput.company_email,
    personalEmail:
      employeeInput.personal_email?.length > 0
        ? employeeInput.personal_email
        : null,
    address: employeeInput.address,
    phone: employeeInput.phone || null,
    dateOfBirth: formatDateISO(
      parseDateEnLocalePermissive(employeeInput.date_of_birth)
    ),
    gender: employeeInput.gender
      ? (employeeInput.gender.toUpperCase() as Gender)
      : null,
    existingPlanID: employeeInput.plan_id,
    class: employeeInput.class,
    salary: employeeInput.salary
      ? parseStringToFloat(employeeInput.salary)
      : null,
    hireDate: employeeInput.hire_date
      ? formatDateISO(parseDateEnLocalePermissive(employeeInput.hire_date))
      : null,
    eligibleFrom: employeeInput.eligibility_start_date
      ? formatDateISO(
          parseDateEnLocalePermissive(employeeInput.eligibility_start_date)
        )
      : null,
  };
}

function logAndAddErrorToResult(
  result: RosterUploadResult,
  index: number,
  error: Error,
  message: string,
  employee: {
    firstName: string;
    lastName: string;
    email: string;
  }
) {
  logger.error(error, message);
  result.success = false;
  result.message =
    'One or more employees could not be created. Check `errors` for details.';
  result.errors.push({
    id: `${index} ${message}`,
    index,
    message,
    employeeDetails: {
      firstName: employee.firstName,
      lastName: employee.lastName,
      email: employee.email,
    },
  });
}

function doesInputHaveRequiredBenefitFields(inputRow: RosterInput): boolean {
  // checks only the mandatory fields. Optional fields can be omitted or left empty
  const { carrier_name: carrierName, allowance, premium } = inputRow;
  return (
    Boolean(carrierName && allowance && premium) &&
    !Number.isNaN(parseStringToFloat(allowance)) &&
    !Number.isNaN(parseStringToFloat(premium))
  );
}

async function createBenefitsFromRoster(
  inputRow: RosterInput,
  createdEmployeeDto: EmployeeDto
) {
  const premium = parseStringToFloat(inputRow.premium);
  const allowance = parseStringToFloat(inputRow.allowance);
  const updateMajorMedicalPlanDto: UpdateMajorMedicalPlanDto = {
    updateManuallyEnteredMajorMedicalPlan: {
      familyUnit: $enum(FamilyUnit).asValueOrDefault(
        inputRow.family_unit,
        FamilyUnit.EMPLOYEE_ONLY
      ),
      carrierName: inputRow.carrier_name,
      premium,
      name: inputRow.plan_name ?? '',
      allowance,
      employeeMonthlyContribution: Math.max(0, premium - allowance),
      selfReportType: SelfReportType.NOT_APPLICABLE,
      isCombinedPlan: false,
      isHsaEligible: null,
      benefitsSummaryUrl: null,
      maxOutOfPocket: null,
      deductible: null,
      externalID: null,
    },
  };
  const onboardingPeriodDtos = await callEndpoint({
    method: 'onboardingPeriodsControllerFindMany',
    params: [createdEmployeeDto.id],
  });

  assertOrThrow(
    onboardingPeriodDtos.length === 1,
    'this feature is not currently supported for employees with multiple onboarding periods'
  );
  const periodId = onboardingPeriodDtos[0].id;

  await callEndpoint({
    method: 'majorMedicalControllerUpsertMajorMedicalPlanByOperations',
    params: [periodId, true, updateMajorMedicalPlanDto],
  });
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export async function createEmployeesFromRosterUpload(
  employerId: string,
  inputRoster: RosterInput[],
  sendActivationEmail: boolean,
  employeeClasses: string[]
): Promise<RosterUploadResult> {
  const rosterLength = inputRoster.length;

  const tryParseToCheckHeadersRow = parseByCsvFormatColumns(inputRoster[0]);
  if (!tryParseToCheckHeadersRow) {
    return {
      success: false,
      message: INCORRECT_FILE_FORMAT_MESSAGE,
      errors: [],
    };
  }

  if (rosterLength > 1000) {
    return {
      success: false,
      message:
        'Roster upload is limited to 1,000 employees. Please split the file.',
      errors: [],
    };
  }

  const result: RosterUploadResult = {
    success: false,
    message: '',
    errors: [],
  };
  const employeesToCreate: CreateEmployeeDto[] = [];

  let existingEmployeesMap: Map<string, EmployeeDto>;
  try {
    const existingEmployees = await callEndpoint({
      method: 'employeesControllerFindAll',
      params: [employerId],
    });

    existingEmployeesMap = new Map<string, EmployeeDto>(
      existingEmployees.map((employeeDto) => [employeeDto.email, employeeDto])
    );

    logger.debug('Iterating roster to create employees...');
    for (let index = 0; index < rosterLength; index++) {
      const inputRow = inputRoster[index];
      const employeeRow: EmployeeInputType = inputRow as EmployeeInputType;
      try {
        const existingEmployee = existingEmployeesMap.get(
          employeeRow.company_email
        );
        const employee = processEmployeeRosterEntry(
          employerId,
          employeeRow,
          employeeClasses,
          existingEmployee
        );
        employeesToCreate.push(employee);
      } catch (error) {
        logger.error(error);
        const message = `${error.message}`;
        logAndAddErrorToResult(result, index, error, message, {
          firstName: employeeRow.first_name,
          lastName: employeeRow.last_name,
          email: employeeRow.company_email,
        });
      }
    }
  } catch {
    return {
      success: false,
      message: 'Failed to fetch existing employees. Try again later.',
      errors: [],
    };
  }

  if (result.errors.length > 0) {
    return {
      ...result,
      message:
        'No entries were created due to input issues. Please review and try again',
    };
  }

  try {
    const createManyResult = await callEndpoint({
      method: 'employeesControllerUpsertMany',
      params: [{ employees: employeesToCreate, sendActivationEmail }],
    });

    createManyResult.errors.forEach((error) => {
      logAndAddErrorToResult(
        result,
        error.index,
        new ZorroError(error.message),
        error.message,
        {
          firstName: error.employee.firstName,
          lastName: error.employee.lastName,
          email: error.employee.email,
        }
      );
    });

    await Promise.all(
      createManyResult.upsertedEmployees.map(async (createdEmployee) => {
        const inputRow = inputRoster.find(
          (input) => input.company_email === createdEmployee.email
        );
        if (inputRow && doesInputHaveRequiredBenefitFields(inputRow)) {
          await createBenefitsFromRoster(inputRow, createdEmployee);
        }
      })
    );

    const totalUpsertedCount = createManyResult.upsertedEmployees.length;
    const updatedCount = createManyResult.upsertedEmployees.filter((employee) =>
      existingEmployeesMap.has(employee.email)
    ).length;

    const createdCount = totalUpsertedCount - updatedCount;

    if (result.errors.length === 0) {
      result.success = true;

      result.message = `${[
        createdCount > 0 ? `${createdCount} employees created` : null,
        updatedCount > 0 ? `${updatedCount} employees updated` : null,
      ]
        .filter(Boolean)
        .join(' and ')} successfully`;

      logger.info(result.message);
    } else {
      if (totalUpsertedCount > 0) {
        result.success = true;
        result.message = `${createdCount} employees created, ${updatedCount} updated, and ${result.errors.length} encountered errors. Review the errors to proceed`;
      } else {
        result.success = false;
        result.message =
          'No entries were created due to input issues. Please review and try again';
      }
      logger.error(result.message);
    }

    return result;
  } catch (error) {
    if (error.status === HttpStatusCode.BadRequest) {
      for (const key in error.body.errors.employees) {
        for (const field in error.body.errors.employees[key]) {
          logAndAddErrorToResult(
            result,
            Number.parseInt(key),
            new ZorroError(error.body.errors.employees[key][field]),
            error.body.errors.employees[key][field],
            {
              firstName:
                error?.employee?.firstName ??
                employeesToCreate[Number.parseInt(key)]?.firstName,
              lastName:
                error?.employee?.lastName ??
                employeesToCreate[Number.parseInt(key)]?.lastName,
              email:
                error?.employee?.email ??
                employeesToCreate[Number.parseInt(key)]?.email,
            }
          );
        }
      }
      return result;
    }

    result.success = false;
    result.message = `Something went wrong. Try again later. ${error.message}`;
    return result;
  }
}
