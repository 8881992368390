import { Stepper, StepperStepProps } from '@mantine/core';

import { StepperPartialIcon } from './StepperPartialIcon';

const partialIcon = <StepperPartialIcon />;

export type IStepperStepProps = StepperStepProps & {
  isPartial?: boolean;
  isPartialAndRequired?: boolean;
  isNavigable?: boolean;
};

export function ZorroStepperStep({
  isPartialAndRequired = false,
  isPartial = false,
  isNavigable = false,
  ...props
}: IStepperStepProps) {
  const shouldShowPartialIcon =
    (isPartial || isPartialAndRequired) && props.state === 'stepCompleted';
  return (
    <Stepper.Step
      {...props}
      data-navigable={isNavigable}
      data-partial={shouldShowPartialIcon}
      data-partial-required={isPartialAndRequired}
      icon={shouldShowPartialIcon ? partialIcon : props.icon}
      completedIcon={shouldShowPartialIcon ? partialIcon : props.completedIcon}
    />
  );
}
