/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum RenewalStatus {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  WAIVED = 'WAIVED',
  RENEWED = 'RENEWED',
  CHANGED = 'CHANGED',
}
