import { Chip } from '@mantine/core';
import { IconSearch, IconX } from '@tabler/icons-react';
import {
  Button,
  Divider,
  Flex,
  Group,
  ITableProps,
  Icon,
  NewTable,
  Space,
  Text,
  TextInput,
  brand,
} from '@zorro/zorro-ui-design';
import { ReactNode } from 'react';

import { DownloadButton } from '../DownloadButton';

type SearchableDataTableProps = {
  componentsAfterSearch?: ReactNode;
  componentsRightDownloadButton?: ReactNode;
  componentsLeftToDownloadButton?: ReactNode;
  onDownload: () => void;
  searchValue: string;
  onSearchChange: (value: string) => void;
  isLoading?: boolean;
  availableViews?: { value: string; label: string; count: number }[];
  currentView?: string;
  setCurrentView?: (view: string) => void;
  filtersCount?: number;
  clearFilters?: () => void;
} & ITableProps;

export function SearchableDataTable({
  componentsAfterSearch,
  componentsRightDownloadButton,
  componentsLeftToDownloadButton,
  searchValue = '',
  onSearchChange,
  onDownload,
  isLoading = false,
  availableViews,
  currentView,
  setCurrentView,
  filtersCount = 0,
  clearFilters,
  ...rest
}: SearchableDataTableProps) {
  const selectedRecordsCount = rest?.selectedRecords?.length || 0;

  return (
    <>
      <Group>
        <Chip.Group
          value={currentView}
          onChange={setCurrentView}
          multiple={false}
        >
          <Group>
            {availableViews?.map((view) => (
              <Chip
                key={view.value}
                value={view.value}
                variant="filled"
                color={brand.primaryButtonSelected}
                style={{
                  '--chip-color': brand.zorroCoal900,
                }}
              >
                {`${view.label} (${view.count})`}
              </Chip>
            ))}
          </Group>
        </Chip.Group>
        {filtersCount > 0 && clearFilters && (
          <Group>
            <Divider orientation="vertical" />
            <Button
              size="sm"
              variant="default"
              style={{
                borderRadius: '4px',
                borderWidth: '1px',
                borderColor: brand.zorroGray200,
                color: brand.zorroCoal900,
              }}
              onClick={() => clearFilters()}
              rightSection={
                <Icon icon={IconX} size="16px" color={brand.zorroGray800} />
              }
            >{`Filters: ${filtersCount}`}</Button>
          </Group>
        )}
      </Group>
      <Space h="md" />
      <Flex justify="space-between">
        <Group h="40px">
          <TextInput
            disabled={isLoading}
            leftSection={
              <IconSearch
                style={{
                  color: brand.zorroGray300,
                  width: '20px',
                  height: '20px',
                  marginLeft: '6px',
                }}
              />
            }
            styles={{
              input: {
                fontSize: 'var(--mantine-font-size-sm)',
                fontWeight: 400,
                borderColor: brand.zorroGray300,
              },
            }}
            placeholder="Search Employees"
            size="sm"
            w="400px"
            value={searchValue}
            onInput={(args) => {
              onSearchChange(args.currentTarget.value);
            }}
          />
          {componentsAfterSearch}
        </Group>
        <Group h="100%" gap="lg" justify="right" pos="relative">
          {selectedRecordsCount !== 0 && (
            <Text mr="1.5rem" c="zorroGray.7">
              {selectedRecordsCount} selected
            </Text>
          )}
          {componentsLeftToDownloadButton}
          <DownloadButton onDownload={onDownload} isDisabled={isLoading} />
          {componentsRightDownloadButton}
        </Group>
      </Flex>

      <Space h="md" />

      <NewTable fetching={isLoading} {...rest} />
    </>
  );
}
