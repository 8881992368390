import { WaitingPeriod } from '@zorro/clients';
import { DateUtilInstance, getNow, parseDate } from '@zorro/shared/formatters';
import { waitingPeriodToNumberOfDays } from '@zorro/shared/utils';

export const calculateEligibleFrom = (
  formHireDate?: Date,
  waitingPeriod?: WaitingPeriod | null
): DateUtilInstance => {
  if (formHireDate instanceof Date) {
    const parsedHireDate = parseDate(formHireDate);

    const eligibilityWithWaitingPeriod = waitingPeriod
      ? parsedHireDate.add(waitingPeriodToNumberOfDays[waitingPeriod], 'days')
      : parsedHireDate;

    const isFirstOfMonth = eligibilityWithWaitingPeriod.date() === 1;
    const eligibleFrom = isFirstOfMonth
      ? eligibilityWithWaitingPeriod
      : eligibilityWithWaitingPeriod.startOf('month').add(1, 'month');
    if (eligibleFrom.isValid()) {
      return eligibleFrom;
    }
  }
  return getNow();
};
