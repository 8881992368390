export function toISOString(date: Date): string {
  const tzo = -date.getTimezoneOffset();
  const dif = tzo >= 0 ? '+' : '-';

  // eslint-disable-next-line unicorn/consistent-function-scoping
  function pad(num: number) {
    return (num < 10 ? '0' : '') + num;
  }

  const datePart = `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
    date.getDate()
  )}`;
  const timePart = `${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
    date.getSeconds()
  )}`;
  const tzPart = `${dif}${pad(Math.floor(Math.abs(tzo) / 60))}:${pad(
    Math.abs(tzo) % 60
  )}`;

  return `${datePart}T${timePart}${tzPart}`;
}
