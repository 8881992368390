import {
  AgentIcon,
  EmployeeIcon,
  IconZorroPaymentCard,
  IconZorroPaymentCombined,
  IconZorroPaymentSelfPay,
} from '@zorro/shared/assets';
import { brand } from '@zorro/zorro-ui-design';

export function PaymentMethodZorroPayIcon() {
  return (
    <IconZorroPaymentCard
      style={{
        borderRadius: '50%',
        width: '32px',
        height: '32px',
        backgroundColor: brand.primaryButtonSelected,
        padding: '5px',
      }}
    />
  );
}

export function PaymentMethodSelfPayIcon() {
  return (
    <IconZorroPaymentSelfPay
      style={{
        borderRadius: '50%',
        width: '32px',
        height: '32px',
        backgroundColor: brand.zorroYolk200,
        padding: '5px',
      }}
    />
  );
}

export function PaymentMethodCombinedPayIcon() {
  return (
    <IconZorroPaymentCombined
      style={{
        borderRadius: '50%',
        width: '32px',
        height: '32px',
        backgroundColor: brand.zorroSky100,
        padding: '5px',
      }}
    />
  );
}

export function PaymentStatusTrueAgentIcon() {
  return (
    <AgentIcon
      style={{
        borderRadius: '50%',
        width: '32px',
        height: '32px',
        backgroundColor: brand.zorroGreen300,
        padding: '5px',
      }}
    />
  );
}

export function PaymentStatusFalseAgentIcon() {
  return (
    <AgentIcon
      style={{
        borderRadius: '50%',
        width: '32px',
        height: '32px',
        backgroundColor: brand.zorroFire300,
        padding: '5px',
      }}
    />
  );
}

export function PaymentStatusTrueEmployeeIcon() {
  return (
    <EmployeeIcon
      style={{
        borderRadius: '50%',
        width: '32px',
        height: '32px',
        backgroundColor: brand.zorroGreen300,
        padding: '5px',
      }}
    />
  );
}

export function PaymentStatusFalseEmployeeIcon() {
  return (
    <EmployeeIcon
      style={{
        borderRadius: '50%',
        width: '32px',
        height: '32px',
        backgroundColor: brand.zorroFire300,
        padding: '5px',
      }}
    />
  );
}

export function PaymentStatusUnknownEmployeeIcon() {
  return (
    <EmployeeIcon
      style={{
        borderRadius: '50%',
        width: '32px',
        height: '32px',
        backgroundColor: brand.zorroYolk400,
        padding: '5px',
      }}
    />
  );
}
