import { EmployeeDto, OnboardingPeriodReportDto } from '@zorro/clients';
import { ThreeDotsIcon } from '@zorro/shared/assets';
import { formatCurrencyEnUs, parseDateISO } from '@zorro/shared/formatters';
import { calculateAgeForEnrollment, useRoles } from '@zorro/shared/utils';
import {
  ActionIcon,
  Card,
  Group,
  Menu,
  Stack,
  Text,
  Title,
  useDisclosure,
} from '@zorro/zorro-ui-design';

import { QualifyingLifeEventLabel } from '../QualifyingLifeEventLabel';
import { EmployeeLayoutAction } from './EmployeePageActions';
import { EmployeePageHeaderSkeleton } from './EmployeePageHeaderSkeleton';

type Props = {
  setAction: (action: EmployeeLayoutAction) => void;
  employee?: EmployeeDto;
  onboardingPeriodReport?: OnboardingPeriodReportDto;
  isOnboardingPeriodsLoading: boolean;
};

export const EmployeePageHeader = ({
  setAction,
  employee,
  onboardingPeriodReport,
  isOnboardingPeriodsLoading,
}: Props) => {
  const [opened, { toggle }] = useDisclosure(false);

  const { isEmployerAdmin } = useRoles();

  if (isOnboardingPeriodsLoading) {
    return <EmployeePageHeaderSkeleton />;
  } else if (!onboardingPeriodReport || !employee) {
    return null;
  }

  const {
    isSpecialEnrollment,
    enrollmentEffectiveFrom,
    qualifyingLifeEvent,
    targetEnrollmentDate,
    allowance,
  } = onboardingPeriodReport;

  const yearLabel = isSpecialEnrollment
    ? 'Special enrollment'
    : 'Open enrollment';
  const year = parseDateISO(enrollmentEffectiveFrom).year();
  const age = calculateAgeForEnrollment(
    employee.dateOfBirth,
    targetEnrollmentDate
  );

  return (
    <Card withBorder={false} bg="zorroGray.10" style={{ borderRadius: 8 }}>
      <Stack>
        <Group gap="xs">
          <Title size="h3" fw={600}>
            {yearLabel} {year}
          </Title>
          {!isEmployerAdmin && (
            <Menu shadow="md" width={200} opened={opened} onChange={toggle}>
              <Menu.Target>
                <ActionIcon variant="filled" isChecked={opened}>
                  <ThreeDotsIcon />
                </ActionIcon>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item
                  onClick={() =>
                    setAction(EmployeeLayoutAction.EDIT_ENROLLMENT)
                  }
                >
                  Edit
                </Menu.Item>
                <Menu.Item
                  onClick={() =>
                    setAction(EmployeeLayoutAction.DELETE_ENROLLMENT)
                  }
                >
                  Delete
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          )}
        </Group>
        <Group>
          <QualifyingLifeEventLabel qualifyingLifeEvent={qualifyingLifeEvent} />
          {allowance !== null && allowance !== undefined && (
            <Group gap="0.25rem">
              <Text c="zorroGray.4" size="sm">
                Allowance:
              </Text>
              <Text size="sm">{formatCurrencyEnUs(allowance)}</Text>
            </Group>
          )}
          {employee.class && (
            <Group gap="0.25rem">
              <Text c="zorroGray.4" size="sm">
                Class
              </Text>
              <Text size="sm">{employee.class}</Text>
            </Group>
          )}
          <Group gap="0.25rem">
            <Text c="zorroGray.4" size="sm">
              Age
            </Text>
            <Text size="sm">{age}</Text>
          </Group>
        </Group>
      </Stack>
    </Card>
  );
};
