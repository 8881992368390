import { useQueryClient } from '@tanstack/react-query';
import { EmployeeDto, UserAndTenantDto } from '@zorro/clients';
import {
  SUCCESS_MESSAGES,
  callEndpoint,
  responseErrorToString,
  showErrorNotification,
  showSuccessNotification,
  useDynamicRouter,
} from '@zorro/shared/utils';
import { Stack, Text } from '@zorro/zorro-ui-design';
import pluralize from 'pluralize';

import { ModalFooter, useLoadingOverlay } from '../../../index';

type TSelectedEmployee = {
  userId: EmployeeDto['userId'];
  employerId: EmployeeDto['employerId'];
};

type AccountInvitationFormProps = {
  selectedEmployees: TSelectedEmployee[];
  onClose: () => void;
  onSuccess: () => void;
};

const AccountInvitationForm = ({
  selectedEmployees,
  onClose,
  onSuccess,
}: AccountInvitationFormProps) => {
  const isSingleEmployee = selectedEmployees.length === 1;

  const { startLoading, stopLoading } = useLoadingOverlay();

  const { reloadPage } = useDynamicRouter({});
  const queryClient = useQueryClient();

  async function handleSubmit() {
    startLoading();

    const userIds: UserAndTenantDto[] = selectedEmployees.map(
      (employeeRow) => ({
        id: employeeRow.userId,
        tenantId: employeeRow.employerId,
      })
    );

    try {
      await callEndpoint({
        method: 'usersControllerSendActivationEmails',
        params: [userIds],
      });

      showSuccessNotification({
        message: SUCCESS_MESSAGES.getEmailSentSuccessfullyMessage(
          selectedEmployees.length
        ),
      });

      onSuccess();
    } catch (error) {
      showErrorNotification({ message: responseErrorToString(error) });
    }

    await queryClient.invalidateQueries();
    reloadPage();
    stopLoading();
  }

  const text = isSingleEmployee
    ? 'Are you sure you want to send this email to the selected employee?'
    : 'Are you sure you want to send this email to all selected employees?';

  const submitText = `Send ${pluralize('email', selectedEmployees.length)}`;

  return (
    <Stack gap="xxl">
      <Text>{text}</Text>
      <ModalFooter
        onClose={onClose}
        submitVariant="primary"
        submitText={submitText}
        onSubmit={handleSubmit}
      />
    </Stack>
  );
};

export { AccountInvitationForm };
