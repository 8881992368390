import { KeyboardEvent } from 'react';

// 9 digits total, optional dashes, no all zeros in any one group
export const ssnRegex = /^(?!000)\d{3}-?(?!00)\d{2}-?(?!0000)\d{4}$/u;

export function isSsn(ssnish: string) {
  return ssnRegex.test(ssnish);
}

export const obfuscateSsn = (ssn: string | null | undefined) => {
  if (!ssn) {
    return null;
  }

  const ssnNoDashes = ssn.replaceAll('-', '');
  const visiblePart = ssnNoDashes.slice(-4);
  return visiblePart.padStart(ssnNoDashes.length, 'X');
};

export const validateOnlyNumbers = (evt: KeyboardEvent<HTMLInputElement>) => {
  const code = evt.code;
  if (
    code === 'ArrowLeft' ||
    code === 'ArrowUp' ||
    code === 'ArrowRight' ||
    code === 'ArrowDown' ||
    code === 'Backspace' ||
    code === 'Delete'
  ) {
    // Left / Up / Right / Down Arrow, Backspace, Delete keys
    return;
  }
  if (!/\d/u.test(code)) {
    evt.preventDefault();
  }
};
