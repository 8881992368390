import { EnrollmentStatus } from '@zorro/clients';
import { Box, Tooltip } from '@zorro/zorro-ui-design';

import { enrollmentStatusConfig } from './EnrollmentStatusUtils';

type Props = {
  enrollmentStatus: EnrollmentStatus;
  iconSize?: number;
};

export function EnrollmentStatusIconTooltip({
  enrollmentStatus,
  iconSize,
}: Props) {
  const { tooltipLabel, renderIcon } = enrollmentStatusConfig[enrollmentStatus];

  return (
    <Tooltip label={tooltipLabel} position="top">
      <Box w="min-content">{renderIcon(iconSize)}</Box>
    </Tooltip>
  );
}
