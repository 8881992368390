import { CompanyOverviewDto, EnrollmentStatus } from '@zorro/clients';
import { Box, Card, Group, Space, Text } from '@zorro/zorro-ui-design';

import { EnrollmentStatusIconTooltip } from '../EnrollmentStatus/EnrollmentStatusIconTooltip';
import { enrollmentStatusConfig } from '../EnrollmentStatus/EnrollmentStatusUtils';

type Props = {
  eligibleEmployeesCount: number;
  currentCoverageCountByStatus: CompanyOverviewDto['currentCoverageCountByStatus'];
};

const coverageIcons = [
  EnrollmentStatus.ACTIVE_COVERAGE,
  EnrollmentStatus.WAIVED_COVERAGE,
  EnrollmentStatus.COVERAGE_ENDED,
];

export const CompanyCoverageOverview = ({
  eligibleEmployeesCount,
  currentCoverageCountByStatus,
}: Props) => {
  return (
    <Box maw={1200}>
      <Text span size="md">
        <strong>Current coverage </strong>({eligibleEmployeesCount} currently
        eligible)
      </Text>
      <Space h="md" />
      <Group grow gap="xl">
        {coverageIcons.map((enrollmentStatus) => (
          <Card key={enrollmentStatus} radius="md">
            <Group mb="lg" justify="space-between" align="center">
              <Text size="24px" fw="bold">
                {currentCoverageCountByStatus[enrollmentStatus] || 0}
              </Text>
              <EnrollmentStatusIconTooltip
                enrollmentStatus={enrollmentStatus}
              />
            </Group>
            <Text size="md">
              {enrollmentStatusConfig[enrollmentStatus].label}
            </Text>
          </Card>
        ))}
      </Group>
    </Box>
  );
};
