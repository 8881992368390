import { Gender, WaitingPeriod } from '@zorro/clients';
import {
  earliestAllowedNonDependentBirthDate,
  latestAllowedNonDependentBirthDate,
} from '@zorro/shared/formatters';
import * as yup from 'yup';

import { nullIfBadRequest } from '../async';
import { callEndpoint } from '../callEndpoint';
import { VALIDATION_MESSAGES } from '../validationMessages';
import { emailValidationSchema } from './emailValidationSchema';

export const addEmployeeSchema = (waitingPeriod?: WaitingPeriod | null) =>
  yup.object({
    idFromEmployer: yup.string().notRequired(),
    firstName: yup
      .string()
      .required(VALIDATION_MESSAGES.firstNameRequired)
      .min(2, VALIDATION_MESSAGES.firstNameMinTwoLetters),
    lastName: yup
      .string()
      .required(VALIDATION_MESSAGES.lastNameRequired)
      .min(2, VALIDATION_MESSAGES.lastNameMinTwoLetters),
    email: emailValidationSchema
      .required(VALIDATION_MESSAGES.emailRequired)
      .test(
        'email-exists',
        VALIDATION_MESSAGES.emailAlreadyExists,
        async (value) => {
          if (!value || !emailValidationSchema.isValidSync(value)) {
            return false;
          }
          const response = await nullIfBadRequest(() =>
            callEndpoint({
              method: 'employeesControllerCheckCompanyEmailExists',
              params: [{ email: value }],
            })
          );
          return !response?.emailInUse;
        }
      ),
    personalEmail: emailValidationSchema.notRequired(),
    phone: yup
      .string()
      .notRequired()
      /**
       * INFO: we expect a 10-digit US phone number entered by the user
       * and react-phone-number-input automatically adds +1 prefix to it
       */
      .matches(/^\+?\d{11}$/u, VALIDATION_MESSAGES.phoneNumberValid),
    address: yup.string().notRequired(),
    dateOfBirth: yup
      .date()
      .min(
        latestAllowedNonDependentBirthDate(),
        VALIDATION_MESSAGES.employeeMaxAgeValid
      )
      .max(
        earliestAllowedNonDependentBirthDate(),
        VALIDATION_MESSAGES.employeeMinAgeValid
      )
      .typeError(VALIDATION_MESSAGES.dateOfBirthRequired)
      .required(VALIDATION_MESSAGES.dateOfBirthRequired),
    gender: yup
      .string()
      .notRequired()
      .oneOf(Object.keys(Gender), VALIDATION_MESSAGES.genderRequired),
    class: yup.string().required(VALIDATION_MESSAGES.classRequired),
    salary: yup
      .number()
      .positive(VALIDATION_MESSAGES.yearlySalaryValid)
      .notRequired()
      .transform((value) => (Number.isNaN(value) ? undefined : value)),
    hireDate: yup.date().when('dateOfBirth', (dateOfBirth) => {
      if (dateOfBirth[0]) {
        return yup
          .date()
          .min(dateOfBirth, VALIDATION_MESSAGES.hireDateValid)
          .typeError(VALIDATION_MESSAGES.hireDateRequired)
          .required(VALIDATION_MESSAGES.hireDateRequired);
      }
      return yup
        .date()
        .min(
          latestAllowedNonDependentBirthDate(),
          VALIDATION_MESSAGES.hireDateValid
        )
        .typeError(VALIDATION_MESSAGES.hireDateRequired)
        .required(VALIDATION_MESSAGES.hireDateRequired);
    }),
    eligibleFrom: yup.date().when('dateOfBirth', (dateOfBirth) => {
      let dateSchema = yup.date();
      dateSchema = dateOfBirth[0]
        ? dateSchema.min(dateOfBirth, VALIDATION_MESSAGES.eligibleFromValid)
        : dateSchema.min(
            latestAllowedNonDependentBirthDate(),
            VALIDATION_MESSAGES.eligibleFromValid
          );
      if (!waitingPeriod) {
        dateSchema = dateSchema
          .typeError(VALIDATION_MESSAGES.eligibleFromRequired)
          .required(VALIDATION_MESSAGES.eligibleFromRequired);
      }
      return dateSchema;
    }),
  });
