import { Autocomplete, AutocompleteProps } from '@mantine/core';
import { ForwardedRef, forwardRef } from 'react';

import { brand } from '../theme';
import classNames from './Autocomplete.module.scss';

export type IAutocompleteProps = AutocompleteProps;

export const ZorroAutocomplete = forwardRef(
  (
    {
      variant = 'default',
      size = 'md',
      color = brand.zorroGray600,
      ...props
    }: IAutocompleteProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => (
    <Autocomplete
      ref={ref}
      variant={variant}
      size={size}
      classNames={classNames}
      color={color}
      {...props}
    />
  )
);

ZorroAutocomplete.displayName = 'ZorroAutocomplete';
