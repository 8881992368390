import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from '@zorro/shared/utils';
import {
  CloseButton,
  Group,
  InputWrapper,
  Stack,
  TabButtonGroup,
  Tabs,
  Title,
} from '@zorro/zorro-ui-design';
import { ReactElement } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { InviteByEmailDecoration } from './InviteByEmailDecoration';
import { InviteByEmailForm } from './InviteByEmailForm';
import { InviteByLinkDecoration } from './InviteByLinkDecoration';
import { InviteByLinkForm } from './InviteByLinkForm';
import {
  InvitationType,
  SendInviteFormFields,
  sendInviteSchema,
} from './SendInviteUtils';

const sendInviteModalConfig: Record<
  InvitationType,
  {
    rightSideDecoration: ReactElement;
    renderForm: (
      employerId: string,
      sendInviteForm: UseFormReturn<SendInviteFormFields>
    ) => ReactElement;
  }
> = {
  [InvitationType.BY_EMAIL]: {
    rightSideDecoration: <InviteByEmailDecoration />,
    renderForm: (employerId, sendInviteForm) => (
      <InviteByEmailForm
        sendInviteForm={sendInviteForm}
        employerId={employerId}
      />
    ),
  },
  [InvitationType.BY_LINK]: {
    rightSideDecoration: <InviteByLinkDecoration />,
    renderForm: (employerId, sendInviteForm) => (
      <InviteByLinkForm
        sendInviteForm={sendInviteForm}
        employerId={employerId}
      />
    ),
  },
};

type Props = {
  employerId: string;
  employerName: string;
  onClose: () => void;
};

export const SendInviteForm = ({
  employerId,
  employerName,
  onClose,
}: Props) => {
  const sendInviteForm = useForm<SendInviteFormFields>({
    mode: 'all',
    resolver: yupResolver(sendInviteSchema),
    defaultValues: {
      invitationType: InvitationType.BY_EMAIL,
      email: '',
    },
  });

  const invitationType = sendInviteForm.watch('invitationType');

  const { rightSideDecoration, renderForm } =
    sendInviteModalConfig[invitationType];

  return (
    <Group wrap="nowrap" h="567px" gap="1.5rem" align="start">
      <Stack ml="2.5rem" mt="2.5rem" gap="xl">
        <Title fw={600}>
          Invite {employerName} to complete their company profile
        </Title>
        <Controller
          control={sendInviteForm.control}
          name="invitationType"
          render={({ field: { ref: _ref, onChange, ...rest } }) => (
            <InputWrapper w="200px">
              <TabButtonGroup
                {...rest}
                onChange={onChange}
                styles={{ list: { flexWrap: 'nowrap' } }}
              >
                <Tabs.List grow>
                  <Tabs.Tab value={InvitationType.BY_EMAIL}>By email</Tabs.Tab>
                  <Tabs.Tab value={InvitationType.BY_LINK}>By link</Tabs.Tab>
                </Tabs.List>
              </TabButtonGroup>
            </InputWrapper>
          )}
        />

        {renderForm(employerId, sendInviteForm)}
      </Stack>
      <Stack
        style={{ borderRadius: '8px' }}
        p="2rem 2rem 0 2rem"
        pos="relative"
        gap="2.75rem"
        bg="#EDEFF6"
        w="480px"
        h="100%"
        ml="8px"
      >
        <CloseButton
          onClick={onClose}
          m="0.5rem"
          pos="absolute"
          right={0}
          top={0}
        />
        {rightSideDecoration}
      </Stack>
    </Group>
  );
};
