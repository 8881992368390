/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum QualifyingLifeEventType {
  MARRIED = 'MARRIED',
  DIVORCED = 'DIVORCED',
  NEW_DEPENDENT = 'NEW_DEPENDENT',
  DEATH_IN_FAMILY = 'DEATH_IN_FAMILY',
  RELOCATION = 'RELOCATION',
  ELIGIBILITY = 'ELIGIBILITY',
  OTHER = 'OTHER',
}
