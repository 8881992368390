import { useQuery } from '@tanstack/react-query';
import { getEmployerLogo } from '@zorro/shared/utils';
import { IImageProps, Image } from '@zorro/zorro-ui-design';

/* eslint-disable id-length */

// Same size as Zorro logo on the right side
const LOGO_MAX_WIDTH = 117;
const LOGO_MAX_HEIGHT = '3.5rem';

const LOGO_HEIGHT_MINUS_TOP_BAR_VERTICAL_PADDING = 56;

type Props = Omit<IImageProps, 'alt'> & {
  logoUrl: string;
};

export const EmployerLogo = ({
  logoUrl,
  maw = LOGO_MAX_WIDTH,
  mah = LOGO_MAX_HEIGHT,
  h = LOGO_HEIGHT_MINUS_TOP_BAR_VERTICAL_PADDING,
  fit = 'contain',
  flex = '1 0 auto',
  ...props
}: Props) => {
  const { data: signedLogoUrl, isLoading } = useQuery({
    enabled: Boolean(logoUrl),
    queryKey: [logoUrl],
    queryFn: async () => {
      return await getEmployerLogo(logoUrl);
    },
  });

  if (isLoading || !signedLogoUrl) {
    return null;
  }

  return (
    <Image
      h={h}
      maw={maw}
      mah={mah}
      fit={fit}
      flex={flex}
      src={signedLogoUrl}
      alt="Employer logo"
      {...props}
    />
  );
};
