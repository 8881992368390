export const StepperPartialIcon = () => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.47 4.95999C16.8557 3.73704 15.9705 2.6703 14.8819 1.84092C13.7932 1.01153 12.5298 0.44135 11.1876 0.173737C9.84542 -0.0938755 8.4599 -0.0518683 7.1364 0.296563C5.81291 0.644995 4.58632 1.29067 3.54991 2.18449C2.51351 3.0783 1.6946 4.1967 1.15547 5.45463C0.616344 6.71255 0.371198 8.07686 0.438682 9.44378C0.506166 10.8107 0.884503 12.1442 1.54491 13.3429C2.20532 14.5416 3.13041 15.5739 4.24982 16.3613L9.42773 9L17.47 4.95999Z"
        fill="#B2B2B2"
        stroke="#B2B2B2"
        strokeWidth="2"
        mask="url(#path-1-inside-1_1111_13425)"
      />
    </svg>
  );
};
