export const ClipboardIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="4.42239"
        y="0.42251"
        width="24.5056"
        height="35.4908"
        rx="2.11255"
        stroke="#3C0789"
        strokeWidth="0.845019"
      />
      <rect
        x="10.8483"
        y="5.573"
        width="24.9471"
        height="34.427"
        rx="2.60387"
        fill="#E0E3FE"
      />
      <path
        d="M15.3422 17.6214H31.3049"
        stroke="#3C0789"
        strokeWidth="0.845019"
        strokeLinecap="round"
      />
      <path
        d="M15.2672 27.7833H31.2299"
        stroke="#3C0789"
        strokeWidth="0.845019"
        strokeLinecap="round"
      />
      <path
        d="M15.2763 12.5405H31.239"
        stroke="#3C0789"
        strokeWidth="0.845019"
        strokeLinecap="round"
      />
      <path
        d="M15.3422 22.7023H31.3049"
        stroke="#3C0789"
        strokeWidth="0.845019"
        strokeLinecap="round"
      />
      <path
        d="M15.2672 32.8642H31.2299"
        stroke="#3C0789"
        strokeWidth="0.845019"
        strokeLinecap="round"
      />
    </svg>
  );
};
