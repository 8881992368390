/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum SelfReportType {
  NEW_ENTRY = 'NEW_ENTRY',
  CONFIRMED = 'CONFIRMED',
  UPDATED = 'UPDATED',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}
