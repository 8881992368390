import { IconEdit } from '@tabler/icons-react';
import { SelectedSupplementalBenefitDto } from '@zorro/clients';
import { ActionIcon, Icon } from '@zorro/zorro-ui-design';

type EditActionColumnProps = {
  onClickEdit?: (supplemental?: SelectedSupplementalBenefitDto) => void;
  supplemental?: SelectedSupplementalBenefitDto;
};

export const EditActionColumn = ({
  supplemental,
  onClickEdit,
}: EditActionColumnProps) => {
  const handleEditClick = () => {
    if (onClickEdit) {
      onClickEdit(supplemental);
    }
  };

  return (
    <ActionIcon onClick={handleEditClick} title="Edit health plan">
      <Icon icon={IconEdit} />
    </ActionIcon>
  );
};
