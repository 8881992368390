import { SelectedCardOption } from './SelectedCardOption';
import {
  CardSelectorOptionalProps,
  TCardSelectorOption,
} from './cardSelector.types';

type CardSelectorRequiredProps = {
  selectData: TCardSelectorOption[];
  selectedValue: string;
  readonly: boolean;
};

export type CardSelectorReadModeProps = CardSelectorOptionalProps &
  CardSelectorRequiredProps;

export const CardSelectorReadMode = (props: CardSelectorReadModeProps) => {
  const { selectData, selectedValue, readonly } = props;

  const option = selectData.find(({ value }) => value === selectedValue);

  if (!option) {
    return null;
  }

  return <SelectedCardOption {...option} readonly={readonly} />;
};
