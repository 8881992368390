import {
  BusinessEntityType,
  CitizenshipStatus,
  EmployerStatus,
  EmploymentType,
  InsuredSubtype,
  OEPPaymentMethod,
  QualifyingLifeEventType,
  SelfReportType,
  WageType,
} from '@zorro/clients';

import { DeepReadonly, Roles } from './types';

export const recordsPerPage = 100;

export const EMPLOYEE_BREAKDOWN_PAGE_WIDTH = 1085;
export const SHOPPING_EXPERIENCE_PAGE_WIDTH = 620;
export const LONG_PAGE_MARGIN_BOTTOM = 240;
export const APP_SHELL_PADDING = 16;
export const NAVBAR_WIDTH_EXPANDED = 272;
export const NAVBAR_WIDTH_COLLAPSED = 80;
export const NAVBAR_VERTICAL_OFFSET = 100;
export const HEADER_HEIGHT = 80;

export const IMPERSONATION_INDICATOR_HEIGHT = 28;

export const DASHBOARD_LAYOUT_PADDING = 16;

export const EMPLOYEE_SIDEBAR_ZINDEX = 50;
export const MODAL_ZINDEX = 300;
export const DRAWER_CONTENT_ZINDEX = 210;
export const DRAWER_OVERLAY_ZINDEX = 200;
export const DEPENDENT_INELIGIBLE_AGE = 26;

export const STEPPER_FLOW_STYLES = { steps: { maxWidth: '880px' } };
export const STEPPER_BODY_MAX_WIDTH = 928;

export const zorroBundleExampleRecommendations = [
  "A $0 deductible/EPO plan, because it will cover medical costs from the get-go, and so that you'd be covered by a wide network of providers",
  'Dental insurance, because your dependents are likely to need it',
  'Life and Critical Illness insurances to protect your loved ones in the event something happens to you or your spouse',
];

export enum OpenEnrollmentPeriodsRelevancyMode {
  EFFECTIVE = 'EFFECTIVE',
  ONBOARDABLE = 'ONBOARDABLE',
}

export const qualifyingLifeEventLabelConfig: DeepReadonly<{
  [key in QualifyingLifeEventType]: string;
}> = {
  [QualifyingLifeEventType.DEATH_IN_FAMILY]: 'Death in family',
  [QualifyingLifeEventType.ELIGIBILITY]: 'Child turned over 26',
  [QualifyingLifeEventType.RELOCATION]: 'Residence change',
  [QualifyingLifeEventType.NEW_DEPENDENT]: 'Birth',
  [QualifyingLifeEventType.DIVORCED]: 'Divorce',
  [QualifyingLifeEventType.MARRIED]: 'Marriage',
  [QualifyingLifeEventType.OTHER]: 'Other reason',
};

export const selfReportTypeLabelConfig: DeepReadonly<{
  [key in SelfReportType]: string;
}> = {
  [SelfReportType.NEW_ENTRY]: 'New entry',
  [SelfReportType.CONFIRMED]: 'Confirmed',
  [SelfReportType.UPDATED]: 'Updated',
  [SelfReportType.NOT_APPLICABLE]: 'Not self-reported',
};

export const businessEntityTypeLabelConfig: DeepReadonly<{
  [key in BusinessEntityType]: string;
}> = {
  [BusinessEntityType.C_CORPORATION]: 'C corporation',
  [BusinessEntityType.S_CORPORATION]: 'S corporation',
  [BusinessEntityType.GOVERNMENT_AGENCY]: 'Government agency',
  [BusinessEntityType.LIMITED_LIABILITY_CORPORATION]:
    'Limited liability corporation',
  [BusinessEntityType.LIMITED_LIABILITY_PARTNERSHIP]:
    'Limited liability partnership',
  [BusinessEntityType.NON_PROFIT_ORGANIZATION]: 'Non profit organization',
  [BusinessEntityType.OTHER]: 'Other',
  [BusinessEntityType.PARTNERSHIP]: 'Partnership',
  [BusinessEntityType.SOLE_PROPRIETORSHIP]: 'Sole proprietorship',
  [BusinessEntityType.UNION]: 'Union',
};

export const oepPaymentMethodLabelConfig: DeepReadonly<{
  [key in OEPPaymentMethod]: string;
}> = {
  [OEPPaymentMethod.ZORRO_PAY]: 'Zorro Pay',
  [OEPPaymentMethod.SELF_PAY]: 'Self-pay (reimbursement)',
};

export const citizenshipStatusLabelConfig: DeepReadonly<{
  [key in CitizenshipStatus]: string;
}> = {
  [CitizenshipStatus.RESIDENT_ALIEN]: 'Resident alien',
  [CitizenshipStatus.US_CITIZEN]: 'US citizen',
  [CitizenshipStatus.OTHER]: 'Other',
};

export const employmentTypeLabelConfig: DeepReadonly<{
  [key in EmploymentType]: string;
}> = {
  [EmploymentType.FULL_TIME]: 'Full time',
  [EmploymentType.PART_TIME]: 'Part time',
};

export const wageTypeLabelConfig: DeepReadonly<{
  [key in WageType]: string;
}> = {
  [WageType.HOURLY]: 'Hourly',
  [WageType.SALARY]: 'Salary',
};

export const insuredSubtypeLabelConfig: DeepReadonly<{
  [key in InsuredSubtype]: string;
}> = {
  [InsuredSubtype.SPOUSE]: 'Spouse',
  [InsuredSubtype.DOMESTIC_PARTNER]: 'Domestic partner',
  [InsuredSubtype.CHILD]: 'Child',
  [InsuredSubtype.OTHER_DEPENDENT]: 'Other dependent',
};

export const rolesLabelConfig: DeepReadonly<{
  [key in Roles]: string;
}> = {
  [Roles.ANY]: 'Any',
  [Roles.EMPLOYEE]: 'Employee',
  [Roles.EMPLOYER_ADMIN]: 'Admin',
  [Roles.OMNIPOTENT_ADMIN]: 'Omnipotent admin',
  [Roles.AGENT]: 'Agent',
  [Roles.AGENCY_ADMIN]: 'Agency admin',
  [Roles.ACCOUNT_SUPERVISOR]: 'Account supervisor',
  [Roles.OPERATOR]: 'Operator',
};

export const employerStatusLabelConfig: DeepReadonly<{
  [key in EmployerStatus]: string;
}> = {
  [EmployerStatus.ACTIVE]: 'Active',
  [EmployerStatus.DEACTIVATED]: 'Deactivated',
  [EmployerStatus.DRAFT]: 'Draft',
  [EmployerStatus.IN_ZORRO_SETUP]: 'In zorro setup',
  [EmployerStatus.NEEDS_REVIEW]: 'Needs review',
  [EmployerStatus.PROSPECT]: 'Prospect',
  [EmployerStatus.WAITING_FOR_EMPLOYER]: 'Waiting for employer',
};
