import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const CsvFileIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="26"
        height="32"
        viewBox="0 0 26 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.40589 1.40589C2.30606 0.505712 3.52696 0 4.8 0H16C16.4243 0 16.8313 0.168571 17.1314 0.468629L25.1314 8.46863C25.4314 8.76869 25.6 9.17565 25.6 9.6V27.2C25.6 28.473 25.0943 29.6939 24.1941 30.5941C23.2939 31.4943 22.073 32 20.8 32H4.8C3.52696 32 2.30606 31.4943 1.40589 30.5941C0.505715 29.6939 0 28.473 0 27.2V4.8C0 3.52696 0.505715 2.30606 1.40589 1.40589Z"
          fill="#00D16C"
        />
        <path
          d="M17.226 14.4688L18.9292 19.6198H18.9963L20.6962 14.4688H22L19.6929 21.013H18.2294L15.9255 14.4688H17.226Z"
          fill="white"
        />
        <path
          d="M14.2434 16.2679C14.2135 15.9888 14.0878 15.7715 13.8663 15.616C13.6469 15.4605 13.3614 15.3828 13.0099 15.3828C12.7628 15.3828 12.5508 15.42 12.374 15.4946C12.1972 15.5692 12.0619 15.6703 11.9682 15.7982C11.8745 15.926 11.8265 16.0719 11.8244 16.2359C11.8244 16.3723 11.8553 16.4905 11.9171 16.5906C11.981 16.6908 12.0673 16.776 12.1759 16.8463C12.2846 16.9144 12.4049 16.972 12.537 17.0188C12.6691 17.0657 12.8022 17.1051 12.9364 17.137L13.5499 17.2904C13.7971 17.3479 14.0346 17.4257 14.2625 17.5237C14.4926 17.6217 14.6982 17.7452 14.8792 17.8944C15.0624 18.0435 15.2073 18.2235 15.3138 18.4344C15.4203 18.6453 15.4736 18.8924 15.4736 19.1757C15.4736 19.5592 15.3756 19.8968 15.1796 20.1887C14.9836 20.4784 14.7003 20.7053 14.3296 20.8693C13.9611 21.0312 13.5148 21.1122 12.9907 21.1122C12.4816 21.1122 12.0396 21.0333 11.6646 20.8757C11.2918 20.7181 11 20.488 10.7891 20.1855C10.5803 19.883 10.4674 19.5144 10.4504 19.0799H11.6167C11.6338 19.3078 11.7041 19.4974 11.8276 19.6487C11.9512 19.7999 12.112 19.9128 12.3101 19.9874C12.5104 20.0619 12.734 20.0992 12.9812 20.0992C13.2389 20.0992 13.4647 20.0609 13.6586 19.9842C13.8546 19.9054 14.008 19.7967 14.1187 19.6582C14.2295 19.5176 14.286 19.3536 14.2881 19.1661C14.286 18.9957 14.2359 18.8551 14.1379 18.7443C14.0399 18.6314 13.9025 18.5377 13.7257 18.4632C13.551 18.3865 13.3465 18.3183 13.1122 18.2586L12.3676 18.0669C11.8287 17.9285 11.4026 17.7186 11.0895 17.4374C10.7784 17.1541 10.6229 16.7781 10.6229 16.3094C10.6229 15.9239 10.7273 15.5862 10.9361 15.2965C11.147 15.0068 11.4335 14.782 11.7957 14.6222C12.1578 14.4603 12.5679 14.3794 13.0259 14.3794C13.4903 14.3794 13.8972 14.4603 14.2465 14.6222C14.598 14.782 14.8739 15.0046 15.0742 15.2901C15.2744 15.5734 15.3777 15.8994 15.3841 16.2679H14.2434Z"
          fill="white"
        />
        <path
          d="M9.78118 16.6769H8.58609C8.552 16.4809 8.48916 16.3073 8.39756 16.1561C8.30595 16.0027 8.19198 15.8727 8.05564 15.7662C7.91931 15.6597 7.76379 15.5798 7.58911 15.5266C7.41656 15.4712 7.23016 15.4435 7.02991 15.4435C6.67415 15.4435 6.35887 15.5329 6.08406 15.7119C5.80926 15.8887 5.5941 16.1486 5.43859 16.4916C5.28308 16.8324 5.20532 17.2489 5.20532 17.741C5.20532 18.2416 5.28308 18.6634 5.43859 19.0064C5.59623 19.3472 5.81139 19.605 6.08406 19.7797C6.35887 19.9522 6.67309 20.0385 7.02672 20.0385C7.2227 20.0385 7.40591 20.0129 7.57633 19.9618C7.74888 19.9085 7.90333 19.8308 8.03967 19.7285C8.17814 19.6263 8.29424 19.5006 8.38797 19.3515C8.48383 19.2024 8.54987 19.0319 8.58609 18.8402L9.78118 18.8466C9.73644 19.1576 9.63951 19.4495 9.49039 19.7221C9.3434 19.9948 9.15061 20.2355 8.91202 20.4443C8.67343 20.651 8.39436 20.8129 8.07482 20.93C7.75527 21.0451 7.40058 21.1026 7.01074 21.1026C6.43556 21.1026 5.92216 20.9694 5.47054 20.7031C5.01892 20.4369 4.66316 20.0523 4.40327 19.5496C4.14338 19.0468 4.01343 18.444 4.01343 17.741C4.01343 17.0359 4.14444 16.433 4.40647 15.9324C4.66849 15.4296 5.02531 15.0451 5.47693 14.7788C5.92855 14.5125 6.43982 14.3794 7.01074 14.3794C7.37502 14.3794 7.71373 14.4305 8.02689 14.5328C8.34004 14.635 8.6191 14.7852 8.86409 14.9833C9.10907 15.1793 9.31038 15.42 9.46802 15.7055C9.62779 15.9888 9.73218 16.3126 9.78118 16.6769Z"
          fill="white"
        />
      </svg>
    </Center>
  );
};
