import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const TotalIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_4832_18693)">
          <ellipse cx="17.5" cy="16" rx="10.5" ry="12" fill="#FFD880" />
          <path
            d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.7333 12.0009C19.4918 11.5819 19.1409 11.2365 18.7181 11.0016C18.2954 10.7667 17.8167 10.6513 17.3333 10.6675H14.6667C13.9594 10.6675 13.2811 10.9485 12.781 11.4486C12.281 11.9487 12 12.6269 12 13.3342C12 14.0414 12.281 14.7197 12.781 15.2198C13.2811 15.7199 13.9594 16.0009 14.6667 16.0009H17.3333C18.0406 16.0009 18.7189 16.2818 19.219 16.7819C19.719 17.282 20 17.9603 20 18.6675C20 19.3748 19.719 20.053 19.219 20.5531C18.7189 21.0532 18.0406 21.3342 17.3333 21.3342H14.6667C14.1833 21.3505 13.7046 21.235 13.2819 21.0001C12.8591 20.7653 12.5082 20.4198 12.2667 20.0009"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 8V10.6667M16 21.3333V24"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_4832_18693">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
