import { Radio, RadioProps } from '@mantine/core';
import { ForwardedRef, forwardRef } from 'react';

import classNames from './Radio.module.scss';

export interface IRadioProps extends RadioProps {
  shouldHaveBorder?: boolean;
}

export const ZorroRadio = forwardRef(
  (
    {
      value,
      disabled,
      checked,
      wrapperProps,
      shouldHaveBorder = true,
      size = 'sm',
      variant = 'filled',
      ...props
    }: IRadioProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <Radio
        ref={ref}
        variant={variant}
        value={value}
        checked={checked}
        disabled={disabled}
        classNames={classNames}
        size={size}
        wrapperProps={{
          ...wrapperProps,
          'data-with-border': shouldHaveBorder,
          'data-disabled': disabled,
          'data-checked': checked,
        }}
        {...props}
      />
    );
  }
);

ZorroRadio.displayName = 'ZorroRadio';
