import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const PayrollReportCalendarIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="104"
        height="104"
        viewBox="0 0 104 104"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_4157_21941)">
          <rect x="18" y="22" width="51" height="69" rx="4" fill="#80E8B6" />
          <path
            d="M49.8335 91H26.0002C23.7016 91 21.4972 90.0869 19.8719 88.4616C18.2466 86.8363 17.3335 84.6319 17.3335 82.3333V30.3333C17.3335 28.0348 18.2466 25.8304 19.8719 24.2051C21.4972 22.5797 23.7016 21.6667 26.0002 21.6667H78.0002C80.2987 21.6667 82.5031 22.5797 84.1284 24.2051C85.7537 25.8304 86.6668 28.0348 86.6668 30.3333V56.3333"
            stroke="#1A1721"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M69.3335 13V30.3333"
            stroke="#1A1721"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M34.6665 13V30.3333"
            stroke="#1A1721"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.3335 47.6667H86.6668"
            stroke="#1A1721"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g clipPath="url(#clip1_4157_21941)">
            <path
              d="M71.75 81.1667C71.75 84.6187 77.3458 87.4167 84.25 87.4167C91.1542 87.4167 96.75 84.6187 96.75 81.1667C96.75 77.7146 91.1542 74.9167 84.25 74.9167C77.3458 74.9167 71.75 77.7146 71.75 81.1667Z"
              stroke="#1A1721"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M71.75 81.1667V89.5C71.75 92.95 77.3458 95.75 84.25 95.75C91.1542 95.75 96.75 92.95 96.75 89.5V81.1667"
              stroke="#1A1721"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M59.25 64.5C59.25 66.7333 61.6333 68.7958 65.5 69.9125C69.3667 71.0292 74.1333 71.0292 78 69.9125C81.8667 68.7958 84.25 66.7333 84.25 64.5C84.25 62.2667 81.8667 60.2042 78 59.0875C74.1333 57.9708 69.3667 57.9708 65.5 59.0875C61.6333 60.2042 59.25 62.2667 59.25 64.5Z"
              stroke="#1A1721"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M59.25 64.5V85.3333C59.25 87.1833 65.5 90.5 69 90.5"
              stroke="#1A1721"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M59.25 74.9167C59.25 76.7667 64.5 80 68 80.5"
              stroke="#1A1721"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_4157_21941">
            <rect width="104" height="104" fill="white" />
          </clipPath>
          <clipPath id="clip1_4157_21941">
            <rect
              width="50"
              height="50"
              fill="white"
              transform="translate(53 52)"
            />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
