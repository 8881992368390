import { Menu, MenuItemProps } from '@mantine/core';
import { MouseEventHandler, forwardRef } from 'react';

type IMenuItemProps = MenuItemProps & {
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export const ZorroMenuItem = forwardRef<HTMLButtonElement, IMenuItemProps>(
  ({ onClick, ...props }, ref) => {
    return (
      <Menu.Item ref={ref} component="button" onClick={onClick} {...props} />
    );
  }
);

ZorroMenuItem.displayName = 'ZorroMenuItem';
