import { IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { useQuery } from '@tanstack/react-query';
import { UploadLogoIcon } from '@zorro/shared/assets';
import {
  ERROR_MESSAGES,
  createEmployerLogoUrl,
  downloadFile,
  getEmployerLogo,
  logger,
  showErrorNotification,
} from '@zorro/shared/utils';
import {
  Box,
  Dropzone,
  Group,
  Image,
  InputWrapper,
  Stack,
  Text,
} from '@zorro/zorro-ui-design';
import { useEffect, useState } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  FieldPath,
  UseFormWatch,
} from 'react-hook-form';

import { FullPageLoader } from '../FullPageLoader';
import { UploadedFileBox } from '../UploadedFileBox';

const TWO_MEGABYTES = 2 * 1024 * 1024;

export enum ReturnType {
  FILE = 'FILE',
  URL = 'URL',
}

export type UploadLogoFormFields = {
  logo?: string | File;
};

type Props<T extends UploadLogoFormFields> = {
  control: Control<T>;
  errors: FieldErrors<T>;
  watch: UseFormWatch<T>;
  onLogoUploaded: (logo: string | File) => void;
  onDeleteCurrentLogo: () => void;
  isRequired?: boolean;
  isDisabled?: boolean;
  returnType?: ReturnType;
};

export const UploadLogoInput = <T extends UploadLogoFormFields>({
  onDeleteCurrentLogo,
  onLogoUploaded,
  control,
  watch,
  isRequired = false,
  isDisabled = false,
  returnType = ReturnType.URL,
}: Props<T>) => {
  const logo = watch('logo' as FieldPath<T>);
  const { data: signedLogoUrl, isLoading } = useQuery({
    enabled: Boolean(logo),
    queryKey: [logo],
    queryFn: async () => {
      return typeof logo === 'string' ? await getEmployerLogo(logo) : null;
    },
  });
  const [isLogoUploadPending, setIsLogoUploadPending] = useState(false);
  const [didLogoUpdate, setDidLogoUpdate] = useState(false);
  const [imagePreview, setImagePreview] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (!isLoading && signedLogoUrl && typeof logo === 'string') {
      setImagePreview(signedLogoUrl);
    }
  }, [isLoading, signedLogoUrl, logo]);

  const handleLogoUpload = async (file: File) => {
    setIsLogoUploadPending(true);

    try {
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result as string);
        };
        reader.readAsDataURL(file);
      }

      if (returnType === ReturnType.URL) {
        const { logoUrl } = await createEmployerLogoUrl(file);
        onLogoUploaded(logoUrl);
      } else {
        onLogoUploaded(file);
      }

      setDidLogoUpdate(true);
    } catch (error) {
      logger.error(error);

      showErrorNotification({
        message: ERROR_MESSAGES.UPLOADING_EMPLOYER_LOGO_FAILED_ERROR_MESSAGE,
      });
    } finally {
      setIsLogoUploadPending(false);
    }
  };

  const isUploadDisabled = (Boolean(logo) && didLogoUpdate) || isDisabled;

  const handleDownloadClick = async () => {
    if (logo && typeof logo === 'string') {
      const signedLogoUrl = await getEmployerLogo(logo);
      const extension = logo.split('.').pop();
      downloadFile(signedLogoUrl, `currentLogo.${extension}`, false);
    }
  };

  if (isLoading) {
    return <FullPageLoader />;
  } else if (imagePreview) {
    return (
      <>
        <Text size="sm" fw={400} lts="sm">
          Company logo{isRequired ? '*' : ''}
        </Text>
        <Group
          m={0}
          w="16.875rem"
          gap={0}
          style={(theme) => ({
            border: `1px solid ${theme.colors.zorroGray[2]}`,
            borderRadius: theme.radius.sm,
          })}
        >
          <Box pos="relative" h="6.25rem" w="100%">
            <Image
              src={imagePreview}
              alt="Preview"
              style={{
                position: 'relative',
                height: '100%',
                width: '100%',
                objectFit: 'contain',
              }}
            />
          </Box>

          <UploadedFileBox
            onClickDelete={() => {
              setImagePreview(undefined);
              setDidLogoUpdate(false);
              onDeleteCurrentLogo();
            }}
            onClickDownload={handleDownloadClick}
            name={typeof logo === 'string' ? logo : logo?.name}
          />
        </Group>
      </>
    );
  }

  return (
    <Controller
      control={control}
      name={'logo' as FieldPath<T>}
      render={({ field: { ref: _ref, onBlur, ...rest } }) => (
        <InputWrapper label="Company logo" required={isRequired}>
          <Dropzone
            {...rest}
            multiple={false}
            accept={IMAGE_MIME_TYPE}
            maxSize={TWO_MEGABYTES}
            onDrop={async (files: File[]) => {
              await handleLogoUpload(files[0]);
              onBlur();
            }}
            onReject={(files) => {
              showErrorNotification({
                message: files[0].errors[0].message,
                title: files[0].errors[0].code,
              });
            }}
            loading={isLogoUploadPending}
            disabled={isUploadDisabled}
          >
            <Stack align="center" gap="xs" w="100%">
              <UploadLogoIcon />
              <Text fw={600} size="sm">
                Upload company logo
              </Text>
              <Text fw={400} c="zorroGray.4" size="xs">
                Or drag it here
              </Text>
            </Stack>
          </Dropzone>
        </InputWrapper>
      )}
    />
  );
};
