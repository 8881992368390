import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';
import classNames from './Icon.module.scss';

export const IconZorroUsers2 = ({ style, grow }: SVGIconProps) => {
  return (
    <Center style={style} classNames={classNames} data-grow={grow}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        height="32"
        width="32"
        fill="none"
      >
        <path
          d="M7.65919 4.91942C7.56778 4.33491 7.99135 3.78825 8.57977 3.72677C12.1233 3.35655 17.9665 2.59605 22.8818 6.31561C27.9949 10.1848 28.0162 14.5374 28.2733 18.8001C28.3036 19.3031 27.962 19.755 27.4699 19.8632C23.7588 20.6792 19.054 21.8998 13.9892 18.0671C9.01474 14.3029 8.26233 8.77635 7.65919 4.91942Z"
          fill="#66D8F2"
        />
        <path
          d="M18.6668 14.6667C21.6123 14.6667 24.0002 12.2789 24.0002 9.33333C24.0002 6.38781 21.6123 4 18.6668 4C15.7213 4 13.3335 6.38781 13.3335 9.33333C13.3335 12.2789 15.7213 14.6667 18.6668 14.6667Z"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.6665 28V25.3333C10.6665 23.9188 11.2284 22.5623 12.2286 21.5621C13.2288 20.5619 14.5853 20 15.9998 20H21.3332C22.7477 20 24.1042 20.5619 25.1044 21.5621C26.1046 22.5623 26.6665 23.9188 26.6665 25.3333V28"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 10C9.85577 10.2558 8.84159 10.8369 8.11735 11.6517C7.39311 12.4665 7 13.4686 7 14.5C7 15.5314 7.39311 16.5335 8.11735 17.3483C8.84159 18.1631 9.85577 18.7442 11 19"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.3335 29.134V26.4673C5.34026 25.2902 5.73628 24.1484 6.45981 23.2199C7.18334 22.2913 8.19373 21.6283 9.3335 21.334"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Center>
  );
};
