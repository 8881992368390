import {
  AnticipatedCareLevel,
  FamilyUnit,
  Gender,
  InsuredPeopleDto,
  isDefined,
} from '@zorro/clients';

import { DeepReadonly, Employee, Person, YesNo } from './types';

export interface Insured extends Person {
  gender?: Gender | null;
  residentialAddress?: string | null;
  isSmoker: YesNo;
  isPregnant?: YesNo;
  ssn?: string | null;
  anticipatedCareLevel?: AnticipatedCareLevel;
}

export interface InsuredEmployee extends Insured, Employee {
  personalEmail?: string | null;
}

interface InsuredPeople {
  employee: Person;
  spouse?: Person;
  dependents: Person[];
}

export type RecommendedBundle = {
  contribution: {
    healthPlan: {
      planId: string;
      planName: string;
      planType?: string;
      familyUnit: FamilyUnit;
      employer: {
        value: number;
        color: string;
      };
      employee: {
        value: number;
        color: string;
      };
    };
    supplementalBenefits: {
      label: string;
      employer: {
        value?: number;
        color: string;
      };
      employee: {
        value: number;
        color: string;
      };
    }[];
  };
  recommendations: string[];
};

export const employerFamilyUnitLabelConfig: DeepReadonly<{
  [key in FamilyUnit]: string;
}> = {
  EMPLOYEE_ONLY: 'Employee',
  EMPLOYEE_SPOUSE: 'Employee & spouse',
  EMPLOYEE_CHILD: 'Employee & child(ren)',
  FAMILY: 'Family',
};

export const employeeFamilyUnitLabelConfig: DeepReadonly<{
  [key in FamilyUnit]: string;
}> = {
  EMPLOYEE_ONLY: 'Me',
  EMPLOYEE_SPOUSE: 'Me & spouse',
  EMPLOYEE_CHILD: 'Me & child(ren)',
  FAMILY: 'Me, spouse & child(ren)',
};

export function getDefaultCoveredOptions(isEmployer = false) {
  const labelConfig = isEmployer
    ? employerFamilyUnitLabelConfig
    : employeeFamilyUnitLabelConfig;
  return {
    employee: {
      value: FamilyUnit.EMPLOYEE_ONLY,
      label: labelConfig.EMPLOYEE_ONLY,
      disabled: false,
    },
    employeeSpouse: {
      value: FamilyUnit.EMPLOYEE_SPOUSE,
      label: labelConfig.EMPLOYEE_SPOUSE,
      disabled: false,
    },
    employeeChildren: {
      value: FamilyUnit.EMPLOYEE_CHILD,
      label: labelConfig.EMPLOYEE_CHILD,
      disabled: false,
    },
    employeeSpouseChildren: {
      value: FamilyUnit.FAMILY,
      label: labelConfig.FAMILY,
      disabled: false,
    },
  };
}

export function getDefaultCoveredOptionsSupplementals(
  hasSpouse: boolean,
  hasDependents: boolean,
  isEdgeCase: boolean = false
) {
  if (!hasSpouse && !hasDependents && !isEdgeCase) {
    return {
      employee: {
        value: FamilyUnit.EMPLOYEE_ONLY,
        label: employerFamilyUnitLabelConfig.EMPLOYEE_ONLY,
        disabled: false,
      },
    };
  }
  if (hasSpouse && !hasDependents && !isEdgeCase) {
    return {
      employee: {
        value: FamilyUnit.EMPLOYEE_ONLY,
        label: employerFamilyUnitLabelConfig.EMPLOYEE_ONLY,
        disabled: false,
      },
      employeeSpouse: {
        value: FamilyUnit.EMPLOYEE_SPOUSE,
        label: employerFamilyUnitLabelConfig.EMPLOYEE_SPOUSE,
        disabled: false,
      },
    };
  }
  if (!hasSpouse && hasDependents && !isEdgeCase) {
    return {
      employee: {
        value: FamilyUnit.EMPLOYEE_ONLY,
        label: employerFamilyUnitLabelConfig.EMPLOYEE_ONLY,
        disabled: false,
      },
      employeeChildren: {
        value: FamilyUnit.EMPLOYEE_CHILD,
        label: employerFamilyUnitLabelConfig.EMPLOYEE_CHILD,
        disabled: false,
      },
    };
  }
  return {
    employee: {
      value: FamilyUnit.EMPLOYEE_ONLY,
      label: employerFamilyUnitLabelConfig.EMPLOYEE_ONLY,
      disabled: false,
    },
    employeeSpouse: {
      value: FamilyUnit.EMPLOYEE_SPOUSE,
      label: employerFamilyUnitLabelConfig.EMPLOYEE_SPOUSE,
      disabled: false,
    },
    employeeChildren: {
      value: FamilyUnit.EMPLOYEE_CHILD,
      label: employerFamilyUnitLabelConfig.EMPLOYEE_CHILD,
      disabled: false,
    },
    employeeSpouseChildren: {
      value: FamilyUnit.FAMILY,
      label: employerFamilyUnitLabelConfig.FAMILY,
      disabled: false,
    },
  };
}

export function getDefaultCoveredOptionsCriticalIllness(hasSpouse: boolean) {
  if (hasSpouse) {
    return {
      employee: {
        value: FamilyUnit.EMPLOYEE_ONLY,
        label: 'Employee+Child(ren)',
        disabled: false,
      },
      employeeSpouseChildren: {
        value: FamilyUnit.EMPLOYEE_SPOUSE,
        label: 'Family',
        disabled: false,
      },
    };
  }
  return {
    employee: {
      value: FamilyUnit.EMPLOYEE_ONLY,
      label: 'Employee+Child(ren)',
      disabled: false,
    },
  };
}

export function calculateFamilyUnit(insuredPeople: InsuredPeople): FamilyUnit {
  const hasSpouse = insuredPeople.spouse !== undefined;
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const hasDependents = insuredPeople.dependents?.length > 0;

  if (!(hasSpouse || hasDependents)) {
    return FamilyUnit.EMPLOYEE_ONLY;
  }
  if (!hasSpouse && hasDependents) {
    return FamilyUnit.EMPLOYEE_CHILD;
  }
  if (hasSpouse && !hasDependents) {
    return FamilyUnit.EMPLOYEE_SPOUSE;
  }
  return FamilyUnit.FAMILY;
}

export function mapInsuredIdsToNameMap(
  insured?: InsuredPeopleDto
): Map<string, string> {
  return new Map<string, string>(
    insured
      ? [insured.employee, insured.spouse, ...insured.dependents]
          .filter((insuredPerson) => isDefined(insuredPerson))
          .map(({ id, firstName, lastName }) => [
            id,
            `${firstName} ${lastName}`,
          ])
      : []
  );
}
