import { Affix, AffixProps } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { ReactElement } from 'react';

// import { brand } from '../theme';
import { IButtonProps, ZorroButton } from '../Button/Button';
import classNames from './FloatingButton.module.scss';

export type IFloatingButtonProps = {
  buttonProps?: IButtonProps;
  affixProps?: AffixProps;
  iconComponent?: ReactElement;
};

/*
 * This needs to be forwardRef() to work properly with next/link
 * See more: https://nextjs.org/docs/api-reference/next/link#if-the-child-is-a-functional-component
 */
export const ZorroFloatingButton = ({
  buttonProps,
  affixProps,
  iconComponent = <IconPlus />,
}: IFloatingButtonProps) => {
  return (
    <Affix
      {...affixProps}
      zIndex={150}
      position={affixProps?.position || { bottom: '1.55rem', right: '1.55rem' }}
    >
      <ZorroButton
        rightSection={iconComponent}
        classNames={classNames}
        {...buttonProps}
      />
    </Affix>
  );
};

ZorroFloatingButton.displayName = 'ZorroFloatingButton';
