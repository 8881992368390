import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import {
  BenefitDocumentDto,
  BenefitDocumentType,
  EmployeeDto,
  OnboardingPeriodDto,
} from '@zorro/clients';
import { formatDateISO } from '@zorro/shared/formatters';
import {
  callEndpoint,
  createBenefitDocument,
  downloadBenefitDocument,
  useForm,
  useMonolithQuery,
} from '@zorro/shared/utils';
import { FormErrorMessage, Group, Icon, Text } from '@zorro/zorro-ui-design';
import { Control, Controller } from 'react-hook-form';
import * as yup from 'yup';

import {
  CoveragePeriodInput,
  FileUploadDisplay,
  FileUploader,
  FormFooter,
  UploadedFileBox,
  useLoadingOverlay,
  useMonolithMutation,
} from '../../../index';
import { getWaiveCoverageEffectiveDates } from './waiveCoverage.utils';

const waiveCoverageSchema = yup.object({
  effectiveDates: yup
    .tuple([yup.date().required().nullable(), yup.date().required().nullable()])
    .required(),
  waiveEvidence: yup.mixed<File | BenefitDocumentDto>().nullable(),
});

type WaiveCoverageFormFields = yup.InferType<typeof waiveCoverageSchema>;

type WaiveCoverageFormSingleProps = {
  employeeId: EmployeeDto['id'];
  onboardingPeriodId: OnboardingPeriodDto['id'];
  onSuccess: () => void;
  onClose: () => void;
};

const WaiveCoverageFormSingle = ({
  employeeId,
  onboardingPeriodId,
  onSuccess,
  onClose,
}: WaiveCoverageFormSingleProps) => {
  const { startLoading, stopLoading } = useLoadingOverlay();

  const { mutate: mutateWaiveCoverage } = useMonolithMutation({
    method: 'benefitsControllerWaiveCoverage',
    successMessage: 'Waiver successfully submitted',
    shouldShowLoadingOverlay: false,
  });

  const { data: onboardingPeriod } = useMonolithQuery({
    method: 'onboardingPeriodsControllerFindOne',
    params: [employeeId, onboardingPeriodId],
  });
  const { data: employee } = useMonolithQuery({
    method: 'employeesControllerFindOne',
    params: [employeeId],
  });
  const { data: benefits } = useMonolithQuery({
    method: 'benefitsControllerGetBenefits',
    params: [onboardingPeriodId],
  });

  const allBenefitDocuments = onboardingPeriod?.benefitDocuments ?? [];
  const previousWaiveEvidence = allBenefitDocuments.find(
    ({ type }) => type === BenefitDocumentType.WAIVE
  );

  const benefitEnrollment = benefits?.majorMedicalBenefit?.benefitEnrollment;

  const { control, getValues, setValue, watch, formState } =
    useForm<WaiveCoverageFormFields>({
      mode: 'all',
      defaultValues: {
        effectiveDates: getWaiveCoverageEffectiveDates(
          employee,
          onboardingPeriod,
          benefitEnrollment
        ),
        waiveEvidence: previousWaiveEvidence || null,
      },
      resolver: yupResolver(waiveCoverageSchema),
    });

  const { isValid, errors } = formState;

  const handleSubmit = async () => {
    try {
      const { waiveEvidence, effectiveDates } = getValues();
      const [effectiveFrom, effectiveUntil] = effectiveDates || [];

      if (!effectiveFrom || !effectiveUntil) {
        return;
      }

      startLoading();

      const newWaiveEvidence =
        waiveEvidence && waiveEvidence instanceof File
          ? await createBenefitDocument(
              waiveEvidence,
              onboardingPeriodId,
              false,
              BenefitDocumentType.WAIVE
            )
          : undefined;

      await Promise.all(
        allBenefitDocuments
          .filter(({ type }) => type === BenefitDocumentType.WAIVE)
          .map((document) =>
            callEndpoint({
              method: 'benefitDocumentsControllerRemoveBenefitDocument',
              params: [document.id],
            })
          )
      );

      if (newWaiveEvidence) {
        await callEndpoint({
          method: 'benefitDocumentsControllerCreateBenefitDocument',
          params: [newWaiveEvidence],
        });
      }

      await mutateWaiveCoverage([
        onboardingPeriodId,
        {
          effectiveDates: {
            from: formatDateISO(effectiveFrom),
            until: formatDateISO(effectiveUntil),
          },
        },
      ]);

      onSuccess();
    } catch {
      /* empty */
    } finally {
      stopLoading();
    }
  };

  const waiveEvidenceField = watch('waiveEvidence');

  return (
    <Stack gap="xl">
      <Text c="zorroGray.4">Confirm coverage waiver details</Text>

      <Controller
        control={control}
        name="effectiveDates"
        render={({ field: { ...rest } }) => (
          <CoveragePeriodInput
            {...rest}
            label="Confirm waiver effective dates"
            onboardingPeriodId={onboardingPeriodId}
            employeeId={employeeId}
            required
          />
        )}
      />
      {!waiveEvidenceField && (
        <FileUploader
          control={control as unknown as Control}
          name="waiveEvidence"
          label="Waiver document"
          isLoading={false}
          onDrop={(files: File[], onBlur, onChange) => {
            onChange(files[0]);
            onBlur();
          }}
          buttonText="Upload document"
          zoneLabelText="Upload a document or screenshot as evidence of the waiver"
          isDisabled={Boolean(waiveEvidenceField)}
        />
      )}
      {waiveEvidenceField && (
        <UploadedFileBox
          label="Waiver document"
          onClickDelete={() =>
            setValue('waiveEvidence', null, { shouldValidate: true })
          }
        >
          {waiveEvidenceField instanceof File ? (
            <FileUploadDisplay
              fileName={`Confirmation file: ${waiveEvidenceField.name}`}
            />
          ) : (
            <FileUploadDisplay
              fileName={`Existing evidence: ${waiveEvidenceField.fileName}`}
              onClick={() =>
                downloadBenefitDocument(
                  waiveEvidenceField.documentUrl,
                  waiveEvidenceField.fileName
                )
              }
            />
          )}
        </UploadedFileBox>
      )}

      <FormErrorMessage fieldName="waiveEvidence" errors={errors} />

      <Group wrap="nowrap" gap="xs">
        <Icon icon={IconInfoCircle} />
        <Text>
          Please make sure to collect evidence of the employee's waive election
          confirmation
        </Text>
      </Group>

      <FormFooter
        primaryLabel="Submit waiver"
        primaryButtonProps={{
          onClick: handleSubmit,
          disabled: !isValid,
        }}
        secondaryLabel="Cancel"
        secondaryButtonProps={{ onClick: onClose }}
      />
    </Stack>
  );
};

export { WaiveCoverageFormSingle };
