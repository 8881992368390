import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { ReactElement } from 'react';

import { ZorroGroup } from '../Group';
import classNames from './RadixMenu.module.scss';

type Props = DropdownMenuPrimitive.DropdownMenuItemProps & {
  leftSection?: ReactElement;
};

export const RadixMenuItem = ({ leftSection, children, ...props }: Props) => {
  return (
    <DropdownMenuPrimitive.Item {...props} className={classNames.item}>
      <ZorroGroup gap="xs">
        {leftSection}
        {children}
      </ZorroGroup>
    </DropdownMenuPrimitive.Item>
  );
};
