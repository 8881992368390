import {
  CardSelectorEditMode,
  CardSelectorEditModeProps,
} from './CardSelectorEditMode';
import {
  CardSelectorReadMode,
  CardSelectorReadModeProps,
} from './CardSelectorReadMode';

type CardSelectorProps = CardSelectorReadModeProps | CardSelectorEditModeProps;

export const CardSelector = (props: CardSelectorProps) => {
  if ('selectedValue' in props) {
    return <CardSelectorReadMode {...props} />;
  }
  return <CardSelectorEditMode {...props} />;
};

CardSelector.displayName = 'CardSelector';
