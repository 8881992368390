import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const VisionIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_535_1623)">
          <path
            d="M10.6667 5.33325H8L4 18.6666V21.9999"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle cx="22" cy="21" r="6" fill="#FFD880" />
          <path
            d="M21.3335 5.33325H24.0002L28.0002 18.6666V21.9999"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.3335 21.3333H18.6668"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23.3332 26.6666C25.9105 26.6666 27.9998 24.5772 27.9998 21.9999C27.9998 19.4226 25.9105 17.3333 23.3332 17.3333C20.7558 17.3333 18.6665 19.4226 18.6665 21.9999C18.6665 24.5772 20.7558 26.6666 23.3332 26.6666Z"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.66667 26.6666C11.244 26.6666 13.3333 24.5772 13.3333 21.9999C13.3333 19.4226 11.244 17.3333 8.66667 17.3333C6.08934 17.3333 4 19.4226 4 21.9999C4 24.5772 6.08934 26.6666 8.66667 26.6666Z"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_535_1623">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
