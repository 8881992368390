import { getNow, parseDateISO } from '@zorro/shared/formatters';
import { EmploymentStatus } from '@zorro/types';

import { logger } from './logger';

export function isOnLeaveOfAbsence(
  leaveOfAbsenceStartDate?: string | null,
  leaveOfAbsenceEndDate?: string | null
) {
  return (
    leaveOfAbsenceStartDate &&
    leaveOfAbsenceEndDate &&
    parseDateISO(leaveOfAbsenceStartDate).isBefore(getNow(), 'day') &&
    parseDateISO(leaveOfAbsenceEndDate).isAfter(getNow(), 'day')
  );
}

export const getEmployeeEmploymentStatus = (
  id: string,
  eligibleFrom?: string | null,
  eligibleUntil?: string | null,
  leaveOfAbsenceStartDate?: string | null,
  leaveOfAbsenceEndDate?: string | null
): EmploymentStatus | null => {
  if (
    eligibleUntil &&
    parseDateISO(eligibleUntil).isSameOrBefore(getNow(), 'day')
  ) {
    return EmploymentStatus.INELIGIBLE_TERMINATED;
  }
  if (isOnLeaveOfAbsence(leaveOfAbsenceStartDate, leaveOfAbsenceEndDate)) {
    return EmploymentStatus.INELIGIBLE_ON_LEAVE;
  }
  if (
    !eligibleFrom ||
    parseDateISO(eligibleFrom).isSameOrBefore(getNow(), 'day')
  ) {
    if (!eligibleUntil) {
      if (
        !leaveOfAbsenceStartDate ||
        !leaveOfAbsenceEndDate ||
        parseDateISO(leaveOfAbsenceEndDate).isBefore(getNow(), 'day')
      ) {
        return EmploymentStatus.ELIGIBLE_EMPLOYED;
      }
      return EmploymentStatus.ELIGIBLE_UPCOMING_LEAVE;
    }
    if (parseDateISO(eligibleUntil).isAfter(getNow(), 'day')) {
      return EmploymentStatus.ELIGIBLE_UPCOMING_TERMINATION;
    }
  }
  if (eligibleFrom && parseDateISO(eligibleFrom).isAfter(getNow(), 'day')) {
    return EmploymentStatus.INELIGIBLE_WAITING;
  }
  logger.error(
    `Could not determine employee employment status for employee ${id}`
  );
  return null;
};
