import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const ADandDIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_535_1617)">
          <circle cx="11" cy="22" r="6" fill="#FFD880" />
          <path
            d="M22.6667 10.6666C24.1394 10.6666 25.3333 9.47268 25.3333 7.99992C25.3333 6.52716 24.1394 5.33325 22.6667 5.33325C21.1939 5.33325 20 6.52716 20 7.99992C20 9.47268 21.1939 10.6666 22.6667 10.6666Z"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.0002 14.6668C10.8557 14.647 9.72531 14.9222 8.71799 15.466C7.71068 16.0098 6.86036 16.8038 6.24892 17.7716C5.63749 18.7393 5.28555 19.8482 5.22699 20.9914C5.16843 22.1346 5.40524 23.2737 5.91459 24.2988C6.42395 25.324 7.18869 26.2007 8.13517 26.8446C9.08165 27.4884 10.178 27.8778 11.3186 27.975C12.4591 28.0723 13.6056 27.8742 14.6474 27.3998C15.6892 26.9255 16.5913 26.1909 17.2669 25.2668"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M25.3333 26.6666L20 19.9999H14.6667L18.6667 13.3333L13.3333 9.33325L8 10.6666"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_535_1617">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
