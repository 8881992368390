import { useRouter } from '@zorro/shared/utils';
import { useEffect, useState } from 'react';

import { ZorroBox } from '../Box';
import { ZorroFlex } from '../Flex';
import { ZorroGroup } from '../Group';
import { ZorroText } from '../Text';

export type ITabStep = {
  id: number;
  label: string;
  path: string;
};

export type TabStepperProps = {
  steps: ITabStep[];
  initialStep?: number;
};

export function ZorroTabStepper({ steps, initialStep }: TabStepperProps) {
  const { router } = useRouter();
  const maxStep = steps.length;
  const [currentStep, setCurrentStep] = useState(initialStep || 0);
  const [stepIndexToVisitStatus, setStepIndexToVisitStatus] = useState<{
    [key: number]: boolean;
  }>({});

  useEffect(() => {
    const newStepIndexToVisibilityStatus = { ...stepIndexToVisitStatus };
    newStepIndexToVisibilityStatus[currentStep] = true;
    setStepIndexToVisitStatus(newStepIndexToVisibilityStatus);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [currentStep]);

  // TODO: this doesnt work with dynamic url path segment, find a better way to initialize correct step
  // useEffect(() => {
  //   const newStepIndex = steps.findIndex(
  //     (step) => step.path === router.pathname
  //   );
  //   setCurrentStep(newStepIndex);
  //   /* eslint-disable-next-line react-hooks/exhaustive-deps */
  // }, [router.pathname]);

  const canGoToNextStep = () => currentStep + 1 <= maxStep;

  const canGoToPrevStep = () => currentStep - 1 >= 0;

  const setStep = (step: number) => {
    const canSetStep =
      step >= 0 &&
      step <= maxStep &&
      (step === currentStep + 1 ||
        stepIndexToVisitStatus[step] ||
        step <= currentStep);

    if (canSetStep) {
      setCurrentStep(step);
      stepIndexToVisitStatus[step] = true;

      router.push(steps[step].path);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const goToNextStep = () => {
    if (canGoToNextStep()) {
      router.push(steps[currentStep + 1].path);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const goToPrevStep = () => {
    if (canGoToPrevStep()) {
      router.push(steps[currentStep - 1].path);
    }
  };

  return (
    <ZorroGroup grow style={{ alignItems: 'flex-start' }}>
      {steps.map((step) => {
        return (
          <ZorroFlex
            key={step.id}
            justify="center"
            direction="column"
            onClick={() => setStep(step.id)}
            style={{ cursor: 'pointer' }}
          >
            <ZorroBox
              h={4}
              style={(theme) => ({
                borderRadius: 2,
                backgroundColor:
                  currentStep >= step.id
                    ? theme.colors.zorroIris[9]
                    : theme.colors.zorroGray[3],
              })}
            />
            <ZorroText size="xs" fw={currentStep === step.id ? 600 : 400}>
              {step.label}
            </ZorroText>
          </ZorroFlex>
        );
      })}
    </ZorroGroup>
  );
}
