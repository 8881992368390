import { MantineTheme } from '@mantine/core';
import {
  IconChevronDown,
  IconChevronUp,
  IconCircleCheck,
} from '@tabler/icons-react';
import { ZORRO_UI_DYNAMIC_ROUTES, useRouter } from '@zorro/shared/utils';
import {
  Collapse,
  Flex,
  Group,
  Icon,
  Text,
  ThemeIcon,
  UnstyledButton,
} from '@zorro/zorro-ui-design';
import Link from 'next/link';
import { Fragment, ReactNode, useEffect, useState } from 'react';

import { CollapsibleMainLinkContent } from './CollapsibleMainLinkContent';
import { CollapsibleSubpageProps } from './CollapsibleMainLinks';

export interface CollapsibleMainLinkProps {
  order: number;
  icon: ReactNode;
  label: string;
  subpages: CollapsibleSubpageProps[];
  currentSubPage?: CollapsibleSubpageProps;
  isParentPageOrderSmallerThanCurrent?: boolean;
}

export const CollapsibleMainLink = ({
  icon,
  label,
  subpages,
  currentSubPage,
  isParentPageOrderSmallerThanCurrent,
}: CollapsibleMainLinkProps) => {
  const { router } = useRouter();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [hoveredLink, setHoveredLink] = useState<string>();

  const isCurrentSubPagePartOfParent = currentSubPage?.parentLabel === label;

  useEffect(() => {
    if (isCurrentSubPagePartOfParent) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [isCurrentSubPagePartOfParent]);

  return (
    <>
      <UnstyledButton
        pl="xs"
        pr="md"
        py="md"
        styles={(theme) => ({
          root: {
            color: theme.colors.zorroCoal[9],
            ...(isCurrentSubPagePartOfParent
              ? {
                  backgroundColor: theme.colors.zorroIris[1],
                  borderLeft: `3px solid ${theme.colors.zorroIris[9]}`,
                }
              : {
                  borderLeft: '3px solid transparent',

                  ...(isParentPageOrderSmallerThanCurrent && {
                    opacity: 0.5,
                    cursor: 'not-allowed',
                  }),
                }),
          },
        })}
        onClick={() => setIsOpen(!isOpen)}
        disabled={isParentPageOrderSmallerThanCurrent}
      >
        <Group justify="space-between">
          <Flex align="center">
            <ThemeIcon mr="xs" style={{ background: 'transparent' }}>
              {icon}
            </ThemeIcon>
            <Text size="md">{label}</Text>
          </Flex>

          <Icon icon={isOpen ? IconChevronUp : IconChevronDown} />
        </Group>
      </UnstyledButton>
      <Collapse in={isOpen}>
        {subpages.map((page) => {
          const isCurrentSubPage = page.link === router.pathname;
          const isHsaPage =
            page.link === ZORRO_UI_DYNAMIC_ROUTES.HSA_CONTRIBUTION();

          const currentNonHsaSubPageLargerThanIteratedPage =
            currentSubPage && currentSubPage.order >= page.order && !isHsaPage;

          const isHsaAndEligiblePage = isHsaPage && page.isHsaEligible;

          return (
            <Fragment key={page.link}>
              {currentNonHsaSubPageLargerThanIteratedPage ||
              isHsaAndEligiblePage ? (
                <Link href={page.link}>
                  <UnstyledButton
                    onMouseEnter={() => setHoveredLink(page.link)}
                    onMouseLeave={() => setHoveredLink('')}
                    style={(theme: MantineTheme) => ({
                      width: '100%',
                      paddingLeft: 32,
                      paddingRight: theme.spacing.lg,
                      paddingTop: theme.spacing.sm,
                      paddingBottom: theme.spacing.sm,
                    })}
                  >
                    <Group justify="space-between">
                      <CollapsibleMainLinkContent
                        routeLabel={page.label}
                        isHovering={page.link === hoveredLink}
                        isCurrentSubPage={isCurrentSubPage}
                      />

                      {currentSubPage && currentSubPage.order > page.order && (
                        <Icon icon={IconCircleCheck} size={16} />
                      )}
                    </Group>
                  </UnstyledButton>
                </Link>
              ) : (
                <UnstyledButton
                  style={(theme: MantineTheme) => ({
                    width: '100%',
                    paddingLeft: 32,
                    paddingRight: theme.spacing.lg,
                    paddingTop: theme.spacing.sm,
                    paddingBottom: theme.spacing.sm,
                    opacity: 0.5,
                    cursor: 'not-allowed',
                  })}
                >
                  <Group justify="space-between">
                    <CollapsibleMainLinkContent
                      routeLabel={page.label}
                      isCurrentSubPage={isCurrentSubPage}
                      isHsaPage={isHsaPage}
                      isHsaEligible={page.isHsaEligible}
                    />
                  </Group>
                </UnstyledButton>
              )}
            </Fragment>
          );
        })}
      </Collapse>
    </>
  );
};

CollapsibleMainLink.displayName = 'CollapsibleMainLink';
