import { KeyedError } from '@zorro/shared/utils';
import { Stack, Table, Title } from '@zorro/zorro-ui-design';

type ErrorsTableProps = {
  tableTitle: string;
  isBulk: boolean;
  shouldHideOnSingle?: boolean;
  errors?: KeyedError[];
};

const ErrorsTable = ({
  tableTitle,
  isBulk,
  shouldHideOnSingle = true,
  errors,
}: ErrorsTableProps) => {
  const defaultIdAccessor = 'key';

  if ((shouldHideOnSingle && !isBulk) || !errors || errors.length === 0) {
    return null;
  }

  const columns = [
    { accessor: defaultIdAccessor, title: tableTitle },
    { accessor: 'error', title: 'Error message' },
  ];

  return (
    <Stack gap="xs">
      <Title c="zorroFire.7" order={3}>
        Errors
      </Title>
      <Table
        columns={columns}
        idAccessor={defaultIdAccessor}
        records={errors}
      />
    </Stack>
  );
};

export { ErrorsTable };
