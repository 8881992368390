import {
  payrollCycleLabelConfig,
  waitingPeriodLabelConfig,
} from '@zorro/shared/utils';

import { CompanySetupFormFields } from '../../../CompanySetupForm';
import { AddEmployerStepType } from '../../AddEmployerStep/AddEmployerUtils';
import { ReviewCard } from '../ReviewCard';

type Props = {
  isFinalizationMode: boolean;
  fields: CompanySetupFormFields;
  employerId: string;
  isValid: boolean;
};

export const CompanySetupCard = ({
  employerId,
  fields,
  isValid,
  isFinalizationMode,
}: Props) => {
  const { payrollCycle, waitingPeriod, isApplicableLargeEmployer } = fields;

  return (
    <ReviewCard
      isValid={isValid}
      title="Company setup"
      employerId={employerId}
      step={AddEmployerStepType.COMPANY_SETUP}
      items={[
        {
          name: 'Payroll cycle',
          value: payrollCycle && payrollCycleLabelConfig[payrollCycle],
          isRequired: isFinalizationMode,
        },
        {
          name: 'Waiting period',
          value: waitingPeriod && waitingPeriodLabelConfig[waitingPeriod],
          isRequired: isFinalizationMode,
        },
        {
          name: 'ALE company',
          value: isApplicableLargeEmployer
            ? "Yes, It's an ALE company"
            : "No, It's not an ALE company",
          isRequired: isFinalizationMode,
        },
      ]}
    />
  );
};
