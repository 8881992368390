export const LockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <circle cx="16" cy="16" r="16" fill="#A4A5AF" />
      <path
        d="M11.6667 12.9444V10.7222C11.6667 10.1329 11.9008 9.56762 12.3175 9.15087C12.7343 8.73413 13.2995 8.5 13.8889 8.5C14.4783 8.5 15.0435 8.73413 15.4602 9.15087C15.877 9.56762 16.1111 10.1329 16.1111 10.7222V12.9444M10 14.0556C10 13.7609 10.1171 13.4783 10.3254 13.2699C10.5338 13.0615 10.8164 12.9444 11.1111 12.9444H16.6667C16.9614 12.9444 17.244 13.0615 17.4523 13.2699C17.6607 13.4783 17.7778 13.7609 17.7778 14.0556V17.3889C17.7778 17.6836 17.6607 17.9662 17.4523 18.1746C17.244 18.3829 16.9614 18.5 16.6667 18.5H11.1111C10.8164 18.5 10.5338 18.3829 10.3254 18.1746C10.1171 17.9662 10 17.6836 10 17.3889V14.0556Z"
        stroke="white"
        strokeWidth="1.36364"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="scale(1.4) translate(-2.5, -2.5)"
      />
    </svg>
  );
};
