import { fipsDatabase } from '@zorro/shared/databases';
import { ValidationIndicatorState } from '@zorro/types';

import { callEndpoint } from './callEndpoint';

export const validateAddress = async (
  address: string | undefined,
  setIsAddressValid?: (validityState: ValidationIndicatorState) => void
) => {
  if (!address) {
    return { isValidAddress: false };
  }
  setIsAddressValid?.(ValidationIndicatorState.VALIDATING);
  const { isValidAddress, isPoBox, possibleFipsCodes } = await callEndpoint({
    method: 'addressControllerValidateAddress',
    params: [{ address }],
  });
  setIsAddressValid?.(
    isValidAddress
      ? ValidationIndicatorState.VALID
      : ValidationIndicatorState.INVALID
  );
  return { isValidAddress, isPoBox, possibleFipsCodes };
};
export const fipsCodeToLabeledData = (fipsCode: string) => {
  const formattedFipsCode = fipsCode.padStart(5, '0');
  const match = fipsDatabase.find(
    (item) => item.fipsCode === formattedFipsCode
  );
  return {
    label: `${match?.county}, ${match?.state}`,
    value: fipsCode,
  };
};
