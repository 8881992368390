import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

type CopyIconProps = SVGIconProps;

export const CopyIcon = ({ style }: CopyIconProps) => {
  return (
    <Center style={style}>
      <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_344_225)">
          <path
            d="M5.83334 8.05584C5.83334 7.4664 6.0675 6.9011 6.4843 6.4843C6.9011 6.0675 7.4664 5.83334 8.05584 5.83334H15.2775C15.5694 5.83334 15.8584 5.89083 16.128 6.00252C16.3977 6.11421 16.6427 6.27792 16.8491 6.4843C17.0554 6.69068 17.2191 6.93568 17.3308 7.20533C17.4425 7.47498 17.5 7.76398 17.5 8.05584V15.2775C17.5 15.5694 17.4425 15.8584 17.3308 16.128C17.2191 16.3977 17.0554 16.6427 16.8491 16.8491C16.6427 17.0554 16.3977 17.2191 16.128 17.3308C15.8584 17.4425 15.5694 17.5 15.2775 17.5H8.05584C7.76398 17.5 7.47498 17.4425 7.20533 17.3308C6.93568 17.2191 6.69068 17.0554 6.4843 16.8491C6.27792 16.6427 6.11421 16.3977 6.00252 16.128C5.89083 15.8584 5.83334 15.5694 5.83334 15.2775V8.05584Z"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M3.34333 13.9475C3.08779 13.8018 2.87523 13.5912 2.72715 13.3371C2.57906 13.0829 2.50071 12.7942 2.5 12.5V4.16667C2.5 3.25 3.25 2.5 4.16667 2.5H12.5C13.125 2.5 13.465 2.82083 13.75 3.33333"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
            fill="none"
          />
        </g>
        <defs>
          <clipPath id="clip0_344_225">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
