import { IconChevronUp } from '@tabler/icons-react';
import { useDynamicMurrietaRouter } from '@zorro/shared/utils';
import { Center, Group, Icon, Stack, Text } from '@zorro/zorro-ui-design';
import { ElementType, Fragment, useState } from 'react';

import { useAppLayout } from '../../AppLayout';
import { EmployeeLayoutAction } from '../EmployeeLayoutActions';
import classNames from './EmployeeLayoutSidebar.module.scss';
import { EmployeeLayoutSidebarHeader } from './EmployeeLayoutSidebarHeader';
import {
  EmployeeLayoutSidebarSubitem,
  EmployeeLayoutSidebarSubitemProps,
} from './EmployeeLayoutSidebarSubitem';
import { EmployeeLayoutSidebarItemsSkeleton } from './EmployeeSidebarItemsSkeleton';

export enum EmployeeLayoutSidebarType {
  GENERAL_NOTES = 'GENERAL_NOTES',
  ENROLLMENTS = 'ENROLLMENTS',
}

export type SidebarItemProps = {
  page: EmployeeLayoutSidebarType;
  label: string;
  icon: ElementType;
  subitems: EmployeeLayoutSidebarSubitemProps[];
  onClick?: () => void;
  isChecked?: boolean;
};

type Props = {
  page: EmployeeLayoutSidebarType;
  items: SidebarItemProps[];
  employerId: string;
  employeeId: string;
  setAction: (action: EmployeeLayoutAction) => void;
  isOnboardingPeriodsLoading: boolean;
};

export const EmployeeLayoutSidebar = ({
  page: currentPage,
  items,
  employerId,
  employeeId,
  setAction,
  isOnboardingPeriodsLoading,
}: Props) => {
  const { navigateToRoute } = useDynamicMurrietaRouter();
  const { isSidebarOpen } = useAppLayout();
  const [openedPage, setOpenedPage] = useState<
    EmployeeLayoutSidebarType | undefined
  >(currentPage);

  const handleClick = ({
    page,
    subitems,
    onClick,
  }: Pick<SidebarItemProps, 'page' | 'subitems' | 'onClick'>) => {
    if (subitems.length > 0) {
      if (isSidebarOpen) {
        setOpenedPage((prev) => {
          return prev === page ? undefined : page;
        });
      } else {
        navigateToRoute('EMPLOYEE_PAGE', [{ employerId, employeeId }]);
      }
    }

    onClick?.();
  };

  return (
    <Stack gap={0}>
      {isSidebarOpen && (
        <EmployeeLayoutSidebarHeader
          setAction={setAction}
          employerId={employerId}
          employeeId={employeeId}
        />
      )}
      {isOnboardingPeriodsLoading ? (
        <EmployeeLayoutSidebarItemsSkeleton />
      ) : (
        items.map(({ page, label, icon, subitems, onClick }) => {
          const isChecked =
            subitems.length === 0 ? page === currentPage : false;
          const isOpen = page === openedPage;
          return (
            <Fragment key={page}>
              <Group
                onClick={() => handleClick({ page, subitems, onClick })}
                data-checked={isSidebarOpen ? isChecked : isOpen}
                classNames={classNames}
              >
                <Center w={22}>
                  <Icon icon={icon} />
                </Center>
                {isSidebarOpen && (
                  <Group justify="space-between" w="100%">
                    <Text>{label}</Text>
                    {subitems.length > 0 && (
                      <Icon
                        icon={IconChevronUp}
                        style={{
                          rotate: isOpen ? '-180deg' : undefined,
                          transition: 'rotate 0.25s',
                        }}
                      />
                    )}
                  </Group>
                )}
              </Group>
              {isOpen && isSidebarOpen && (
                <EmployeeLayoutSidebarSubitem subitems={subitems} />
              )}
            </Fragment>
          );
        })
      )}
    </Stack>
  );
};
