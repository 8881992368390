import { IconHeartHandshake } from '@tabler/icons-react';
import { isDevelopmentEnvironment } from '@zorro/environment';
import { useRoles } from '@zorro/shared/utils';
import {
  Drawer,
  Modal,
  brand,
  useDisclosure,
  useHotkeys,
} from '@zorro/zorro-ui-design';

import { ModalTitle } from '../ModalComponents';
import { ShareSupportInformationForm } from './ShareSupportInformationForm';
import { ShareSupportInformationOperationsForm } from './ShareSupportInformationOperationsForm';

const SupportInformation = () => {
  const { isZorroOperations } = useRoles();

  const [opened, { close, toggle }] = useDisclosure();

  useHotkeys([['mod+shift+P', toggle, { preventDefault: true }]]);

  if (typeof window === 'undefined') {
    return null;
  }

  if (isZorroOperations || isDevelopmentEnvironment()) {
    return (
      <Drawer opened={opened} title="Support Information" onClose={close}>
        <ShareSupportInformationOperationsForm onClose={close} />
      </Drawer>
    );
  }

  return (
    <Modal
      opened={opened}
      onClose={close}
      title={
        <ModalTitle
          title="Send session details to Zorro Support?"
          icon={<IconHeartHandshake color={brand.zorroCoal900} />}
          bgIcon={brand.zorroPlum100}
        />
      }
      showSeparator={false}
      size="lg"
    >
      <ShareSupportInformationForm onClose={close} />
    </Modal>
  );
};

export { SupportInformation };
