import { EMPLOYEE_DYNAMIC_ROUTES } from '@zorro/shared/utils';
import { StrictExtract } from '@zorro/types';
import { $enum } from 'ts-enum-util';

type ManualSubmissionRoutes = StrictExtract<
  typeof EMPLOYEE_DYNAMIC_ROUTES,
  | 'MANUAL_SUBMISSION_ALLOWANCE'
  | 'MANUAL_SUBMISSION_HOUSEHOLD_INFORMATION'
  | 'MANUAL_SUBMISSION_MEDICAL_PLAN'
  | 'EMPLOYEE_PAGE'
>;

export type ManualSubmissionStepSubmit = () => Promise<{
  navigate: boolean;
}>;

export enum ManualSubmissionStepType {
  HOUSEHOLD_INFORMATION = 'HOUSEHOLD_INFORMATION',
  MEDICAL_PLAN = 'MEDICAL_PLAN',
  ALLOWANCE = 'ALLOWANCE',
}

export const getManualSubmissionStepConfig = (step: ManualSubmissionStepType) =>
  $enum.mapValue(step).with<{
    nextRoute: ManualSubmissionRoutes;
    route: ManualSubmissionRoutes;
    stepIndex: number;
    label: string;
  }>({
    [ManualSubmissionStepType.HOUSEHOLD_INFORMATION]: {
      route: 'MANUAL_SUBMISSION_HOUSEHOLD_INFORMATION',
      nextRoute: 'MANUAL_SUBMISSION_MEDICAL_PLAN',
      label: 'Household information',
      stepIndex: 0,
    },
    [ManualSubmissionStepType.MEDICAL_PLAN]: {
      route: 'MANUAL_SUBMISSION_MEDICAL_PLAN',
      nextRoute: 'MANUAL_SUBMISSION_ALLOWANCE',
      label: 'Medical plan',
      stepIndex: 1,
    },
    [ManualSubmissionStepType.ALLOWANCE]: {
      route: 'MANUAL_SUBMISSION_ALLOWANCE',
      nextRoute: 'EMPLOYEE_PAGE',
      label: 'Allowance',
      stepIndex: 2,
    },
  });
