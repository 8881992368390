import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { OEPPaymentMethod, OpenEnrollmentPeriodDto } from '@zorro/clients';
import {
  formatDateISO,
  parseDate,
  parseDateISO,
} from '@zorro/shared/formatters';
import {
  ERROR_MESSAGES,
  callEndpoint,
  logger,
  responseErrorToString,
  showErrorNotification,
  showWarningNotification,
  useForm,
  useMonolithQuery,
} from '@zorro/shared/utils';
import axios from 'axios';
import csv from 'csvtojson';
import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';

import {
  PlanYearSetupFormFields,
  getPlanYearSetupSchema,
} from './PlanYearSetupUtils';

type UsePlanYearSetupForm = (props: {
  isFinalizationMode: boolean;
  shouldFallbackToEarliestPeriod?: boolean;
  selectedOpenEnrollmentPeriodId?: string;
  employerId?: string;
}) => {
  planYearSetupForm: UseFormReturn<PlanYearSetupFormFields>;
  openEnrollmentPeriodId?: string;
  onSubmit: () => Promise<boolean>;
};

export const usePlanYearSetupForm: UsePlanYearSetupForm = ({
  employerId,
  selectedOpenEnrollmentPeriodId,
  shouldFallbackToEarliestPeriod = false,
  isFinalizationMode,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const queryClient = useQueryClient();
  const { data: openEnrollmentPeriods = [] } = useMonolithQuery({
    method: 'openEnrollmentPeriodsControllerFindAllForEmployer',
    params: employerId && [employerId],
  });

  let openEnrollmentPeriod: OpenEnrollmentPeriodDto | undefined;
  if (selectedOpenEnrollmentPeriodId) {
    openEnrollmentPeriod = openEnrollmentPeriods.find(
      ({ id }) => id === selectedOpenEnrollmentPeriodId
    );
  } else if (shouldFallbackToEarliestPeriod) {
    openEnrollmentPeriod =
      openEnrollmentPeriods.length > 0
        ? openEnrollmentPeriods.reduce((prev, current) => {
            return parseDateISO(prev.effectiveFrom).isBefore(
              parseDateISO(current.onboardingFrom)
            )
              ? prev
              : current;
          })
        : undefined;
  }

  const planYearSetupForm = useForm<PlanYearSetupFormFields>({
    mode: 'all',
    resolver: yupResolver(getPlanYearSetupSchema(isFinalizationMode)),
    defaultValues: {
      effectiveDates: openEnrollmentPeriod
        ? [
            parseDateISO(openEnrollmentPeriod.effectiveFrom).toDate(),
            parseDateISO(openEnrollmentPeriod.effectiveUntil).toDate(),
          ]
        : undefined,
      oepDates: openEnrollmentPeriod
        ? [
            parseDateISO(openEnrollmentPeriod.onboardingFrom).toDate(),
            parseDateISO(openEnrollmentPeriod.onboardingUntil).toDate(),
          ]
        : undefined,
      totalPEPMFees: openEnrollmentPeriod?.totalPEPMFees ?? undefined,
      pepmBrokerCommission: openEnrollmentPeriod?.pepmCommissions ?? undefined,
      monthlyPlatformFee: openEnrollmentPeriod?.monthlyPlatformFee ?? undefined,
      initialSetupFee: openEnrollmentPeriod?.initialSetupFee ?? undefined,
      paymentMethod:
        openEnrollmentPeriod?.paymentMethod || OEPPaymentMethod.ZORRO_PAY,
      initialDrawAmount: openEnrollmentPeriod?.initialDrawAmount ?? undefined,
      initialDrawDate: openEnrollmentPeriod?.initialDrawDate
        ? parseDateISO(openEnrollmentPeriod?.initialDrawDate).toDate()
        : undefined,
      reserveAmount: openEnrollmentPeriod?.reserveAmount ?? undefined,
    },
  });

  const { watch, setValue } = planYearSetupForm;

  const effectiveDatesRange = watch('effectiveDates');
  const [effectiveFrom] = effectiveDatesRange || [];

  const { data: allowanceModel } = useMonolithQuery({
    method: 'allowanceModelControllerSearch',
    params: employerId &&
      effectiveFrom && [employerId, { year: effectiveFrom.getFullYear() }],
  });

  useEffect(() => {
    if (allowanceModel) {
      setValue('allowanceModel', allowanceModel, { shouldValidate: true });
    }
  }, [allowanceModel, setValue]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  async function onSubmit(): Promise<boolean> {
    const data = planYearSetupForm.getValues();

    const [planYearStartDate, planYearEndDate] = data.effectiveDates || [];
    const [onboardingPeriodStartDate, onboardingPeriodEndDate] =
      data.oepDates || [];

    if (!employerId) {
      showErrorNotification({
        message: 'Employer id is missing',
      });
      return false;
    }

    if (
      !planYearStartDate ||
      !planYearEndDate ||
      !onboardingPeriodStartDate ||
      !onboardingPeriodEndDate
    ) {
      showErrorNotification({
        message:
          'Election window and coverage period are required to set up a plan year',
      });
      return false;
    }

    const body = {
      effectiveFrom: formatDateISO(planYearStartDate),
      effectiveUntil: formatDateISO(planYearEndDate),
      onboardingFrom: formatDateISO(onboardingPeriodStartDate),
      onboardingUntil: formatDateISO(onboardingPeriodEndDate),
      totalPEPMFees: data.totalPEPMFees ?? undefined,
      pepmCommissions: data.pepmBrokerCommission ?? undefined,
      monthlyPlatformFee: data.monthlyPlatformFee ?? undefined,
      paymentMethod: data.paymentMethod,
      initialSetupFee: data.initialSetupFee ?? undefined,
      initialDrawAmount: data.initialDrawAmount ?? undefined,
      initialDrawDate: data.initialDrawDate
        ? formatDateISO(data.initialDrawDate)
        : null,
      reserveAmount: data.reserveAmount ?? undefined,
    };

    try {
      openEnrollmentPeriod
        ? await callEndpoint({
            method: 'openEnrollmentPeriodsControllerUpdate',
            params: [openEnrollmentPeriod.id, body],
          })
        : await callEndpoint({
            method: 'openEnrollmentPeriodsControllerCreate',
            params: [{ ...body, employerId }],
          });
    } catch (error) {
      logger.error(error);
      showErrorNotification({ message: responseErrorToString(error) });
      return false;
    }

    if (data.allowanceModel && data.allowanceModel instanceof File) {
      try {
        const contents = await data.allowanceModel.text();
        const parsedFile = await csv({ eol: '\n' }).fromString(contents);
        if (parsedFile.length === 0) {
          showErrorNotification({
            message: ERROR_MESSAGES.NO_DATA_FOUND_IN_THE_FILE_ERROR_MESSAGE,
          });
        }

        const allowanceModelPayload = {
          year: parseDate(planYearStartDate).year(),
          useExcessStipend: false,
          items: parsedFile,
        };

        const { data: uploadAllowanceModelResponse } = await axios.put(
          `/api/employers/${employerId}/upload-allowance-model`,
          allowanceModelPayload
        );

        if (uploadAllowanceModelResponse.warning) {
          showWarningNotification({
            message: uploadAllowanceModelResponse.warning,
          });
        }
      } catch (error) {
        logger.error(error);
        showErrorNotification({
          message: error.message,
        });
        return false;
      }
    }

    await queryClient.invalidateQueries();
    return true;
  }

  return {
    openEnrollmentPeriodId: openEnrollmentPeriod?.id,
    planYearSetupForm,
    onSubmit,
  };
};
