import { Card, Grid, Skeleton, Stack, brand } from '@zorro/zorro-ui-design';

export const EmployeePageSidebarHeaderSkeleton = () => {
  return (
    <Card bd="none">
      <Grid gutter="lg" mb="lg">
        <Grid.Col span={3}>
          <Skeleton h={44} w={44} circle />
        </Grid.Col>
        <Grid.Col span={9}>
          <Skeleton h={12} mb="xs" />
          <Skeleton h={12} />
        </Grid.Col>
      </Grid>
      <Grid mb="xs">
        <Grid.Col span={3}>
          <Skeleton h={12} w={100} />
        </Grid.Col>
        <Grid.Col span={3} offset={5}>
          <Skeleton h={12} w={70} />
        </Grid.Col>
      </Grid>
      <Grid mb="lg">
        <Grid.Col span={3}>
          <Skeleton h={12} w={100} />
        </Grid.Col>
        <Grid.Col span={3} offset={5}>
          <Skeleton h={12} w={70} />
        </Grid.Col>
      </Grid>
      <Stack bg={brand.zorroGray900} p="lg" style={{ borderRadius: 8 }}>
        <Grid>
          <Grid.Col span={2}>
            <Skeleton h={24} w={24} />
          </Grid.Col>
          <Grid.Col span={10}>
            <Skeleton h={12} mb="xs" />
            <Skeleton h={12} />
          </Grid.Col>
        </Grid>
      </Stack>
    </Card>
  );
};
