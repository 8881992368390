import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const UploadedGenericFile = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="18"
        height="24"
        viewBox="0 0 18 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.02806 1.05442C1.68631 0.379284 2.57909 0 3.51 0H10.98C11.2903 0 11.5879 0.126428 11.8073 0.351472L17.6573 6.35147C17.8767 6.57652 18 6.88174 18 7.2V20.4C18 21.3548 17.6302 22.2705 16.9719 22.9456C16.3137 23.6207 15.4209 24 14.49 24H3.51C2.57909 24 1.68631 23.6207 1.02806 22.9456C0.369804 22.2705 0 21.3548 0 20.4V3.6C0 2.64522 0.369804 1.72955 1.02806 1.05442Z"
          fill="#3C0789"
        />
        <path
          d="M5 8H7.33333M5 12.5H13M5 17H13"
          stroke="white"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Center>
  );
};
