/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ContactType {
  LEGAL = 'LEGAL',
  HR = 'HR',
  FINANCE = 'FINANCE',
  ONBOARDING = 'ONBOARDING',
}
