import { BusinessEntityType, EmployerDocumentDto } from '@zorro/clients';
import { VALIDATION_MESSAGES } from '@zorro/shared/utils';
import * as yup from 'yup';

import { taxIdExclusions, taxIdSchemaObject } from '../TaxIdInput';

export const getCompanyDetailsSchemaObject = (isFinalizationMode: boolean) => {
  return yup.object().shape(
    {
      name: yup.string().required(VALIDATION_MESSAGES.companyNameRequired),
      isLegalNameSameAsCompanyName: yup
        .boolean()
        .required(VALIDATION_MESSAGES.isLegalNameSameAsCompanyName),
      isMailingAddressSameAsHQAddress: yup
        .boolean()
        .required(VALIDATION_MESSAGES.isMailingAddressSameAsHQAddress),
      mailingAddress: yup.string().when('isMailingAddressSameAsHQAddress', {
        is: false,
        then: () =>
          yup.string().required(VALIDATION_MESSAGES.companyMailingAddress),
        otherwise: () => yup.string().notRequired(),
      }),
      signedAgreement: yup.mixed<File | EmployerDocumentDto>().notRequired(),
      ...(isFinalizationMode
        ? {
            legalName: yup.string().when('isLegalNameSameAsCompanyName', {
              is: false,
              then: () =>
                yup.string().required(VALIDATION_MESSAGES.legalNameRequired),
              otherwise: () => yup.string().notRequired(),
            }),
            address: yup.string().required(VALIDATION_MESSAGES.addressRequired),
            businessEntityType: yup
              .mixed<BusinessEntityType>()
              .oneOf(
                Object.values(BusinessEntityType),
                VALIDATION_MESSAGES.businessEntityType
              )
              .typeError(VALIDATION_MESSAGES.businessEntityType)
              .required(VALIDATION_MESSAGES.businessEntityType),
            taxId: taxIdSchemaObject.taxId.required(
              VALIDATION_MESSAGES.taxIdTypeRequired
            ),
            taxIdType: taxIdSchemaObject.taxIdType.required(
              VALIDATION_MESSAGES.taxIdTypeRequired
            ),
            logo: yup
              .mixed<File | string>()
              .required(VALIDATION_MESSAGES.logoUrlRequired),
          }
        : {
            legalName: yup.string(),
            address: yup.string(),
            businessEntityType: yup
              .mixed<BusinessEntityType>()
              .oneOf(
                Object.values(BusinessEntityType),
                VALIDATION_MESSAGES.businessEntityType
              )
              .typeError(VALIDATION_MESSAGES.businessEntityType)
              .nullable(),
            taxId: taxIdSchemaObject.taxId,
            taxIdType: taxIdSchemaObject.taxIdType,
            logo: yup.mixed<File | string>(),
          }),
    },
    taxIdExclusions
  );
};

export type CompanyDetailsFormFields = yup.InferType<
  ReturnType<typeof getCompanyDetailsSchemaObject>
>;
