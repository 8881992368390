const isRequired = (field: string) => {
  return `${field} is required`;
};

const alreadyExists = (field: string) => {
  return `${field} already exists`;
};

const isPositive = (field: string) => {
  return `${field} must be a positive number`;
};

const notNegative = (field: string) => {
  return `${field} cannot be a negative number`;
};

const isValid = (value: string) => {
  return `Please enter a valid ${value}`;
};

const minLetters = (field: string, amount: number) => {
  return `${field} should have at least ${amount} letters`;
};

const atLeast = (amount: string, value: string) => {
  return `Please select at least ${amount} ${value}`;
};

const greaterThan = (field: string, amount: number) => {
  return `${field} must be greater than ${amount}`;
};

/* eslint perfectionist/sort-objects: "error" */
export const PAYMENT_METHOD_VALIDATION_MESSAGES = {
  paymentMethodTypeRequired: isRequired('Payment Method type'),
  premiumAmountMoreThanZero: greaterThan('Premium amount', 0),
  premiumAmountRequired: isRequired('Premium amount'),
  selfPayAmountRequired: isRequired('Self-pay amount'),
  selfPayAmountWhenCombined:
    'Self-pay amount must be greater than 0 when Payment Method type is Combined',
  selfPayAmountWhenSelfPay:
    'Self-pay amount must equal to Premium amount when Payment Method type is Self-pay',
  selfPayAmountWhenZorroPay:
    'Self-pay amount must be 0 when Payment Method type is Zorro Pay',
  zorroPayAmountRequired: isRequired('Zorro Pay amount'),
  zorroPayAmountWhenSelfPay:
    'Zorro Pay amount must be 0 when Payment Method Type is Self-pay',
  zorroPayAmountWhenZorroPayOrCombined:
    'Zorro Pay amount must be greater than 0 when Payment Method Type is Zorro Pay or Combined',
};

/* eslint perfectionist/sort-objects: "error" */
export const VALIDATION_MESSAGES = {
  accessLevelRequired: isRequired('Access level'),
  accessLevelValid: isValid('Access level'),
  accidentalDeathAndDismembermentRequired: isRequired('AD&D'),
  accidentRequired: isRequired('Accident'),
  addressRequired: isRequired('Address'),
  agencyNameRequired: isRequired('Agency name'),
  agencyTypeRequired: isRequired('Agency type'),
  agencyTypeValid: isValid('Agency type'),
  ageRequired: isRequired('Age'),
  aleRequired: isRequired('ALE'),
  allowanceModelRequired: isRequired('Allowance model'),
  allowanceNotNegative: notNegative('Allowance'),
  allowanceRequired: isRequired('Allowance'),
  anticipatedCareLevelRequired: isRequired('AnticipatedCareLevel'),
  assignableAgenciesRequired: isRequired('Assignable agencies'),
  autoPaySetterRequired: isRequired('Auto-pay setter'),
  businessEntityType: isRequired('Business entity type'),
  carrierRequired: isRequired('Carrier'),
  censusFileRequired: isRequired('Census file'),
  citizenshipStatus: isRequired('Citizenship status'),
  classRequired: isRequired('Class'),
  commissionCannotExceedTotal: 'Commission cannot exceed total fees',
  companyMailingAddress: isRequired('Company mailing address'),
  companyNameNoSpecialCharacters:
    'Company name cannot contain special characters',
  companyNameRequired: isRequired('Company name'),
  consultationLinkMax: 'Consultation link must not exceed ${max} characters',
  consultationLinkValid: isValid('consultation link'),
  countyDisambiguity:
    'Select the county matching the provided residential address',
  coverageStartDate: isRequired('Coverage start date'),
  criticalIllnessRequired: isRequired('Critical illness'),
  crmRecordIdValid: isValid('CRM record ID'),
  dateOfBirthRequired: isRequired('Date of birth'),
  dateOfEventRequired: 'Date of event is a required field',
  dentalCareRequired: isRequired('Dental'),
  dependentMaxAgeValid: 'Dependent must be younger than 26',
  doctorsRequired: atLeast('one', 'doctor'),
  documentRequired: isRequired('Document'),
  documentUrlRequired: isRequired('Document url'),
  edgeCaseRequired: isRequired('Edge case'),
  effectiveDateRequired: isRequired('Effective date'),
  einRequired: isRequired('EIN'),
  eligibleFromRequired: isRequired('Eligibility start date'),
  eligibleFromValid: isValid('Eligibility start date'),
  emailAlreadyExists: alreadyExists('Employee email'),
  emailRequired: isRequired('Email'),
  emailsValid: 'Please enter valid emails',
  emailValid: isValid('email'),
  employeeAndChildrenRequired: isRequired(
    'Allowance for Employee and Children'
  ),
  employeeAndFamilyRequired: isRequired('Allowance for Employee and Family'),
  employeeAndSpouseRequired: isRequired('Allowance for Employee and Spouse'),
  employeeID: isRequired('Employee id'),
  employeeMaxAgeValid: 'Employee must be younger than 120',
  employeeMinAgeValid: 'Employee must be older than 18',
  employeeMonthlyContributionNotNegative: notNegative('Employee contribution'),
  employeeMonthlyContributionRequired: isRequired('Employee contribution'),
  employeeOnlyRequired: isRequired('Allowance for Employee'),
  employerMonthlyContribution: isRequired('Employer monthly contribution'),
  employerMonthlyContributionNotNegative: notNegative('Employer contribution'),
  employerMonthlyContributionRequired: isRequired('Employer contribution'),
  employerRequired: isRequired('Employer'),
  employmentTypeRequired: isRequired('Employment type'),
  enrollmentStatusRequired: isRequired('Status'),
  enrollmentTeamRequired: isRequired('Enrollment team'),
  excludeAffordability: isRequired('Exclude affordability'),
  exerciseRequired: isRequired('Exercise'),
  externalIDRequired: isRequired('Plan id'),
  facilityRequired: atLeast('one', 'facility'),
  familyHistoryRequired: isRequired('Family history'),
  familyUnitRequired: isRequired('Family Unit'),
  firstNameMinTwoLetters: minLetters('First name', 2),
  firstNameRequired: isRequired('First name'),
  fixedPlatformFee: isRequired('Fixed platform fee'),
  genderRequired: isRequired('Gender'),
  hasAutoPayBeenSetUp: isRequired('Has auto-pay been set up'),
  hasNoPaymentDocumentRequired: isRequired('Has no payment document'),
  hireDateRequired: isRequired('Hire date'),
  hireDateValid: isValid('Hire date'),
  hospitalIndemnityRequired: isRequired('Hospital indemnity'),
  initialSetupFee: isRequired('Initial setup fee'),
  isAdminRequired: isValid('Make a Zorro admin'),
  isCombinedPlanRequired: isRequired('Combined plan'),
  isLegalNameSameAsCompanyName: isRequired(
    'Legal name is the same as company name'
  ),
  isMailingAddressSameAsHQAddress: isRequired(
    'Mailing address is the same as HQ address'
  ),
  isMailingAddressSameAsResidentialAddress: isRequired(
    'Mailing address is the same as Residential address'
  ),
  ITINRequired: isRequired('ITIN'),
  lastDateOfBenefitEligibilityRequired: isRequired(
    'Last date of benefit eligibility'
  ),
  lastDateOfEmploymentRequired: isRequired('Last date of employment'),
  lastNameMinTwoLetters: minLetters('Last name', 2),
  lastNameRequired: isRequired('Last name'),
  legalNameRequired: isRequired('Legal name'),
  lifeInsuranceRequired: isRequired('Life insurance'),
  logoUrlRequired: isRequired('Logo url'),
  lossOfAbilityToWorkRequired: isRequired('Ability to work'),
  mailingAddressRequired: isRequired('Mailing address'),
  medicationRequired: atLeast('one', 'medication'),
  monthlyPlatformFee: isRequired('Monthly platform fee'),
  nonDependentMaxAgeValid: 'Non-dependent must be younger than 120',
  nonDependentMinAgeValid: 'Non-dependent must be older than 18',
  notesRequired: isRequired('Notes'),
  oepDateRequired: isRequired('OEP date'),
  optionValid: isValid('option'),
  paymentHandlerRequired: isRequired('Payment handler'),
  paymentMethodRequired: isRequired('Payment method'),
  payrollCycleRequired: isRequired('Payroll cycle'),
  pepmBrokerCommission: isRequired('PEPM broker commission'),
  pepmFee: isRequired('PEPM fee'),
  personalEmailRequired: isRequired('Personal email'),
  personalEmailValid: isValid('Personal email'),
  phoneNumberRequired: isRequired('Phone number'),
  phoneNumberValid: isValid('US phone number'),
  planDetailsCarrier: isRequired('Carrier'),
  planDetailsDeductible: isRequired('Individual deductible'),
  planDetailsMaxOutOfPocket: isRequired('Individual max out of pocket'),
  planDetailsNetworkType: isRequired('Network type'),
  planDetailsPlanName: isRequired('Plan name'),
  planMarketRequired: isRequired('Enrollment type'),
  planNameRequired: isRequired('Plan name'),
  plansDetailsDeductibleNotNegative: notNegative('Individual deductible'),
  plansDetailsMoopNotNegative: notNegative('Individual Max out of pocket'),
  pregnantRequired: isRequired('Pregnant'),
  premiumPositive: isPositive('Premium'),
  premiumRequired: isRequired('Premium'),
  producerRequired: isRequired('Producer'),
  quoteNameRequired: isRequired('Quote name'),
  quoteYearRequired: isRequired('Quote year'),
  relationshipRequired: isRequired('Relationship'),
  residentialAddressInvalid:
    "Your address couldn't be verified. Try choosing it from the search results.",
  residentialAddressRequired: isRequired('Residential address'),
  roleRequired: isRequired('Role'),
  rosterFileRequired: isRequired('Roster file'),
  selfPayPositive: isPositive('Self pay'),
  selfPayRequired: isRequired('Self pay'),
  selfReportTypeRequired: isRequired('Self report type'),
  sendInvitationRequired: isRequired('Send invitation'),
  sendInvitationValid: isValid('Send invitation'),
  shouldSendEnrollmentConfirmationEmailRequired: isRequired(
    'Send enrollment confirmation email'
  ),
  shouldSendEnrollmentConfirmationEmailValid: isValid(
    'Send enrollment confirmation email'
  ),
  signedAgreementRequired: isRequired('Signed agreement'),
  smokerRequired: isRequired('Smoker'),
  sportsRequired: isRequired('Sports'),
  ssnNumberValid: isValid('Social Security Number'),
  ssnRequired: isRequired('Social Security Number'),
  supplementalTypeRequired: isRequired('Supplemental type'),
  taxIdTypeRequired: isRequired('EIN/ITIN type'),
  totalPepmFees: isRequired('Total PEPM fees'),
  validNumber: isValid('number'),
  visionCareRequired: isRequired('Vision'),
  waitingPeriodRequired: isRequired('Waiting period'),
  waiveRequired: isRequired('Waive'),
  wasPaymentCompletedRequired: isRequired('Was the payment completed'),
  whoIsEnrolledRequired: isRequired('Who is enrolled'),
  yearlySalaryRequired: isRequired('Yearly salary'),
  yearlySalaryValid: isValid('Yearly salary'),
  zipCodeOnlyFiveDigits: 'Zip code must have 5 digits',
  zipCodeRequired: isRequired('Zip code'),
  zorroPartnerRequired: isRequired('Zorro partner'),
};
