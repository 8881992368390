import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';

import classNames from './RadixMenu.module.scss';

export const RadixMenuDivider = (
  props: DropdownMenuPrimitive.DropdownMenuSeparatorProps
) => {
  return (
    <DropdownMenuPrimitive.Separator
      {...props}
      className={classNames.separator}
    />
  );
};
