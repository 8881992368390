import { useMonolithQuery } from '@zorro/shared/utils';
import { Card, Grid, Text } from '@zorro/zorro-ui-design';

export type MajorMedicalCombinedPlanCardProps = {
  onboardingPeriodId?: string;
};

export const MajorMedicalCombinedPlanCard = ({
  onboardingPeriodId,
}: MajorMedicalCombinedPlanCardProps) => {
  const { data: benefits, isLoading } = useMonolithQuery({
    method: 'benefitsControllerGetBenefits',
    params: onboardingPeriodId && [onboardingPeriodId],
  });

  const plan = benefits?.majorMedicalBenefit?.plan;
  if (!plan || isLoading) {
    return null;
  }

  const { isCombinedPlan, extendedPlanDetails } = plan;
  if (!isCombinedPlan || !extendedPlanDetails) {
    return null;
  }

  return (
    <Card
      style={(theme) => ({
        borderColor: theme.colors.zorroGray[2],
      })}
    >
      <Grid align="center">
        <Grid.Col span={{ base: 12, md: 3.4 }}>
          <Text fw={600}>Combined plan info</Text>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 8 }}>
          <Text>{extendedPlanDetails}</Text>
        </Grid.Col>
      </Grid>
    </Card>
  );
};
