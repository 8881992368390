import { formatCurrencyEnUs } from '@zorro/shared/formatters';
import {
  Box,
  Card,
  CurrencyInput,
  FormErrorMessage,
  Grid,
  Stack,
  Text,
} from '@zorro/zorro-ui-design';
import { Controller, UseFormReturn } from 'react-hook-form';

import { PremiumAmountFormFields } from './PremiumAmountUtils';

type Props = {
  premiumAmountForm: UseFormReturn<PremiumAmountFormFields>;
  employerMonthlyContribution: number;
  shouldShowInCard?: boolean;
  isReadonlyMode?: boolean;
};

export const PremiumAmountFormInputs = ({
  premiumAmountForm,
  employerMonthlyContribution,
  shouldShowInCard,
  isReadonlyMode,
}: Props) => {
  const { control, formState, watch } = premiumAmountForm;
  const { errors } = formState;

  const { employeeMonthlyContribution, premium } = watch();

  const content = (
    <Grid>
      <Grid.Col span={{ base: 12, md: 3.4 }} pos="relative">
        <Text fw={600}>Monthly breakdown</Text>
      </Grid.Col>
      <Grid.Col span={2}>
        <Stack gap="xs">
          <Text fw={600} size="xl">
            {formatCurrencyEnUs(employerMonthlyContribution)}
          </Text>
          <Text c="zorroGray.4">Employer</Text>
        </Stack>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 1 }}>
        <Text fw={600} size="lg">
          +
        </Text>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 2 }}>
        <Stack gap="xs">
          <Text fw={600} size="xl">
            {formatCurrencyEnUs(employeeMonthlyContribution)}
          </Text>
          <Text c="zorroGray.4">Employee</Text>
        </Stack>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 1 }}>
        <Text fw={600} size="lg">
          =
        </Text>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 2 }}>
        <Stack gap="xs">
          {isReadonlyMode ? (
            <Text fw={600} size="xl">
              {formatCurrencyEnUs(premium)}
            </Text>
          ) : (
            <Controller
              control={control}
              name="premium"
              render={({ field: { ...rest } }) => (
                <CurrencyInput
                  {...rest}
                  decimalScale={2}
                  prefix="$"
                  size="sm"
                  styles={(theme) => ({
                    input: {
                      background: 'transparent',
                      fontSize: theme.fontSizes.xl,
                      color: theme.colors.zorroCoal[0],
                      fontWeight: 600,
                    },
                  })}
                />
              )}
            />
          )}
          <Text c="zorroGray.4">Total premium</Text>
        </Stack>

        <FormErrorMessage errors={errors} fieldName="premium" />
      </Grid.Col>
    </Grid>
  );

  return shouldShowInCard ? (
    <Card style={(theme) => ({ borderColor: theme.colors.zorroGray[2] })}>
      {content}
    </Card>
  ) : (
    <Box p="lg" bg="zorroGray.10">
      {content}
    </Box>
  );
};
