import {
  QueryObserverResult,
  QueryStatus,
  useQuery,
} from '@tanstack/react-query';

import {
  EndpointCall,
  MonolithMethodNames,
  MonolithMethods,
  callEndpoint,
} from '../callEndpoint';

type Falsy = false | 0 | -0 | '' | null | undefined;

type UseMonolithQueryProps<T extends MonolithMethodNames> = Omit<
  EndpointCall<T>,
  'params'
> & {
  params?: EndpointCall<T>['params'] | Falsy;
  initialData?: Awaited<ReturnType<MonolithMethods[T]>> | null;
  enabled?: boolean;
};

type UseMonolithQueryReturnType<T extends MonolithMethodNames> = {
  isLoading: boolean;
  isError: boolean;
  refetch: () => Promise<
    QueryObserverResult<Awaited<ReturnType<MonolithMethods[T]>> | undefined>
  >;
  data?: Awaited<ReturnType<MonolithMethods[T]>>;
  error?: unknown;
  status: QueryStatus;
};

export function useMonolithQuery<T extends MonolithMethodNames>({
  method,
  params,
  anonymous,
  initialData,
  enabled = true,
}: UseMonolithQueryProps<T>): UseMonolithQueryReturnType<T> {
  const { isLoading, isError, refetch, data, error, status } = useQuery({
    initialData,
    queryKey: [method, ...(params || [])],
    queryFn: async () => {
      if (params) {
        return await callEndpoint({ method, params, anonymous });
      }
      return null;
    },
    enabled,
  });

  return {
    isLoading,
    isError,
    refetch,
    data: data ?? undefined,
    error,
    status,
  } as UseMonolithQueryReturnType<T>;
}
