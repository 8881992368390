import { Center, Text } from '@zorro/zorro-ui-design';

type Props = {
  fullName: string;
};
export const AppLayoutHeaderImpersonation = ({ fullName }: Props) => {
  return (
    <Center bg="zorroIris.9" w="100vw" py="0.25rem">
      <Text c="zorroWhite.0" size="sm" fw={700}>
        Joint session:
        <Text ml="0.25rem" c="zorroWhite.0">
          browsing employee mode with {fullName}
        </Text>
      </Text>
    </Center>
  );
};
