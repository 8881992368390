import { CloseButton, CloseButtonProps } from '@mantine/core';
import {
  ComponentPropsWithoutRef,
  ElementType,
  MouseEventHandler,
  forwardRef,
} from 'react';

export type ICloseButtonProps = CloseButtonProps &
  ComponentPropsWithoutRef<ElementType> & {
    onClick?: MouseEventHandler<HTMLButtonElement>;
  };

/*
 * This needs to be forwardRef() to work properly with next/link
 * See more: https://nextjs.org/docs/api-reference/next/link#if-the-child-is-a-functional-component
 */
export const ZorroCloseButton = forwardRef<
  HTMLButtonElement,
  ICloseButtonProps
>(({ onClick, ...props }, ref) => {
  return <CloseButton onClick={onClick} ref={ref} {...props} />;
});

ZorroCloseButton.displayName = 'ZorroCloseButton';
