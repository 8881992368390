import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const UploadLogoIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.1796 2.55173C24.5122 2.43498 26.5238 4.38381 26.4875 6.71911C26.4088 11.7913 25.7418 18.5824 20.6866 23.8992C15.3515 29.5103 10.0588 29.8316 4.82986 29.5685C2.60016 29.4563 0.765762 27.5028 0.625316 25.2747C0.335984 20.6846 0.205119 14.4183 5.19527 9.16996C10.3678 3.72976 16.8982 2.81607 22.1796 2.55173Z"
          fill="#E5FAF0"
        />
        <path
          d="M26.3192 21C26.3192 21.6293 26.2013 22.1683 25.9655 22.6169C25.7317 23.0637 25.4123 23.4059 25.0074 23.6435C24.6043 23.8812 24.1471 24 23.6357 24C23.1243 24 22.6661 23.8812 22.2612 23.6435C21.8581 23.404 21.5387 23.0608 21.303 22.6141C21.0692 22.1654 20.9522 21.6274 20.9522 21C20.9522 20.3707 21.0692 19.8327 21.303 19.3859C21.5387 18.9373 21.8581 18.5941 22.2612 18.3565C22.6661 18.1188 23.1243 18 23.6357 18C24.1471 18 24.6043 18.1188 25.0074 18.3565C25.4123 18.5941 25.7317 18.9373 25.9655 19.3859C26.2013 19.8327 26.3192 20.3707 26.3192 21ZM25.2555 21C25.2555 20.557 25.1861 20.1835 25.0473 19.8793C24.9104 19.5732 24.7203 19.3422 24.4769 19.1863C24.2336 19.0285 23.9532 18.9496 23.6357 18.9496C23.3182 18.9496 23.0378 19.0285 22.7944 19.1863C22.5511 19.3422 22.36 19.5732 22.2212 19.8793C22.0844 20.1835 22.0159 20.557 22.0159 21C22.0159 21.443 22.0844 21.8175 22.2212 22.1236C22.36 22.4278 22.5511 22.6587 22.7944 22.8165C23.0378 22.9724 23.3182 23.0504 23.6357 23.0504C23.9532 23.0504 24.2336 22.9724 24.4769 22.8165C24.7203 22.6587 24.9104 22.4278 25.0473 22.1236C25.1861 21.8175 25.2555 21.443 25.2555 21Z"
          fill="#1A1721"
        />
        <path
          d="M19.273 19.9449C19.2255 19.7909 19.1599 19.653 19.0762 19.5314C18.9945 19.4078 18.8956 19.3023 18.7796 19.2148C18.6656 19.1274 18.5344 19.0618 18.3861 19.0181C18.2378 18.9724 18.0762 18.9496 17.9013 18.9496C17.5876 18.9496 17.3082 19.0285 17.0629 19.1863C16.8177 19.3441 16.6247 19.576 16.484 19.8821C16.3452 20.1863 16.2758 20.557 16.2758 20.9943C16.2758 21.4354 16.3452 21.8089 16.484 22.115C16.6228 22.4211 16.8158 22.654 17.0629 22.8137C17.3101 22.9715 17.5971 23.0504 17.9241 23.0504C18.2207 23.0504 18.4774 22.9933 18.6941 22.8793C18.9127 22.7652 19.081 22.6036 19.1988 22.3945C19.3167 22.1835 19.3756 21.9363 19.3756 21.653L19.6152 21.6901H18.0296V20.8631H20.3994V21.5646C20.3994 22.0646 20.2929 22.4971 20.08 22.8622C19.8671 23.2272 19.5743 23.5086 19.2017 23.7063C18.8291 23.9021 18.4013 24 17.9184 24C17.3804 24 16.908 23.8793 16.5011 23.6378C16.0962 23.3945 15.7796 23.0494 15.5515 22.6027C15.3253 22.154 15.2122 21.6217 15.2122 21.0057C15.2122 20.5342 15.2787 20.1131 15.4118 19.7424C15.5468 19.3717 15.735 19.057 15.9764 18.7985C16.2179 18.538 16.5011 18.3403 16.8262 18.2053C17.1513 18.0684 17.5049 18 17.8871 18C18.2102 18 18.5116 18.0475 18.791 18.1426C19.0705 18.2357 19.3186 18.3688 19.5353 18.5418C19.754 18.7148 19.9336 18.9202 20.0743 19.1578C20.215 19.3954 20.3072 19.6578 20.3509 19.9449H19.273Z"
          fill="#1A1721"
        />
        <path
          d="M14.6807 21C14.6807 21.6293 14.5628 22.1683 14.3271 22.6169C14.0932 23.0637 13.7738 23.4059 13.3689 23.6435C12.9658 23.8812 12.5086 24 11.9972 24C11.4858 24 11.0276 23.8812 10.6227 23.6435C10.2196 23.404 9.90025 23.0608 9.66451 22.6141C9.43067 22.1654 9.31375 21.6274 9.31375 21C9.31375 20.3707 9.43067 19.8327 9.66451 19.3859C9.90025 18.9373 10.2196 18.5941 10.6227 18.3565C11.0276 18.1188 11.4858 18 11.9972 18C12.5086 18 12.9658 18.1188 13.3689 18.3565C13.7738 18.5941 14.0932 18.9373 14.3271 19.3859C14.5628 19.8327 14.6807 20.3707 14.6807 21ZM13.617 21C13.617 20.557 13.5476 20.1835 13.4088 19.8793C13.2719 19.5732 13.0818 19.3422 12.8385 19.1863C12.5951 19.0285 12.3147 18.9496 11.9972 18.9496C11.6797 18.9496 11.3993 19.0285 11.156 19.1863C10.9126 19.3422 10.7215 19.5732 10.5828 19.8793C10.4459 20.1835 10.3774 20.557 10.3774 21C10.3774 21.443 10.4459 21.8175 10.5828 22.1236C10.7215 22.4278 10.9126 22.6587 11.156 22.8165C11.3993 22.9724 11.6797 23.0504 11.9972 23.0504C12.3147 23.0504 12.5951 22.9724 12.8385 22.8165C13.0818 22.6587 13.2719 22.4278 13.4088 22.1236C13.5476 21.8175 13.617 21.443 13.617 21Z"
          fill="#1A1721"
        />
        <path
          d="M5.46239 23.9202V18.0798H6.52037V23.0333H9.09261V23.9202H5.46239Z"
          fill="#1A1721"
        />
        <rect
          x="0.75"
          y="12.75"
          width="30.5"
          height="16.5"
          rx="1.25"
          stroke="#1A1721"
          strokeWidth="1.5"
        />
      </svg>
    </Center>
  );
};
