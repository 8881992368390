import { Card, Grid, Skeleton, Stack } from '@zorro/zorro-ui-design';

export const MajorMedicalPlanCardSkeleton = () => {
  return (
    <Card style={(theme) => ({ borderColor: theme.colors.zorroGray[2] })}>
      <Grid align="start" justify="space-around" gutter="lg">
        <Grid.Col span={4}>
          <Skeleton circle mb="xl" h={50} w={120} />
        </Grid.Col>
        <Grid.Col span={4}>
          <Stack>
            <Skeleton h={16} />
            <Skeleton h={16} />
          </Stack>
        </Grid.Col>
        <Grid.Col span={4}>
          <Skeleton h={16} />
        </Grid.Col>
      </Grid>
    </Card>
  );
};
