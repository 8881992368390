import Head from 'next/head';

import { AllowanceFormInputs, useAllowanceForm } from '../../AllowanceForm';
import {
  ManualSubmissionStep,
  ManualSubmissionStepSubmit,
  ManualSubmissionStepType,
} from '../ManualSubmissionStep';

type Props = {
  employerId: string;
  employeeId: string;
  onboardingPeriodId: string;
  isDon?: boolean;
};

export const ManualSubmissionAllowanceStep = ({
  employerId,
  employeeId,
  onboardingPeriodId,
  isDon = false,
}: Props) => {
  const { allowanceForm, employerMonthlyContribution, onSubmit } =
    useAllowanceForm({ employeeId, onboardingPeriodId });

  const handleStepSubmit: ManualSubmissionStepSubmit = async () => {
    if (await allowanceForm.trigger()) {
      return { navigate: await onSubmit() };
    }

    return { navigate: false };
  };

  return (
    <>
      <Head>
        <title>Allowance | Submission</title>
      </Head>
      <ManualSubmissionStep
        title="Confirm the allowance amount and check the monthly premium distribution"
        step={ManualSubmissionStepType.ALLOWANCE}
        onboardingPeriodId={onboardingPeriodId}
        submitLabel="Confirm allowance"
        onSubmit={handleStepSubmit}
        employerId={employerId}
        employeeId={employeeId}
        isDon={isDon}
      >
        <AllowanceFormInputs
          employerMonthlyContribution={employerMonthlyContribution}
          allowanceForm={allowanceForm}
        />
      </ManualSubmissionStep>
    </>
  );
};
