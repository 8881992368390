import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const ShoppingBagXIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M6 10L4 11.5L6 24L8.5 27H15.5L19.1035 10H6Z" fill="#E0E3FE" />
        <path
          d="M15.559 27H9.65765C8.70218 27 7.77829 26.6579 7.05316 26.0357C6.32802 25.4136 5.84955 24.5524 5.70432 23.608L4.03099 12.7387C3.97254 12.3586 3.99697 11.9705 4.10259 11.6008C4.20821 11.2311 4.39253 10.8886 4.64291 10.5968C4.89329 10.305 5.20381 10.0708 5.55318 9.91027C5.90254 9.74972 6.2825 9.66662 6.66699 9.66667H21.7857C22.1701 9.66662 22.5501 9.74972 22.8995 9.91027C23.2488 10.0708 23.5593 10.305 23.8097 10.5968C24.0601 10.8886 24.2444 11.2311 24.35 11.6008C24.4557 11.9705 24.4801 12.3586 24.4216 12.7387L23.747 17.1213M10.2256 13.6667V7C10.2256 5.93913 10.647 4.92172 11.3972 4.17157C12.1473 3.42143 13.1647 3 14.2256 3C15.2865 3 16.3039 3.42143 17.054 4.17157C17.8042 4.92172 18.2256 5.93913 18.2256 7V13.6667M27.5589 28.3333L20.8923 21.6667M20.8923 28.3333L27.5589 21.6667"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Center>
  );
};
