import {
  CountryCode,
  E164Number,
  parsePhoneNumber as parsePhoneNumberUtil,
} from 'libphonenumber-js';

export function parsePhoneNumber(
  phone: string,
  country: CountryCode = 'US'
): E164Number | undefined {
  try {
    const phoneNumber = parsePhoneNumberUtil(phone, country);
    /*
     *  `isValid` is too strict - it rejects some numbers that aren't against our product requirements/restrictions
     * www.npmjs.com/package/libphonenumber-js#using-phone-number-validation-feature
     */
    if (phoneNumber.isPossible()) {
      return phoneNumber.number;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error parsing phone number', error);
  }

  return undefined;
}
