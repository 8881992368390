import { Breadcrumbs, BreadcrumbsProps } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';

import { ZorroLink } from '../Link';
import { brand } from '../theme';

type BreadCrumbsItem = {
  href: string;
  title: string;
};

export interface IBreadcrumbsProps extends Omit<BreadcrumbsProps, 'children'> {
  items: BreadCrumbsItem[];
}

export function ZorroBreadcrumbs({ items, ...props }: IBreadcrumbsProps) {
  return (
    <Breadcrumbs
      separator={
        <IconChevronRight height="1.25rem" color={brand.zorroGray400} />
      }
      {...props}
    >
      {items.map((item, index) => (
        <ZorroLink
          key={item.title}
          href={item.href}
          anchorProps={{
            // eslint-disable-next-line id-length
            c: index === items.length - 1 ? 'zorroCoal.9' : 'zorroGray.4',
          }}
        >
          {item.title}
        </ZorroLink>
      ))}
    </Breadcrumbs>
  );
}
