import { RenewalStatus } from '@zorro/clients';
import { useMonolithQuery } from '@zorro/shared/utils';

import { MajorMedicalPlanCardProps } from './MajorMedicalPlanCard';

type Props = {
  employeeId: string;
  onboardingPeriodId?: string;
};

export const useMajorMedicalPlanCardQuery = ({
  employeeId,
  onboardingPeriodId,
}: Props): MajorMedicalPlanCardProps => {
  const { data: benefits, isLoading: isLoadingBenefits } = useMonolithQuery({
    method: 'benefitsControllerGetBenefits',
    params: onboardingPeriodId && [onboardingPeriodId],
  });

  const {
    data: onboardingPeriodRenewalStatus,
    isLoading: isLoadingRenewalStatus,
  } = useMonolithQuery({
    method: 'benefitsControllerGetOnboardingPeriodRenewalStatus',
    params: onboardingPeriodId && [employeeId, onboardingPeriodId],
  });

  const plan = benefits?.majorMedicalBenefit?.plan;

  return {
    name: plan?.name ?? undefined,
    carrierName: plan?.carrierName,
    externalID: plan?.externalID ?? undefined,
    planMarket: plan?.planMarket ?? undefined,
    benefitsSummaryUrl: plan?.benefitsSummaryUrl ?? undefined,
    shouldShowRenewalTag:
      onboardingPeriodRenewalStatus?.renewalStatus === RenewalStatus.RENEWED,
    isLoading: isLoadingBenefits || isLoadingRenewalStatus,
  };
};
