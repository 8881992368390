import { Styles, TagsInputFactory } from '@mantine/core';
import { VALIDATION_MESSAGES, optionalNumber } from '@zorro/shared/utils';
import { brand } from '@zorro/zorro-ui-design';
import * as yup from 'yup';

export const tagsInputStyles: Styles<TagsInputFactory> = {
  pill: {
    backgroundColor: brand.primaryButtonSelected,
    borderRadius: 4,
  },
};

export const getZorroSetupSchemaObject = (
  isFinalizationMode?: boolean,
  shouldShowCoverageStartDate?: boolean
) =>
  yup.object({
    zorroPartnerId: yup
      .string()
      .required(VALIDATION_MESSAGES.zorroPartnerRequired),
    ccEmailList: yup
      .array()
      .of(yup.string().email(VALIDATION_MESSAGES.emailsValid).required())
      .required(VALIDATION_MESSAGES.emailRequired),
    crmRecordId: yup.number().nullable().transform(optionalNumber),
    consultationLink: yup
      .string()
      .url(VALIDATION_MESSAGES.consultationLinkValid)
      .max(255, VALIDATION_MESSAGES.consultationLinkMax),
    producerAgentId: yup.string().nullable(),
    ...(isFinalizationMode
      ? {
          producerId: yup
            .string()
            .required(VALIDATION_MESSAGES.producerRequired),
          enrollmentTeamIds: yup
            .array()
            .of(yup.string().required())
            .min(1, VALIDATION_MESSAGES.enrollmentTeamRequired)
            .required(VALIDATION_MESSAGES.enrollmentTeamRequired),
        }
      : {
          producerId: yup.string().nullable(),
          enrollmentTeamIds: yup
            .array()
            .of(yup.string().required())
            .required(VALIDATION_MESSAGES.enrollmentTeamRequired),
        }),
    ...(shouldShowCoverageStartDate
      ? {
          prospectCoverageStartDate: yup
            .date()
            .required(VALIDATION_MESSAGES.coverageStartDate)
            .typeError(VALIDATION_MESSAGES.coverageStartDate),
        }
      : { prospectCoverageStartDate: yup.date() }),
  });

export type ZorroSetupFormFields = yup.InferType<
  ReturnType<typeof getZorroSetupSchemaObject>
>;
