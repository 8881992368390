import { Card, Flex, Stack, Text, brand } from '@zorro/zorro-ui-design';
import Image from 'next/image';

const PaymentMethodNotice = () => {
  return (
    <Card px="md" py="lg" bg={brand.zorroGray900} withBorder={false}>
      <Flex columnGap="xs" align="flex-start" wrap="nowrap">
        <Image
          src="icons/zorro-bulb.svg"
          alt="icon bulb"
          width="24"
          height="24"
        />
        <Stack>
          <Text size="sm">
            This payment method is set up for your healthcare premiums, funded
            by your employer. It covers both your allowance and any
            self-contributions. Please use it exclusively for premium payments
            to your carrier
          </Text>
        </Stack>
      </Flex>
    </Card>
  );
};

export { PaymentMethodNotice };
