import { ZorroCenter } from '../Center';

export const StepperCheckmarkIcon = () => {
  return (
    <ZorroCenter>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_5437_24114)">
          <path
            d="M5 12L10 17L20 7"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_5437_24114">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </ZorroCenter>
  );
};
