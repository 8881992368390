import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiError } from '@zorro/clients';
import {
  MonolithMethodNames,
  MonolithMethods,
  callEndpoint,
  responseErrorToString,
  showErrorNotification,
  showSuccessNotification,
  useDynamicRouter,
} from '@zorro/shared/utils';
import { ResultAsync } from 'neverthrow';

import { useLoadingOverlay } from '../LoadingOverlayContext';

type UseMonolithMutationProps<T extends MonolithMethodNames> = {
  method: T;
  anonymous?: boolean;
  successMessage?: string;
  shouldShowLoadingOverlay?: boolean;
  shouldInvalidateQueries?: boolean;
  shouldReloadPage?: boolean;
  displayErrorNotification?: boolean;
};

type UseMonolithMutationReturnType<T extends MonolithMethodNames> = {
  mutate: (
    params: Parameters<MonolithMethods[T]>
  ) => Promise<ReturnType<MonolithMethods[T]>>;
  tryMutate: (
    params: Parameters<MonolithMethods[T]>
  ) => ResultAsync<ReturnType<MonolithMethods[T]>, Error>;
  data?: Awaited<ReturnType<MonolithMethods[T]>>;
  isPending: boolean;
  status: 'pending' | 'error' | 'success' | 'idle';
};

export function useMonolithMutation<T extends MonolithMethodNames>({
  method,
  anonymous,
  successMessage,
  shouldReloadPage = true,
  shouldInvalidateQueries = true,
  shouldShowLoadingOverlay = true,
  displayErrorNotification = true,
}: UseMonolithMutationProps<T>): UseMonolithMutationReturnType<T> {
  const { startLoading, stopLoading } = useLoadingOverlay();
  const { reloadPage } = useDynamicRouter({});
  const queryClient = useQueryClient();
  const { mutateAsync, status, isPending, data } = useMutation<
    ReturnType<MonolithMethods[T]>,
    ApiError,
    Parameters<MonolithMethods[T]>
  >({
    mutationFn: (params) => {
      if (shouldShowLoadingOverlay) {
        startLoading();
      }
      return callEndpoint({
        method,
        anonymous,
        params,
      });
    },
    onSuccess: async () => {
      if (successMessage) {
        showSuccessNotification({ message: successMessage });
      }
      if (shouldInvalidateQueries) {
        await queryClient.invalidateQueries();
      }
      if (shouldReloadPage) {
        reloadPage();
      }
    },
    onError: (error) => {
      if (displayErrorNotification) {
        showErrorNotification({ message: responseErrorToString(error) });
      }
    },
    onSettled: () => {
      if (shouldShowLoadingOverlay) {
        stopLoading();
      }
    },
  });

  return {
    mutate: mutateAsync,
    tryMutate: ResultAsync.fromThrowable(mutateAsync),
    isPending,
    status,
    data,
  } as UseMonolithMutationReturnType<T>;
}
