import { formatDateISO, parseDateISO } from '@zorro/shared/formatters';

export function minimalDateISO(
  dateDefinitelyExists: string,
  ...additionalDates: (string | null | undefined)[]
): string {
  let minDate = parseDateISO(dateDefinitelyExists);

  for (const date of additionalDates) {
    if (date) {
      const parsedDate = parseDateISO(date);
      if (parsedDate.isBefore(minDate)) {
        minDate = parsedDate;
      }
    }
  }

  return formatDateISO(minDate);
}

export function maximalDateISO(
  dateDefinitelyExists: string,
  ...additionalDates: (string | null | undefined)[]
): string {
  let maxDate = parseDateISO(dateDefinitelyExists);

  for (const date of additionalDates) {
    if (date) {
      const parsedDate = parseDateISO(date);
      if (parsedDate.isAfter(maxDate)) {
        maxDate = parsedDate;
      }
    }
  }

  return formatDateISO(maxDate);
}
