import { Box, BoxProps } from '@mantine/core';
import { HTMLAttributes, RefAttributes, forwardRef } from 'react';

export type IBoxProps = BoxProps &
  HTMLAttributes<HTMLDivElement> &
  RefAttributes<HTMLDivElement>;

export const ZorroBox = forwardRef<HTMLDivElement, IBoxProps>(
  ({ ...props }, ref) => {
    // @ts-expect-error - remove this comment in React 19
    return <Box ref={ref} {...props} />;
  }
);

ZorroBox.displayName = 'ZorroBox';
