import { Stack } from '@mantine/core';
import { useMonolithQuery } from '@zorro/shared/utils';

import { FormFooter } from '../FormFooter';
import { FullPageLoader } from '../FullPageLoader';
import {
  InsuredFormInputs,
  ReadonlyInsuredForm,
  useInsuredForm,
} from '../InsuredForm';
import { shouldShowReadonlyMode } from '../InsuredForm/InsuredFormUtils';

type Props = {
  employerId: string;
  employeeId: string;
  onboardingPeriodId: string;
  isFinalizationMode?: boolean;
  isEmployeeMode?: boolean;
};

export const PersonalDetailsTab = ({
  employerId,
  employeeId,
  isEmployeeMode = false,
  isFinalizationMode = false,
  onboardingPeriodId,
}: Props) => {
  const insuredForm = useInsuredForm({
    onboardingPeriodId,
    employeeId,
    isFinalizationMode,
    isEmployeeMode,
  });
  const { data: onboardingPeriod, isLoading } = useMonolithQuery({
    method: 'onboardingPeriodReportsControllerGenerateOnboardingPeriodReport',
    params: [onboardingPeriodId],
  });

  if (!onboardingPeriod || isLoading) {
    return <FullPageLoader />;
  }

  const { enrollmentStatus } = onboardingPeriod;
  const isReadonlyMode = shouldShowReadonlyMode(enrollmentStatus);

  const handleCancel = () => {
    insuredForm.employeeForm.reset();
    insuredForm.spouseForm.reset();
    insuredForm.dependentsForm.reset();
  };

  const { isDirty: isEmployeeDirty } = insuredForm.employeeForm.formState;
  const { isDirty: isSpouseDirty } = insuredForm.spouseForm.formState;
  const { isDirty: isDependentsDirty } = insuredForm.dependentsForm.formState;

  const isAnyFieldTouched =
    isEmployeeDirty || isSpouseDirty || isDependentsDirty;

  return (
    <Stack maw={800} mb="5rem">
      {isReadonlyMode ? (
        <ReadonlyInsuredForm
          onboardingPeriodId={onboardingPeriodId}
          isEmployeeMode={isEmployeeMode}
          insuredForm={insuredForm}
          employeeId={employeeId}
        />
      ) : (
        <InsuredFormInputs
          isFinalizationMode={isFinalizationMode}
          isEmployeeMode={isEmployeeMode}
          insuredForm={insuredForm}
          employerId={employerId}
        />
      )}

      <FormFooter
        primaryLabel="Save"
        primaryButtonProps={{
          onClick: () => insuredForm.onSubmit(),
          disabled: !isAnyFieldTouched,
        }}
        secondaryLabel={isAnyFieldTouched ? 'Cancel' : undefined}
        secondaryButtonProps={{ onClick: handleCancel }}
      />
    </Stack>
  );
};
