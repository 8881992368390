import { TagsInput, TagsInputProps } from '@mantine/core';
import { IconCirclePlus } from '@tabler/icons-react';
import { forwardRef } from 'react';

import { ActionIcon, Icon, brand } from '../../index';
import classNames from './TagsInput.module.scss';

export type ITagsInputProps = Omit<TagsInputProps, 'value'> & {
  values?: string[];
};

export const ZorroTagsInput = forwardRef<HTMLInputElement, ITagsInputProps>(
  ({ size = 'xl', disabled = false, ...props }, ref) => {
    return (
      <TagsInput
        ref={ref}
        size={size}
        classNames={classNames}
        disabled={disabled}
        rightSection={
          <ActionIcon variant="transparent">
            <Icon
              icon={IconCirclePlus}
              size="1.25rem"
              color={brand.zorroGray400}
            />
          </ActionIcon>
        }
        {...props}
      />
    );
  }
);

ZorroTagsInput.displayName = 'ZorroTagsInput';
