import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../../types';
import classNames from './MissingDetailsIcon.module.scss';

type Props = SVGIconProps & {
  isRequired?: boolean;
};

export const MissingDetailsIcon = ({ style, isRequired }: Props) => {
  return (
    <Center style={style} classNames={classNames} data-required={isRequired}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.3482 21.9025C6.2502 21.8076 6.15399 21.7109 6.05963 21.6125C4.17005 19.6421 3.00676 16.9697 3.00003 14.0257C3.00001 14.0171 3 14.0086 3 14C3 13.9899 3.00001 13.9798 3.00004 13.9697C3.01639 7.91183 7.92955 3.00542 13.99 3C13.9933 3 13.9967 3 14 3C20.0751 3 25 7.92487 25 14C25 20.0751 20.0751 25 14 25C11.0262 25 8.32803 23.8199 6.3482 21.9025ZM8.24628 22.1799L14 14L22.9378 9.51013C23.6174 10.8602 24 12.3854 24 14C24 19.5228 19.5228 24 14 24C11.8581 24 9.87346 23.3266 8.24628 22.1799Z"
          fill="#A4A5AF"
        />
      </svg>
    </Center>
  );
};
