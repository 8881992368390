import { ZorroQuestionmarkIcon } from '@zorro/shared/assets';
import { Divider, Flex, Group, Text, Tooltip } from '@zorro/zorro-ui-design';

type CollapsibleMainLinkContentProps = {
  routeLabel: string;
  isCurrentSubPage: boolean;
  isHovering?: boolean;
  isHsaPage?: boolean;
  isHsaEligible?: boolean;
};

export const CollapsibleMainLinkContent = ({
  routeLabel,
  isCurrentSubPage,
  isHovering,
  isHsaPage,
  isHsaEligible,
}: CollapsibleMainLinkContentProps) => {
  return (
    <Flex align="center">
      <Divider
        orientation="vertical"
        color="zorroIris.9"
        h={32}
        mr="sm"
        opacity={isCurrentSubPage ? 1 : 0}
        size="md"
        style={(theme) => ({
          borderRadius: theme.radius.xl,
        })}
      />

      {isHsaPage && !isHsaEligible ? (
        <Tooltip
          label="You can contribute to HSA if you elect an HSA-eligible plan"
          position="right"
        >
          <Group gap={4}>
            <Text c="zorroGray.6">{routeLabel}</Text>
            <ZorroQuestionmarkIcon />
          </Group>
        </Tooltip>
      ) : (
        <Text
          style={{ transition: 'font-weight 0.1s ease-out' }}
          fw={isCurrentSubPage || isHovering ? 500 : undefined}
        >
          {routeLabel}
        </Text>
      )}
    </Flex>
  );
};
