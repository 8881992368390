import { Card, Grid, Skeleton } from '@zorro/zorro-ui-design';

export const EmployeeLayoutHeaderSkeleton = () => {
  return (
    <Card withBorder={false} bg="zorroGray.10" style={{ borderRadius: 8 }}>
      <Skeleton h="1.25rem" w="30%" mb="md" />
      <Grid>
        <Grid.Col span={2}>
          <Skeleton h="1.5rem" />
        </Grid.Col>
        <Grid.Col span={2}>
          <Skeleton h="1.5rem" />
        </Grid.Col>
        <Grid.Col span={2}>
          <Skeleton h="1.5rem" />
        </Grid.Col>
      </Grid>
    </Card>
  );
};
