import { EmployeeRow } from '../../EmployeesDatatable';
import { WaiveCoverageFormBulk } from './WaiveCoverageFormBulk';
import { WaiveCoverageFormSingle } from './WaiveCoverageFormSingle';

type WaiveCoverageFormProps = {
  selectedEmployees: Pick<
    EmployeeRow,
    'id' | 'onboardingPeriodId' | 'fullName'
  >[];
  onSuccess: () => void;
  onClose: () => void;
};

export const WaiveCoverageForm = ({
  selectedEmployees,
  onSuccess,
  onClose,
}: WaiveCoverageFormProps) => {
  const isSingleEmployee = selectedEmployees.length === 1;
  const singleEmployee = selectedEmployees[0];

  if (isSingleEmployee) {
    const { id, onboardingPeriodId } = singleEmployee;

    if (!onboardingPeriodId) {
      return null;
    }

    return (
      <WaiveCoverageFormSingle
        employeeId={id}
        onboardingPeriodId={onboardingPeriodId}
        onSuccess={onSuccess}
        onClose={onClose}
      />
    );
  }

  return (
    <WaiveCoverageFormBulk
      selectedEmployees={selectedEmployees}
      onSuccess={onSuccess}
      onClose={onClose}
    />
  );
};
