import { EnrollmentStatus } from '@zorro/clients';
import { useMonolithQuery } from '@zorro/shared/utils';
import { Title } from '@zorro/zorro-ui-design';
import { $enum } from 'ts-enum-util';

type Props = {
  enrollmentStatus: EnrollmentStatus;
  employeeId: string;
};

export const EnrollmentTabTitle = ({ enrollmentStatus, employeeId }: Props) => {
  const { data: employee, isLoading } = useMonolithQuery({
    method: 'employeesControllerFindOne',
    params: [employeeId],
  });

  if (!employee || isLoading) {
    return null;
  }

  const employeeName = `${employee.firstName} ${employee.lastName}`;
  const title = $enum.mapValue(enrollmentStatus).with<string>({
    [EnrollmentStatus.PENDING_ELECTION_WINDOW]: `The election window for ${employeeName} has not yet opened`,
    [EnrollmentStatus.ELECTION_ACTIVE]: `Waiting for ${employeeName} to start the election`,
    [EnrollmentStatus.ELECTION_ACTIVE_HAS_NOT_STARTED]: `Waiting for ${employeeName} to start the election`,
    [EnrollmentStatus.ELECTION_ACTIVE_STARTED]: `${employeeName} has started the election but hasn't completed it yet`,
    [EnrollmentStatus.ELECTION_SUBMITTED]: `${employeeName}'s election has been submitted and is awaiting carrier application`,
    [EnrollmentStatus.WAIVED_ELECTION]: `${employeeName} has elected to waive coverage`,
    [EnrollmentStatus.DEADLINE_PASSED]: `${employeeName} missed the deadline to submit the election`,
    [EnrollmentStatus.ENROLLMENT_CONFIRMED]: `${employeeName}'s enrollment is complete`,
    [EnrollmentStatus.CARRIER_APPLICATION_SENT]: `${employeeName}'s application was sent and is awaiting enrollment confirmation`,
    [EnrollmentStatus.COVERAGE_ENDED]: `${employeeName}'s coverage has ended`,
    [EnrollmentStatus.ACTIVE_COVERAGE]: `${employeeName}'s coverage is now active`,
    [EnrollmentStatus.WAIVED_COVERAGE]: `${employeeName}'s coverage is waived`,
    [EnrollmentStatus.NO_ENROLLMENTS]: 'No enrollments',
  });

  return (
    <Title size="h3" fw={600}>
      {title}
    </Title>
  );
};
