import { EmployerStatus } from '@zorro/clients';
import { useRoles } from '@zorro/shared/utils';

import { useCompanyDetailsForm } from '../../CompanyDetailsForm';
import { useCompanySetupForm } from '../../CompanySetupForm';
import { useContactsForm } from '../../ContactsForm';
import { usePlanYearSetupForm } from '../../PlanYearSetupForm';
import { useZorroSetupForm } from '../../ZorroSetupForm';

const isFinalizationMode = true;

type Props = {
  employerStatus: EmployerStatus | undefined;
  isFinalizationMode: boolean;
  employerId: string;
};

export const useReviewFormFinalization = ({
  isFinalizationMode: isFinalizationModeProp,
  employerStatus,
  employerId,
}: Props) => {
  const { isAgent } = useRoles();
  const { companyDetailsForm } = useCompanyDetailsForm({
    employerId,
    isFinalizationMode,
  });
  const { contactsForm } = useContactsForm(employerId, isFinalizationMode);
  const { companySetupForm } = useCompanySetupForm(
    employerId,
    isFinalizationMode
  );
  const { zorroSetupForm } = useZorroSetupForm({ employerId });
  const { planYearSetupForm } = usePlanYearSetupForm({
    shouldFallbackToEarliestPeriod: true,
    isFinalizationMode,
    employerId,
  });

  const { isValid: isCompanyDetailsValid } = companyDetailsForm.formState;
  const { isValid: isContactsValid } = contactsForm.formState;
  const { isValid: isCompanySetupValid } = companySetupForm.formState;
  const { isValid: isZorroSetupValid } = zorroSetupForm.formState;
  const { isValid: isPlanYearSetupValid } = planYearSetupForm.formState;

  return {
    shouldHideActivationButtons:
      isAgent && employerStatus === EmployerStatus.IN_ZORRO_SETUP,
    isFinalizationModeValid:
      !isFinalizationModeProp &&
      isCompanyDetailsValid &&
      isContactsValid &&
      isCompanySetupValid &&
      isZorroSetupValid &&
      isPlanYearSetupValid,
  };
};
