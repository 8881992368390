import { Badge, brand } from '@zorro/zorro-ui-design';

const InHandlingBadge = () => {
  return (
    <Badge
      size="md"
      variant="transparent"
      c="black"
      bg={brand.primaryButtonSelected}
      radius={4}
      p="4px 8px"
      h={24}
      style={{ fontSize: '14px' }}
    >
      In-handling
    </Badge>
  );
};

export { InHandlingBadge };
