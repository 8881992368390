import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const AccidentIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_535_1685)">
          <rect x="4" y="15" width="14" height="8" fill="#FF8972" />
          <path
            d="M13.3332 25.3333C14.8059 25.3333 15.9998 24.1394 15.9998 22.6667C15.9998 21.1939 14.8059 20 13.3332 20C11.8604 20 10.6665 21.1939 10.6665 22.6667C10.6665 24.1394 11.8604 25.3333 13.3332 25.3333Z"
            fill="white"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.6667 14.6667L9.33333 8H4M14.6667 14.6667H16C16.7072 14.6667 17.3855 14.9476 17.8856 15.4477C18.3857 15.9478 18.6667 16.6261 18.6667 17.3333V22.6667H16M14.6667 14.6667H4M10.6667 22.6667H4M6.66667 14.6667V8"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.6665 10.6667V8"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M25.3335 16H28.0002"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23.3335 20.6667L25.3335 22.6667"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23.3335 11.3333L25.3335 9.33325"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_535_1685">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
