import { EnrollmentStatus } from '@zorro/types';
import {
  Box,
  Card,
  DonutChart,
  Group,
  SimpleGrid,
  Text,
  brand,
} from '@zorro/zorro-ui-design';

import { enrollmentStatusConfig } from '../EnrollmentStatus';
import { EnrollmentStatusBadge } from '../EnrollmentStatus/EnrollmentStatusBadge';

const enrollmentStatusToColorMap = new Map<EnrollmentStatus, string>([
  [EnrollmentStatus.PENDING_ELECTION_WINDOW, brand.zorroGray900],
  [EnrollmentStatus.ELECTION_ACTIVE_HAS_NOT_STARTED, brand.zorroYolk200],
  [EnrollmentStatus.ELECTION_ACTIVE_STARTED, brand.zorroYolk200],
  [EnrollmentStatus.ELECTION_SUBMITTED, brand.primaryButtonSelected],
  [EnrollmentStatus.CARRIER_APPLICATION_SENT, brand.zorroSky100],
  [EnrollmentStatus.ENROLLMENT_CONFIRMED, brand.zorroGreen300],
  [EnrollmentStatus.WAIVED_ELECTION, brand.zorroGray200],
  [EnrollmentStatus.DEADLINE_PASSED, brand.zorroFire300],
]);

const statusesToDisplay = [...enrollmentStatusToColorMap.keys()];

function getBadgeAndCounter(
  enrollmentStatus: EnrollmentStatus,
  count: number = 0
) {
  return (
    <Group key={enrollmentStatus} gap="xxs" wrap="nowrap">
      <EnrollmentStatusBadge
        enrollmentStatus={enrollmentStatus}
        backgroundColor={brand.zorroWhite}
        iconSize={30}
      />
      <Text size="sm" fw="bold">
        {count}
      </Text>
    </Group>
  );
}

interface CompanyEnrollmentOverviewProps {
  title: string;
  enrollmentYear: number;
  enrollmentCounts: Record<EnrollmentStatus, number>;
}

export default function CompanyEnrollmentOverview({
  title,
  enrollmentYear,
  enrollmentCounts,
}: CompanyEnrollmentOverviewProps) {
  const enrollmentTotal = statusesToDisplay.reduce(
    (total, status) => total + (enrollmentCounts[status] || 0),
    0
  );

  return (
    <Box maw={1200}>
      <Text>
        <strong>{`${enrollmentYear} ${title} `}</strong>({enrollmentTotal}{' '}
        currently in process)
      </Text>
      <Card>
        <SimpleGrid cols={3} spacing="xl">
          <Box>
            {statusesToDisplay
              .slice(0, 4)
              .map((status) =>
                getBadgeAndCounter(status, enrollmentCounts[status])
              )}
          </Box>
          <Box>
            {statusesToDisplay
              .slice(4)
              .map((status) =>
                getBadgeAndCounter(status, enrollmentCounts[status])
              )}
          </Box>
          <DonutChart
            data={statusesToDisplay.map((status) => ({
              name: enrollmentStatusConfig[status].label,
              value: enrollmentCounts[status] ?? 0,
              color: enrollmentStatusToColorMap.get(status)!,
            }))}
            tooltipDataSource="segment"
            size={175}
            thickness={22}
            paddingAngle={2}
          />
        </SimpleGrid>
      </Card>
    </Box>
  );
}
