import { ActionIcon, ActionIconProps, ActionIconVariant } from '@mantine/core';
import { HTMLAttributes, forwardRef } from 'react';

import classNames from './ActionIcon.module.scss';

export type IActionIconProps = Omit<ActionIconProps, 'variant'> &
  HTMLAttributes<HTMLButtonElement> & {
    grow?: boolean;
    variant?: ActionIconVariant | 'circle';
    isChecked?: boolean;
  };

export const ZorroActionIcon = forwardRef<HTMLButtonElement, IActionIconProps>(
  ({ grow, disabled = false, onClick, isChecked = false, ...props }, ref) => {
    const handleClick = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      if (!disabled && onClick) {
        onClick(event);
      }
    };

    return (
      <ActionIcon
        ref={ref}
        classNames={classNames}
        data-disabled={disabled}
        data-grow={grow}
        data-checked={isChecked}
        {...props}
        onClick={handleClick}
      />
    );
  }
);

ZorroActionIcon.displayName = 'ZorroActionIcon';
