import { IconInfoCircle } from '@tabler/icons-react';
import {
  Box,
  Center,
  Grid,
  Group,
  Icon,
  Space,
  Text,
  Tooltip,
} from '@zorro/zorro-ui-design';
import { UseFormReturn } from 'react-hook-form';

import { AgentOrEmployee, AgentOrEmployeeInput } from './AgentOrEmployeeInput';
import { PaymentStatusFormFields } from './PaymentStatusUtils';
import { YesNoNotSureInput } from './YesNoNotSureInput';

type Props = {
  paymentStatusForm: UseFormReturn<PaymentStatusFormFields>;
};

export const PaymentStatusForm = ({ paymentStatusForm }: Props) => {
  const { control, watch } = paymentStatusForm;
  const initialPremiumHandler = watch('initialPremiumHandler');
  const autoPaySetter = watch('autoPaySetter');

  return (
    <>
      <Grid>
        <Grid.Col span={12}>
          <AgentOrEmployeeInput
            name="initialPremiumHandler"
            control={control}
            label="Initial payment"
            description={
              <Group gap="xxs">
                <Text size="sm">Who handles the payment?</Text>
                <Tooltip
                  label="Typically, you would handle the initial payment as the enrollment team agent. The employee handles the initial payment if you helped them enroll independently on an exchange, or if the carrier does not allow you to submit an initial payment as the Agent"
                  inline
                >
                  <Box pos="relative">
                    <Center>
                      <Icon icon={IconInfoCircle} size="1rem" />
                    </Center>
                  </Box>
                </Tooltip>
              </Group>
            }
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <YesNoNotSureInput
            name="initialPremiumCompleted"
            control={control}
            description="Was the initial payment completed?"
            shouldShowUnknown={
              initialPremiumHandler === AgentOrEmployee.EMPLOYEE
            }
          />
        </Grid.Col>
      </Grid>
      <Space h="24px" />
      <Grid>
        <Grid.Col span={12}>
          <AgentOrEmployeeInput
            name="autoPaySetter"
            control={control}
            label="Auto-pay"
            description={
              <Group gap="xxs">
                <Text size="sm">Who handles setting up auto-pay?</Text>
                <Tooltip
                  label="If the carrier does not allow you to set up auto-pay as the agent, indicate that the employee handles this step"
                  inline
                >
                  <Box pos="relative">
                    <Center>
                      <Icon icon={IconInfoCircle} size="1rem" />
                    </Center>
                  </Box>
                </Tooltip>
              </Group>
            }
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <YesNoNotSureInput
            name="autoPayCompleted"
            control={control}
            description="Has auto-pay been set up?"
            shouldShowUnknown={autoPaySetter === AgentOrEmployee.EMPLOYEE}
          />
        </Grid.Col>
      </Grid>
    </>
  );
};
