import { BenefitDocumentType } from '@zorro/clients';
import { ClipboardIcon } from '@zorro/shared/assets';
import {
  ERROR_MESSAGES,
  createBenefitDocument,
  showErrorNotification,
} from '@zorro/shared/utils';
import {
  Card,
  Dropzone,
  Stack,
  Text,
  Title,
  brand,
} from '@zorro/zorro-ui-design';

import { useLoadingOverlay } from '../LoadingOverlayContext';
import { useMonolithMutation } from '../hooks/useMonolithMutation';

export type Props = {
  onboardingPeriodId: string;
  isSharedWithEmployee: boolean;
  isDon: boolean;
};

export const EmptyFileSection = ({
  isDon,
  onboardingPeriodId,
  isSharedWithEmployee,
}: Props) => {
  const { startLoading, stopLoading } = useLoadingOverlay();

  const { mutate: createDocument } = useMonolithMutation({
    method: 'benefitDocumentsControllerCreateBenefitDocument',
    successMessage: 'Document uploaded',
    shouldShowLoadingOverlay: false,
  });
  const uploadDocument = async (file: File) => {
    startLoading();
    try {
      if (!file) {
        showErrorNotification({
          message: ERROR_MESSAGES.NO_FILE_SELECTED_ERROR_MESSAGE,
        });
        return;
      }

      const document = await createBenefitDocument(
        file,
        onboardingPeriodId,
        isSharedWithEmployee,
        BenefitDocumentType.OTHER
      );

      await createDocument([document]);
    } catch {
      /* empty */
    } finally {
      stopLoading();
    }
  };

  return isDon ? (
    <Card h="193px">
      <Stack align="center" justify="center" gap={4} w="100%" h="100%">
        <ClipboardIcon />
        <Title order={4} fw={600}>
          Nothing here yet
        </Title>
        <Title order={5} fw={400} c={brand.zorroGray400}>
          Enrollment documents will be uploaded here
        </Title>
      </Stack>
    </Card>
  ) : (
    <Card h="193px">
      <Dropzone
        w="100%"
        h="100%"
        multiple={false}
        onDrop={(files: File[]) => uploadDocument(files[0])}
        onReject={(files) => {
          showErrorNotification({
            message: files[0].errors[0].message,
            title: files[0].errors[0].code,
          });
        }}
        loading={false}
        disabled={false}
      >
        <Stack align="center" justify="center" gap={4} w="100%" h="100%">
          <ClipboardIcon />
          <Text fw={600} size="sm">
            Nothing here yet
          </Text>
          <Text fw={400} c="zorroGray.4" size="xs">
            Upload an item to get started{' '}
          </Text>
        </Stack>
      </Dropzone>
    </Card>
  );
};
