import { IconInfoCircle } from '@tabler/icons-react';
import {
  QualifyingLifeEventDto,
  QualifyingLifeEventType,
} from '@zorro/clients';
import { parseDateISOThenFormatEnLocale } from '@zorro/shared/formatters';
import { qualifyingLifeEventLabelConfig } from '@zorro/types';
import {
  Box,
  Center,
  Group,
  Icon,
  Text,
  Tooltip,
} from '@zorro/zorro-ui-design';

type Props = {
  qualifyingLifeEvent?: QualifyingLifeEventDto | null;
  shouldShowTitle?: boolean;
};

export const QualifyingLifeEventLabel = ({
  qualifyingLifeEvent,
  shouldShowTitle = true,
}: Props) => {
  if (!qualifyingLifeEvent) {
    return null;
  }

  const label = qualifyingLifeEventLabelConfig[qualifyingLifeEvent.type];
  const date = parseDateISOThenFormatEnLocale(qualifyingLifeEvent.occurredOn);
  const isOtherReason =
    qualifyingLifeEvent.type === QualifyingLifeEventType.OTHER;

  return (
    <Group gap="0.25rem">
      {shouldShowTitle && (
        <Text c="zorroGray.4" size="sm">
          QLE details
        </Text>
      )}
      <Group gap="0.25rem" align="center" justify="center">
        <Tooltip
          disabled={!isOtherReason}
          inline
          label={
            <Text fw={600} size="sm" c="zorroWhite.0">
              Employee's description:
              <Text fw={400} c="zorroWhite.0">
                {qualifyingLifeEvent.otherReason}
              </Text>
            </Text>
          }
        >
          <Box pos="relative">
            <Text size="sm" display="inline-flex">
              {label}
              {isOtherReason && (
                <Center ml="0.25rem">
                  <Icon icon={IconInfoCircle} size="1.25rem" />
                </Center>
              )}
              ,
              <Text size="sm" ml="0.25rem">
                {date}
              </Text>
            </Text>
          </Box>
        </Tooltip>
      </Group>
    </Group>
  );
};
