/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PaymentsMigrationDto = {
  status: PaymentsMigrationDto.status;
  info?: Array<string>;
};
export namespace PaymentsMigrationDto {
  export enum status {
    SUCCESS = 'success',
    FAILURE = 'failure',
  }
}
