import { Center, CenterProps } from '@mantine/core';
import { MouseEventHandler } from 'react';

export type ICenterProps = CenterProps & {
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export function ZorroCenter({ ...props }: ICenterProps) {
  return <Center {...props} />;
}
