import { BusinessEntityType } from '@zorro/clients';
import { businessEntityTypeLabelConfig } from '@zorro/types';
import { ISelectProps, Select } from '@zorro/zorro-ui-design';
import { ForwardedRef, forwardRef } from 'react';
import { ControllerRenderProps, Path } from 'react-hook-form';

const businessEntityInputOptions = Object.values(BusinessEntityType).map(
  (entity) => ({
    label: businessEntityTypeLabelConfig[entity],
    value: entity,
  })
);

type BusinessEntityFieldValues = {
  businessEntityType?: BusinessEntityType | null;
};
type Props<
  T extends BusinessEntityFieldValues,
  U extends Path<T>
> = ControllerRenderProps<T, U> & {
  selectProps?: ISelectProps;
};

export const BusinessEntityInput = forwardRef(
  <T extends BusinessEntityFieldValues, U extends Path<T>>(
    { selectProps, ...field }: Props<T, U>,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <Select
        {...field}
        {...selectProps}
        label="Business entity type"
        placeholder="Business entity type"
        data={businessEntityInputOptions}
        ref={ref}
        size="md"
      />
    );
  }
);

BusinessEntityInput.displayName = 'BusinessEntityInput';
