import { yupResolver } from '@hookform/resolvers/yup';
import { DocumentType } from '@zorro/clients';
import {
  ERROR_MESSAGES,
  VALIDATION_MESSAGES,
  createEmployerDocumentUrl,
  showErrorNotification,
  useForm,
} from '@zorro/shared/utils';
import {
  Button,
  Center,
  FormErrorMessage,
  Space,
  Stack,
} from '@zorro/zorro-ui-design';
import * as yup from 'yup';

import { FileUploader } from '../FileUploader';
import { UploadedFileBox } from '../UploadedFileBox';
import { useMonolithMutation } from '../hooks';

const uploadDocumentSchema = yup.object({
  document: yup.mixed().required(VALIDATION_MESSAGES.documentRequired),
});

export type UploadDocumentProps = {
  employerId: string;
  type: DocumentType;
  successText: string;
  onSuccess: () => Promise<void> | void;
};

export interface UploadDocumentFormFields {
  document?: File;
}

export function UploadDocumentForm({
  employerId,
  type,
  successText,
  onSuccess,
}: UploadDocumentProps) {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { isValid, isSubmitting, errors },
  } = useForm<UploadDocumentFormFields>({
    mode: 'all',
    resolver: yupResolver(uploadDocumentSchema),
    defaultValues: {
      document: undefined,
    },
  });

  const document = watch('document');

  const handleDocumentRemoval = () => {
    setValue('document', undefined, { shouldValidate: true });
  };

  const { tryMutate: createDocument } = useMonolithMutation({
    method: 'employerDocumentControllerPostDocument',
    successMessage: 'Document uploaded successfully!',
  });

  const uploadDocument = async () => {
    if (!document) {
      showErrorNotification({
        message: ERROR_MESSAGES.NO_FILE_SELECTED_ERROR_MESSAGE,
      });
      return;
    }

    const { documentUrl, fileName } = await createEmployerDocumentUrl(document);

    const createDocumentResult = await createDocument([
      employerId,
      { type, documentUrl, fileName },
    ]);

    if (createDocumentResult.isOk()) {
      await onSuccess();
    }
  };

  return (
    <form onSubmit={handleSubmit(uploadDocument)}>
      {!document && (
        <FileUploader
          control={control}
          name="document"
          label="Upload Document"
          isLoading={false}
          onDrop={(files: File[], onBlur, onChange) => {
            onChange(files[0]);
            onBlur();
          }}
          isRequired
          isMultiple={false}
          isDisabled={!!document}
          zoneLabelText="Drag file here or click to select it"
          buttonText="Upload Document"
        />
      )}
      {document && (
        <UploadedFileBox
          onClickDelete={handleDocumentRemoval}
          name={document.name}
          label="Upload Document"
          isRequired
        />
      )}
      <Space h="sm" />
      <FormErrorMessage fieldName="document" errors={errors} />
      <Space h="sm" />
      <Center>
        <Stack gap="xl" justify="center">
          <Button type="submit" disabled={!isValid || isSubmitting}>
            {successText}
          </Button>
        </Stack>
      </Center>
    </form>
  );
}
