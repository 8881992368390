import { AppShell, AppShellProps, Stack } from '@zorro/zorro-ui-design';
import { ElementType, ReactElement } from 'react';

import { CollapsibleMainLinkProps } from '../shared/CollapsibleMainLink';
import { MainLinkProps } from '../shared/MainLink';
import { MainLinks } from '../shared/MainLinks';

export interface AppShellSideBarProps extends AppShellProps {
  headerComponent: ReactElement;
  navItems?: MainLinkProps[] | CollapsibleMainLinkProps[];
  mainLinks?: ElementType;
  impersonatedEmployeeFullName?: string;
}

export const AppShellSideBar = ({
  navItems,
  headerComponent,
  mainLinks = MainLinks,
  impersonatedEmployeeFullName,
  ...props
}: AppShellSideBarProps) => {
  const MainLinks = mainLinks;

  return (
    <AppShell
      headerComponent={headerComponent}
      navbarComponent={
        navItems ? (
          <Stack gap="0">
            <MainLinks items={navItems} />
          </Stack>
        ) : undefined
      }
      impersonatedEmployeeFullName={impersonatedEmployeeFullName}
      {...props}
    />
  );
};
