import {
  Combobox,
  ComboboxProps,
  Pill,
  PillsInput,
  useCombobox,
} from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import partition from 'lodash/partition';
import { ReactElement, useMemo } from 'react';

import { ZorroCenter } from '../Center';
import { ZorroIcon } from '../Icon';
import { ZorroLoader } from '../Loader';
import { brand } from '../theme';
import classNames from './CustomMultiSelect.module.scss';

export type MultiSelectOption<T> = T & {
  value: string;
  active?: boolean;
};

export type CustomMultiSelectProps<T> = ComboboxProps & {
  search: string;
  onSearchChange: (newSearch: string) => void;
  options: MultiSelectOption<T>[];
  onOptionSelected: (value: string, option: MultiSelectOption<T>) => void;
  onBackspaceClick: () => void;
  renderSelectedOption: (option: MultiSelectOption<T>) => ReactElement;
  renderDropdownOption: (option: MultiSelectOption<T>) => ReactElement;
  placeholder?: string;
  isLoading?: boolean;
};

export const ZorroCustomMultiSelect = <T,>({
  search,
  onSearchChange,
  options,
  renderSelectedOption,
  onBackspaceClick,
  renderDropdownOption,
  onOptionSelected,
  placeholder,
  isLoading,
  ...props
}: CustomMultiSelectProps<T>) => {
  const combobox = useCombobox({
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const handleOptionSubmit = (newValue: string) => {
    const option = options.find((option) => option.value === newValue);
    if (option) {
      option.active = true;
      onOptionSelected(newValue, option);
    }
  };

  const [selectedOptions, dropdownOptions] = useMemo(
    () => partition(options, ({ active }) => active),
    [options]
  );

  return (
    <Combobox
      store={combobox}
      classNames={classNames}
      onOptionSubmit={handleOptionSubmit}
      {...props}
    >
      <Combobox.DropdownTarget>
        <PillsInput
          onClick={() => combobox.openDropdown()}
          styles={(theme) => ({
            input: {
              border: `1px solid ${theme.colors.zorroGray[7]}`,
            },
          })}
        >
          <Pill.Group py="sm" pl="sm" gap="1rem">
            {selectedOptions.length === 0 && (
              <ZorroIcon
                color={brand.zorroGray300}
                icon={IconSearch}
                size="1.5rem"
              />
            )}
            {selectedOptions.map((option) => renderSelectedOption(option))}

            <Combobox.EventsTarget>
              <PillsInput.Field
                onFocus={() => combobox.openDropdown()}
                onBlur={() => combobox.closeDropdown()}
                placeholder={placeholder}
                value={search}
                onChange={(event) => {
                  combobox.updateSelectedOptionIndex();
                  onSearchChange(event.currentTarget.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace' && search.length === 0) {
                    event.preventDefault();
                    onBackspaceClick();
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown
        data-is-empty={dropdownOptions.length === 0 && !isLoading}
      >
        <Combobox.Options>
          {isLoading ? (
            <ZorroCenter p="lg">
              <ZorroLoader />
            </ZorroCenter>
          ) : (
            dropdownOptions.map((option) => (
              <Combobox.Option
                active={option.active}
                value={option.value}
                key={option.value}
              >
                {renderDropdownOption(option)}
              </Combobox.Option>
            ))
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

ZorroCustomMultiSelect.displayName = 'CustomMultiSelect';
