import { Roles } from '@zorro/clients';
import { useMonolithQuery, useRoles } from '@zorro/shared/utils';
import { Loader } from '@zorro/zorro-ui-design';

import { PlanYearsTable } from '../../PlanYearsTable';
import {
  CompanySetupTabType,
  CompanySetupTabWrapper,
} from '../CompanySetupTabWrapper';

type Props = {
  employerId: string;
};

export const PlanYearSetupTab = ({ employerId }: Props) => {
  const { userRoles } = useRoles();

  const isEditable = userRoles.some((role) =>
    [Roles.OPERATOR, Roles.OMNIPOTENT_ADMIN, Roles.ACCOUNT_SUPERVISOR].includes(
      role
    )
  );

  const { data: employer, isLoading } = useMonolithQuery({
    method: 'employersControllerFindOne',
    params: [employerId],
  });

  return (
    <CompanySetupTabWrapper
      tab={CompanySetupTabType.PLAN_YEAR_SETUP}
      employerId={employerId}
    >
      {employer && !isLoading ? (
        <PlanYearsTable
          employerName={employer.name}
          employerId={employer.id}
          isEditable={isEditable}
        />
      ) : (
        <Loader />
      )}
    </CompanySetupTabWrapper>
  );
};
