import { Checkbox, CheckboxProps } from '@mantine/core';
import { forwardRef } from 'react';

import classNames from './Checkbox.module.scss';

export interface ICheckboxProps extends CheckboxProps {
  isChecked: boolean;
  shouldHaveBorder?: boolean;
  masked?: boolean;
}

export const ZorroCheckbox = forwardRef<HTMLInputElement, ICheckboxProps>(
  (
    {
      value,
      disabled = false,
      readOnly = false,
      isChecked,
      shouldHaveBorder = true,
      masked = false,
      ...props
    },
    ref
  ) => {
    const labelClassNames = [
      classNames.label ?? null,
      masked ? 'fs-mask' : null,
    ].filter((className): className is string => Boolean(className));

    return (
      <Checkbox
        ref={ref}
        value={value}
        checked={isChecked}
        disabled={disabled}
        classNames={{
          ...classNames,
          label: labelClassNames.join(' '),
        }}
        color="zorroCoal.9"
        wrapperProps={{
          'data-with-border': shouldHaveBorder,
          'data-readonly': readOnly,
          'data-disabled': disabled,
          'data-checked': isChecked,
        }}
        {...props}
      />
    );
  }
);

ZorroCheckbox.displayName = 'ZorroCheckbox';
