import { ScrollArea } from '@mantine/core';
import {
  Button,
  Card,
  Code,
  Group,
  Stack,
  Text,
  Title,
} from '@zorro/zorro-ui-design';

import { FormFooter } from '../../index';
import { clearStorage, toggleDebugMode } from '../Analytics/analytics.utils';
import { useSupportInformationForm } from './useSupportInformationForm';

type ShareSupportInformationOperationsFormProps = {
  onClose: () => void;
};

const ShareSupportInformationOperationsForm = ({
  onClose,
}: ShareSupportInformationOperationsFormProps) => {
  const { supportInformation, handleSubmit } = useSupportInformationForm({
    onSuccess: onClose,
  });

  return (
    <Stack>
      <Card>
        <Stack gap="xs">
          <Title order={3}>General Information</Title>
          <Group gap="xs">
            <Text>Environment:</Text>
            <Code>{supportInformation.environment}</Code>
          </Group>
          <Group gap="xs">
            <Text>Page URL:</Text>
            <Code>{supportInformation.pageURL}</Code>
          </Group>
          <Group gap="xs">
            <Text>Session URL:</Text>
            <Code>{supportInformation.sessionURL}</Code>
          </Group>
        </Stack>
      </Card>
      <Card>
        <Stack gap="xs">
          <Title order={3}>User Details</Title>
          <Code block mah="15rem" className="fs-mask">
            <ScrollArea.Autosize>
              {JSON.stringify(supportInformation.userDetails, null, 2)}
            </ScrollArea.Autosize>
          </Code>
        </Stack>
      </Card>

      <Card>
        <Stack gap="xs">
          <Title order={3}>Dev Area</Title>
          <Group justify="space-between">
            <Group gap="xs">
              <Text>Analytics Debug Mode:</Text>
              <Code>
                {supportInformation.isAnalyticsDebugMode ? 'Yes' : 'No'}
              </Code>
            </Group>
            <Group gap="xs">
              <Button size="xs" variant="light" onClick={toggleDebugMode}>
                Toggle Debug Mode
              </Button>
              <Button size="xs" variant="light" onClick={clearStorage}>
                Clear Storage
              </Button>
            </Group>
          </Group>
        </Stack>
      </Card>

      <FormFooter
        primaryLabel="Send support information"
        primaryButtonProps={{
          onClick: handleSubmit,
        }}
      />
    </Stack>
  );
};

export { ShareSupportInformationOperationsForm };
