import { PayrollCycle, WaitingPeriod } from '@zorro/clients';
import { VALIDATION_MESSAGES } from '@zorro/shared/utils';
import { YesNo } from '@zorro/types';
import * as yup from 'yup';

export const getCompanySetupSchemaObject = (isFinalizationMode: boolean) => {
  return yup.object({
    ...(isFinalizationMode
      ? {
          payrollCycle: yup
            .mixed<PayrollCycle>()
            .oneOf(
              Object.values(PayrollCycle),
              VALIDATION_MESSAGES.payrollCycleRequired
            )
            .typeError(VALIDATION_MESSAGES.payrollCycleRequired)
            .required(VALIDATION_MESSAGES.payrollCycleRequired),
          waitingPeriod: yup
            .mixed<WaitingPeriod>()
            .oneOf(
              Object.values(WaitingPeriod),
              VALIDATION_MESSAGES.waitingPeriodRequired
            )
            .typeError(VALIDATION_MESSAGES.waitingPeriodRequired)
            .required(VALIDATION_MESSAGES.waitingPeriodRequired),
          isApplicableLargeEmployer: yup
            .mixed<YesNo>()
            .oneOf(Object.values(YesNo), VALIDATION_MESSAGES.aleRequired)
            .typeError(VALIDATION_MESSAGES.aleRequired)
            .required(VALIDATION_MESSAGES.aleRequired),
        }
      : {
          payrollCycle: yup
            .mixed<PayrollCycle>()
            .oneOf(
              Object.values(PayrollCycle),
              VALIDATION_MESSAGES.payrollCycleRequired
            )
            .typeError(VALIDATION_MESSAGES.payrollCycleRequired)
            .nullable(),
          waitingPeriod: yup
            .mixed<WaitingPeriod>()
            .oneOf(
              Object.values(WaitingPeriod),
              VALIDATION_MESSAGES.waitingPeriodRequired
            )
            .typeError(VALIDATION_MESSAGES.waitingPeriodRequired)
            .nullable(),
          isApplicableLargeEmployer: yup
            .mixed<YesNo>()
            .oneOf(Object.values(YesNo), VALIDATION_MESSAGES.aleRequired)
            .typeError(VALIDATION_MESSAGES.aleRequired)
            .nullable(),
        }),
  });
};

export type CompanySetupFormFields = yup.InferType<
  ReturnType<typeof getCompanySetupSchemaObject>
>;
