import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const ZorroQuestionmarkIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_4993_1944)">
          <path
            d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
            stroke="#B2B2B2"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 13.2139V13.2246"
            stroke="#B2B2B2"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.99975 10.0004C10.2694 10.0006 10.5348 9.93282 10.7714 9.80347C11.0081 9.67411 11.2084 9.48729 11.3538 9.26021C11.4993 9.03313 11.5853 8.77309 11.6039 8.50405C11.6225 8.23501 11.5731 7.96562 11.4602 7.72069C11.3473 7.47576 11.1747 7.26317 10.9581 7.1025C10.7415 6.94183 10.4879 6.83825 10.2208 6.80129C9.95364 6.76434 9.6815 6.7952 9.42942 6.89104C9.17734 6.98688 8.95343 7.14461 8.77832 7.3497"
            stroke="#B2B2B2"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_4993_1944">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
