import {
  EMPLOYEE_DYNAMIC_ROUTES,
  findLatestPeriod,
  getQueryValue,
  useDynamicRouter,
  useMonolithQuery,
} from '@zorro/shared/utils';

import { FullPageLoader } from '../FullPageLoader';

type Props = {
  employerId: string;
  employeeId: string;
};

export const EmployeePageRedirect = ({ employerId, employeeId }: Props) => {
  const { navigateToRoute, router, isNavigating } = useDynamicRouter(
    EMPLOYEE_DYNAMIC_ROUTES
  );
  const { data: onboardingPeriods } = useMonolithQuery({
    method: 'onboardingPeriodsControllerFindMany',
    params: [employeeId],
  });

  if (isNavigating) {
    return <FullPageLoader />;
  }

  const initialOPId = getQueryValue(router.query.initialOPId);
  if (initialOPId) {
    navigateToRoute(
      'EMPLOYEE_ENROLLMENT_TAB',
      [{ employerId, employeeId, onboardingPeriodId: initialOPId }],
      {},
      true
    );
  } else if (onboardingPeriods) {
    const latestPeriod = findLatestPeriod(onboardingPeriods);
    if (latestPeriod) {
      navigateToRoute(
        'EMPLOYEE_ENROLLMENT_TAB',
        [{ employerId, employeeId, onboardingPeriodId: latestPeriod.id }],
        {},
        true
      );
    } else {
      navigateToRoute(
        'EMPLOYEE_NO_ENROLLMENTS',
        [{ employerId, employeeId }],
        {},
        true
      );
    }
  }

  return <FullPageLoader />;
};
