import { yupResolver } from '@hookform/resolvers/yup';
import {
  VALIDATION_MESSAGES,
  useForm,
  useMonolithQuery,
} from '@zorro/shared/utils';
import {
  FormErrorMessage,
  Grid,
  Space,
  TextInput,
} from '@zorro/zorro-ui-design';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

import { FormFooter } from '../../FormFooter';
import { InformationBoxComponent } from '../../InformationBox';
import { useMonolithMutation } from '../../hooks/useMonolithMutation';

const editCompanyEmailSchema = yup.object({
  email: yup
    .string()
    .email(VALIDATION_MESSAGES.emailRequired)
    // eslint-disable-next-line require-unicode-regexp
    .matches(/^[^\s@]+@[^\s@][^\s.@]*\.[^\s@]{2,}$/, {
      message: VALIDATION_MESSAGES.emailValid,
    })
    .typeError(VALIDATION_MESSAGES.emailRequired)
    .required(VALIDATION_MESSAGES.emailRequired),
});

export type EditCompanyEmailFormFields = {
  email: string;
};

type Props = {
  employeeId: string;
  onSuccess?: () => void;
  onCancel?: () => void;
};

export const EmployeeEmailForm = ({
  employeeId,
  onSuccess,
  onCancel,
}: Props) => {
  const { tryMutate: mutateEditCompanyEmail } = useMonolithMutation({
    method: 'employeesControllerUpdateCompanyEmail',
  });
  const { data: employee } = useMonolithQuery({
    method: 'employeesControllerFindOne',
    params: [employeeId],
  });
  const { control, getValues, formState } = useForm<EditCompanyEmailFormFields>(
    {
      mode: 'all',
      resolver: yupResolver(editCompanyEmailSchema),
      defaultValues: { email: employee?.email || '' },
    }
  );

  const { isValid, isDirty, errors } = formState;

  const handleSubmit = async () => {
    const { email } = getValues();
    const mutateResult = await mutateEditCompanyEmail([employeeId, { email }]);

    if (mutateResult.isOk()) {
      onSuccess?.();
    }
  };

  return (
    <>
      <InformationBoxComponent title="The company email is used by the employee to log in to Zorro. Make sure the employee is aware of this change" />
      <Space h="md" />
      <Grid>
        <Grid.Col>
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <TextInput {...field} label="Company email" />
            )}
          />

          <FormErrorMessage fieldName="email" errors={errors} />
        </Grid.Col>
      </Grid>

      <FormFooter
        primaryLabel="Save"
        primaryButtonProps={{
          onClick: handleSubmit,
          disabled: !isValid || !isDirty,
        }}
        secondaryLabel="Cancel"
        secondaryButtonProps={{ onClick: onCancel }}
      />
    </>
  );
};
