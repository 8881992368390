import { EnrollmentActivityLogDto } from '@zorro/clients';
import {
  formatDateTimeEnLocale,
  parseDateTimeISO,
} from '@zorro/shared/formatters';
import { Stack, Text } from '@zorro/zorro-ui-design';

import { EnrollmentActivityLogEntry } from './EnrollmentActivityLogEntry';

type Props = {
  userId: string;
  enrollmentActivityLogs: EnrollmentActivityLogDto[];
};

function getUserColorMap(
  userId: string,
  enrollmentActivityLogs: EnrollmentActivityLogDto[]
): Map<string, string> {
  const colors = [
    'zorroSky.0',
    'zorroPrimaryButtonSelected.0',
    'zorroYolk.0',
    'zorroFire.0',
    'zorroYolk.0',
    'zorroGray.8',
  ];
  const employeeColor = 'zorroGreen.0';
  const colorMap = new Map();
  let colorIndex = 0;
  enrollmentActivityLogs.forEach(({ performedById }) => {
    if (performedById === userId) {
      colorMap.set(performedById, employeeColor);
    } else if (!colorMap.has(performedById)) {
      colorMap.set(performedById, colors[colorIndex % colors.length]);
      colorIndex = ++colorIndex;
    }
  });
  return colorMap;
}

export const EnrollmentActivityLog = ({
  userId,
  enrollmentActivityLogs,
}: Props) => {
  if (enrollmentActivityLogs.length === 0) {
    return null;
  }
  const userColorMap = getUserColorMap(userId, enrollmentActivityLogs);
  return (
    <Stack gap={16}>
      <Text size="sm" fw={600}>
        Enrollment activity
      </Text>
      <Stack gap={12}>
        {enrollmentActivityLogs
          .sort((first, second) =>
            second.createdAt.localeCompare(first.createdAt)
          )
          .map((log) => (
            <EnrollmentActivityLogEntry
              key={log.id}
              timeStamp={formatDateTimeEnLocale(
                parseDateTimeISO(log.createdAt)
              )}
              activity={log.activity}
              submissionType={log.submissionType}
              performedByEmail={log.performedByEmail}
              performedByFirstName={log.performedByFirstName}
              performedByLastName={log.performedByLastName}
              performedByAvatarColor={userColorMap.get(log.performedById)!}
            />
          ))}
      </Stack>
    </Stack>
  );
};
