import { Stepper, StepperProps } from '@mantine/core';

import classNames from './Stepper.module.scss';
import { StepperCheckmarkIcon } from './StepperCheckmarkIcon';
import { ZorroStepperStep } from './StepperStep';

export type IStepperProps = StepperProps & {
  variant?: 'default' | 'outline' | 'headless' | 'modern';
};

export function ZorroStepper({ variant = 'default', ...props }: IStepperProps) {
  return (
    <Stepper
      completedIcon={StepperCheckmarkIcon}
      classNames={classNames}
      data-variant={variant}
      {...props}
    />
  );
}

ZorroStepper.Step = ZorroStepperStep;
ZorroStepper.Completed = Stepper.Completed;
