import { AllAgenciesDto } from '@zorro/clients';
import { getEmployerAgencyNames, useMonolithQuery } from '@zorro/shared/utils';
import { UseFormReturn } from 'react-hook-form';

import { ZorroSetupFormFields } from '../../../ZorroSetupForm/ZorroSetupUtils';
import { AddEmployerStepType } from '../../AddEmployerStep/AddEmployerUtils';
import { ReviewCard } from '../ReviewCard';

type Props = {
  zorroSetupForm: UseFormReturn<ZorroSetupFormFields>;
  allAgencies: AllAgenciesDto;
  employerId: string;
  isValid: boolean;
};

export const ZorroSetupCard = ({
  employerId,
  zorroSetupForm,
  allAgencies,
  isValid,
}: Props) => {
  const { watch } = zorroSetupForm;

  const {
    zorroPartnerId,
    producerId,
    enrollmentTeamIds,
    crmRecordId,
    producerAgentId,
  } = watch();

  const { myZorroPartner, myProducer, myEnrollmentTeams } =
    getEmployerAgencyNames(
      zorroPartnerId,
      producerId,
      enrollmentTeamIds,
      allAgencies
    );

  const { data: producerAgent } = useMonolithQuery({
    method: 'agentsControllerFindOne',
    params: producerAgentId && [producerAgentId],
  });

  return (
    <ReviewCard
      isValid={isValid}
      title="Zorro setup"
      employerId={employerId}
      step={AddEmployerStepType.ZORRO_SETUP}
      items={[
        {
          name: 'Zorro partner',
          value: myZorroPartner,
          isRequired: true,
        },
        {
          name: 'Enrollment team(s)',
          value: myEnrollmentTeams.join(', '),
          isRequired: true,
        },
        {
          name: 'Producer agency',
          value: myProducer,
          isRequired: true,
        },
        {
          name: 'Producer agent',
          value: producerAgent
            ? `${producerAgent?.firstName} ${producerAgent?.lastName}`
            : undefined,
        },
        {
          name: 'CRM record ID',
          value: crmRecordId,
        },
      ]}
    />
  );
};
