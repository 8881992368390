/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ExtendedBenefitStatus {
  APPLICATION_SENT = 'Application Sent',
  SUBMITTED = 'Submitted',
  ENROLLED = 'Enrolled',
  WAIVED = 'Waived',
  IN_PROGRESS = 'In Progress',
}
