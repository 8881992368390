import { Progress, ProgressProps } from '@mantine/core';
import { forwardRef } from 'react';

export type IProgressProps = ProgressProps;
export const ZorroProgress = forwardRef<HTMLDivElement, IProgressProps>(
  ({ ...props }, ref) => {
    return <Progress ref={ref} {...props} />;
  }
);

ZorroProgress.displayName = 'ZorroProgress';
