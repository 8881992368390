import { Divider, Group } from '@zorro/zorro-ui-design';
import { ReactElement, ReactNode } from 'react';

import { EmployerLogo, LogoDark } from '../AppShell';

type Props = {
  user: ReactNode;
  employerLogoUrl?: string;
  leftSection?: ReactElement;
};

export const EmployerHeaderTopBar = ({
  user,
  employerLogoUrl,
  leftSection,
}: Props) => {
  return (
    <Group justify="space-between" align="center" h="100%" wrap="nowrap">
      <Group gap="md" w="75%" wrap="nowrap">
        {employerLogoUrl && <EmployerLogo logoUrl={employerLogoUrl} />}
        {leftSection}
      </Group>
      <Group gap="0" align="center" wrap="nowrap">
        <LogoDark />
        <Divider orientation="vertical" mx={32} my={7} />
        {user}
      </Group>
    </Group>
  );
};
