import { EmployeeDto, OnboardingPeriodDto } from '@zorro/clients';
import { useMonolithQuery } from '@zorro/shared/utils';

import { CardSelector, getPaymentMethodSelectOptions } from '../../../index';

type PaymentMethodTypeProps = {
  employeeId: EmployeeDto['id'];
  onboardingPeriodId: OnboardingPeriodDto['id'];
  isElena?: boolean;
};

const PaymentMethodType = ({
  employeeId,
  onboardingPeriodId,
  isElena = false,
}: PaymentMethodTypeProps) => {
  const {
    data: paymentMethodDetails,
    isLoading: isLoadingPaymentMethodDetails,
  } = useMonolithQuery({
    method: 'paymentsControllerEmployeePaymentMethodDetails',
    params: [employeeId, { onboardingPeriodId }],
  });

  if (isLoadingPaymentMethodDetails || !paymentMethodDetails) {
    return null;
  }

  return (
    <CardSelector
      selectData={getPaymentMethodSelectOptions(isElena)}
      selectedValue={paymentMethodDetails.type}
      readonly
    />
  );
};

export { PaymentMethodType };
