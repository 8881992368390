import { PaymentCreditCardDto } from '@zorro/clients';

import { PAYMENT_METHOD_CREDIT_CARD_CONTAINER_WIDTH } from '../paymentsDesign.consts';
import {
  getFormattedCVV,
  getFormattedCardNumber,
} from '../paymentsPciDssMasking.utils';
import CopyToClipboardActionIcon from './CopyToClipboard/CopyToClipboardActionIcon';

type PaymentMethodCreditCardProps = Omit<
  PaymentCreditCardDto,
  'billingAddress'
> & {
  isMasked: boolean;
};

export const PaymentMethodCreditCard = ({
  cardNumber,
  nameOnCard,
  cvv,
  expirationDate,
  isMasked,
}: PaymentMethodCreditCardProps) => {
  const formattedCardNumber = getFormattedCardNumber(cardNumber, isMasked);
  const formattedCVV = getFormattedCVV(cvv, isMasked);
  const formattedNameOnCard = nameOnCard.toUpperCase();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox={`0 0 ${PAYMENT_METHOD_CREDIT_CARD_CONTAINER_WIDTH} 258`}
      style={{ maxWidth: `${PAYMENT_METHOD_CREDIT_CARD_CONTAINER_WIDTH}px` }}
    >
      <rect width="396" height="258" fill="url(#a)" rx="16" />
      <mask
        id="c"
        width="396"
        height="258"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
      >
        <rect width="396" height="258" fill="url(#b)" rx="8" />
      </mask>
      <g mask="url(#c)" opacity=".75">
        <mask
          id="d"
          width="386"
          height="379"
          x="99"
          y="22"
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'luminance' }}
        >
          <path
            fill="#fff"
            d="M350.365 22 99 198.752l133.913 202.045 251.365-176.752L350.365 22Z"
          />
        </mask>
        <g stroke="#CDABFF" strokeOpacity=".2" strokeWidth=".7" mask="url(#d)">
          <path d="M339.094 94.066 351.9 113.12l-.031-.003c2.672 3.972 4.368 8.576 4.98 13.516.611 4.94.124 10.106-1.429 15.168l-35.96 117.17c-1.806 5.859 4.515 9.762 9.751 6.021l69.994-49.889c.322-.23.679-.401 1.049-.505a2.97 2.97 0 0 1 1.11-.099c.362.039.702.148 1 .323.298.174.549.41.737.694l13.645 20.323c4.489 6.671 5.777 15.223 3.579 23.776-2.197 8.553-7.7 16.407-15.298 21.836l-47.713 34.009a3.405 3.405 0 0 1-1.05.505c-.37.103-.747.137-1.109.099a2.501 2.501 0 0 1-1.001-.323 2.249 2.249 0 0 1-.737-.695l-22.191-33.026c-1.023-1.525-2.693-2.498-4.644-2.704-1.951-.206-4.024.371-5.763 1.605l-68.392 48.761a7.884 7.884 0 0 1-2.448 1.186 6.899 6.899 0 0 1-2.591.235 5.811 5.811 0 0 1-2.336-.752 5.216 5.216 0 0 1-1.72-1.622l-13.07-19.454c-2.672-3.972-4.368-8.577-4.98-13.516-.611-4.94-.124-10.107 1.429-15.168l34.768-113.275c2.061-6.701-5.145-11.152-11.132-6.882l-66.112 47.129a3.405 3.405 0 0 1-1.05.505c-.37.103-.747.137-1.109.099a2.506 2.506 0 0 1-1.001-.323 2.249 2.249 0 0 1-.737-.695l-13.15-19.575c-4.621-6.861-5.949-15.658-3.692-24.458 2.256-8.8 7.913-16.883 15.727-22.473l46.527-33.172a3.37 3.37 0 0 1 1.05-.505c.37-.104.748-.138 1.11-.099.362.038.702.148 1 .322.298.175.548.411.737.695l21.915 32.608c1.022 1.526 2.693 2.499 4.644 2.705 1.952.206 4.025-.372 5.764-1.607l67.997-48.483a7.946 7.946 0 0 1 2.457-1.18 6.96 6.96 0 0 1 2.598-.233 5.857 5.857 0 0 1 2.342.751 5.245 5.245 0 0 1 1.73 1.621Z" />
          <path d="m333.061 92.561 13.938 18.237-.032-.001c2.908 3.802 4.868 8.28 5.752 13.148.885 4.868.676 10.017-.614 15.117l-29.854 118.067c-1.501 5.905 5.079 9.449 10.151 5.469l67.805-53.094a3.438 3.438 0 0 1 1.029-.555 3.022 3.022 0 0 1 1.113-.155c.367.019.716.11 1.025.268.31.158.576.379.781.651l14.852 19.452c4.886 6.385 6.648 14.8 4.9 23.396-1.749 8.597-6.866 16.671-14.225 22.447l-46.221 36.193a3.431 3.431 0 0 1-1.03.555 3.022 3.022 0 0 1-1.113.155 2.54 2.54 0 0 1-1.025-.268 2.264 2.264 0 0 1-.781-.651l-24.154-31.61c-1.113-1.461-2.849-2.34-4.826-2.443-1.978-.104-4.035.576-5.72 1.89l-66.253 51.891a7.967 7.967 0 0 1-2.402 1.303 7.064 7.064 0 0 1-2.598.366 5.927 5.927 0 0 1-2.394-.625 5.249 5.249 0 0 1-1.821-1.52l-14.226-18.62c-2.908-3.802-4.867-8.281-5.752-13.148-.885-4.868-.675-10.017.615-15.117l28.866-114.142c1.712-6.752-5.789-10.795-11.59-6.251l-64.043 50.154a3.412 3.412 0 0 1-1.03.556 3.035 3.035 0 0 1-1.112.155 2.561 2.561 0 0 1-1.026-.268 2.26 2.26 0 0 1-.78-.651l-14.314-18.737c-5.029-6.566-6.845-15.223-5.05-24.067 1.795-8.845 7.055-17.153 14.623-23.1l45.072-35.302a3.412 3.412 0 0 1 1.03-.556 3.035 3.035 0 0 1 1.112-.155c.367.019.716.111 1.026.268.309.158.575.379.78.651l23.854 31.211c1.113 1.461 2.849 2.34 4.826 2.443 1.978.104 4.035-.576 5.72-1.89l65.87-51.595a8.035 8.035 0 0 1 2.41-1.297 7.123 7.123 0 0 1 2.604-.365 5.994 5.994 0 0 1 2.4.624 5.288 5.288 0 0 1 1.832 1.516Z" />
          <path d="m326.836 91.54 15.019 17.351-.032-.001c3.134 3.618 5.349 7.954 6.504 12.732 1.156 4.777 1.225 9.89.203 15.009L324.896 255.15c-1.189 5.928 5.627 9.1 10.515 4.895l65.363-56.1a3.427 3.427 0 0 1 1.006-.603c.364-.14.741-.212 1.112-.212.37.001.726.073 1.046.213.321.141.6.346.821.605l16.006 18.507c5.265 6.074 7.496 14.321 6.203 22.928-1.293 8.608-6.004 16.871-13.098 22.974l-44.556 38.242a3.47 3.47 0 0 1-1.006.603c-.364.14-.742.212-1.112.211a2.6 2.6 0 0 1-1.047-.213 2.28 2.28 0 0 1-.821-.605l-26.029-30.073c-1.2-1.39-2.995-2.172-4.991-2.172-1.996-.001-4.029.779-5.654 2.168l-63.865 54.829a8.038 8.038 0 0 1-2.347 1.414 7.235 7.235 0 0 1-2.595.498 6.086 6.086 0 0 1-2.445-.497 5.303 5.303 0 0 1-1.916-1.413l-15.33-17.715c-3.134-3.617-5.349-7.954-6.504-12.731-1.155-4.777-1.224-9.889-.202-15.009l22.852-114.577c1.356-6.779-6.414-10.398-12.005-5.596l-61.735 52.993a3.462 3.462 0 0 1-1.007.603c-.363.14-.741.212-1.111.211-.371 0-.726-.072-1.047-.213a2.29 2.29 0 0 1-.821-.604l-15.425-17.827c-5.418-6.247-7.717-14.73-6.391-23.585 1.326-8.855 6.169-17.358 13.463-23.64l43.447-37.301a3.444 3.444 0 0 1 1.007-.604 3.117 3.117 0 0 1 1.111-.211c.371 0 .726.073 1.047.213.32.141.6.346.821.605l25.705 29.694c1.2 1.39 2.995 2.171 4.991 2.172 1.996 0 4.03-.78 5.654-2.169l63.496-54.514a8.093 8.093 0 0 1 2.355-1.411 7.276 7.276 0 0 1 2.602-.496 6.13 6.13 0 0 1 2.45.494 5.352 5.352 0 0 1 1.927 1.41Z" />
          <path d="m320.482 91.082 16.027 16.38-.033.001c3.344 3.415 5.805 7.588 7.224 12.252 1.419 4.663 1.766 9.714 1.018 14.828l-17.314 118.383c-.874 5.921 6.143 8.708 10.826 4.297l62.614-58.82c.288-.271.62-.492.978-.648a3.131 3.131 0 0 1 1.105-.266 2.67 2.67 0 0 1 1.063.156c.329.123.62.311.857.556l17.081 17.473c5.617 5.734 8.305 13.772 7.473 22.348-.832 8.576-5.117 16.987-11.911 23.385l-42.681 40.098a3.47 3.47 0 0 1-.978.648 3.158 3.158 0 0 1-1.105.266 2.652 2.652 0 0 1-1.063-.157 2.292 2.292 0 0 1-.857-.555l-27.776-28.393c-1.281-1.312-3.126-1.992-5.131-1.891-2.005.102-4.005.978-5.562 2.435l-61.177 57.488a8.063 8.063 0 0 1-2.28 1.519 7.35 7.35 0 0 1-2.579.625 6.217 6.217 0 0 1-2.482-.365 5.35 5.35 0 0 1-2.001-1.298l-16.36-16.723c-3.344-3.415-5.804-7.588-7.223-12.252-1.42-4.663-1.766-9.714-1.018-14.828l16.745-114.448c.995-6.77-7.006-9.949-12.361-4.915l-59.141 55.565c-.288.271-.62.492-.978.648a3.136 3.136 0 0 1-1.105.266 2.67 2.67 0 0 1-1.063-.156 2.303 2.303 0 0 1-.857-.556l-16.46-16.829c-5.782-5.897-8.551-14.166-7.699-22.988.852-8.823 5.255-17.478 12.242-24.064l41.618-39.11c.289-.271.621-.491.979-.648a3.131 3.131 0 0 1 1.105-.266c.372-.019.733.034 1.062.156.33.123.621.311.858.556l27.429 28.034c1.281 1.312 3.126 1.992 5.131 1.89 2.005-.102 4.005-.978 5.562-2.435l60.823-57.16a8.129 8.129 0 0 1 2.29-1.515 7.396 7.396 0 0 1 2.586-.624 6.264 6.264 0 0 1 2.488.363 5.403 5.403 0 0 1 2.011 1.294Z" />
          <path d="m314.077 90.953 16.987 15.37-.032.003c3.544 3.204 6.242 7.203 7.921 11.74 1.678 4.538 2.3 9.513 1.826 14.608l-10.972 117.927c-.557 5.899 6.644 8.292 11.109 3.692l59.704-61.373c.275-.282.597-.517.948-.691.35-.173.723-.282 1.095-.319a2.716 2.716 0 0 1 1.076.1c.338.104.641.276.892.505l18.104 16.396c5.954 5.38 9.09 13.189 8.72 21.71-.371 8.52-4.218 17.056-10.696 23.731l-40.7 41.837a3.438 3.438 0 0 1-.947.691 3.204 3.204 0 0 1-1.096.32 2.72 2.72 0 0 1-1.076-.101 2.315 2.315 0 0 1-.891-.505l-29.441-26.641c-1.357-1.232-3.248-1.809-5.256-1.605-2.009.204-3.971 1.172-5.455 2.693l-58.337 59.98a8.096 8.096 0 0 1-2.208 1.619 7.463 7.463 0 0 1-2.557.751c-.87.089-1.724.01-2.513-.233a5.423 5.423 0 0 1-2.08-1.18l-17.34-15.693c-3.544-3.205-6.242-7.204-7.921-11.741-1.678-4.538-2.301-9.513-1.826-14.608l10.613-114.006c.632-6.746-7.578-9.476-12.684-4.223l-56.391 57.973a3.478 3.478 0 0 1-.948.691 3.22 3.22 0 0 1-1.095.32 2.735 2.735 0 0 1-1.076-.101 2.312 2.312 0 0 1-.892-.505l-17.448-15.79c-6.127-5.533-9.356-13.565-8.979-22.331.378-8.766 4.331-17.549 10.992-24.419l39.686-40.807c.274-.283.596-.517.947-.691a3.226 3.226 0 0 1 1.096-.32 2.718 2.718 0 0 1 1.076.101 2.3 2.3 0 0 1 .891.505l29.073 26.302c1.357 1.232 3.248 1.809 5.256 1.605 2.008-.203 3.97-1.172 5.455-2.692l57.999-59.636a8.147 8.147 0 0 1 2.218-1.616 7.516 7.516 0 0 1 2.563-.75 6.4 6.4 0 0 1 2.519.23 5.47 5.47 0 0 1 2.091 1.177Z" />
          <path d="m307.634 91.154 17.899 14.321-.032.005a29.444 29.444 0 0 1 8.594 11.2c1.932 4.4 2.827 9.286 2.627 14.347l-4.627 117.152c-.239 5.861 7.124 7.856 11.359 3.08l56.649-63.746c.26-.293.571-.542.914-.731.343-.19.711-.317 1.083-.373a2.799 2.799 0 0 1 1.086.044c.345.085.659.239.923.454l19.078 15.278c6.272 5.013 9.846 12.571 9.936 21.015.09 8.443-3.312 17.08-9.458 24.012l-38.616 43.453a3.44 3.44 0 0 1-.914.732 3.26 3.26 0 0 1-1.083.373 2.799 2.799 0 0 1-1.086-.044 2.37 2.37 0 0 1-.923-.454l-31.02-24.825c-1.43-1.148-3.361-1.622-5.367-1.317-2.007.305-3.925 1.364-5.334 2.943l-55.348 62.298a8.096 8.096 0 0 1-2.131 1.715 7.587 7.587 0 0 1-2.528.874 6.508 6.508 0 0 1-2.537-.101 5.51 5.51 0 0 1-2.155-1.06l-18.27-14.623a29.451 29.451 0 0 1-8.594-11.2c-1.932-4.4-2.827-9.287-2.627-14.347l4.479-113.258c.269-6.701-8.126-8.979-12.971-3.523l-53.504 60.214a3.501 3.501 0 0 1-.915.732 3.25 3.25 0 0 1-1.083.372 2.78 2.78 0 0 1-1.086-.044 2.354 2.354 0 0 1-.923-.453l-18.382-14.716c-6.455-5.155-10.135-12.929-10.232-21.615-.097-8.686 3.398-17.572 9.717-24.707l37.654-42.383c.261-.293.571-.542.914-.732a3.258 3.258 0 0 1 1.083-.372 2.766 2.766 0 0 1 1.087.044c.345.085.658.239.923.453l30.633 24.511c1.43 1.148 3.36 1.621 5.367 1.316 2.006-.305 3.925-1.363 5.333-2.942l55.027-61.94a8.14 8.14 0 0 1 2.14-1.712 7.628 7.628 0 0 1 2.535-.874 6.536 6.536 0 0 1 2.542.099 5.55 5.55 0 0 1 2.164 1.055Z" />
          <path d="m301.173 91.683 18.758 13.239-.032.006a29.367 29.367 0 0 1 9.241 10.631c2.18 4.25 3.344 9.036 3.417 14.049l1.709 116.058c.078 5.807 7.583 7.401 11.579 2.46l53.45-65.933a3.48 3.48 0 0 1 .879-.77 3.298 3.298 0 0 1 1.068-.424 2.855 2.855 0 0 1 1.093-.013c.351.067.675.203.952.401l19.994 14.125c6.573 4.633 10.574 11.922 11.122 20.265.548 8.342-2.401 17.056-8.199 24.225l-36.435 44.945a3.465 3.465 0 0 1-.879.771 3.298 3.298 0 0 1-1.068.424 2.84 2.84 0 0 1-1.094.012 2.414 2.414 0 0 1-.952-.401l-32.51-22.948c-1.499-1.061-3.464-1.43-5.463-1.025s-3.868 1.55-5.198 3.183l-52.223 64.436a8.079 8.079 0 0 1-2.048 1.803 7.685 7.685 0 0 1-2.492.994 6.642 6.642 0 0 1-2.554.031 5.603 5.603 0 0 1-2.221-.937l-19.148-13.518a29.36 29.36 0 0 1-9.241-10.631c-2.18-4.25-3.344-9.036-3.417-14.049l-1.647-112.2c-.093-6.639-8.65-8.459-13.222-2.816l-50.483 62.279a3.46 3.46 0 0 1-.878.771 3.298 3.298 0 0 1-1.068.424 2.84 2.84 0 0 1-1.094.012 2.4 2.4 0 0 1-.952-.401l-19.265-13.602c-6.765-4.765-10.884-12.262-11.452-20.844-.569-8.582 2.46-17.548 8.421-24.926l35.528-43.836a3.46 3.46 0 0 1 .878-.771 3.312 3.312 0 0 1 1.069-.424c.37-.075.742-.079 1.093-.013.351.067.675.203.952.401l32.103 22.658c1.499 1.061 3.464 1.43 5.463 1.026 1.999-.405 3.869-1.55 5.199-3.183l51.92-64.067a8.125 8.125 0 0 1 2.057-1.802 7.74 7.74 0 0 1 2.499-.993 6.686 6.686 0 0 1 2.56-.034c.822.154 1.58.471 2.231.932Z" />
          <path d="m294.709 92.54 19.564 12.124-.032.009a29.37 29.37 0 0 1 9.861 10.034c2.421 4.09 3.85 8.761 4.196 13.714l8.014 114.649c.392 5.736 8.019 6.927 11.767 1.837l50.119-67.929a3.415 3.415 0 0 1 1.891-1.282 2.907 2.907 0 0 1 1.098-.068c.356.048.689.166.978.347l20.853 12.937c6.855 4.242 11.269 11.243 12.273 19.463 1.004 8.219-1.486 16.985-6.922 24.37l-34.166 46.308c-.23.312-.516.587-.841.807a3.342 3.342 0 0 1-1.05.474 2.907 2.907 0 0 1-1.098.068 2.462 2.462 0 0 1-.978-.347l-33.906-21.017c-1.563-.973-3.558-1.236-5.545-.734-1.986.503-3.803 1.732-5.05 3.416l-48.965 66.385a8.045 8.045 0 0 1-1.959 1.888 7.764 7.764 0 0 1-2.45 1.111 6.777 6.777 0 0 1-2.564.161 5.681 5.681 0 0 1-2.283-.812l-19.969-12.38a29.363 29.363 0 0 1-9.861-10.035c-2.421-4.09-3.851-8.761-4.197-13.713l-7.743-110.839c-.454-6.558-9.15-7.918-13.436-2.105l-47.336 64.166a3.45 3.45 0 0 1-.841.807 3.32 3.32 0 0 1-1.05.474 2.907 2.907 0 0 1-1.098.068 2.447 2.447 0 0 1-.978-.347l-20.095-12.457c-7.054-4.363-11.599-11.563-12.635-20.019-1.036-8.455 1.52-17.474 7.109-25.075l33.312-45.163a3.433 3.433 0 0 1 1.891-1.281 2.907 2.907 0 0 1 1.098-.069c.356.048.688.166.978.348l33.483 20.749c1.563.972 3.558 1.236 5.544.733 1.987-.503 3.804-1.731 5.051-3.415l48.68-66.006a8.08 8.08 0 0 1 1.969-1.887 7.818 7.818 0 0 1 2.457-1.11 6.829 6.829 0 0 1 2.57-.165c.834.11 1.613.385 2.292.807Z" />
          <path d="m288.26 93.72 20.312 10.982-.031.009c4.237 2.289 7.797 5.496 10.45 9.414 2.654 3.919 4.344 8.464 4.96 13.342L338.228 240.4c.707 5.65 8.433 6.436 11.921 1.212l46.663-69.731a3.425 3.425 0 0 1 1.831-1.364 2.968 2.968 0 0 1 1.099-.124c.361.029.701.129 1.002.293l21.652 11.718c7.117 3.842 11.932 10.536 13.387 18.61 1.454 8.075-.571 16.869-5.631 24.45l-31.81 47.533a3.439 3.439 0 0 1-1.83 1.364 2.973 2.973 0 0 1-1.1.124 2.504 2.504 0 0 1-1.001-.293l-35.204-19.036c-1.624-.88-3.642-1.039-5.61-.44a9.174 9.174 0 0 0-4.888 3.635l-45.592 68.145a7.994 7.994 0 0 1-1.865 1.968 7.83 7.83 0 0 1-2.401 1.224 6.912 6.912 0 0 1-2.567.291 5.814 5.814 0 0 1-2.337-.686l-20.735-11.213c-4.237-2.289-7.797-5.496-10.451-9.415-2.654-3.919-4.343-8.463-4.959-13.342l-13.797-109.176c-.812-6.461-9.622-7.358-13.612-1.391l-44.073 65.867a3.439 3.439 0 0 1-.801.841 3.372 3.372 0 0 1-1.03.522 2.968 2.968 0 0 1-1.099.124 2.483 2.483 0 0 1-1.001-.293l-20.863-11.283c-7.324-3.951-12.281-10.835-13.782-19.141-1.5-8.306.579-17.353 5.78-25.155l31.015-46.36a3.42 3.42 0 0 1 1.83-1.363 2.968 2.968 0 0 1 1.099-.124c.361.029.701.128 1.001.293l34.764 18.793c1.624.881 3.642 1.039 5.61.44a9.166 9.166 0 0 0 4.888-3.635l45.327-67.755a8.04 8.04 0 0 1 4.283-3.191 6.976 6.976 0 0 1 2.573-.295 5.866 5.866 0 0 1 2.346.68Z" />
          <path d="m281.843 95.223 21.003 9.813-.031.011c4.381 2.045 8.132 5.033 11.011 8.77 2.879 3.738 4.823 8.144 5.707 12.936l20.478 110.906c1.016 5.55 8.822 5.93 12.041.586l43.095-71.33c.199-.329.457-.625.76-.872a3.393 3.393 0 0 1 1.005-.57 3.043 3.043 0 0 1 1.098-.179c.364.011.711.092 1.022.239l22.389 10.472c7.359 3.432 12.56 9.803 14.46 17.71 1.9 7.908.342 16.706-4.33 24.459l-29.377 48.624a3.417 3.417 0 0 1-1.765 1.442 3.022 3.022 0 0 1-1.098.179 2.55 2.55 0 0 1-1.022-.238l-36.402-17.01c-1.679-.787-3.714-.84-5.66-.147a9.164 9.164 0 0 0-4.713 3.845l-42.102 69.709a7.925 7.925 0 0 1-1.768 2.041 7.91 7.91 0 0 1-2.345 1.333 7.07 7.07 0 0 1-2.563.42 5.952 5.952 0 0 1-2.386-.558l-21.44-10.02c-4.381-2.045-8.132-5.033-11.011-8.77-2.879-3.738-4.823-8.144-5.707-12.936l-19.791-107.222c-1.166-6.345-10.066-6.78-13.751-.676l-40.701 67.379a3.39 3.39 0 0 1-.759.872 3.373 3.373 0 0 1-1.006.569c-.361.129-.734.19-1.098.18a2.572 2.572 0 0 1-1.022-.239l-21.573-10.083c-7.572-3.53-12.926-10.081-14.885-18.215-1.958-8.134-.361-17.185 4.441-25.164l28.641-47.423a3.4 3.4 0 0 1 .76-.873 3.38 3.38 0 0 1 1.005-.57 3.043 3.043 0 0 1 1.098-.179c.364.01.711.091 1.022.239l35.946 16.793c1.679.787 3.715.84 5.66.147a9.167 9.167 0 0 0 4.713-3.844l41.858-69.31a7.956 7.956 0 0 1 1.777-2.04 7.963 7.963 0 0 1 2.353-1.334 7.107 7.107 0 0 1 2.569-.424 5.99 5.99 0 0 1 2.394.552Z" />
          <path d="m275.475 97.04 21.635 8.622-.031.013c4.513 1.796 8.443 4.557 11.539 8.104 3.096 3.547 5.288 7.803 6.437 12.494l26.597 108.587c1.322 5.434 9.185 5.409 12.129-.039l39.42-72.726a3.406 3.406 0 0 1 1.695-1.516 3.081 3.081 0 0 1 1.093-.234c.366-.008.719.054 1.04.184l23.062 9.202c7.58 3.016 13.152 9.046 15.49 16.766 2.338 7.719 1.251 16.496-3.021 24.4l-26.872 49.576a3.412 3.412 0 0 1-1.694 1.516 3.086 3.086 0 0 1-1.093.234 2.61 2.61 0 0 1-1.04-.184l-37.496-14.946c-1.729-.692-3.777-.64-5.694.146a9.144 9.144 0 0 0-4.526 4.042l-38.511 71.073a7.829 7.829 0 0 1-1.665 2.108 7.917 7.917 0 0 1-2.285 1.438 7.174 7.174 0 0 1-2.552.547 6.062 6.062 0 0 1-2.427-.43l-22.084-8.804c-4.513-1.797-8.444-4.557-11.54-8.104-3.095-3.547-5.288-7.803-6.437-12.494l-25.708-104.978c-1.516-6.213-10.482-6.185-13.852.039l-37.23 68.693a3.412 3.412 0 0 1-1.694 1.516 3.091 3.091 0 0 1-1.094.234 2.613 2.613 0 0 1-1.04-.184l-22.222-8.861c-7.799-3.1-13.534-9.301-15.944-17.241-2.409-7.94-1.297-16.97 3.094-25.104l26.2-48.349a3.406 3.406 0 0 1 1.696-1.516 3.08 3.08 0 0 1 1.093-.233c.366-.008.719.054 1.04.184l37.026 14.755c1.729.692 3.777.64 5.694-.145a9.14 9.14 0 0 0 4.527-4.043l38.286-70.664a8.014 8.014 0 0 1 3.966-3.548 7.249 7.249 0 0 1 2.558-.55 6.12 6.12 0 0 1 2.435.42Z" />
          <path d="m269.136 98.964 22.235 7.426-.03.014c4.638 1.547 8.743 4.079 12.05 7.433 3.308 3.353 5.745 7.455 7.156 12.042l32.671 106.161c1.627 5.313 9.536 4.884 12.202-.662l35.701-74.045c.164-.341.392-.657.67-.93a3.46 3.46 0 0 1 .952-.659c.35-.162.719-.26 1.087-.287.368-.028.727.016 1.056.128l23.703 7.928c7.79 2.597 13.724 8.282 16.497 15.806 2.772 7.524 2.157 16.271-1.711 24.318L409.04 254.11a3.332 3.332 0 0 1-.67.929 3.44 3.44 0 0 1-.952.659c-.35.162-.719.26-1.087.287a2.651 2.651 0 0 1-1.056-.128l-38.538-12.872c-1.778-.597-3.835-.44-5.721.436-1.885.876-3.444 2.4-4.334 4.236l-34.878 72.361a7.727 7.727 0 0 1-1.56 2.173 8 8 0 0 1-2.221 1.543 7.319 7.319 0 0 1-2.538.672 6.204 6.204 0 0 1-2.465-.3l-22.699-7.584c-4.638-1.547-8.743-4.079-12.051-7.432-3.307-3.354-5.745-7.456-7.155-12.042l-31.579-102.635c-1.864-6.073-10.882-5.586-13.934.75l-33.715 69.94a3.404 3.404 0 0 1-1.623 1.588c-.35.163-.719.26-1.087.288a2.672 2.672 0 0 1-1.056-.128l-22.839-7.632c-8.016-2.669-14.123-8.515-16.979-16.254-2.857-7.739-2.229-16.738 1.746-25.019l23.724-49.226a3.418 3.418 0 0 1 1.622-1.588c.35-.163.72-.26 1.087-.288a2.67 2.67 0 0 1 1.056.129l38.055 12.708c1.778.596 3.836.439 5.721-.437 1.886-.876 3.445-2.4 4.334-4.236l34.675-71.943a7.807 7.807 0 0 1 1.57-2.174 8.041 8.041 0 0 1 2.227-1.543 7.386 7.386 0 0 1 2.543-.677 6.267 6.267 0 0 1 2.473.293Z" />
          <path d="m262.864 101.175 22.78 6.213-.029.015c4.751 1.295 9.019 3.591 12.53 6.743 3.511 3.151 6.187 7.089 7.857 11.559l38.656 103.472c1.926 5.179 9.862 4.347 12.242-1.283l31.894-75.175c.147-.346.359-.671.625-.955.265-.284.578-.523.922-.702a3.188 3.188 0 0 1 1.078-.34 2.73 2.73 0 0 1 1.07.073l24.284 6.633c7.981 2.171 14.261 7.497 17.461 14.807 3.199 7.31 3.056 16.005-.398 24.175l-21.743 51.244c-.147.346-.359.67-.624.955a3.443 3.443 0 0 1-.922.701c-.343.179-.71.295-1.078.341a2.729 2.729 0 0 1-1.07-.073l-39.481-10.77c-1.821-.499-3.884-.238-5.733.727-1.85.965-3.335 2.554-4.131 4.418l-31.156 73.466a7.645 7.645 0 0 1-1.451 2.233 7.99 7.99 0 0 1-2.15 1.641 7.473 7.473 0 0 1-2.517.797 6.365 6.365 0 0 1-2.497-.171l-23.254-6.344c-4.751-1.294-9.019-3.591-12.53-6.743-3.511-3.151-6.187-7.088-7.857-11.559l-37.366-100.034c-2.207-5.92-11.254-4.974-13.981 1.459l-30.121 71.009a3.24 3.24 0 0 1-.624.955 3.41 3.41 0 0 1-.922.702 3.19 3.19 0 0 1-1.078.341 2.725 2.725 0 0 1-1.069-.073l-23.398-6.384c-8.212-2.232-14.676-7.708-17.972-15.227-3.295-7.518-3.153-16.464.395-24.87l21.195-49.979c.147-.346.359-.671.624-.955.266-.284.579-.523.922-.701.344-.179.71-.295 1.079-.341a2.725 2.725 0 0 1 1.069.073l38.986 10.63c1.822.499 3.884.238 5.733-.727 1.849-.964 3.335-2.553 4.131-4.418l30.974-73.042c.344-.81.841-1.57 1.462-2.235a8.083 8.083 0 0 1 2.156-1.643 7.528 7.528 0 0 1 2.523-.801 6.422 6.422 0 0 1 2.504.163Z" />
          <path d="m256.673 103.689 23.26 4.981-.029.017c4.852 1.037 9.272 3.092 12.977 6.033a29.097 29.097 0 0 1 8.535 11.045l44.533 100.501c2.22 5.031 10.16 3.798 12.248-1.901l27.999-76.098c.129-.351.325-.683.576-.979.252-.295.554-.547.89-.742a3.25 3.25 0 0 1 1.066-.393 2.821 2.821 0 0 1 1.081.017l24.797 5.321c8.149 1.741 14.759 6.693 18.377 13.769 3.618 7.075 3.947 15.695.917 23.965L414.813 241.1a3.22 3.22 0 0 1-.576.978 3.45 3.45 0 0 1-.889.743 3.253 3.253 0 0 1-1.067.392 2.777 2.777 0 0 1-1.08-.017l-40.314-8.636c-1.86-.401-3.921-.037-5.729 1.014-1.809 1.051-3.217 2.701-3.916 4.589l-27.351 74.368a7.516 7.516 0 0 1-1.339 2.286 7.99 7.99 0 0 1-2.074 1.736 7.565 7.565 0 0 1-2.49.919c-.86.15-1.717.137-2.521-.041l-23.745-5.088c-4.852-1.038-9.272-3.093-12.976-6.034a29.077 29.077 0 0 1-8.535-11.045l-43.048-97.162c-2.543-5.751-11.596-4.348-13.989 2.164l-26.444 71.882c-.129.35-.325.683-.576.978a3.467 3.467 0 0 1-.889.743 3.262 3.262 0 0 1-1.067.392 2.797 2.797 0 0 1-1.08-.016l-23.892-5.122c-8.385-1.788-15.187-6.88-18.913-14.157-3.726-7.278-4.07-16.145-.957-24.654l18.606-50.593c.129-.35.325-.682.576-.977.252-.296.554-.548.89-.743a3.256 3.256 0 0 1 1.066-.392 2.777 2.777 0 0 1 1.08.017l39.809 8.525c1.86.401 3.921.036 5.73-1.015 1.808-1.051 3.217-2.701 3.916-4.589l27.191-73.939a7.586 7.586 0 0 1 1.349-2.288 8.089 8.089 0 0 1 2.081-1.739 7.64 7.64 0 0 1 2.494-.923 6.547 6.547 0 0 1 2.529.033Z" />
          <path d="m250.581 106.499 23.676 3.738-.027.019c4.939.778 9.498 2.586 13.386 5.309a29.086 29.086 0 0 1 9.19 10.501l50.283 97.252c2.508 4.868 10.43 3.24 12.222-2.512l24.032-76.813c.11-.354.289-.693.526-.999a3.46 3.46 0 0 1 .855-.781c.327-.21.685-.361 1.052-.444a2.86 2.86 0 0 1 1.088-.038l25.243 3.995c8.295 1.305 15.215 5.87 19.241 12.693 4.026 6.822 4.827 15.343 2.227 23.69l-16.382 52.363c-.11.353-.289.693-.526.998a3.44 3.44 0 0 1-.855.781c-.327.21-.685.361-1.052.444a2.861 2.861 0 0 1-1.088.039l-41.036-6.482c-1.894-.302-3.948.165-5.711 1.299-1.762 1.133-3.09 2.84-3.69 4.746l-23.473 75.065a7.4 7.4 0 0 1-1.223 2.333 7.989 7.989 0 0 1-1.992 1.826 7.693 7.693 0 0 1-2.455 1.038 6.655 6.655 0 0 1-2.54.089l-24.17-3.819c-4.939-.778-9.498-2.587-13.386-5.309a29.086 29.086 0 0 1-9.19-10.501l-48.607-94.021c-2.873-5.566-11.905-3.711-13.959 2.862l-22.696 72.554c-.11.354-.289.693-.526.998a3.422 3.422 0 0 1-.855.781 3.259 3.259 0 0 1-1.052.444 2.861 2.861 0 0 1-1.088.039l-24.32-3.844c-8.534-1.34-15.657-6.034-19.802-13.05-4.145-7.017-4.975-15.782-2.305-24.37l15.967-51.066c.111-.354.29-.693.527-.999.237-.305.527-.57.854-.78a3.268 3.268 0 0 1 1.052-.444 2.861 2.861 0 0 1 1.088-.039l40.522 6.397c1.894.302 3.948-.166 5.71-1.299 1.763-1.134 3.091-2.841 3.691-4.747l23.334-74.632a7.487 7.487 0 0 1 1.234-2.336 8.046 8.046 0 0 1 1.999-1.828 7.731 7.731 0 0 1 2.46-1.043 6.715 6.715 0 0 1 2.547-.097Z" />
          <path d="m244.603 109.596 24.029 2.486-.028.019c5.012.517 9.699 2.074 13.759 4.57a29.155 29.155 0 0 1 9.819 9.929l55.894 93.738c2.788 4.693 10.672 2.673 12.161-3.116l19.999-77.315c.092-.356.253-.702.475-1.016a3.416 3.416 0 0 1 1.852-1.312c.365-.1.736-.133 1.093-.094l25.617 2.66c8.418.866 15.63 5.032 20.053 11.582 4.422 6.55 5.693 14.948 3.532 23.349l-13.635 52.704a3.128 3.128 0 0 1-.475 1.016c-.222.314-.5.592-.817.817-.318.225-.67.393-1.035.494a2.912 2.912 0 0 1-1.092.094l-41.646-4.311c-1.922-.201-3.963.367-5.675 1.58-1.712 1.214-2.955 2.973-3.456 4.891l-19.53 75.555a7.256 7.256 0 0 1-1.104 2.374 7.947 7.947 0 0 1-1.905 1.911 7.745 7.745 0 0 1-2.414 1.154 6.803 6.803 0 0 1-2.551.22l-24.529-2.541c-5.012-.517-9.698-2.073-13.758-4.57a29.163 29.163 0 0 1-9.82-9.928l-54.03-90.625c-3.194-5.364-12.182-3.063-13.891 3.553l-18.884 73.029a3.13 3.13 0 0 1-.476 1.016 3.408 3.408 0 0 1-1.852 1.311 2.912 2.912 0 0 1-1.092.094l-24.681-2.557c-8.661-.888-16.083-5.171-20.636-11.907-4.553-6.736-5.865-15.375-3.647-24.018l13.287-51.399c.092-.356.253-.701.475-1.016.222-.314.5-.592.817-.817.318-.225.67-.393 1.035-.494a2.912 2.912 0 0 1 1.092-.094l41.124 4.253c1.922.201 3.963-.367 5.675-1.58 1.712-1.214 2.955-2.973 3.455-4.891l19.415-75.12a7.338 7.338 0 0 1 1.114-2.377 8.026 8.026 0 0 1 1.912-1.914 7.835 7.835 0 0 1 2.418-1.159 6.882 6.882 0 0 1 2.557-.228Z" />
          <path d="m238.758 112.973 24.313 1.226-.027.019c5.071.255 9.872 1.555 14.093 3.82 4.222 2.264 7.772 5.441 10.421 9.328l61.347 89.969c3.062 4.505 10.884 2.099 12.068-3.711l15.913-77.604a3.07 3.07 0 0 1 .423-1.031 3.423 3.423 0 0 1 1.792-1.394 3.003 3.003 0 0 1 1.094-.149l25.923 1.317c8.517.425 16.001 4.181 20.808 10.441 4.806 6.261 6.542 14.514 4.824 22.945l-10.845 52.899a3.082 3.082 0 0 1-.423 1.031 3.406 3.406 0 0 1-1.792 1.394c-.362.119-.734.17-1.094.15l-42.142-2.127c-1.945-.101-3.968.567-5.624 1.856-1.657 1.29-2.811 3.096-3.211 5.022L351.08 304.21a7.124 7.124 0 0 1-.981 2.407 7.907 7.907 0 0 1-1.813 1.991 7.84 7.84 0 0 1-2.366 1.267 6.95 6.95 0 0 1-2.555.348l-24.82-1.255c-5.071-.254-9.872-1.555-14.093-3.819-4.222-2.265-7.772-5.442-10.421-9.329l-59.303-86.98c-3.505-5.149-12.424-2.408-13.783 4.232l-15.026 73.301a3.062 3.062 0 0 1-.423 1.031 3.387 3.387 0 0 1-1.792 1.394c-.361.119-.733.17-1.094.15l-24.975-1.265c-8.763-.434-16.465-4.295-21.413-10.733-4.949-6.438-6.739-14.926-4.978-23.601l10.57-51.59c.073-.358.217-.708.423-1.031a3.404 3.404 0 0 1 1.792-1.394 2.976 2.976 0 0 1 1.094-.149l41.611 2.098c1.945.101 3.968-.567 5.625-1.856 1.656-1.29 2.811-3.096 3.21-5.022l15.446-75.398a7.174 7.174 0 0 1 .992-2.411 7.962 7.962 0 0 1 1.819-1.994 7.879 7.879 0 0 1 2.371-1.272 7.007 7.007 0 0 1 2.561-.357Z" />
          <path d="m233.061 116.619 24.531-.035-.025.022c5.116-.009 10.017 1.033 14.388 3.058 4.372 2.026 8.116 4.99 10.994 8.704l66.629 85.954c3.326 4.304 11.067 1.518 11.94-4.297l11.784-77.678c.055-.357.18-.712.37-1.042a3.382 3.382 0 0 1 1.727-1.473 3.038 3.038 0 0 1 1.093-.204l26.155-.027c8.593-.017 16.329 3.318 21.506 9.271 5.178 5.953 7.374 14.038 6.105 22.477l-8.033 52.951c-.054.357-.18.712-.37 1.042a3.382 3.382 0 0 1-1.727 1.473 3.056 3.056 0 0 1-1.093.204l-42.517.057c-1.962 0-3.961.766-5.558 2.129-1.596 1.362-2.659 3.211-2.956 5.138l-11.505 75.908a6.959 6.959 0 0 1-.856 2.435 7.841 7.841 0 0 1-1.716 2.065 7.907 7.907 0 0 1-2.312 1.376 7.09 7.09 0 0 1-2.551.476l-25.043.033c-5.117.009-10.018-1.033-14.389-3.059-4.371-2.025-8.116-4.99-10.994-8.703l-64.408-83.099c-3.807-4.919-12.634-1.745-13.638 4.9l-11.13 73.371c-.054.357-.18.712-.37 1.042a3.396 3.396 0 0 1-1.727 1.473 3.056 3.056 0 0 1-1.093.204l-25.2.031c-8.841.02-16.801-3.407-22.131-9.529-5.33-6.122-7.594-14.437-6.294-23.119l7.826-51.639a3 3 0 0 1 .37-1.043c.189-.33.44-.631.736-.883.296-.253.633-.453.991-.589a3.036 3.036 0 0 1 1.093-.205l41.984-.06c1.962 0 3.961-.766 5.558-2.129 1.596-1.362 2.66-3.211 2.956-5.138l11.438-75.469a7.061 7.061 0 0 1 .866-2.439 7.94 7.94 0 0 1 1.723-2.068 7.98 7.98 0 0 1 2.316-1.382 7.172 7.172 0 0 1 2.557-.485Z" />
          <path d="m227.527 120.524 24.682-1.295-.025.023c5.148-.272 10.136.508 14.645 2.289 4.508 1.782 8.438 4.525 11.536 8.055l71.723 81.705c3.581 4.092 11.217.935 11.78-4.869l7.628-77.537a2.92 2.92 0 0 1 .315-1.052 3.404 3.404 0 0 1 1.658-1.548 3.105 3.105 0 0 1 1.089-.258l26.316-1.372c8.645-.458 16.61 2.448 22.144 8.078 5.534 5.63 8.183 13.525 7.366 21.948l-5.2 52.855a2.924 2.924 0 0 1-.314 1.052 3.343 3.343 0 0 1-.694.913 3.383 3.383 0 0 1-.965.634 3.101 3.101 0 0 1-1.089.259l-42.777 2.241c-1.975.101-3.945.962-5.477 2.394-1.532 1.431-2.501 3.316-2.695 5.24l-7.442 75.769a6.838 6.838 0 0 1-.729 2.456 7.747 7.747 0 0 1-1.615 2.133 7.906 7.906 0 0 1-2.251 1.482 7.235 7.235 0 0 1-2.542.603l-25.196 1.319c-5.148.272-10.136-.508-14.645-2.29-4.509-1.781-8.438-4.525-11.536-8.054l-69.334-78.991c-4.099-4.676-12.806-1.08-13.456 5.554l-7.2 73.238a2.94 2.94 0 0 1-.315 1.052 3.422 3.422 0 0 1-1.658 1.548 3.11 3.11 0 0 1-1.089.258l-25.352 1.326c-8.895.474-17.091-2.512-22.788-8.301-5.696-5.789-8.427-13.908-7.592-22.573l5.062-51.546c.035-.358.142-.715.315-1.052.173-.338.408-.648.693-.914.285-.265.613-.481.965-.634a3.134 3.134 0 0 1 1.089-.259l42.242-2.217c1.974-.101 3.944-.961 5.477-2.393 1.532-1.431 2.501-3.316 2.695-5.241l7.395-75.331a6.885 6.885 0 0 1 .739-2.46 7.838 7.838 0 0 1 1.62-2.137 8.013 8.013 0 0 1 2.255-1.487 7.294 7.294 0 0 1 2.547-.613Z" />
          <path d="m222.172 124.678 24.764-2.551-.023.024c5.164-.533 10.226-.018 14.86 1.515 4.634 1.532 8.737 4.048 12.046 7.384l76.617 77.234c3.826 3.869 11.337.35 11.589-5.427l3.451-77.184c.016-.356.104-.715.259-1.058.156-.343.376-.662.647-.94.272-.277.59-.508.936-.678a3.16 3.16 0 0 1 1.082-.312l26.405-2.709c8.674-.898 16.846 1.57 22.721 6.861 5.875 5.292 8.971 12.974 8.608 21.357l-2.353 52.614a2.889 2.889 0 0 1-.259 1.058 3.306 3.306 0 0 1-.648.94c-.272.277-.59.507-.936.677a3.16 3.16 0 0 1-1.081.312l-42.922 4.419c-1.981.202-3.916 1.155-5.38 2.652-1.464 1.496-2.337 3.413-2.427 5.329l-3.361 75.422a6.673 6.673 0 0 1-.599 2.47 7.697 7.697 0 0 1-1.508 2.195 7.987 7.987 0 0 1-2.185 1.584 7.368 7.368 0 0 1-2.524.727l-25.281 2.601c-5.165.534-10.227.018-14.86-1.514-4.634-1.533-8.737-4.048-12.047-7.385l-74.066-74.668c-4.379-4.421-12.944-.411-13.237 6.193l-3.254 72.902a2.87 2.87 0 0 1-.26 1.058 3.283 3.283 0 0 1-.647.94c-.272.277-.59.508-.936.677-.347.17-.714.276-1.082.312l-25.438 2.617c-8.923.926-17.333-1.61-23.379-7.05-6.047-5.441-9.237-13.341-8.869-21.965L158.88 222c.016-.355.104-.715.26-1.057a3.407 3.407 0 0 1 1.582-1.617c.346-.17.713-.276 1.081-.312l42.382-4.366c1.981-.202 3.916-1.156 5.38-2.652 1.464-1.496 2.337-3.413 2.427-5.329l3.34-74.989a6.748 6.748 0 0 1 .609-2.474 7.761 7.761 0 0 1 1.514-2.2 8.04 8.04 0 0 1 2.188-1.589 7.458 7.458 0 0 1 2.529-.737Z" />
          <path d="m217.012 129.069 24.778-3.799-.023.025c5.168-.794 10.289-.543 15.035.737s9.011 3.56 12.523 6.694l81.297 72.552c4.062 3.633 11.428-.236 11.365-5.971l-.733-76.616a2.808 2.808 0 0 1 .204-1.06c.138-.348.342-.675.6-.964.259-.289.566-.534.905-.72a3.2 3.2 0 0 1 1.071-.364l26.419-4.041c8.678-1.335 17.036.69 23.235 5.628 6.199 4.939 9.733 12.387 9.824 20.709l.5 52.227c.004.352-.065.713-.203 1.06a3.238 3.238 0 0 1-.601.964 3.423 3.423 0 0 1-.905.719c-.339.186-.703.31-1.07.365l-42.946 6.581c-1.983.302-3.878 1.346-5.27 2.903-1.391 1.557-2.165 3.501-2.151 5.403l.725 74.868a6.514 6.514 0 0 1-.469 2.477 7.56 7.56 0 0 1-1.398 2.252 7.98 7.98 0 0 1-2.111 1.68 7.487 7.487 0 0 1-2.501.851l-25.295 3.875c-5.167.793-10.288.542-15.034-.737-4.746-1.28-9.011-3.56-12.523-6.695l-78.588-70.144c-4.647-4.153-13.047.258-12.982 6.814l.697 72.367c.003.352-.066.713-.204 1.06a3.242 3.242 0 0 1-.6.964 3.443 3.443 0 0 1-.905.72 3.2 3.2 0 0 1-1.071.364l-25.453 3.898c-8.929 1.376-17.528-.703-23.909-5.78-6.381-5.078-10.021-12.739-10.12-21.299l-.495-50.933a2.792 2.792 0 0 1 .204-1.06c.138-.348.342-.675.6-.964.258-.289.566-.534.905-.72a3.223 3.223 0 0 1 1.071-.364l42.406-6.502c1.983-.301 3.878-1.345 5.269-2.903 1.392-1.557 2.166-3.5 2.152-5.402l-.726-74.434a6.582 6.582 0 0 1 .478-2.482c.323-.813.8-1.58 1.404-2.256a8.04 8.04 0 0 1 2.114-1.687 7.564 7.564 0 0 1 2.505-.86Z" />
          <path d="m212.06 133.683 24.723-5.035-.021.026c5.156-1.052 10.323-1.066 15.167-.043 4.845 1.024 9.261 3.063 12.965 5.987l85.751 67.673c4.283 3.391 11.484-.821 11.108-6.497l-4.91-75.838a2.734 2.734 0 0 1 .147-1.061c.12-.351.307-.686.551-.985.245-.299.541-.557.872-.759.331-.202.69-.343 1.057-.416l26.362-5.358c8.66-1.768 17.179-.193 23.685 4.379 6.507 4.572 10.468 11.768 11.013 20.004l3.348 51.697c.023.349-.027.709-.147 1.06a3.2 3.2 0 0 1-.552.986 3.44 3.44 0 0 1-.871.759 3.28 3.28 0 0 1-1.058.416l-42.851 8.724c-1.979.4-3.829 1.532-5.144 3.146-1.315 1.613-1.987 3.578-1.869 5.46l4.809 74.107a6.366 6.366 0 0 1-.336 2.477 7.487 7.487 0 0 1-1.284 2.302 7.997 7.997 0 0 1-2.033 1.772 7.61 7.61 0 0 1-2.47.971l-25.241 5.137c-5.156 1.052-10.323 1.066-15.167.043-4.845-1.024-9.261-3.063-12.965-5.986l-82.895-65.428c-4.901-3.873-13.112.926-12.69 7.414l4.643 71.632c.023.349-.027.709-.147 1.061-.12.351-.307.686-.551.985a3.444 3.444 0 0 1-.872.759c-.331.202-.69.343-1.057.416l-25.397 5.168c-8.909 1.822-17.675.205-24.371-4.496-6.697-4.701-10.777-12.1-11.343-20.573l-3.273-50.415a2.734 2.734 0 0 1 .147-1.061c.12-.351.308-.686.552-.985a3.44 3.44 0 0 1 .871-.759 3.25 3.25 0 0 1 1.058-.416l42.312-8.618c1.979-.4 3.829-1.532 5.144-3.145 1.315-1.614 1.987-3.578 1.87-5.461l-4.785-73.678a6.426 6.426 0 0 1 .346-2.483 7.521 7.521 0 0 1 1.289-2.306 8.044 8.044 0 0 1 2.037-1.779 7.675 7.675 0 0 1 2.473-.98Z" />
          <path d="m207.329 138.508 24.601-6.256-.02.027c5.131-1.307 10.328-1.586 15.258-.822 4.93.765 9.485 2.558 13.371 5.263l89.964 62.612c4.494 3.138 11.51-1.402 10.823-7.004l-9.072-74.849a2.68 2.68 0 0 1 .09-1.058c.102-.354.272-.695.502-1.004a3.399 3.399 0 0 1 1.876-1.263l26.232-6.66c8.616-2.196 17.274-1.074 24.069 3.12 6.796 4.194 11.173 11.116 12.17 19.244l6.185 51.024c.042.345.011.705-.09 1.058a3.15 3.15 0 0 1-.502 1.005 3.417 3.417 0 0 1-.835.796 3.295 3.295 0 0 1-1.041.466l-42.64 10.84c-1.969.498-3.768 1.713-5.003 3.379-1.236 1.666-1.805 3.645-1.584 5.504l8.876 73.14a6.19 6.19 0 0 1-.204 2.47 7.312 7.312 0 0 1-1.166 2.346 7.962 7.962 0 0 1-1.949 1.86 7.69 7.69 0 0 1-2.432 1.088l-25.115 6.383c-5.13 1.307-10.327 1.587-15.257.822s-9.484-2.557-13.37-5.262l-86.97-60.535c-5.143-3.584-13.144 1.589-12.365 7.994l8.575 70.699c.042.345.011.705-.09 1.058a3.15 3.15 0 0 1-.502 1.005 3.417 3.417 0 0 1-.835.796 3.318 3.318 0 0 1-1.041.466l-25.271 6.423c-8.865 2.261-17.772 1.11-24.766-3.201s-11.502-11.429-12.533-19.791l-6.04-49.758a2.68 2.68 0 0 1 .09-1.058c.102-.354.272-.695.502-1.004a3.399 3.399 0 0 1 1.876-1.263l42.103-10.707c1.969-.498 3.769-1.714 5.004-3.38 1.235-1.665 1.805-3.645 1.584-5.504l-8.829-72.719a6.264 6.264 0 0 1 .213-2.476 7.369 7.369 0 0 1 1.172-2.35 7.995 7.995 0 0 1 1.951-1.866 7.753 7.753 0 0 1 2.435-1.098Z" />
        </g>
      </g>
      <text
        xmlSpace="preserve"
        fill="#fff"
        fontFamily="Inter"
        fontSize="20"
        fontWeight="500"
        letterSpacing="-.02em"
        style={{ whiteSpace: 'pre' }}
        className="fs-exclude"
      >
        <tspan x="24" y="233">
          {formattedNameOnCard}
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        fill="#fff"
        fontFamily="Inter"
        fontSize="20"
        fontWeight="500"
        letterSpacing="-.02em"
        style={{ whiteSpace: 'pre' }}
        className="fs-exclude"
      >
        <tspan x="24" y="171.273">
          {expirationDate}
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        fill="#fff"
        fontFamily="Inter"
        fontSize="20"
        fontWeight="500"
        letterSpacing="-.02em"
        style={{ whiteSpace: 'pre' }}
        className="fs-exclude"
      >
        <tspan x="126" y="171.273">
          {formattedCVV}
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        fill="#fff"
        fontFamily="Inter"
        fontSize="12"
        letterSpacing="-.02em"
        style={{ whiteSpace: 'pre' }}
      >
        <tspan x="24" y="146.364">
          VALID THRU
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        fill="#fff"
        fontFamily="Inter"
        fontSize="12"
        letterSpacing="-.02em"
        style={{ whiteSpace: 'pre' }}
      >
        <tspan x="126" y="146.364">
          CVV
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        fill="#fff"
        fontFamily="Inter"
        fontSize="24"
        fontWeight="500"
        letterSpacing=".03em"
        style={{ whiteSpace: 'pre' }}
        className="fs-exclude"
      >
        {[...formattedCardNumber].map((letter, index) => {
          return (
            <tspan x={index * 14 + 24} y="104.727" key={Math.random()}>
              {letter}
            </tspan>
          );
        })}
      </text>
      {isMasked ? null : (
        <foreignObject width="24" height="24" x={350} y={84}>
          <CopyToClipboardActionIcon numberToCopy={cardNumber} />
        </foreignObject>
      )}
      <g fill="#fff" clipPath="url(#e)">
        <path d="M44.505 32.867v-2.615a.689.689 0 0 0-.685-.691h-8.136a.787.787 0 0 1-.783-.79v-4.475a.295.295 0 0 0-.294-.296H29.04c-1.948 0-3.528 1.594-3.528 3.558v2.687c0 .163.132.296.294.296h7.91c.715 0 1.056.889.526 1.376l-8.97 8.21A3.923 3.923 0 0 0 24 43.02v2.67c0 .38.307.69.685.69h8.187c.432 0 .783.355.783.79v4.533c0 .163.132.296.294.296h5.708c1.894 0 3.427-1.55 3.427-3.456v-2.785a.295.295 0 0 0-.294-.297h-8.376c-.627 0-.925-.78-.459-1.202l9.28-8.492a3.923 3.923 0 0 0 1.273-2.894l-.003-.007ZM88.86 37.93c0 5.006-3.649 8.717-8.804 8.717s-8.804-3.711-8.804-8.717c0-5.005 3.65-8.683 8.804-8.683 5.155 0 8.804 3.712 8.804 8.683Zm-3.082 0c0-4.028-2.67-6.207-5.725-6.207-3.055 0-5.726 2.179-5.726 6.207 0 4.028 2.67 6.245 5.726 6.245 3.055 0 5.725-2.18 5.725-6.245ZM128 37.93c0 5.006-3.649 8.717-8.804 8.717s-8.804-3.711-8.804-8.717c0-5.005 3.649-8.683 8.804-8.683S128 32.96 128 37.93Zm-3.082 0c0-4.028-2.67-6.207-5.726-6.207-3.055 0-5.725 2.179-5.725 6.207 0 4.028 2.67 6.245 5.725 6.245 3.056 0 5.726-2.18 5.726-6.245ZM98.937 29.557H95.95a1.906 1.906 0 0 0-1.904 1.914v.691c0 .259-.206.47-.463.477h-2.052a.431.431 0 0 0-.43.432v13.065c0 .12.095.215.213.215h2.56a.431.431 0 0 0 .428-.432V33.04h.003a.472.472 0 0 1 .476-.436h4.156a.431.431 0 0 0 .429-.433V29.99a.431.431 0 0 0-.429-.433ZM108.869 29.557h-2.988a1.907 1.907 0 0 0-1.904 1.914v.691c0 .259-.205.47-.462.477h-2.053a.43.43 0 0 0-.428.432v13.065c0 .12.094.215.212.215h2.559a.431.431 0 0 0 .429-.432V33.04h.003a.473.473 0 0 1 .476-.436h4.156a.432.432 0 0 0 .429-.433V29.99a.432.432 0 0 0-.429-.433ZM68.96 33.14c.381-.457.59-1.036.59-1.635v-1.512a.428.428 0 0 0-.425-.43H55.831c-.939 0-1.698.767-1.698 1.714v.83c0 .184.115.341.277.399h10.92c.186 0 .274.231.136.357L54.352 42.8a2.548 2.548 0 0 0-.59 1.634v1.512c0 .235.188.429.425.429h13.605c.938 0 1.698-.766 1.698-1.713v-.848c0-.235-.19-.429-.426-.429H57.991c-.185 0-.273-.231-.135-.357l11.104-9.892v.003Z" />
      </g>
      <path
        fill="#fff"
        d="m322.375 208.457-10.472 25.145h-6.835l-5.149-20.065c-.317-1.237-.588-1.688-1.539-2.213-1.557-.847-4.118-1.642-6.38-2.137l.158-.73h10.997c1.402 0 2.663.942 2.98 2.564l2.723 14.554 6.726-17.118h6.791Zm26.773 16.932c.031-6.633-9.12-6.994-9.055-9.961.018-.906.871-1.865 2.74-2.107.927-.124 3.481-.215 6.375 1.122l1.134-5.33c-1.557-.568-3.559-1.113-6.046-1.113-6.389 0-10.887 3.42-10.925 8.312-.041 3.619 3.209 5.638 5.661 6.843 2.518 1.233 3.36 2.02 3.351 3.126-.016 1.687-2.009 2.425-3.867 2.458-3.251.051-5.136-.884-6.642-1.589l-1.171 5.512c1.509.697 4.301 1.302 7.191 1.338 6.791 0 11.233-3.383 11.254-8.611Zm16.872 8.213H372l-5.218-25.145h-5.52c-1.241 0-2.287.728-2.753 1.844l-9.694 23.301h6.785l1.347-3.755h8.294l.779 3.755Zm-7.213-8.909 3.404-9.441 1.958 9.441h-5.362Zm-27.194-16.236-5.348 25.145h-6.466l5.35-25.145h6.464Z"
      />
      <defs>
        <linearGradient
          id="a"
          x1="36"
          x2="388.5"
          y1="36.75"
          y2="258"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8033EF" />
          <stop offset="1" stopColor="#3C0789" />
        </linearGradient>
        <linearGradient
          id="b"
          x1="36"
          x2="388.5"
          y1="36.75"
          y2="258"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8033EF" />
          <stop offset="1" stopColor="#3C0789" />
        </linearGradient>
        <clipPath id="e">
          <path fill="#fff" d="M24 24h104v28H24z" />
        </clipPath>
        <clipPath id="f">
          <path fill="#fff" d="M284 84h24v24h-24z" />
        </clipPath>
      </defs>
    </svg>
  );
};
