import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

export const useMobileDetector = (): {
  isMobile: boolean;
  isDesktop: boolean;
} => {
  const theme = useMantineTheme();
  const isMobile =
    useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, false) || false;

  return { isMobile, isDesktop: !isMobile };
};
