import { S3 } from '@aws-sdk/client-s3';
import { EnvParams } from '@zorro/environment';

export const s3Client = new S3({
  region: process.env[EnvParams.ZORRO_AWS_REGION],
  credentials: {
    accessKeyId: process.env[EnvParams.ZORRO_AWS_ACCESS_KEY_ID]!,
    secretAccessKey: process.env[EnvParams.ZORRO_AWS_SECRET_ACCESS_KEY]!,
  },
});
