import { Stack, Text, Title } from '@zorro/zorro-ui-design';
import { ReactNode } from 'react';

type StepHeadingProps = {
  title: string;
  subtitle?: string | ReactNode;
  subtitleColor?: string;
  align?: 'left' | 'center' | 'right';
};

export function StepHeading({
  title,
  subtitle,
  subtitleColor = 'zorroGray.4',
  align = 'left',
}: StepHeadingProps) {
  return (
    <Stack align={align} gap="xs">
      <Title ta={align}>{title}</Title>

      {subtitle && (
        <Text ta={align} c={subtitleColor}>
          {subtitle}
        </Text>
      )}
    </Stack>
  );
}
