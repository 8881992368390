/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum EmployeePaymentMethodStatus {
  EMPTY = 'EMPTY',
  VALID = 'VALID',
  INVALID = 'INVALID',
  NEEDS_HANDLING = 'NEEDS_HANDLING',
}
