import { Stack, Text } from '@zorro/zorro-ui-design';

import { ModalFooter } from '../../index';
import { useSupportInformationForm } from './useSupportInformationForm';

type ShareSupportInformationFormProps = {
  onClose: () => void;
};

const ShareSupportInformationForm = ({
  onClose,
}: ShareSupportInformationFormProps) => {
  const { handleSubmit } = useSupportInformationForm({ onSuccess: onClose });

  return (
    <Stack gap="xxl">
      <Text>
        This will share information about your current session to help resolve
        your issue more effectively.
      </Text>
      <ModalFooter
        onClose={onClose}
        submitVariant="primary"
        submitText="Confirm"
        onSubmit={handleSubmit}
      />
    </Stack>
  );
};

export { ShareSupportInformationForm };
