import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import { RefObject, useCallback, useEffect } from 'react';

type ThrottleType = 'throttle' | 'debounce' | 'instant';

type UseScrollEventProps = {
  elementRef?: RefObject<HTMLElement>;
  onScroll: (scrollPercentage: number) => void;
  options?: {
    throttleType?: ThrottleType;
    milliseconds?: number;
  };
};

export const useScrollEvent = ({
  elementRef,
  onScroll: onScrollProp,
  options = {},
}: UseScrollEventProps) => {
  const handleMouseScroll = useCallback(() => {
    const element = elementRef?.current || window.document.documentElement;
    const clientHeight = element.scrollHeight - element.clientHeight;
    onScrollProp(element.scrollTop / clientHeight);
  }, [elementRef, onScrollProp]);

  useEffect(() => {
    const { throttleType = 'throttle', milliseconds = 250 } = options;
    let onScroll: () => void;

    if (throttleType === 'throttle') {
      onScroll = throttle(handleMouseScroll, milliseconds, { trailing: true });
    } else if (throttleType === 'debounce') {
      onScroll = debounce(handleMouseScroll, milliseconds, { leading: true });
    } else {
      onScroll = handleMouseScroll;
    }

    const element = elementRef?.current || window;
    element?.addEventListener('scroll', onScroll);

    return () => element?.removeEventListener('scroll', onScroll);
  }, [elementRef, handleMouseScroll, options]);
};
