import { PlanMarket } from '@zorro/clients';
import { VALIDATION_MESSAGES } from '@zorro/shared/utils';
import * as yup from 'yup';

import { PlanMarketConfig } from '../../PlanMarketEnrollment';

export const planMarketEnrollmentValues: (keyof typeof PlanMarketConfig)[] = [
  PlanMarket.ON_EXCHANGE,
  PlanMarket.OFF_EXCHANGE,
  'NOT_APPLICABLE',
] as const;

export const confirmEnrollmentSchema = yup.object({
  planMarket: yup
    .string()
    .required(VALIDATION_MESSAGES.planMarketRequired)
    .oneOf(planMarketEnrollmentValues, VALIDATION_MESSAGES.planMarketRequired),
});

export type ConfirmEnrollmentFormFields = yup.InferType<
  typeof confirmEnrollmentSchema
>;
