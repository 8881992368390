import { EmployerSignupStatus } from '@zorro/clients';
import { useDynamicDonRouter, useMonolithQuery } from '@zorro/shared/utils';

import { useCompanyDetailsForm } from '../../CompanyDetailsForm';
import { useCompanySetupForm } from '../../CompanySetupForm';
import { useContactsForm } from '../../ContactsForm';
import { useMonolithMutation } from '../../hooks';
import {
  AddEmployerStep,
  AddEmployerStepType,
  HandleStepSubmit,
} from '../AddEmployerStep';
import { CompanyDetailsCard } from './Cards/CompanyDetailsCard';
import { CompanySetupCard } from './Cards/CompanySetupCard';
import { ContactsCard } from './Cards/ContactsCard';

const isFinalizationMode = true;

type Props = {
  employerId: string;
};

export const EmployerReviewForm = ({ employerId }: Props) => {
  const { navigateToRoute } = useDynamicDonRouter();
  const { mutate: mutateEmployer } = useMonolithMutation({
    method: 'employersControllerUpdate',
    shouldReloadPage: false,
  });

  const { data: employer } = useMonolithQuery({
    method: 'employersControllerFindOne',
    params: employerId && [employerId],
  });

  const { companyDetailsForm } = useCompanyDetailsForm({
    employerId,
    isFinalizationMode,
  });
  const { contactsForm } = useContactsForm(employerId, isFinalizationMode);
  const { companySetupForm } = useCompanySetupForm(
    employerId,
    isFinalizationMode
  );

  const isSubmitDisabled =
    !companyDetailsForm.formState.isValid ||
    !contactsForm.formState.isValid ||
    !companySetupForm.formState.isValid;

  const handleSubmit: HandleStepSubmit = async () => {
    if (!employer) return { navigate: false };

    try {
      await mutateEmployer([
        employerId,
        {
          zorroPartnerId: employer.zorroPartnerId,
          isProspect: false,
          signupStatus: EmployerSignupStatus.NEEDS_REVIEW,
        },
      ]);
      navigateToRoute('ADD_EMPLOYER_THANK_YOU', [employerId]);
    } catch {
      /* empty */
    }

    return { navigate: false };
  };

  return (
    <AddEmployerStep
      submitLabel="Complete company profile"
      isFinalizationMode={isFinalizationMode}
      isSubmitDisabled={isSubmitDisabled}
      step={AddEmployerStepType.REVIEW}
      title="Review employer info"
      onSubmit={handleSubmit}
      employerId={employerId}
      isDon
    >
      <CompanyDetailsCard
        isFinalizationMode={isFinalizationMode}
        isValid={companyDetailsForm.formState.isValid}
        fields={companyDetailsForm.watch()}
        employerId={employerId}
      />
      <ContactsCard
        isFinalizationMode={isFinalizationMode}
        isValid={contactsForm.formState.isValid}
        fields={contactsForm.watch()}
        employerId={employerId}
      />
      <CompanySetupCard
        isFinalizationMode={isFinalizationMode}
        isValid={companySetupForm.formState.isValid}
        fields={companySetupForm.watch()}
        employerId={employerId}
      />
    </AddEmployerStep>
  );
};
