import { Box, Text } from '@zorro/zorro-ui-design';
import _noop from 'lodash/noop';

import classNames from './CardSelector.module.scss';
import { TCardSelectorOption } from './cardSelector.types';

type CardOptionProps = {
  item: TCardSelectorOption;
  isSelected: boolean;
  onClick: (value: TCardSelectorOption) => void;
};

export const CardOption = ({ item, isSelected, onClick }: CardOptionProps) => {
  const { value, label } = item;

  const props = {
    'data-selected': isSelected,
    onClick: isSelected ? _noop : onClick,
  };

  return (
    <Box
      key={value}
      px="xs"
      py="xxs"
      className={classNames['option']}
      {...props}
    >
      <Text>{label}</Text>
    </Box>
  );
};
