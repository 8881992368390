import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const IconZorroProcess = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.15686 14.2091C3.99179 11.992 5.29012 9.13117 7.72501 8.54448C11.2136 7.70391 15.6428 7.21774 20.2252 9.24925C25.3939 11.5407 27.2756 14.7853 28.5711 18.1633C29.3404 20.169 28.3135 22.5058 26.3029 23.262C23.0553 24.4834 19.1229 25.3007 14.0969 23.0726C9.39138 20.9865 6.86101 17.452 5.15686 14.2091Z"
          fill="#FFD880"
        />
        <path
          d="M23 8H24.093C26.2508 8 28 9.74921 28 11.907V11.907C28 14.0647 26.2508 15.814 24.093 15.814H10.093C7.83251 15.814 6 17.6465 6 19.907V19.907C6 22.1675 7.83251 24 10.093 24H10.5"
          stroke="#1A1721"
          strokeWidth="1.5"
        />
        <circle cx="9" cy="8" r="3.25" stroke="#1A1721" strokeWidth="1.5" />
        <circle cx="14" cy="24" r="3.25" stroke="#1A1721" strokeWidth="1.5" />
        <circle cx="20" cy="8" r="3.25" stroke="#1A1721" strokeWidth="1.5" />
        <circle cx="25" cy="24" r="3.25" stroke="#1A1721" strokeWidth="1.5" />
        <path d="M17 24H21.5" stroke="#1A1721" strokeWidth="1.5" />
        <path d="M12 8H16.5" stroke="#1A1721" strokeWidth="1.5" />
      </svg>
    </Center>
  );
};
