import { IconProps } from '@tabler/icons-react';
import { ElementType } from 'react';

import { brand } from '../theme';

export type IIconProps = IconProps & {
  icon: ElementType;
};

export function ZorroIcon({
  icon,
  color = brand.zorroCoal900,
  size = 24,
  stroke = 1.5,
  ...props
}: IIconProps) {
  const Icon = icon;

  return <Icon color={color} size={size} stroke={stroke} {...props} />;
}
