import { Container, ContainerProps } from '@mantine/core';

export type IContainerProps = ContainerProps;

const DEFAULT_PAGE_WIDTH = 700;

export function ZorroContainer({
  size = DEFAULT_PAGE_WIDTH,
  pb = 'xl',
  mb = 'xl',
  ...props
}: IContainerProps) {
  return <Container size={size} pb={pb} mb={mb} {...props} />;
}
