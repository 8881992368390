import { Tabs, TabsProps } from '@mantine/core';
import { ForwardedRef, forwardRef } from 'react';

import classNames from './TabButtonGroup.module.scss';

export type ITabButtonGroupProps = TabsProps;

export const ZorroTabButtonGroup = forwardRef(
  (
    { value, onChange, ...props }: ITabButtonGroupProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    /*
     * INFO: We trigger onBlur in the onChange event here because it can be confusing that
     * a click outside the component is required to get rid of validation errors
     */
    const handleTabChange = (value: string | null) => {
      onChange?.(value);
      /*
       * INFO: onTabChange() from Mantine doesn't pass on event arg which onBlur() wants,
       * but blurring works correctly here without args as well
       */
      // @ts-expect-error Expected 1 arguments, but got 0.
      props.onBlur?.();
    };

    return (
      <Tabs
        ref={ref}
        value={value}
        classNames={classNames}
        onChange={handleTabChange}
        {...props}
      />
    );
  }
);

ZorroTabButtonGroup.displayName = 'ZorroTabButtonGroup';
