import {
  EmployeeDto,
  GetUserRolesDto,
  OnboardingPeriodReportDto,
  Roles,
} from '@zorro/clients';
import {
  DateUtilInstance,
  formatDateEnLocaleRange,
  getDateUtil,
  parseDateISO,
} from '@zorro/shared/formatters';

import { EnrollmentStatusIconTooltip } from '../EnrollmentStatus/EnrollmentStatusIconTooltip';
import { EmployeePageSidebarSubitemProps } from './EmployeePageSidebar/EmployeePageSidebarSubitem';

export const sortOnboardingPeriodsForEmployeePageSidebar = (
  first: OnboardingPeriodReportDto,
  second: OnboardingPeriodReportDto
): number => {
  const firstStartOfCoverage = parseDateISO(
    first.majorMedicalBenefit?.benefitEnrollment?.effectiveFrom ||
      first.targetEnrollmentDate
  );

  const secondStartOfCoverage = parseDateISO(
    second.majorMedicalBenefit?.benefitEnrollment?.effectiveFrom ||
      second.targetEnrollmentDate
  );

  return firstStartOfCoverage.isAfter(secondStartOfCoverage) ? -1 : 1;
};

type MapOnboardingPeriodToEmployeePageSidebarSubitemProps = {
  onboardingPeriod: OnboardingPeriodReportDto;
  onClick: () => void;
  currentOnboardingPeriodId?: string;
  employee?: EmployeeDto;
};

export const mapOnboardingPeriodToEmployeeSidebarSubitem = ({
  onClick,
  employee,
  onboardingPeriod,
  currentOnboardingPeriodId,
}: MapOnboardingPeriodToEmployeePageSidebarSubitemProps): EmployeePageSidebarSubitemProps => {
  const {
    id,
    majorMedicalBenefit,
    isSpecialEnrollment,
    enrollmentEffectiveFrom,
    enrollmentEffectiveUntil,
    targetEnrollmentDate,
    enrollmentStatus,
  } = onboardingPeriod;
  const benefitEnrollment = majorMedicalBenefit?.benefitEnrollment;

  const coverageStart = benefitEnrollment
    ? benefitEnrollment.effectiveFrom
    : targetEnrollmentDate;

  let coverageEnd: DateUtilInstance;
  if (benefitEnrollment) {
    coverageEnd = parseDateISO(benefitEnrollment.effectiveUntil);
  } else if (employee?.eligibleUntil) {
    coverageEnd = getDateUtil().min(
      parseDateISO(enrollmentEffectiveUntil),
      parseDateISO(employee.eligibleUntil)
    );
  } else {
    coverageEnd = parseDateISO(enrollmentEffectiveUntil);
  }

  const year = parseDateISO(enrollmentEffectiveFrom).year();
  const yearLabel = isSpecialEnrollment
    ? 'Special enrollment'
    : 'Open enrollment';

  return {
    id,
    onClick,
    label: `${yearLabel} ${year}`,
    isChecked: currentOnboardingPeriodId === id,
    description: formatDateEnLocaleRange(coverageStart, coverageEnd),
    rightContent: (
      <EnrollmentStatusIconTooltip
        enrollmentStatus={enrollmentStatus}
        iconSize={30}
      />
    ),
  };
};

export const getEmployeePageRole = (userRoles: GetUserRolesDto): Roles => {
  return userRoles.roles.includes(Roles.EMPLOYER_ADMIN)
    ? Roles.EMPLOYER_ADMIN
    : Roles.EMPLOYEE;
};
