import { yupResolver } from '@hookform/resolvers/yup';
import { PaymentBy } from '@zorro/clients';
import { formatDateISO, parseDateISO } from '@zorro/shared/formatters';
import { useForm } from '@zorro/shared/utils';
import {
  Button,
  Center,
  DateRangeInput,
  FormErrorMessage,
  Group,
  Radio,
  RadioGroup,
  Space,
  Stack,
} from '@zorro/zorro-ui-design';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

import { EmployeeRow } from '../../EmployeesDatatable';
import { InformationBoxComponent } from '../../InformationBox';
import { useMonolithMutation } from '../../hooks/useMonolithMutation';

const EmployeeLeaveOfAbsenceFormSchema = yup.object({
  leaveDates: yup
    .tuple([yup.date().required().nullable(), yup.date().required().nullable()])
    .required(),
  paymentBy: yup.string().oneOf(Object.values(PaymentBy)).required(),
});

type EmployeeLeaveOfAbsenceFormFields = yup.InferType<
  typeof EmployeeLeaveOfAbsenceFormSchema
>;

type EmployeeLeaveOfAbsenceFormProps = {
  selectedEmployees: Pick<EmployeeRow, 'id' | 'leaveOfAbsence'>[];
  onSuccess?: () => void;
};

export function EmployeeLeaveOfAbsenceForm({
  selectedEmployees,
  onSuccess,
}: EmployeeLeaveOfAbsenceFormProps) {
  const singleEmployee = selectedEmployees[0];

  const { tryMutate: mutateEmployeeByAdmin } = useMonolithMutation({
    method: 'employeesControllerUpdateByAdmin',
    successMessage: 'Leave of absence set successfully!',
  });

  const {
    handleSubmit,
    control,
    formState: { isValid, isSubmitting, errors },
  } = useForm<EmployeeLeaveOfAbsenceFormFields>({
    mode: 'all',
    resolver: yupResolver(EmployeeLeaveOfAbsenceFormSchema),
    defaultValues: {
      leaveDates:
        singleEmployee?.leaveOfAbsence?.startDate &&
        singleEmployee?.leaveOfAbsence.endDate
          ? [
              parseDateISO(singleEmployee.leaveOfAbsence.startDate).toDate(),
              parseDateISO(singleEmployee.leaveOfAbsence.endDate).toDate(),
            ]
          : undefined,
      paymentBy: singleEmployee?.leaveOfAbsence?.paymentBy || undefined,
    },
  });

  if (!singleEmployee) {
    return null;
  }

  async function setLeaveOfAbsence(
    formFields: EmployeeLeaveOfAbsenceFormFields
  ) {
    const { leaveDates, paymentBy } = formFields;

    if (leaveDates.length !== 2 || !(leaveDates[0] && leaveDates[1])) {
      return;
    }

    const mutateResult = await mutateEmployeeByAdmin([
      singleEmployee.id,
      {
        leaveOfAbsence: {
          startDate: formatDateISO(leaveDates[0]),
          endDate: formatDateISO(leaveDates[1]),
          paymentBy,
        },
      },
    ]);

    if (mutateResult.isOk()) {
      onSuccess?.();
    }
  }

  return (
    <Stack>
      <InformationBoxComponent
        title="Here's what to expect:"
        items={[
          "After you set a leave of absence, the Zorro team will temporarily suspend the employee's allowance in the system.",
          'The employee will remain enrolled in their plan. Depending on the payment set-up during the leave, they may need to contact the carrier to replace the Zorro card with another form of payment.',
        ]}
      />

      <form onSubmit={handleSubmit(setLeaveOfAbsence)}>
        <Stack>
          <Controller
            control={control}
            name="leaveDates"
            render={({ field }) => {
              return (
                <DateRangeInput
                  {...field}
                  required
                  label="Leave dates"
                  w="20rem"
                />
              );
            }}
          />

          <Controller
            control={control}
            name="paymentBy"
            render={({
              field: { ref: _ref, ...rest },
              fieldState: { error },
            }) => (
              <RadioGroup
                {...rest}
                label="How would you like to manage premium payments during the leave?"
                required
                styles={(theme) => ({
                  label: { fontSize: theme.fontSizes.md },
                })}
              >
                <Group>
                  <Radio
                    mt="sm"
                    value={PaymentBy.EMPLOYEE}
                    label="Let the employee handle the payments"
                    shouldHaveBorder={false}
                  />
                  <Radio
                    value={PaymentBy.EMPLOYER}
                    label="The employer will continue to pay and collect any owed amounts"
                    shouldHaveBorder={false}
                  />
                </Group>
              </RadioGroup>
            )}
          />
          <FormErrorMessage errors={errors} fieldName="paymentBy" />
        </Stack>
        <Space h="xl" />
        <Center>
          <Button type="submit" disabled={!isValid || isSubmitting}>
            Submit
          </Button>
        </Center>
      </form>
    </Stack>
  );
}
