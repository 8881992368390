import { Box, StyleProp } from '@mantine/core';
import { IconCheck, IconCircleCheck } from '@tabler/icons-react';
import { CSSProperties } from 'react';

import { ZorroIcon } from '../Icon';
import { brand } from '../theme';

type CheckmarkCircleProps = {
  size?: number;
  position?: StyleProp<CSSProperties['position']>;
  isPlumVariant?: boolean;
};

export function CheckmarkCircle({
  size = 24,
  position = 'absolute',
  isPlumVariant = false,
}: CheckmarkCircleProps) {
  return (
    <Box
      pos={position}
      p={isPlumVariant ? 4 : undefined}
      style={(theme) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: size,
        width: size,
        right: -10,
        top: -10,
        borderRadius: '50%',
        background: isPlumVariant
          ? theme.colors.zorroIris[9]
          : theme.colors.zorroWhite,
        zIndex: 1,
      })}
    >
      <ZorroIcon
        icon={isPlumVariant ? IconCheck : IconCircleCheck}
        color={isPlumVariant ? brand.zorroIris50 : undefined}
        aria-label="Checkmark"
      />
    </Box>
  );
}
