import { Badge, brand } from '@zorro/zorro-ui-design';

const NotAvailableBadge = () => {
  return (
    <Badge
      size="md"
      p="4px 8px"
      variant="transparent"
      c="black"
      bg={brand.zorroFire300}
      radius={4}
      h={24}
      style={{ fontSize: '14px' }}
    >
      Not available
    </Badge>
  );
};

export { NotAvailableBadge };
