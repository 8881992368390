import { brand } from '../../theme';
import { ITableProps, ZorroTable } from '../Table/Table';

export function NewZorroTable({
  sortStatus = { columnAccessor: '', direction: 'asc' },
  onSortStatusChange,
  fetching = false,
  ...props
}: ITableProps) {
  return (
    <ZorroTable
      {...props}
      sortStatus={sortStatus}
      onSortStatusChange={onSortStatusChange}
      highlightOnHover
      highlightOnHoverColor={brand.zorroGray50}
      fetching={fetching}
    />
  );
}
