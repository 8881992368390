import { IconDots, IconDownload } from '@tabler/icons-react';
import { BenefitDocumentDto } from '@zorro/clients';
import { FileIcon } from '@zorro/shared/assets';
import {
  formatDateEnLocale,
  singleLineTruncatedTextWithEllipsis,
} from '@zorro/shared/formatters';
import {
  downloadBenefitDocument,
  useImpersonation,
  useRoles,
} from '@zorro/shared/utils';
import {
  ActionIcon,
  Card,
  Flex,
  Group,
  Menu,
  Text,
  ThemeIcon,
  brand,
} from '@zorro/zorro-ui-design';
import _noop from 'lodash/noop';

import { useMonolithMutation } from '../hooks/useMonolithMutation';

export type Props = {
  document: BenefitDocumentDto;
  onDelete?: (document: BenefitDocumentDto) => void;
};

export const BenefitDocumentCard = ({ document, onDelete = _noop }: Props) => {
  const { isAgent, isZorroOperations } = useRoles();
  const { isImpersonated } = useImpersonation();
  const isDon = isImpersonated || !(isAgent || isZorroOperations);

  const { mutate: updateDocument } = useMonolithMutation({
    method: 'benefitDocumentsControllerUpdateBenefitDocument',
    successMessage: 'Document updated',
  });

  const handleChangeBenefitDocumentVisibility = () =>
    updateDocument([
      document.id,
      {
        isSharedWithEmployee: !document.isSharedWithEmployee,
      },
    ]);

  const handleDeleteBenefitDocument = () => onDelete(document);

  const handleDownloadBenefitDocument = async () =>
    await downloadBenefitDocument(document.documentUrl, document.fileName);

  return (
    <Card p="sm">
      <Group justify="space-between" align="center" wrap="nowrap">
        <Flex
          gap="xs"
          align="center"
          style={{
            overflow: 'hidden',
          }}
        >
          <ThemeIcon color={brand.primaryButtonSelected} size="lg">
            <FileIcon />
          </ThemeIcon>
          <Text style={{ ...singleLineTruncatedTextWithEllipsis }}>
            {document.fileName}
          </Text>
        </Flex>

        <Group gap="xs" wrap="nowrap" flex="0 0 auto">
          <Text size="sm" c="zorroGray.4" flex="0 0 auto">
            {`Updated on ${
              document.updatedAt
                ? formatDateEnLocale(document.updatedAt)
                : formatDateEnLocale(document.createdAt)
            }`}
          </Text>
          <ActionIcon variant="filled" size="md">
            <IconDownload onClick={handleDownloadBenefitDocument} size={20} />
          </ActionIcon>
          {!isDon && (
            <Menu>
              <Menu.Target>
                <ActionIcon variant="filled">
                  <IconDots />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item onClick={handleChangeBenefitDocumentVisibility}>
                  <Text size="sm">
                    {`Change to ${
                      document.isSharedWithEmployee ? 'admin' : 'employee'
                    } document`}
                  </Text>
                </Menu.Item>
                <Menu.Item onClick={handleDeleteBenefitDocument}>
                  <Text size="sm">Delete</Text>
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          )}
        </Group>
      </Group>
    </Card>
  );
};
