import { FamilyUnit, InsuredSubtype } from '@zorro/clients';
import {
  IconZorroUser,
  IconZorroUsers,
  IconZorroUsers2,
} from '@zorro/shared/assets';
import { useMonolithQuery } from '@zorro/shared/utils';
import { Accordion, Text } from '@zorro/zorro-ui-design';
import { useFieldArray } from 'react-hook-form';

import { useInsuredForm } from '../useInsuredForm';
import { ReadonlyDependentForm } from './ReadonlyDependentForm';
import { ReadonlyEmployeeForm } from './ReadonlyEmployeeForm';

type Props = {
  insuredForm: ReturnType<typeof useInsuredForm>;
  onboardingPeriodId: string;
  isEmployeeMode?: boolean;
  employeeId: string;
};

export const ReadonlyInsuredForm = ({
  insuredForm,
  onboardingPeriodId,
  employeeId,
  isEmployeeMode = false,
}: Props) => {
  const { employeeForm, spouseForm, dependentsForm } = insuredForm;
  const { data: benefits } = useMonolithQuery({
    method: 'benefitsControllerGetBenefits',
    params: [onboardingPeriodId],
  });

  const { fields } = useFieldArray({
    control: dependentsForm.control,
    name: 'dependents',
  });

  const familyUnit = benefits?.majorMedicalBenefit?.familyUnit || undefined;
  const shouldShowSpouseForm = familyUnit
    ? familyUnit.includes(FamilyUnit.EMPLOYEE_SPOUSE) ||
      familyUnit.includes(FamilyUnit.FAMILY)
    : true;
  const shouldShowDependentsForm = familyUnit
    ? familyUnit.includes(FamilyUnit.EMPLOYEE_CHILD) ||
      familyUnit.includes(FamilyUnit.FAMILY)
    : true;

  const [employeeFirstName, employeeLastName] = employeeForm.watch([
    'firstName',
    'lastName',
  ]);
  const [spouseFirstName, spouseLastName] = spouseForm.watch([
    'firstName',
    'lastName',
  ]);

  return (
    <Accordion variant="separated" multiple>
      <Accordion.Item value="Employee">
        <Accordion.Control icon={<IconZorroUser />}>
          <Text fw={600}>
            {isEmployeeMode ? 'You' : 'Employee'}
            <Text ml="0.25rem" fw={600} c="zorroGray.4">
              ({employeeFirstName} {employeeLastName})
            </Text>
          </Text>
        </Accordion.Control>
        <Accordion.Panel>
          <ReadonlyEmployeeForm
            employeeForm={employeeForm}
            employeeId={employeeId}
          />
        </Accordion.Panel>
      </Accordion.Item>
      {shouldShowSpouseForm && (
        <Accordion.Item value="Spouse">
          <Accordion.Control icon={<IconZorroUsers />}>
            <Text fw={600}>
              {spouseForm.watch('subtype') === InsuredSubtype.DOMESTIC_PARTNER
                ? 'Domestic partner'
                : 'Spouse'}
              <Text ml="0.25rem" fw={600} c="zorroGray.4">
                ({spouseFirstName} {spouseLastName})
              </Text>
            </Text>
          </Accordion.Control>
          <Accordion.Panel>
            <ReadonlyDependentForm fields={spouseForm.watch()} />
          </Accordion.Panel>
        </Accordion.Item>
      )}

      {shouldShowDependentsForm &&
        fields.map((dependent) => (
          <Accordion.Item key={dependent.id} value={dependent.id}>
            <Accordion.Control icon={<IconZorroUsers2 />}>
              <Text fw={600}>
                {dependent.subtype === InsuredSubtype.OTHER_DEPENDENT
                  ? 'Dependent'
                  : 'Child'}
                <Text ml="0.25rem" fw={600} c="zorroGray.4">
                  ({dependent.firstName} {dependent.lastName})
                </Text>
              </Text>
            </Accordion.Control>
            <Accordion.Panel>
              <ReadonlyDependentForm fields={dependent} />
            </Accordion.Panel>
          </Accordion.Item>
        ))}
    </Accordion>
  );
};
