import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const CriticalIllnessIcon = ({ style, imageAlt }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-label={imageAlt}
      >
        <g clipPath="url(#clip0_535_1680)">
          <path
            d="M10.8079 5.67295C10.853 5.09659 11.3697 4.66739 11.9451 4.72276C15.567 5.07125 21.631 5.47175 25.6819 10.2183C29.8891 15.148 28.8517 19.5258 28.0956 23.8629C28.0113 24.3467 27.5935 24.7034 27.1025 24.7114C23.1929 24.7749 18.1757 25.0683 14.0062 20.1828C9.90784 15.3806 10.4959 9.65979 10.8079 5.67295Z"
            fill="#FF8972"
          />
          <path
            d="M4 9.33325V23.9999M4 18.6666H28M28 23.9999V13.3333C28 12.626 27.719 11.9477 27.219 11.4476C26.7189 10.9475 26.0406 10.6666 25.3333 10.6666H14.6667V18.6666"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.33333 14.6667C10.0697 14.6667 10.6667 14.0697 10.6667 13.3333C10.6667 12.597 10.0697 12 9.33333 12C8.59695 12 8 12.597 8 13.3333C8 14.0697 8.59695 14.6667 9.33333 14.6667Z"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_535_1680">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
