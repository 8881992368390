import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { ForwardedRef, forwardRef } from 'react';

import classNames from './RadixMenu.module.scss';

export const RadixMenuContent = forwardRef(
  (
    { children, ...props }: DropdownMenuPrimitive.DropdownMenuContentProps,
    forwardedRef: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <DropdownMenuPrimitive.Portal>
        <DropdownMenuPrimitive.Content
          {...props}
          className={classNames.content}
          ref={forwardedRef}
        >
          {children}
          <DropdownMenuPrimitive.Arrow className={classNames.arrow} />
        </DropdownMenuPrimitive.Content>
      </DropdownMenuPrimitive.Portal>
    );
  }
);

RadixMenuContent.displayName = 'DropdownMenuContent';
