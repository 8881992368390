import {
  IconCirclePlus,
  IconNotebook,
  IconWritingSign,
} from '@tabler/icons-react';
import {
  useDynamicMurrietaRouter,
  useMonolithQuery,
  useRoles,
} from '@zorro/shared/utils';
import { Group, Icon, Stack } from '@zorro/zorro-ui-design';
import { ReactElement, useMemo, useState } from 'react';

import {
  EmployeeLayoutAction,
  EmployeePageActions,
} from './EmployeePageActions';
import { EmployeePageBreadcrumbs } from './EmployeePageBreadcrumbs';
import { EmployeePageHeader } from './EmployeePageHeader';
import {
  EmployeePageSidebar,
  EmployeePageSidebarType,
  SidebarItemProps,
} from './EmployeePageSidebar/EmployeePageSidebar';
import { EmployeePageSidebarSubitemProps } from './EmployeePageSidebar/EmployeePageSidebarSubitem';
import {
  mapOnboardingPeriodToEmployeeSidebarSubitem,
  sortOnboardingPeriodsForEmployeePageSidebar,
} from './EmployeePageUtils';

type Props = {
  page: EmployeePageSidebarType;
  employerId: string;
  employeeId: string;
  onboardingPeriodId?: string;
  children: ReactElement;
  isDon?: boolean;
};

export const EmployeePage = ({
  page,
  employerId,
  employeeId,
  onboardingPeriodId,
  children,
  isDon = false,
}: Props) => {
  const { isEmployerAdmin } = useRoles();
  const [action, setAction] = useState<EmployeeLayoutAction>();
  const { navigateToRoute } = useDynamicMurrietaRouter();
  const {
    data: onboardingPeriods = [],
    isLoading: isOnboardingPeriodsLoading,
  } = useMonolithQuery({
    method: 'onboardingPeriodReportsControllerGenerateEmployeeOnboardingReport',
    params: [employeeId],
  });
  const { data: employee } = useMonolithQuery({
    method: 'employeesControllerFindOne',
    params: [employeeId],
  });

  const enrollmentSubitems: EmployeePageSidebarSubitemProps[] = useMemo(() => {
    const subitems =
      onboardingPeriods.length > 0
        ? onboardingPeriods
            .sort(sortOnboardingPeriodsForEmployeePageSidebar)
            .map((onboardingPeriod) => {
              const handleClick = () =>
                navigateToRoute('EMPLOYEE_ENROLLMENT_TAB', [
                  {
                    employerId,
                    employeeId,
                    onboardingPeriodId: onboardingPeriod.id,
                  },
                ]);

              return mapOnboardingPeriodToEmployeeSidebarSubitem({
                currentOnboardingPeriodId: onboardingPeriodId,
                onClick: handleClick,
                onboardingPeriod,
                employee,
              });
            })
        : [
            {
              id: 'no-enrollments',
              label: 'No enrollments yet',
              isChecked: page === EmployeePageSidebarType.ENROLLMENTS,
              onClick: () =>
                navigateToRoute('EMPLOYEE_NO_ENROLLMENTS', [
                  { employerId, employeeId },
                ]),
            },
          ];

    if (!isEmployerAdmin) {
      subitems.push({
        id: 'new-enrollment',
        label: 'New enrollment',
        onClick: () => setAction(EmployeeLayoutAction.ADD_ENROLLMENT),
        leftContent: <Icon icon={IconCirclePlus} />,
      });
    }

    return subitems;
  }, [
    onboardingPeriods,
    page,
    isEmployerAdmin,
    onboardingPeriodId,
    employee,
    navigateToRoute,
    employerId,
    employeeId,
  ]);

  const selectedOnboardingPeriod = onboardingPeriods.find(
    ({ id }) => onboardingPeriodId === id
  );

  const sideBarItems: SidebarItemProps[] = [
    {
      page: EmployeePageSidebarType.ENROLLMENTS,
      label: 'Enrollments',
      icon: IconWritingSign,
      subitems: enrollmentSubitems,
    },
  ];

  if (!isEmployerAdmin) {
    sideBarItems.push({
      page: EmployeePageSidebarType.GENERAL_NOTES,
      label: 'General notes',
      icon: IconNotebook,
      onClick: () =>
        navigateToRoute('EMPLOYEE_GENERAL_NOTES', [{ employeeId, employerId }]),
      subitems: [],
    });
  }

  return (
    <>
      <Group wrap="nowrap" align="flex-start" gap={0}>
        <EmployeePageSidebar
          isOnboardingPeriodsLoading={isOnboardingPeriodsLoading}
          employerId={employerId}
          employeeId={employeeId}
          setAction={setAction}
          items={sideBarItems}
          page={page}
        />
        <Stack pl="27.5rem" pr="5rem" py="1.75rem" w="100%">
          <EmployeePageBreadcrumbs
            employerId={employerId}
            employeeId={employeeId}
            isDon={isDon}
          />

          <EmployeePageHeader
            isOnboardingPeriodsLoading={isOnboardingPeriodsLoading}
            onboardingPeriodReport={selectedOnboardingPeriod}
            setAction={setAction}
            employee={employee}
          />

          {children}
        </Stack>
      </Group>
      <EmployeePageActions
        onboardingPeriodId={onboardingPeriodId}
        employeeId={employeeId}
        employerId={employerId}
        setAction={setAction}
        action={action}
      />
    </>
  );
};
