import { IMPERSONATION_INDICATOR_HEIGHT } from '@zorro/types';

import { ZorroBox } from '../Box';
import { ZorroText } from '../Text';
import { brand } from '../theme';

type Props = {
  impersonatedEmployeeFullName: string;
};
export const ImpersonationIndicator = ({
  impersonatedEmployeeFullName,
}: Props) => {
  return (
    <ZorroBox
      bg={brand.zorroIris900}
      w="100vw"
      h={IMPERSONATION_INDICATOR_HEIGHT}
      style={{
        top: 0,
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ZorroText c={brand.zorroWhite} size="sm">
        <span style={{ fontWeight: 700 }}>Joint session:</span>{' '}
        {`browsing employee mode with ${impersonatedEmployeeFullName}`}
      </ZorroText>
    </ZorroBox>
  );
};
