import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const DocumentsIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1327_24338)">
          <rect x="7" y="10" width="14" height="18" rx="2" fill="#FFD880" />
          <path
            d="M20 4V9.33333C20 9.68696 20.1405 10.0261 20.3905 10.2761C20.6406 10.5262 20.9797 10.6667 21.3333 10.6667H26.6667"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M24 22.6667H14.6667C13.9594 22.6667 13.2811 22.3857 12.781 21.8856C12.281 21.3855 12 20.7072 12 20V6.66667C12 5.95942 12.281 5.28115 12.781 4.78105C13.2811 4.28095 13.9594 4 14.6667 4H20L26.6667 10.6667V20C26.6667 20.7072 26.3857 21.3855 25.8856 21.8856C25.3855 22.3857 24.7072 22.6667 24 22.6667Z"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.3334 22.6667V25.3333C21.3334 26.0406 21.0525 26.7189 20.5524 27.219C20.0523 27.719 19.374 28 18.6667 28H9.33342C8.62617 28 7.94789 27.719 7.4478 27.219C6.9477 26.7189 6.66675 26.0406 6.66675 25.3333V12C6.66675 11.2928 6.9477 10.6145 7.4478 10.1144C7.94789 9.61428 8.62617 9.33333 9.33342 9.33333H12.0001"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1327_24338">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
