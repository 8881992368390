import { IconInfoCircle } from '@tabler/icons-react';
import {
  Alert,
  Group,
  Icon,
  List,
  Stack,
  Text,
  Title,
  brand,
} from '@zorro/zorro-ui-design';
import { ReactNode } from 'react';

type Props = {
  title?: string;
  items?: string[];
  bgColor?: string;
  bd?: string;
  actionButton?: ReactNode;
  whiteSpace?: 'pre-line' | 'normal';
};

export const InformationBoxComponent = ({
  title,
  items = [],
  bgColor = 'zorroGray.10',
  bd = brand.zorroGray900,
  whiteSpace,
  actionButton,
}: Props) => {
  return (
    <Alert bg={bgColor} bd={bd}>
      <Group gap="xs" wrap="nowrap" align="start">
        <Icon icon={IconInfoCircle} size="1.25rem" style={{ flexShrink: 0 }} />
        <Stack gap="xs">
          {title && <Title order={4}>{title}</Title>}
          {items.length === 1 ? (
            <Text c={brand.zorroCoal900} style={{ whiteSpace }} size="md">
              {items[0]}
            </Text>
          ) : (
            items.length > 0 && (
              <List
                styles={(theme) => ({
                  item: {
                    color: theme.colors.zorroGray[4],
                    whiteSpace,
                  },
                })}
              >
                {items.map((item) => (
                  <List.Item key={item}>{item}</List.Item>
                ))}
              </List>
            )
          )}
          {actionButton}
        </Stack>
      </Group>
    </Alert>
  );
};
