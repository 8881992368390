import { Card, Grid, Skeleton, Stack } from '@zorro/zorro-ui-design';

export const PremiumAmountFormSkeleton = () => {
  return (
    <Card style={(theme) => ({ borderColor: theme.colors.zorroGray[2] })}>
      <Grid gutter="lg" h="6rem">
        <Grid.Col span={3}>
          <Skeleton h={16} w={130} />
        </Grid.Col>
        <Grid.Col span={3}>
          <Stack>
            <Skeleton h={26} w={70} />
            <Skeleton h={16} w={90} />
          </Stack>
        </Grid.Col>
        <Grid.Col span={3}>
          <Stack>
            <Skeleton h={26} w={70} />
            <Skeleton h={16} w={90} />
          </Stack>
        </Grid.Col>
        <Grid.Col span={3}>
          <Stack>
            <Skeleton h={26} w={70} />
            <Skeleton h={16} w={90} />
          </Stack>
        </Grid.Col>
      </Grid>
    </Card>
  );
};
