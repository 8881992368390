import {
  MURRIETA_DYNAMIC_ROUTES,
  useDynamicMurrietaRouter,
  useRoles,
} from '@zorro/shared/utils';
import { StrictExtract } from '@zorro/types';
import { Button, Stack } from '@zorro/zorro-ui-design';

import { ReadonlyInsuredForm, useInsuredForm } from '../../InsuredForm';
import { MajorMedicalCombinedPlanCard } from '../../MajorMedicalCombinedPlanCard';
import {
  MajorMedicalPlanCard,
  useMajorMedicalPlanCardQuery,
} from '../../MajorMedicalPlanCard';
import {
  PremiumAmountFormInputs,
  usePremiumAmountForm,
} from '../../PremiumAmountForm';

type ElectionSubmittedTabRoutes = StrictExtract<
  typeof MURRIETA_DYNAMIC_ROUTES,
  | 'CARRIER_APPLICATION_ENROLLMENT_DETAILS'
  | 'ENROLLMENT_CONFIRMATION_PAYMENT_STATUS'
>;

type Props = {
  employerId: string;
  employeeId: string;
  onboardingPeriodId: string;
  routeButton?: {
    route: ElectionSubmittedTabRoutes;
    label: string;
  };
};

export const ElectionSubmittedTabContent = ({
  employerId,
  employeeId,
  onboardingPeriodId,
  routeButton,
}: Props) => {
  const { isEmployerAdmin } = useRoles();
  const { navigateToRoute } = useDynamicMurrietaRouter();
  const { premiumAmountForm, employerMonthlyContribution } =
    usePremiumAmountForm({ mode: 'fetch', employeeId, onboardingPeriodId });
  const majorMedicalPlanCardData = useMajorMedicalPlanCardQuery({
    employeeId,
    onboardingPeriodId,
  });
  const insuredForm = useInsuredForm({
    onboardingPeriodId,
    employeeId,
    isFinalizationMode: false,
    isEmployeeMode: false,
  });

  return (
    <Stack>
      <MajorMedicalPlanCard {...majorMedicalPlanCardData} />
      <PremiumAmountFormInputs
        premiumAmountForm={premiumAmountForm}
        employerMonthlyContribution={employerMonthlyContribution}
        shouldShowInCard
        isReadonlyMode
      />
      <MajorMedicalCombinedPlanCard onboardingPeriodId={onboardingPeriodId} />
      <ReadonlyInsuredForm
        insuredForm={insuredForm}
        onboardingPeriodId={onboardingPeriodId}
        employeeId={employeeId}
      />
      {!isEmployerAdmin && routeButton && (
        <Button
          ml="auto"
          size="md"
          fw={400}
          onClick={() =>
            navigateToRoute(routeButton.route, [
              { employerId, employeeId, onboardingPeriodId },
            ])
          }
        >
          {routeButton.label}
        </Button>
      )}
    </Stack>
  );
};
