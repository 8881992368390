import { ValueOf } from '@zorro/types';
import { Box, Center, brand } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';
import classNames from './Icon.module.scss';

type Props = SVGIconProps & {
  flagColor?: ValueOf<typeof brand>;
  withDot?: boolean;
};

export const FlagIcon = ({
  style,
  grow,
  withDot = false,
  flagColor = '#FFC214',
}: Props) => {
  return (
    <Center
      pos="relative"
      style={style}
      classNames={classNames}
      data-grow={grow}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M14.5 4.5H4V12H15.5L12 8L14.5 4.5Z" fill="#FFBBAF" />
        <path
          d="M4.16675 11.667H15.8334L12.0834 7.91699L15.8334 4.16699H4.16675V11.667Z"
          fill={flagColor}
          stroke={flagColor}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 4V17.5"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
      {withDot && (
        <Box
          style={{ borderRadius: '100%' }}
          pos="absolute"
          bottom="2px"
          right="2px"
          bg="red"
          h="4px"
          w="4px"
        />
      )}
    </Center>
  );
};
