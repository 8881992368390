import { QueryObserverResult, useQuery } from '@tanstack/react-query';
import { PayrollReportRowDto } from '@zorro/clients';
import { formatDateISO } from '@zorro/shared/formatters';
import { callEndpoint, useRouter } from '@zorro/shared/utils';
import { recordsPerPage } from '@zorro/types';
import { HttpStatusCode } from 'axios';

import { PayrollFormData } from '../PayrollReportUtils';

async function getPage(
  payrollRange: PayrollFormData,
  page: number,
  employerId: string
) {
  return await callEndpoint({
    method: 'employerReportsControllerGeneratePayrollReport',
    params: [
      employerId,
      formatDateISO(payrollRange.effectiveOn),
      payrollRange.payrollCycle,
      recordsPerPage,
      page,
    ],
  });
}

export function usePaginatedPayrollReports(
  employerId: string,
  payrollRange?: PayrollFormData | null
): {
  isLoading: boolean;
  data: PayrollReportRowDto[];
  refetch: () => Promise<QueryObserverResult<PayrollReportRowDto[] | null>>;
} {
  const { reloadPage } = useRouter();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['getGeneratePayrollReportArgs', [employerId, payrollRange]],
    queryFn: async () => {
      try {
        if (payrollRange) {
          const firstPage = await getPage(payrollRange, 1, employerId);
          if (firstPage) {
            const restOfPages = await Promise.all(
              Array.from({ length: firstPage.meta.lastPage - 1 }).map(
                (_, index) => {
                  const pageNumber = index + 2;
                  return getPage(payrollRange, pageNumber, employerId);
                }
              )
            );
            return [firstPage, ...restOfPages].flatMap((page) => page.data);
          }
        }
      } catch (error) {
        if (error.status === HttpStatusCode.Unauthorized) {
          reloadPage();
        }
      }
      return null;
    },
  });
  return {
    isLoading,
    data: data ?? [],
    refetch,
  };
}
