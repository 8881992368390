import {
  Button,
  Group,
  IButtonProps,
  IPaperProps,
  Paper,
} from '@zorro/zorro-ui-design';

type Props = {
  primaryLabel: string;
  secondaryLabel?: string;
  secondaryButtonProps?: IButtonProps;
  primaryButtonProps?: IButtonProps;
  paperProps?: Omit<IPaperProps, 'children'>;
};

export const FormFooter = ({
  primaryButtonProps,
  secondaryButtonProps,
  secondaryLabel,
  primaryLabel,
  paperProps,
}: Props) => {
  return (
    <Paper
      pos="fixed"
      right={0}
      bottom={0}
      shadow="0px -4px 24.6px 0px rgba(0, 0, 0, 0.04)"
      w="100%"
      py="lg"
      pr="lg"
      display="flex"
      style={{ zIndex: 1 }}
      {...paperProps}
    >
      <Group ml="auto">
        {secondaryLabel && (
          <Button
            ml="auto"
            size="lg"
            px="md"
            variant="secondary"
            {...secondaryButtonProps}
          >
            {secondaryLabel}
          </Button>
        )}
        <Button size="lg" ml="auto" {...primaryButtonProps}>
          {primaryLabel}
        </Button>
      </Group>
    </Paper>
  );
};
