import { Divider, Flex, Text } from '@zorro/zorro-ui-design';

type Props = {
  routeLabel: string;
  isCurrentSubPage: boolean;
  isHovering?: boolean;
};

export const MainLinkDropdownContent = ({
  routeLabel,
  isCurrentSubPage,
  isHovering,
}: Props) => {
  return (
    <Flex align="center">
      <Divider
        orientation="vertical"
        color="zorroIris.9"
        h={32}
        mr="sm"
        opacity={isCurrentSubPage ? 1 : 0}
        size="md"
        style={(theme) => ({
          borderRadius: theme.radius.xl,
        })}
      />
      <Text
        style={{ transition: 'font-weight 0.1s ease-out' }}
        fw={isCurrentSubPage || isHovering ? 500 : undefined}
      >
        {routeLabel}
      </Text>
    </Flex>
  );
};
