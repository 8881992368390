import { EmployerSignupStatus, GetEmployerDto } from '@zorro/clients';
import { ADD_EMPLOYER_DYNAMIC_ROUTES } from '@zorro/shared/utils';
import { $enum } from 'ts-enum-util';

export type HandleStepSubmit = () => Promise<
  { navigate: false } | { navigate: true; employerId: string }
>;

export enum AddEmployerStepType {
  COMPANY_DETAILS = 'COMPANY_DETAILS',
  CONTACTS = 'CONTACTS',
  COMPANY_SETUP = 'COMPANY_SETUP',
  ZORRO_SETUP = 'ZORRO_SETUP',
  PLAN_YEAR_SETUP = 'PLAN_YEAR_SETUP',
  REVIEW = 'REVIEW',
}

export const getAddEmployerStepConfig = (
  step: AddEmployerStepType,
  isDon: boolean = false
) =>
  $enum.mapValue(step).with<{
    nextRoute?: keyof typeof ADD_EMPLOYER_DYNAMIC_ROUTES;
    route: keyof typeof ADD_EMPLOYER_DYNAMIC_ROUTES;
    shouldShowMissingDetails: boolean;
    stepIndex: number;
    label: string;
  }>({
    [AddEmployerStepType.COMPANY_DETAILS]: {
      route: 'ADD_EMPLOYER_COMPANY_DETAILS',
      nextRoute: 'ADD_EMPLOYER_CONTACTS',
      shouldShowMissingDetails: true,
      label: 'Company details',
      stepIndex: 0,
    },
    [AddEmployerStepType.CONTACTS]: {
      route: 'ADD_EMPLOYER_CONTACTS',
      nextRoute: 'ADD_EMPLOYER_COMPANY_SETUP',
      shouldShowMissingDetails: true,
      label: 'Contacts',
      stepIndex: 1,
    },
    [AddEmployerStepType.COMPANY_SETUP]: {
      route: 'ADD_EMPLOYER_COMPANY_SETUP',
      nextRoute: isDon ? 'ADD_EMPLOYER_REVIEW' : 'ADD_EMPLOYER_ZORRO_SETUP',
      shouldShowMissingDetails: true,
      label: 'Company setup',
      stepIndex: 2,
    },
    [AddEmployerStepType.ZORRO_SETUP]: {
      route: 'ADD_EMPLOYER_ZORRO_SETUP',
      nextRoute: 'ADD_EMPLOYER_PLAN_YEAR_SETUP',
      shouldShowMissingDetails: true,
      label: 'Zorro setup',
      stepIndex: 3,
    },
    [AddEmployerStepType.PLAN_YEAR_SETUP]: {
      route: 'ADD_EMPLOYER_PLAN_YEAR_SETUP',
      nextRoute: 'ADD_EMPLOYER_REVIEW',
      shouldShowMissingDetails: true,
      label: 'Plan year setup',
      stepIndex: 4,
    },
    [AddEmployerStepType.REVIEW]: {
      route: 'ADD_EMPLOYER_REVIEW',
      shouldShowMissingDetails: false,
      label: 'Review',
      stepIndex: 5,
    },
  });

export const getAddEmployerFinalizationMode = (
  employer: GetEmployerDto
): boolean => {
  return (
    employer.signupStatus === EmployerSignupStatus.NEEDS_REVIEW ||
    employer.signupStatus === EmployerSignupStatus.IN_ZORRO_SETUP
  );
};
