import { MantineSpacing, StyleProp } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { Box, Group, Icon, Text, Tooltip } from '@zorro/zorro-ui-design';
import { ReactNode } from 'react';

type IconWithTitleProps = {
  title: string;
  tooltipText?: string;
  icon?: ReactNode;
  editActionColumn?: ReactNode;
  mr?: StyleProp<MantineSpacing>;
  pl?: StyleProp<MantineSpacing>;
  gap?: MantineSpacing;
};

export const IconWithTitle = ({
  title,
  tooltipText,
  icon,
  editActionColumn,
  gap,
  mr,
  pl = 14,
}: IconWithTitleProps) => {
  return (
    <Group pl={pl} mr={mr} align="center" gap={gap} wrap="nowrap">
      {icon}
      <Group gap="xs">
        <Tooltip disabled={!tooltipText} label={tooltipText}>
          <Box h="1.5rem">
            <Text fw="600" component="span" mr="0.25rem">
              {title}
            </Text>
            {tooltipText && <Icon icon={IconInfoCircle} size="1.25rem" />}
          </Box>
        </Tooltip>
        {editActionColumn}
      </Group>
    </Group>
  );
};
