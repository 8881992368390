import { CSSProperties } from '@mantine/core';

/*
 * INFO: Provides a single-line truncated text with ellipsis
 * Taken from: https://collaboradev.com/2015/03/28/responsive-css-truncate-and-ellipsis/
 */
export const singleLineTruncatedTextWithEllipsisParent = {
  display: 'table',
  tableLayout: 'fixed',
  width: '100%',
  whiteSpace: 'nowrap',
} as CSSProperties;

export const singleLineTruncatedTextWithEllipsis = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
} as CSSProperties;

/*
 * INFO: Provides a multi-line truncated text with ellipsis
 * Taken from: https://albertwalicki.com/learn/solutions/how-to-limit-text-to-n-lines
 */
export const twoLineTruncatedTextWithEllipsis = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  lineClamp: 2,
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  wordWrap: 'break-word',
} as CSSProperties;
