import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const IconZorroMessages = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="none"
      >
        <g clipPath="url(#a)">
          <path
            fill="#80E8B6"
            d="M20 19.273v2.909a1.454 1.454 0 0 1-1.454 1.454H8.364L4 28V13.454A1.455 1.455 0 0 1 5.455 12h2.909"
          />
          <path
            stroke="#1A1721"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.1"
            d="M20 19.273v2.909a1.454 1.454 0 0 1-1.454 1.454H8.364L4 28V13.454A1.455 1.455 0 0 1 5.455 12h2.909"
          />
          <path
            fill="#FFD880"
            stroke="#1A1721"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.1"
            d="m28 20-4.364-4.364H13.455A1.455 1.455 0 0 1 12 14.182V5.455A1.454 1.454 0 0 1 13.454 4h13.091A1.455 1.455 0 0 1 28 5.455V20Z"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h32v32H0z" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
