import { MURRIETA_ROUTES } from '@zorro/shared/utils';
import { Breadcrumbs } from '@zorro/zorro-ui-design';

const breadcrumbsItems = [
  {
    title: 'All employers',
    href: MURRIETA_ROUTES.EMPLOYERS,
  },
  {
    title: 'New employer',
    href: MURRIETA_ROUTES.ADD_EMPLOYER_COMPANY_DETAILS,
  },
];

export const AddEmployerBreadcrumbs = () => {
  return <Breadcrumbs items={breadcrumbsItems} mb="1rem" />;
};
