import { PlanMarket } from '@zorro/clients';
import {
  IconNotApplicable,
  IconZorroBothMarkets,
  IconZorroOffExchange,
  IconZorroOnExchange,
} from '@zorro/shared/assets';
import { Icon, brand } from '@zorro/zorro-ui-design';

export const PlanMarketConfig = {
  [PlanMarket.OFF_EXCHANGE]: {
    icon: IconZorroOffExchange,
    color: brand.zorroSky100,
  },
  [PlanMarket.ON_EXCHANGE]: {
    icon: IconZorroOnExchange,
    color: brand.zorroGreen100,
  },
  [PlanMarket.BOTH_MARKETS]: {
    icon: IconZorroBothMarkets,
    color: brand.zorroFire300,
  },
  NOT_APPLICABLE: {
    icon: IconNotApplicable,
    color: brand.zorroFire300,
  },
} as const;

export function PlanMarketIcon({ planMarket }: { planMarket: PlanMarket }) {
  const { icon, color } = PlanMarketConfig[planMarket];
  return (
    <Icon
      icon={icon}
      color={brand.zorroCoal900}
      style={{
        backgroundColor: color,
        padding: '4px',
        borderRadius: '4px',
      }}
    />
  );
}
