import { Badge, BadgeProps } from '@mantine/core';
import { HTMLAttributes } from 'react';

type IZorroBadge = HTMLAttributes<HTMLDivElement> & BadgeProps;

export const ZorroBadge = ({
  variant = 'filled',
  size = 'lg',
  ...props
}: IZorroBadge) => {
  return (
    <Badge
      variant={variant}
      size={size}
      styles={{
        root: {
          fontWeight: 400,
          textTransform: 'none',
          padding: '4px 16px',
        },
      }}
      {...props}
    />
  );
};
