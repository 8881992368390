import { List, Stack, Title } from '@zorro/zorro-ui-design';

import {
  PlanYearSetupFormInputs,
  usePlanYearSetupForm,
} from '../../PlanYearSetupForm';
import { AddEmployerStep } from '../AddEmployerStep';
import {
  AddEmployerStepType,
  HandleStepSubmit,
} from '../AddEmployerStep/AddEmployerUtils';
import FaqSection from '../FaqSection/FaqSection';

const AddEmployerPlanYearSetupStepFaq = () => {
  return (
    <FaqSection
      links={[{ name: 'Zorro Pay vs. self-pay', href: '#', isDrawer: true }]}
    >
      <Stack>
        <Title size="medium">Understanding payment methods</Title>
        Zorro Pay:
        <List type="ordered">
          <List.Item>
            Zorro sets up a dedicated FBO (For the Benefit Of) bank account for
            the company’s health benefit funds.
          </List.Item>
          <List.Item>
            Monthly, Zorro draws funds to cover all employee benefits.
          </List.Item>
          <List.Item>
            Employees receive a virtual payment card or bank account for carrier
            payments.
          </List.Item>
          <List.Item>
            Employee contributions are calculated by Zorro and captured in our
            payroll tool.
          </List.Item>
        </List>
        Self-pay (reimbursement):
        <List type="ordered">
          <List.Item>
            Employees pay carriers directly with their personal card or bank
            account.
          </List.Item>
          <List.Item>
            Reimbursements are calculated by Zorro and captured in our payroll
            tool.
          </List.Item>
          <List.Item>
            Employer contributions are reimbursed to employees through payroll.
          </List.Item>
        </List>
      </Stack>
    </FaqSection>
  );
};

type Props = {
  employerId: string;
  isFinalizationMode?: boolean;
};

export const AddEmployerPlanYearSetupStep = ({
  employerId,
  isFinalizationMode = false,
}: Props) => {
  const { planYearSetupForm, onSubmit } = usePlanYearSetupForm({
    shouldFallbackToEarliestPeriod: true,
    isFinalizationMode,
    employerId,
  });

  const handleStepSubmit: HandleStepSubmit = async () => {
    if (await onSubmit()) {
      return { navigate: true, employerId };
    }

    return { navigate: false };
  };

  const isSubmitDisabled = !planYearSetupForm.formState.isValid;

  return (
    <AddEmployerStep
      isFinalizationMode={isFinalizationMode}
      step={AddEmployerStepType.PLAN_YEAR_SETUP}
      subtitle="will not be included in the employer invitation"
      title="Set up the company’s first plan year"
      isSubmitDisabled={isSubmitDisabled}
      onSubmit={handleStepSubmit}
      employerId={employerId}
      secondaryLabel="Skip"
      isDon={false}
      rightSection={<AddEmployerPlanYearSetupStepFaq />}
    >
      <PlanYearSetupFormInputs
        planYearSetupForm={planYearSetupForm}
        isFinalizationMode={isFinalizationMode}
      />
    </AddEmployerStep>
  );
};
