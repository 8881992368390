import { FieldInfo, Parser } from '@json2csv/plainjs';
import JSZip from 'jszip';

import { downloadFile } from './downloadFile';
import { logger } from './logger';

export const exportToCsv = <T>(
  items: T[],
  fileName: string,
  fields?: (string | FieldInfo<object, unknown>)[]
) => {
  try {
    const parser = new Parser({
      fields,
      formatters: {
        boolean: (value) => (value ? 'Yes' : 'No'),
      },
    });
    const csv = parser.parse(items);

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    downloadFile(url, fileName);
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

export const exportToMultipleCsvToZip = (
  items: Map<string, []>,
  fileName: string,
  fields?: (string | FieldInfo<object, unknown>)[]
) => {
  try {
    const zip = new JSZip();
    items.forEach((value, key) => {
      const parser = new Parser({
        fields,
        formatters: {
          boolean: (value) => (value ? 'Yes' : 'No'),
        },
      });
      const file = parser.parse(value);

      zip.file(`${key}`, file);
    });
    return zip.generateAsync({ type: 'blob' }).then((blob) => {
      const url = URL.createObjectURL(blob);
      return downloadFile(url, fileName);
    });
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
