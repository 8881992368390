import { Button, Center, Space, Text } from '@zorro/zorro-ui-design';

type Props = {
  employerName: string;
  onClose: () => void;
};

export const CancelInviteForm = ({ employerName, onClose }: Props) => {
  return (
    <>
      <Text mt="lg" fw={500}>
        {`Are you sure you want to cancel the invitation to ${employerName}? They
          won't be able to use the link you shared`}
      </Text>
      <Space h="xl" />
      <Center mt="md">
        <Button variant="tertiary" size="lg" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button ml="xl" variant="primary-red" size="lg">
          Cancel invitation
        </Button>
      </Center>
    </>
  );
};
