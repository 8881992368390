import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';
import classNames from './Icon.module.scss';

export const IconScale = ({ style, grow }: SVGIconProps) => {
  return (
    <Center style={style} classNames={classNames} data-grow={grow}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="none"
      >
        <path
          fill="#66D8F2"
          d="M16.87 23.447c-3.003 3.636-7.996 4.47-11.153 1.862-3.157-2.607-3.283-7.668-.28-11.303C8.439 10.37 17.085 5.113 20.242 7.72c3.157 2.607-.37 12.09-3.373 15.726Z"
        />
        <path
          stroke="#1A1721"
          d="M9.334 26.667h13.333M8 8l8-1.333L24 8M16 4v22.667M12 16 8 8l-4 8a4 4 0 1 0 8 0ZM28 16l-4-8-4 8a4 4 0 1 0 8 0Z"
        />
      </svg>
    </Center>
  );
};
