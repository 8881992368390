import { useRouter } from '@zorro/shared/utils';
import { useEffect, useState } from 'react';

import { MainLink, MainLinkProps } from '../MainLink';
import { MainLinkDropdown, MainLinkDropdownProps } from './MainLinkDropdown';

export type NewSidebarMainLinkProps = MainLinkProps | MainLinkDropdownProps;

type Props = {
  items: NewSidebarMainLinkProps[];
};

export const NewSidebar = ({ items }: Props) => {
  const { router } = useRouter();
  const [selectedRoute, setSelectedRoute] = useState<string>();

  useEffect(() => {
    router.beforePopState(() => {
      setSelectedRoute(undefined);

      return true;
    });
  }, [router]);

  return (
    <>
      {items.map((item) => {
        let isCurrentRoute = false;
        if ('subpages' in item) {
          isCurrentRoute = item.subpages.some((subpage) => {
            if (selectedRoute) {
              return subpage.link === selectedRoute;
            }

            return router.asPath.includes(subpage.link);
          });

          return (
            <MainLinkDropdown
              key={item.label}
              icon={item.icon}
              label={item.label}
              subpages={item.subpages}
              onClick={setSelectedRoute}
              isCurrentRoute={isCurrentRoute}
            />
          );
        }

        if (selectedRoute) {
          isCurrentRoute = item.link === selectedRoute;
        } else {
          isCurrentRoute = item.exactLinkOnly
            ? item.link === router.asPath
            : router.asPath.includes(item.link);
        }

        return (
          <MainLink
            {...item}
            key={item.label}
            onClick={setSelectedRoute}
            isCurrentRoute={isCurrentRoute}
          />
        );
      })}
    </>
  );
};
