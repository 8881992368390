import { Image, ImageProps } from '@mantine/core';
import { useState } from 'react';

import { ZorroText } from '../Text';

export type IImageProps = ImageProps & { alt: string; placeholder?: string };

export function ZorroImage({
  placeholder = 'Not Available',
  ...props
}: IImageProps) {
  const [errorState, setErrorState] = useState(false);
  const handleError = () => {
    setErrorState(true);
  };

  if (errorState) {
    return <ZorroText fw={600}>{placeholder}</ZorroText>;
  }

  return (
    <Image
      styles={(theme) => ({
        root: {
          backgroundColor: theme.colors.zorroWhite[0],
        },
      })}
      onError={handleError}
      {...props}
    />
  );
}
