import { UnstyledButton, UnstyledButtonProps } from '@mantine/core';
import { ReactElement, forwardRef } from 'react';

export type IUnstyledButtonProps = UnstyledButtonProps & {
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
  disabled?: boolean;
  children?: ReactElement;
};

/*
 * This needs to be forwardRef() to work properly with next/link
 * See more: https://nextjs.org/docs/api-reference/next/link#if-the-child-is-a-functional-component
 */
export const ZorroUnstyledButton = forwardRef<
  HTMLButtonElement,
  IUnstyledButtonProps
>(({ onClick, ...props }, ref) => {
  return <UnstyledButton onClick={onClick} ref={ref} {...props} />;
});

ZorroUnstyledButton.displayName = 'ZorroUnstyledButton';
