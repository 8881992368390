export const LogoDark = ({ ...props }) => {
  return (
    <svg
      width="117"
      height="32"
      viewBox="0 0 117 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_5_888)">
        <path
          d="M23.0681 10.1335V7.14484C23.0681 6.70899 22.7225 6.35486 22.2971 6.35486H13.1443C12.6582 6.35486 12.2632 5.95014 12.2632 5.45202V0.338563C12.2632 0.151769 12.1151 0 11.9328 0H5.67017C3.47882 0 1.70143 1.82123 1.70143 4.06664V7.13705C1.70143 7.32385 1.84955 7.47562 2.03184 7.47562H10.9302C11.7353 7.47562 12.1189 8.4913 11.5226 9.04779L1.43178 18.4303C0.520304 19.2786 0 20.4772 0 21.7381V24.789C0 25.2249 0.345603 25.579 0.770961 25.579H9.98072C10.4668 25.579 10.8618 25.9837 10.8618 26.4818V31.6614C10.8618 31.8482 11.0099 32 11.1922 32H17.6144C19.745 32 21.4692 30.2294 21.4692 28.0501V24.8668C21.4692 24.68 21.3211 24.5283 21.1388 24.5283H11.7163C11.0099 24.5283 10.6757 23.6371 11.1998 23.1546L21.6401 13.4491C22.5516 12.6008 23.0719 11.4022 23.0719 10.1413L23.0681 10.1335Z"
          fill="#812EE5"
        />
        <path
          d="M72.9677 15.9205C72.9677 21.641 68.8623 25.8828 63.063 25.8828C57.2637 25.8828 53.1582 21.641 53.1582 15.9205C53.1582 10.1999 57.2637 5.99707 63.063 5.99707C68.8623 5.99707 72.9677 10.2388 72.9677 15.9205ZM69.5003 15.9205C69.5003 11.3168 66.4962 8.82621 63.0592 8.82621C59.6221 8.82621 56.618 11.3168 56.618 15.9205C56.618 20.5241 59.6221 23.0575 63.0592 23.0575C66.4962 23.0575 69.5003 20.5669 69.5003 15.9205Z"
          fill="#1A1721"
        />
        <path
          d="M117 15.9205C117 21.641 112.894 25.8828 107.095 25.8828C101.296 25.8828 97.1904 21.641 97.1904 15.9205C97.1904 10.1999 101.296 5.99707 107.095 5.99707C112.894 5.99707 117 10.2388 117 15.9205ZM113.533 15.9205C113.533 11.3168 110.528 8.82621 107.091 8.82621C103.654 8.82621 100.65 11.3168 100.65 15.9205C100.65 20.5241 103.654 23.0575 107.091 23.0575C110.528 23.0575 113.533 20.5669 113.533 15.9205Z"
          fill="#1A1721"
        />
        <path
          d="M84.3045 6.35107H80.9434C79.7585 6.35107 78.8015 7.32785 78.8015 8.53811V9.32809C78.8015 9.62385 78.5698 9.86512 78.2812 9.8729H75.9721C75.7062 9.8729 75.4897 10.0947 75.4897 10.3671V25.2989C75.4897 25.4351 75.5961 25.5441 75.729 25.5441H78.6078C78.8736 25.5441 79.0901 25.3223 79.0901 25.0498V10.3321H79.0939C79.1167 10.0714 79.3218 9.83399 79.6294 9.83399H84.3045C84.5704 9.83399 84.7869 9.61217 84.7869 9.33976V6.8453C84.7869 6.57289 84.5704 6.35107 84.3045 6.35107Z"
          fill="#1A1721"
        />
        <path
          d="M95.4779 6.35107H92.1168C90.9319 6.35107 89.9748 7.32785 89.9748 8.53811V9.32809C89.9748 9.62385 89.7431 9.86512 89.4545 9.8729H87.1454C86.8796 9.8729 86.6631 10.0947 86.6631 10.3671V25.2989C86.6631 25.4351 86.7694 25.5441 86.9024 25.5441H89.7811C90.047 25.5441 90.2634 25.3223 90.2634 25.0498V10.3321H90.2672C90.29 10.0714 90.4951 9.83399 90.8027 9.83399H95.4779C95.7437 9.83399 95.9602 9.61217 95.9602 9.33976V6.8453C95.9602 6.57289 95.7437 6.35107 95.4779 6.35107Z"
          fill="#1A1721"
        />
        <path
          d="M50.5798 10.445C51.009 9.92352 51.2444 9.26196 51.2444 8.57706V6.84922C51.2444 6.5807 51.0317 6.35889 50.7659 6.35889H35.81C34.7542 6.35889 33.8997 7.23448 33.8997 8.31632V9.26585C33.8997 9.476 34.0288 9.65501 34.2111 9.72116H46.4971C46.706 9.72116 46.8047 9.98579 46.649 10.1298L34.1466 21.4852C33.7174 22.0067 33.4819 22.6683 33.4819 23.3532V25.081C33.4819 25.3495 33.6946 25.5713 33.9605 25.5713H49.2657C50.3215 25.5713 51.1761 24.6957 51.1761 23.6139V22.6449C51.1761 22.3764 50.9634 22.1546 50.6975 22.1546H38.2406C38.0317 22.1546 37.933 21.89 38.0887 21.746L50.5798 10.4411V10.445Z"
          fill="#1A1721"
        />
      </g>
      <defs>
        <clipPath id="clip0_5_888">
          <rect width="117" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
