import { Tabs, TabsProps } from '@mantine/core';

import classNames from './Tabs.module.scss';

export type ITabsProps = TabsProps;

export function ZorroTabs({ ...props }: ITabsProps) {
  return <Tabs classNames={classNames} {...props} />;
}

ZorroTabs.List = Tabs.List;
ZorroTabs.Tab = Tabs.Tab;
ZorroTabs.Panel = Tabs.Panel;
