import { PlanMarket } from '@zorro/clients';
import { screamingSnakeCaseToText } from '@zorro/shared/utils';
import { Badge, Box, Text } from '@zorro/zorro-ui-design';

import { PlanMarketConfig, PlanMarketIcon } from './PlanMarketEnrollmentUtils';

type PlanMarketBadgeProps = {
  planMarket: PlanMarket;
};

export const PlanMarketBadgeTableView = ({
  planMarket,
}: PlanMarketBadgeProps) => {
  return (
    <Box w="min-content" ml="xs">
      <Badge
        variant="transparent"
        style={{
          padding: '21px 22px 21px 3px',
          display: 'flex',
        }}
        leftSection={<PlanMarketIcon planMarket={planMarket} />}
      >
        <Text size="sm" style={{ paddingLeft: '6px' }}>
          {screamingSnakeCaseToText(planMarket)}
        </Text>
      </Badge>
    </Box>
  );
};

export const PlanMarketBadgePageView = ({
  planMarket,
}: PlanMarketBadgeProps) => {
  return (
    <Badge
      size="md"
      variant="transparent"
      c="black"
      bg={PlanMarketConfig[planMarket].color}
      radius={4}
      p="4px 8px"
      h={24}
      style={{ fontSize: '14px' }}
    >
      {screamingSnakeCaseToText(planMarket)}
    </Badge>
  );
};
