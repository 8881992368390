import { CSSProperties } from 'react';

import styles from './AddIcon.module.css';

type AddIconProps = {
  style?: CSSProperties;
  isDisabled?: boolean;
};

export const AddIcon = ({ style, isDisabled = false }: AddIconProps) => {
  const circleColor = isDisabled ? '#E8E8E8' : '#8033EF';
  const plusColor = isDisabled ? '#B2B2B2' : 'white';
  return (
    <svg
      data-disabled={isDisabled}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="add icon"
      style={style}
      className={styles.addIcon}
    >
      <circle
        cx="12"
        cy="12"
        r="11"
        fill={circleColor}
        stroke={circleColor}
        strokeWidth="2"
      />
      <mask
        id="mask0_343_1537"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="4"
        width="16"
        height="16"
      >
        <rect
          x="5.30005"
          y="5.3"
          width="13.4"
          height="13.4"
          fill="#D9D9D9"
          stroke="#8033EF"
        />
      </mask>
      <g mask="url(#mask0_343_1537)">
        <path
          d="M12.0001 6.90883V17.0912"
          stroke={plusColor}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.90894 12H17.0913"
          stroke={plusColor}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
