export const DependentIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_502_1701)">
        <path
          d="M31.4784 7.88349C30.899 11.2202 30.6452 18.0861 25.2151 22.45C19.7851 26.814 15.2507 25.3061 10.6916 24.5139C10.8763 20.5457 10.4019 15.1385 15.832 10.7746C21.262 6.41064 27.5274 7.59748 31.4784 7.88349Z"
          fill="#66D8F2"
        />
        <path
          d="M16 15C17.6569 15 19 13.6569 19 12C19 10.3431 17.6569 9 16 9C14.3431 9 13 10.3431 13 12C13 13.6569 14.3431 15 16 15Z"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 24V22.3333C11 21.4493 11.3512 20.6014 11.9763 19.9763C12.6014 19.3512 13.4493 19 14.3333 19H17.6667C18.5507 19 19.3986 19.3512 20.0237 19.9763C20.6488 20.6014 21 21.4493 21 22.3333V24"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_502_1701">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
