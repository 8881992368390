import { Button, Flex } from '@zorro/zorro-ui-design';

type Props = {
  onClose: () => void;
  onSubmit: () => void;
  submitVariant: string;
  submitText: string;
  isLoading?: boolean;
};

export const ModalFooter = ({
  onClose,
  onSubmit,
  submitVariant,
  submitText,
  isLoading,
}: Props) => {
  return (
    <Flex justify="flex-end" gap="sm">
      <Button onClick={onClose} color="black" variant="tertiary" size="md">
        Cancel
      </Button>
      <Button
        onClick={onSubmit}
        size="md"
        variant={submitVariant}
        loading={isLoading}
      >
        {submitText}
      </Button>
    </Flex>
  );
};
