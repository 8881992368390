import { Switch, SwitchProps } from '@mantine/core';

import classNames from './Switch.module.scss';

export type ISwitchProps = SwitchProps;

export function ZorroSwitch({
  size = 'xl',
  checked = false,
  disabled = false,
  ...props
}: ISwitchProps) {
  return (
    <Switch
      checked={checked}
      size={size}
      classNames={classNames}
      styles={(theme) => ({
        label: {
          fontSize: theme.fontSizes.sm,
          color: theme.colors.zorroCoal[9],
        },
        track: {
          border: 'unset',
          borderRadius: 24,
        },
        thumb: {
          border: 'unset',
        },
      })}
      wrapperProps={{ 'data-checked': checked }}
      disabled={disabled}
      {...props}
    />
  );
}
