import { Overlay, OverlayProps } from '@mantine/core';
import { HTMLAttributes, RefAttributes, forwardRef } from 'react';

export type IOverlayProps = OverlayProps &
  HTMLAttributes<HTMLDivElement> &
  RefAttributes<HTMLDivElement>;

export const ZorroOverlay = forwardRef<HTMLDivElement, IOverlayProps>(
  ({ blur = 2, opacity = 0.25, ...props }, ref) => {
    // @ts-expect-error - remove this comment in React 19
    return <Overlay ref={ref} blur={blur} opacity={opacity} {...props} />;
  }
);

ZorroOverlay.displayName = 'ZorroOverlay';
