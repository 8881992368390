import { IconMail } from '@tabler/icons-react';
import { RadixMenu } from '@zorro/zorro-ui-design';

import { ActionsSubMenu } from '../ActionsSubMenu';
import {
  calculateElectionWindowLaunchStatus,
  getOnboardingPeriodsStatus,
} from '../actionsMenu.utils';
import { EmployeeActionsOverlays } from '../employeeActions.consts';
import { ActionsMenuSection } from '../employeeActions.types';

const EmailCommunicationsSection = ({
  selectedEmployees,
  setOverlayId,
}: ActionsMenuSection) => {
  const {
    areAllOnboardingPeriodsActive,
    areAllOnboardingPeriodsSubmitted,
    areAllOnboardingPeriodsEnrolled,
    areAllOnboardingPeriodsWaived,
  } = getOnboardingPeriodsStatus(selectedEmployees);

  const isElectionWindowLaunch =
    calculateElectionWindowLaunchStatus(selectedEmployees);

  return (
    <ActionsSubMenu title="Email communication" icon={IconMail}>
      <RadixMenu.Item
        onClick={() =>
          setOverlayId(EmployeeActionsOverlays.EMAIL_ACCOUNT_INVITATION)
        }
      >
        {EmployeeActionsOverlays.EMAIL_ACCOUNT_INVITATION}
      </RadixMenu.Item>
      <RadixMenu.Item
        disabled={!isElectionWindowLaunch}
        onClick={() =>
          setOverlayId(EmployeeActionsOverlays.EMAIL_ELECTION_WINDOW_LAUNCH)
        }
      >
        {EmployeeActionsOverlays.EMAIL_ELECTION_WINDOW_LAUNCH}
      </RadixMenu.Item>
      <RadixMenu.Item
        disabled={!areAllOnboardingPeriodsActive}
        onClick={() =>
          setOverlayId(EmployeeActionsOverlays.EMAIL_ELECTION_DEADLINE_REMINDER)
        }
      >
        {EmployeeActionsOverlays.EMAIL_ELECTION_DEADLINE_REMINDER}
      </RadixMenu.Item>
      <RadixMenu.Item
        disabled={!areAllOnboardingPeriodsSubmitted}
        onClick={() =>
          setOverlayId(EmployeeActionsOverlays.EMAIL_ELECTION_SUMMARY)
        }
      >
        {EmployeeActionsOverlays.EMAIL_ELECTION_SUMMARY}
      </RadixMenu.Item>
      <RadixMenu.Item
        disabled={!areAllOnboardingPeriodsEnrolled}
        onClick={() =>
          setOverlayId(EmployeeActionsOverlays.EMAIL_ENROLLMENT_CONFIRMATION)
        }
      >
        {EmployeeActionsOverlays.EMAIL_ENROLLMENT_CONFIRMATION}
      </RadixMenu.Item>
      <RadixMenu.Item
        disabled={!areAllOnboardingPeriodsWaived}
        onClick={() =>
          setOverlayId(EmployeeActionsOverlays.EMAIL_WAIVER_CONFIRMATION)
        }
      >
        {EmployeeActionsOverlays.EMAIL_WAIVER_CONFIRMATION}
      </RadixMenu.Item>
    </ActionsSubMenu>
  );
};

export { EmailCommunicationsSection };
