import { Checkbox, CheckboxGroupProps } from '@mantine/core';
import { forwardRef } from 'react';

export type ICheckboxGroupProps = CheckboxGroupProps;

export const ZorroCheckboxGroup = forwardRef(
  (
    { size = 'sm', ...props }: ICheckboxGroupProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <Checkbox.Group
        ref={ref}
        size={size}
        styles={(theme) => ({
          label: {
            fontSize: theme.fontSizes.sm,
            fontWeight: 400,
          },
        })}
        {...props}
      />
    );
  }
);

ZorroCheckboxGroup.displayName = 'ZorroCheckboxGroup';
