import { HttpStatusCode } from 'axios';

import { logger } from './logger';

async function nullIf<T>(
  statusCode: HttpStatusCode,
  functionToExecute: () => Promise<T>
) {
  try {
    return await functionToExecute();
  } catch (error) {
    const message = `Caught an exception when executing ${functionToExecute}`;
    if (error.status === statusCode) {
      logger.warn(error, message);
      return null;
    }
    logger.error(error, message);
    throw error;
  }
}
export async function nullIfNotFound<T>(functionToExecute: () => Promise<T>) {
  return await nullIf(HttpStatusCode.NotFound, functionToExecute);
}

export async function nullIfBadRequest<T>(functionToExecute: () => Promise<T>) {
  return await nullIf(HttpStatusCode.BadRequest, functionToExecute);
}

export async function nullIfUnprocessableEntity<T>(
  functionToExecute: () => Promise<T>
) {
  return await nullIf(HttpStatusCode.UnprocessableEntity, functionToExecute);
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
