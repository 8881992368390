import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const GenericFileIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="20"
        height="26"
        viewBox="0 0 20 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 5C1 2.79086 2.79086 1 5 1H12V13V25H5C2.79086 25 1 23.2091 1 21V5Z"
          fill="#C5C9F0"
        />
        <path
          d="M2.02806 2.05442C2.68631 1.37928 3.57909 1 4.51 1H11.98C12.2903 1 12.5879 1.12643 12.8073 1.35147L18.6573 7.35147C18.8767 7.57652 19 7.88174 19 8.2V21.4C19 22.3548 18.6302 23.2705 17.9719 23.9456C17.3137 24.6207 16.4209 25 15.49 25H4.51C3.57909 25 2.68631 24.6207 2.02806 23.9456C1.3698 23.2705 1 22.3548 1 21.4V4.6C1 3.64522 1.3698 2.72955 2.02806 2.05442Z"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 9H8.33333M6 13.5H14M6 18H14"
          stroke="#1A1721"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Center>
  );
};
