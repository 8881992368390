import { EnrollmentStatus } from '@zorro/clients';

import { ActionsMenuSection } from './employeeActions.types';

function isEnrollmentStatus(
  status: EnrollmentStatus | undefined
): status is EnrollmentStatus {
  return status !== undefined;
}

export function calculateElectionWindowLaunchStatus(
  selectedEmployees: ActionsMenuSection['selectedEmployees']
) {
  const enrollmentStatuses = selectedEmployees.map(
    (employee) => employee.enrollmentStatus
  );

  if (enrollmentStatuses.length === 0) {
    return false;
  }

  // if any status is undefined, return false
  if (!enrollmentStatuses.every((status) => isEnrollmentStatus(status))) {
    return false;
  }

  const electionWindowLaunchStatuses = new Set([
    EnrollmentStatus.PENDING_ELECTION_WINDOW,
    EnrollmentStatus.ELECTION_ACTIVE_STARTED,
    EnrollmentStatus.ELECTION_ACTIVE_HAS_NOT_STARTED,
  ]);

  return enrollmentStatuses.every((status) =>
    electionWindowLaunchStatuses.has(status)
  );
}

export function getOnboardingPeriodsStatus(
  selectedEmployees: ActionsMenuSection['selectedEmployees']
) {
  const enrollmentStatuses = selectedEmployees.map(
    (employee) => employee.enrollmentStatus
  );

  const defaultReturn = {
    areAllOnboardingPeriodsExist: false,
    areAllOnboardingPeriodsActive: false,
    areAllOnboardingPeriodsSubmitted: false,
    areAllOnboardingPeriodsEnrolled: false,
    areAllOnboardingPeriodsWaived: false,
    areAllWithBenefitEnrollments: false,
    areAnyWithBenefitEnrollments: false,
  };

  if (enrollmentStatuses.length === 0) {
    return defaultReturn;
  }

  // if any status is undefined, set all to false
  if (!enrollmentStatuses.every((status) => isEnrollmentStatus(status))) {
    return defaultReturn;
  }

  const doNotExistStatuses = new Set([EnrollmentStatus.NO_ENROLLMENTS]);

  const areAllOnboardingPeriodsExist = enrollmentStatuses.every(
    (status) => !doNotExistStatuses.has(status)
  );

  const activeStatuses = new Set([
    EnrollmentStatus.ELECTION_ACTIVE_STARTED,
    EnrollmentStatus.ELECTION_ACTIVE_HAS_NOT_STARTED,
  ]);

  const areAllOnboardingPeriodsActive = enrollmentStatuses.every((status) =>
    activeStatuses.has(status)
  );

  const submittedStatuses = new Set([
    EnrollmentStatus.ELECTION_SUBMITTED,
    EnrollmentStatus.ENROLLMENT_CONFIRMED,
    EnrollmentStatus.CARRIER_APPLICATION_SENT,
    EnrollmentStatus.ACTIVE_COVERAGE,
    EnrollmentStatus.COVERAGE_ENDED,
  ]);

  const areAllOnboardingPeriodsSubmitted = enrollmentStatuses.every((status) =>
    submittedStatuses.has(status)
  );

  const waivedStatuses = new Set([
    EnrollmentStatus.WAIVED_ELECTION,
    EnrollmentStatus.WAIVED_COVERAGE,
  ]);

  const enrolledStatuses = new Set([
    EnrollmentStatus.ENROLLMENT_CONFIRMED,
    EnrollmentStatus.ACTIVE_COVERAGE,
    EnrollmentStatus.COVERAGE_ENDED,
  ]);

  const areAllOnboardingPeriodsWaived = enrollmentStatuses.every((status) =>
    waivedStatuses.has(status)
  );

  const areAllOnboardingPeriodsEnrolled = enrollmentStatuses.every((status) =>
    enrolledStatuses.has(status)
  );

  const noBenefitEnrollmentStatuses = new Set([
    EnrollmentStatus.PENDING_ELECTION_WINDOW,
    EnrollmentStatus.DEADLINE_PASSED,
    EnrollmentStatus.ELECTION_ACTIVE_STARTED,
    EnrollmentStatus.ELECTION_ACTIVE_HAS_NOT_STARTED,
  ]);

  const areAllWithBenefitEnrollments = enrollmentStatuses.every(
    (status) => !noBenefitEnrollmentStatuses.has(status)
  );
  const areAnyWithBenefitEnrollments = enrollmentStatuses.some(
    (status) => !noBenefitEnrollmentStatuses.has(status)
  );

  return {
    areAllOnboardingPeriodsExist,
    areAllOnboardingPeriodsActive,
    areAllOnboardingPeriodsSubmitted,
    areAllOnboardingPeriodsEnrolled,
    areAllOnboardingPeriodsWaived,
    areAllWithBenefitEnrollments,
    areAnyWithBenefitEnrollments,
  };
}

export function getEmployeeNameOrEmployeesCount(
  selectedEmployees: ActionsMenuSection['selectedEmployees']
) {
  const isSingleEmployee = selectedEmployees.length === 1;

  if (isSingleEmployee) {
    const [employee] = selectedEmployees;
    return `${employee.firstName} ${employee.lastName}`;
  }

  return `${selectedEmployees.length} employees`;
}
