/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum DecisionFactors {
  MINIMIZE_ALL_IN_COST = 'MINIMIZE_ALL_IN_COST',
  MINIMIZE_RISK = 'MINIMIZE_RISK',
  KEEP_DOCTORS = 'KEEP_DOCTORS',
  KEEP_MEDICATIONS = 'KEEP_MEDICATIONS',
  FREE_REFERRALS = 'FREE_REFERRALS',
}
