import axios from 'axios';

export async function getIpAddress(): Promise<string> {
  try {
    const response = await axios.get('https://checkip.amazonaws.com');
    return response.data.trim();
  } catch {
    return '';
  }
}
