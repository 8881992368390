import {
  callEndpoint,
  responseErrorToString,
  showErrorNotification,
} from '@zorro/shared/utils';

export async function resetPassword(userId: string) {
  try {
    const passwordResetDto = await callEndpoint({
      method: 'usersControllerGeneratePasswordResetToken',
      params: [userId],
    });
    window.open(passwordResetDto.link, '_blank');
  } catch (error) {
    showErrorNotification({ message: responseErrorToString(error) });
  }
}
