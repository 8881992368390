import { List, ListProps } from '@mantine/core';

export type IListProps = ListProps;

export function ZorroList({ ...props }: IListProps) {
  return (
    <List
      styles={(theme) => ({
        item: {
          color: theme.colors.zorroCoal[9],
        },
      })}
      {...props}
    />
  );
}

ZorroList.Item = List.Item;
