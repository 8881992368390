import { InsuredPeopleDto, OnboardingPeriodDto } from '@zorro/clients';
import { handleKeyDown } from '@zorro/shared/utils';
import { Box, Text } from '@zorro/zorro-ui-design';
import { Fragment } from 'react';
import { UseFormReturn, useFieldArray } from 'react-hook-form';

import { MedicalPlanFormInputs } from './MedicalPlanFormInputs';
import {
  MedicalPlanFormFields,
  getDefaultMedicalPlanFormFields,
} from './MedicalPlansFormUtils';

type Props = {
  medicalPlanForm: UseFormReturn<MedicalPlanFormFields>;
  insuredIdToNameMap: Map<string, string>;
  onboardingPeriod?: OnboardingPeriodDto;
  insured: InsuredPeopleDto;
  whoIsEnrolled: string[];
  isSubmitting: boolean;
  employeeId: string;
};

export const MedicalPlansFormInputs = ({
  insuredIdToNameMap,
  onboardingPeriod,
  medicalPlanForm,
  whoIsEnrolled,
  isSubmitting,
  employeeId,
  insured,
}: Props) => {
  const { fields, append, remove } = useFieldArray({
    control: medicalPlanForm.control,
    name: 'plans',
  });

  const handleAppendPlan = () => append(getDefaultMedicalPlanFormFields([]));

  return (
    <>
      {fields.map(({ id }, index) => (
        <Fragment key={id}>
          <MedicalPlanFormInputs
            handleDeleteDependent={() => remove(index)}
            hasMoreThanOnePlan={fields.length > 1}
            insuredIdToNameMap={insuredIdToNameMap}
            whoIsEnrolledOptions={whoIsEnrolled}
            onboardingPeriod={onboardingPeriod}
            medicalPlanForm={medicalPlanForm}
            isSubmitting={isSubmitting}
            insured={insured}
            index={index}
            employeeId={employeeId}
          />
        </Fragment>
      ))}
      <Box
        w="100%"
        mx="auto"
        opacity={1}
        tabIndex={0}
        p="18px 48px"
        pos="relative"
        bg="zorroWhite.0"
        style={(theme) => ({
          cursor: 'pointer',
          borderRadius: theme.radius.md,
          boxShadow: `inset 0 0 0 1px ${theme.colors.zorroGray[7]}`,
        })}
        onClick={handleAppendPlan}
        onKeyDown={(event) => handleKeyDown(event, handleAppendPlan)}
      >
        <Text ta="center" c="zorroIris.9">
          Add another plan
        </Text>
      </Box>
    </>
  );
};
