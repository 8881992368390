import { RingProgress, RingProgressProps } from '@mantine/core';
import { ForwardedRef, forwardRef } from 'react';

export type IRingProgressProps = RingProgressProps & {
  ref: ForwardedRef<HTMLDivElement>;
};

export const ZorroRingProgress = forwardRef(
  ({ ...props }: IRingProgressProps, ref: ForwardedRef<HTMLDivElement>) => {
    return <RingProgress {...props} ref={ref} />;
  }
);

ZorroRingProgress.displayName = 'RingProgress';
