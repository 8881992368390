import { RemoveScroll } from '@mantine/core';
import { LoadingOverlay } from '@zorro/zorro-ui-design';
import { ReactNode, createContext, useContext, useState } from 'react';

export type LoadingOverlayContextProps = {
  isLoading: boolean;
  startLoading: () => void;
  stopLoading: () => void;
};

export const LoadingOverlayContext = createContext<LoadingOverlayContextProps>({
  isLoading: false,
  startLoading: () => {
    return;
  },
  stopLoading: () => {
    return;
  },
});

type LoadingOverlayProviderProps = {
  children?: ReactNode;
};

export const LoadingOverlayProvider = ({
  children,
}: LoadingOverlayProviderProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const startLoading = () => setIsLoading(true);
  const stopLoading = () => setIsLoading(false);

  return (
    <LoadingOverlayContext.Provider
      value={{ isLoading, startLoading, stopLoading }}
    >
      <RemoveScroll enabled={isLoading}>
        <LoadingOverlay visible={isLoading} />
        {children}
      </RemoveScroll>
    </LoadingOverlayContext.Provider>
  );
};

export function useLoadingOverlay() {
  return useContext(LoadingOverlayContext);
}
