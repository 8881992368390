import { TypographyStylesProvider } from '@mantine/core';
import { generateHTML } from '@tiptap/html';
import { JSONContent } from '@tiptap/react';
import { useMemo } from 'react';

import { richTextEditorExtensions } from './richTextEditor.consts';

type RichTextViewerProps = {
  content: JSONContent;
};

const ZorroRichTextViewer = ({ content }: RichTextViewerProps) => {
  const htmlContent = useMemo(() => {
    return generateHTML(content, richTextEditorExtensions);
  }, [content]);

  return (
    <TypographyStylesProvider>
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </TypographyStylesProvider>
  );
};

export { ZorroRichTextViewer };
