import { Group, Stack, ThemeIcon } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { ActionIcon, Icon, Text, brand } from '@zorro/zorro-ui-design';

import className from './CardSelector.module.scss';
import { TCardSelectorOption } from './cardSelector.types';

export const SelectedCardOption = ({
  label,
  description,
  iconBgColor,
  icon,
  readonly,
  opened,
  onClick,
}: TCardSelectorOption) => {
  return (
    <Group
      wrap="nowrap"
      gap="lg"
      py="md"
      pl="sm"
      align="flex-start"
      justify="space-between"
      flex="1 0 auto"
      onClick={onClick}
      className={className['selected-option']}
      data-opened={opened}
      data-readonly={readonly}
    >
      <Group wrap="nowrap">
        <ThemeIcon color={iconBgColor} size={48} radius={4}>
          <Icon icon={icon} />
        </ThemeIcon>
        <Stack gap={0}>
          <Text fw={600}>{label}</Text>
          <Text size="sm" c={brand.zorroGray400}>
            {description}
          </Text>
        </Stack>
      </Group>
      {!readonly && (
        <ActionIcon variant="filled" mr="md">
          <IconChevronDown />
        </ActionIcon>
      )}
    </Group>
  );
};
