import { VALIDATION_MESSAGES } from '@zorro/shared/utils';
import * as yup from 'yup';

export const changeEmployeeClassSchema = yup.object({
  class: yup.string().required(VALIDATION_MESSAGES.classRequired),
});

export type ChangeEmployeeClassFormFields = yup.InferType<
  typeof changeEmployeeClassSchema
>;
