import { EmployeePaymentMethodType } from '@zorro/clients';
import {
  IconZorroPaymentCard,
  IconZorroPaymentCombined,
  IconZorroPaymentSelfPay,
} from '@zorro/shared/assets';
import { brand } from '@zorro/zorro-ui-design';
import _memoize from 'lodash/memoize';

import { TCardSelectorOption } from '../../CardSelector/cardSelector.types';

export const getPaymentMethodSelectOptions = _memoize(
  (isElena: boolean = false): TCardSelectorOption[] => {
    return [
      {
        value: EmployeePaymentMethodType.ZORRO_PAY,
        label: 'Zorro Pay',
        description:
          'Payments paid from Zorro Pay and amounts above allowance deducted through payroll',
        icon: IconZorroPaymentCard,
        iconBgColor: brand.primaryButtonSelected,
      },
      {
        value: EmployeePaymentMethodType.COMBINED,
        label: 'Combined',
        description: isElena
          ? 'Payments split between Zorro Pay and your personal card/account'
          : "Payments split between Zorro Pay and employee's personal card/account",
        icon: IconZorroPaymentCombined,
        iconBgColor: brand.zorroSky100,
      },
      {
        value: EmployeePaymentMethodType.SELF_PAY,
        label: 'Self-pay (reimbursement)',
        description: isElena
          ? 'Payments paid from your personal card/account and allowance reimbursed through payroll'
          : "Payments paid from employee's personal card/account and allowance reimbursed through payroll",
        icon: IconZorroPaymentSelfPay,
        iconBgColor: brand.zorroYolk400,
      },
    ];
  }
);
