import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const FsaIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_535_1668)">
          <path
            d="M11.017 18.1984L15.0856 14.4251C16.3203 13.2799 16.4134 11.3727 15.2936 10.1652L11.6035 6.1864C10.4836 4.97889 8.5748 4.92835 7.34005 6.07351L3.27148 9.84684C2.03672 10.992 1.9436 12.8992 3.06349 14.1067L6.75355 18.0855C8.5144 20.5194 9.78225 19.3435 11.017 18.1984Z"
            fill="#80E8B6"
          />
          <path
            d="M20 14.6667V14.6792"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.3332 4V9.07067C22.9833 10.0255 24.2396 11.5366 24.8772 13.3333H26.6652C27.0188 13.3333 27.3579 13.4738 27.608 13.7239C27.858 13.9739 27.9985 14.313 27.9985 14.6667V17.3333C27.9985 17.687 27.858 18.0261 27.608 18.2761C27.3579 18.5262 27.0188 18.6667 26.6652 18.6667H24.8758C24.4273 19.9338 23.667 21.0678 22.6652 21.964V24.6667C22.6652 25.1971 22.4544 25.7058 22.0794 26.0809C21.7043 26.456 21.1956 26.6667 20.6652 26.6667C20.1347 26.6667 19.626 26.456 19.2509 26.0809C18.8759 25.7058 18.6652 25.1971 18.6652 24.6667V23.8893C18.2246 23.9631 17.7786 24.0002 17.3318 24H11.9985C11.5517 24.0002 11.1058 23.9631 10.6652 23.8893V24.6667C10.6652 25.1971 10.4544 25.7058 10.0794 26.0809C9.7043 26.456 9.19559 26.6667 8.66516 26.6667C8.13473 26.6667 7.62602 26.456 7.25095 26.0809C6.87587 25.7058 6.66516 25.1971 6.66516 24.6667V22L6.66649 21.964C5.45853 20.884 4.60698 19.4627 4.22455 17.8881C3.84212 16.3136 3.94684 14.66 4.52484 13.1463C5.10285 11.6325 6.12689 10.33 7.46145 9.41101C8.796 8.49205 10.3781 8.00001 11.9985 8H15.3318L21.3318 4H21.3332Z"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.6665 12L7.99984 14.6667"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_535_1668">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
