import { Tooltip, TooltipProps } from '@mantine/core';
import { useMobileDetector, useScrollEvent } from '@zorro/shared/utils';
import { useState } from 'react';

import { brand } from '../theme';

export type ITooltipProps = TooltipProps;

export const ZorroTooltip = ({
  multiline = true,
  arrowSize = 14,
  position = 'bottom',
  color = brand.zorroGray400,
  // eslint-disable-next-line id-length
  c = brand.zorroWhite,
  maw = 218,
  ...props
}: ITooltipProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useMobileDetector();

  const handleOnClick = () => {
    setIsOpen(isMobile ? true : !isOpen);
  };

  useScrollEvent({
    onScroll: () => isMobile && setIsOpen(false),
  });

  return (
    <Tooltip
      onMouseLeave={() => setIsOpen(false)}
      onMouseEnter={() => setIsOpen(true)}
      onClick={handleOnClick}
      withArrow={!!arrowSize}
      arrowSize={arrowSize}
      multiline={multiline}
      position={position}
      opened={isOpen}
      color={color}
      radius="sm"
      maw={maw}
      px="md"
      py="xs"
      c={c}
      styles={{
        tooltip: {
          boxShadow: '0px 4px 8px 0px #00000033',
        },
      }}
      {...props}
    />
  );
};
