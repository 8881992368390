import { ActionIcon, AppShell } from '@mantine/core';
import { IconMenu2 } from '@tabler/icons-react';
import { ZorroLogo } from '@zorro/shared/assets';
import {
  COMMON_DYNAMIC_ROUTES,
  getFullName,
  useDynamicRouter,
  useImpersonation,
} from '@zorro/shared/utils';
import { Center, Group, Icon } from '@zorro/zorro-ui-design';

import { useAppLayout } from '../AppLayoutContext';
import classNames from './AppLayoutHeader.module.scss';
import { AppLayoutHeaderImpersonation } from './AppLayoutHeaderImpersonation';
import { AppLayoutHeaderMenu } from './AppLayoutHeaderMenu';

/* eslint-disable react/boolean-prop-naming */
type Props = {
  hideSidebar: boolean;
  shouldShowDonElenaMenuItems: boolean;
  isDon: boolean;
};

export const AppLayoutHeader = ({
  hideSidebar,
  shouldShowDonElenaMenuItems,
  isDon,
}: Props) => {
  const { navigateToRoute } = useDynamicRouter(COMMON_DYNAMIC_ROUTES);
  const { isImpersonated, user, isLoading } = useImpersonation();
  const { isSidebarOpen, toggleSidebar } = useAppLayout();

  let impersonatedFullName: string | undefined = undefined;
  if (isImpersonated) {
    if (!user || isLoading) {
      return null;
    }

    impersonatedFullName = getFullName(user);
  }

  return (
    <AppShell.Header bg="zorroGray.10">
      {impersonatedFullName && (
        <AppLayoutHeaderImpersonation fullName={impersonatedFullName} />
      )}
      <Group mt="0.625rem" mx="sm" justify="space-between">
        <Group>
          {!hideSidebar && (
            <ActionIcon
              onClick={() => toggleSidebar()}
              data-open={isSidebarOpen}
              classNames={classNames}
              variant="subtle"
              size="3.5rem"
            >
              <Icon icon={IconMenu2} />
            </ActionIcon>
          )}
          <Center
            onClick={() => navigateToRoute('HOME', [])}
            style={{ cursor: 'pointer' }}
          >
            <ZorroLogo />
          </Center>
        </Group>
        <AppLayoutHeaderMenu
          shouldShowDonElenaMenuItems={shouldShowDonElenaMenuItems}
          isDon={isDon}
        />
      </Group>
    </AppShell.Header>
  );
};
