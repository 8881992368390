import { yupResolver } from '@hookform/resolvers/yup';
import { DatesRangeValue } from '@mantine/dates';
import { LeaveOfAbsenceDto } from '@zorro/clients';
import { formatDateISO, parseDateISO } from '@zorro/shared/formatters';
import { useForm } from '@zorro/shared/utils';
import {
  Button,
  Center,
  DateInput,
  DateRangeInput,
  FormErrorMessage,
  Grid,
  Space,
  Stack,
  Switch,
} from '@zorro/zorro-ui-design';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

import { useBatchCallEndpoint } from '../../hooks';
import { ErrorsTable } from '../Errors/ErrorsTable';

export type UpdateEmployeeEligibilityProps = {
  id: string;
  firstName?: string;
  lastName?: string;
  eligibleFrom?: string | null;
  eligibleUntil?: string | null;
  leaveOfAbsence?: LeaveOfAbsenceDto | null;
};

export interface UpdateEligibilityFormFields {
  shouldSetStartDate: boolean;
  startDate?: Date;
  shouldSetEndDate: boolean;
  endDate?: Date;
  shouldSetLeaveOfAbsence: boolean;
  leaveOfAbsence?: DatesRangeValue;
}

const updateEligibilityFormSchema = yup.object().shape({
  shouldSetStartDate: yup.boolean(),
  startDate: yup.date().when('shouldSetStartDate', {
    is: true,
    then: () => yup.date().required(),
    otherwise: () => yup.date().notRequired(),
  }),
  shouldSetEndDate: yup.boolean(),
  endDate: yup.date().when('shouldSetEndDate', {
    is: true,
    then: () => yup.date().required(),
    otherwise: () => yup.date().notRequired(),
  }),
  shouldSetLeaveOfAbsence: yup.boolean(),
  leaveOfAbsence: yup.array().when('shouldSetLeaveOfAbsence', {
    is: true,
    then: () => yup.array().required(),
    otherwise: () => yup.array().notRequired(),
  }),
});

type UpdateEligibilityFormProps = {
  selectedEmployees: UpdateEmployeeEligibilityProps[];
  onSuccess?: (
    updateEligibilityProps: Omit<UpdateEmployeeEligibilityProps, 'id'>
  ) => void;
  onError?: () => void;
};

export function UpdateEligibilityForm({
  selectedEmployees,
  onSuccess,
  onError,
}: UpdateEligibilityFormProps) {
  const { executeBatchCall, errors: callEndpointErrors } = useBatchCallEndpoint(
    {
      methodName: 'employeesControllerUpdateEligibility',
      singularItemName: 'special onboarding period',
      action: 'updated',
      batchSize: 5,
    }
  );

  const startDate = selectedEmployees.every(
    (employee) => employee.eligibleFrom === selectedEmployees[0].eligibleFrom
  )
    ? selectedEmployees[0].eligibleFrom
    : undefined;

  const endDate = selectedEmployees.every(
    (employee) => employee.eligibleUntil === selectedEmployees[0].eligibleUntil
  )
    ? selectedEmployees[0].eligibleUntil
    : undefined;

  const leaveOfAbsence = selectedEmployees.every(
    (employee) =>
      employee.leaveOfAbsence?.startDate ===
        selectedEmployees[0].leaveOfAbsence?.startDate &&
      employee.leaveOfAbsence?.endDate ===
        selectedEmployees[0].leaveOfAbsence?.endDate
  )
    ? selectedEmployees[0].leaveOfAbsence
    : undefined;

  const {
    handleSubmit,
    control,
    watch,
    formState: { isValid, isSubmitting, errors: formErrors },
  } = useForm<UpdateEligibilityFormFields>({
    mode: 'all',
    resolver: yupResolver(updateEligibilityFormSchema),
    defaultValues: {
      shouldSetStartDate: Boolean(startDate),
      startDate: startDate ? parseDateISO(startDate).toDate() : undefined,
      shouldSetEndDate: Boolean(endDate),
      endDate: endDate ? parseDateISO(endDate).toDate() : undefined,
      shouldSetLeaveOfAbsence: Boolean(leaveOfAbsence),
      leaveOfAbsence: leaveOfAbsence
        ? [
            parseDateISO(leaveOfAbsence.startDate).toDate(),
            parseDateISO(leaveOfAbsence.endDate).toDate(),
          ]
        : undefined,
    },
  });

  const updateEligibility = async (formFields: UpdateEligibilityFormFields) => {
    const eligibilityData = {
      eligibleFrom:
        formFields.startDate && formFields.shouldSetStartDate
          ? formatDateISO(formFields.startDate)
          : null,
      eligibleUntil:
        formFields.endDate && formFields.shouldSetEndDate
          ? formatDateISO(formFields.endDate)
          : null,
      leaveOfAbsence:
        formFields.leaveOfAbsence && formFields.shouldSetLeaveOfAbsence
          ? {
              startDate: formatDateISO(formFields.leaveOfAbsence[0] || ''),
              endDate: formatDateISO(formFields.leaveOfAbsence[1] || ''),
            }
          : null,
    };

    const batchItems = selectedEmployees.map((employee) => ({
      key: `${employee.firstName} ${employee.lastName}`,
      params: [employee.id, eligibilityData] as const,
    }));

    await executeBatchCall(batchItems, {
      onSuccess: () => onSuccess?.(eligibilityData),
      onError,
    });
  };

  return (
    <form onSubmit={handleSubmit(updateEligibility)}>
      <Grid>
        <Grid.Col span={{ sm: 6 }}>
          <Stack>
            <Controller
              control={control}
              name="shouldSetStartDate"
              render={({ field: { value, ref: _ref, ...rest } }) => (
                <Switch
                  {...rest}
                  size="md"
                  checked={value}
                  label="Set eligibility start date"
                />
              )}
            />
            <Controller
              control={control}
              name="startDate"
              render={({
                field: { ref: _ref, ...rest },
                fieldState: { error },
              }) => (
                <DateInput
                  {...rest}
                  label="Start date"
                  disabled={!watch('shouldSetStartDate')}
                />
              )}
            />

            <FormErrorMessage errors={formErrors} fieldName="startDate" />
          </Stack>
        </Grid.Col>
        <Grid.Col span={{ sm: 6 }}>
          <Stack>
            <Controller
              control={control}
              name="shouldSetEndDate"
              render={({ field: { value, ref: _ref, ...rest } }) => (
                <Switch
                  {...rest}
                  size="md"
                  checked={value}
                  label="Set eligibility end date"
                />
              )}
            />
            <Controller
              control={control}
              name="endDate"
              render={({
                field: { ref: _ref, ...rest },
                fieldState: { error },
              }) => (
                <DateInput
                  {...rest}
                  label="End date"
                  disabled={!watch('shouldSetEndDate')}
                />
              )}
            />

            <FormErrorMessage errors={formErrors} fieldName="endDate" />
          </Stack>
        </Grid.Col>
        <Grid.Col span={{ sm: 6 }}>
          <Stack>
            <Controller
              control={control}
              name="shouldSetLeaveOfAbsence"
              render={({ field: { value, ref: _ref, ...rest } }) => (
                <Switch
                  {...rest}
                  size="md"
                  checked={value}
                  label="Set a leave of absence"
                />
              )}
            />
            <Controller
              control={control}
              name="leaveOfAbsence"
              render={({ field: { ref: _ref, ...rest } }) => (
                <DateRangeInput
                  {...rest}
                  label="Leave dates"
                  disabled={!watch('shouldSetLeaveOfAbsence')}
                />
              )}
            />
          </Stack>
        </Grid.Col>
      </Grid>

      <Space h="xl" />

      <Center mt="md">
        <Button
          disabled={!isValid || isSubmitting}
          type="submit"
          size="lg"
          ml="xl"
        >
          Save
        </Button>
      </Center>

      <ErrorsTable
        tableTitle="Employee name"
        errors={callEndpointErrors}
        isBulk={selectedEmployees?.length > 1}
      />
    </form>
  );
}
