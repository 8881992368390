import { DocumentType, EmployerDocumentDto, Roles } from '@zorro/clients';
import { AddIcon, CancelIcon } from '@zorro/shared/assets';
import { useMonolithQuery, useRoles } from '@zorro/shared/utils';
import {
  Button,
  Drawer,
  Flex,
  Grid,
  Modal,
  Space,
  Title,
} from '@zorro/zorro-ui-design';
import { Fragment, useState } from 'react';

import { ModalTitle } from '../ModalComponents';
import { DeleteDocumentConfirmationForm } from './DeleteDocumentConfirmationForm';
import { DownloadableDocument } from './DownloadableDocument';
import { UploadDocumentForm } from './UploadDocumentForm';

type Props = {
  title: string;
  employerId: string;
  documentType: DocumentType;
};

export const DocumentsPage = ({ title, employerId, documentType }: Props) => {
  const { userRoles } = useRoles();
  const canCreateAndDeleteDocuments = userRoles.some((role) =>
    [Roles.OPERATOR, Roles.OMNIPOTENT_ADMIN, Roles.ACCOUNT_SUPERVISOR].includes(
      role
    )
  );

  const [selectedDocument, setSelectedDocument] =
    useState<EmployerDocumentDto>();
  const [isDeleteDocumentsModalOpen, setIsDeleteDocumentsModalOpen] =
    useState(false);
  const [isUploadDocumentModalOpen, setIsUploadDocumentModalOpen] =
    useState(false);

  const { data: documents } = useMonolithQuery({
    method: 'employerDocumentControllerGetDocuments',
    params: [employerId],
  });

  const filteredDocuments = documents
    ? documents.filter((document) => document.type === documentType)
    : [];

  return (
    <>
      <Grid>
        <Grid.Col span={{ sm: 6 }}>
          <Flex justify="space-between">
            <Title>{title}</Title>
            {canCreateAndDeleteDocuments && (
              <Button
                variant="subtle"
                p={0}
                onClick={() => setIsUploadDocumentModalOpen(true)}
              >
                <AddIcon />
              </Button>
            )}
          </Flex>
          <Space h="xs" />
          {filteredDocuments.map((document) => (
            <Fragment key={document.id}>
              <DownloadableDocument
                key={document.id}
                name={document.fileName}
                documentLocationUrl={document.documentUrl}
                onDelete={() => {
                  setSelectedDocument(document);
                  setIsDeleteDocumentsModalOpen(true);
                }}
                canDelete={canCreateAndDeleteDocuments}
              />
              <Space h="xs" />
            </Fragment>
          ))}
        </Grid.Col>
      </Grid>

      <Drawer
        opened={isUploadDocumentModalOpen}
        onClose={() => setIsUploadDocumentModalOpen(false)}
        title="Upload document"
      >
        <UploadDocumentForm
          employerId={employerId}
          type={documentType}
          successText="Upload"
          onSuccess={() => setIsUploadDocumentModalOpen(false)}
        />
      </Drawer>
      <Modal
        size="lg"
        opened={isDeleteDocumentsModalOpen}
        onClose={() => setIsDeleteDocumentsModalOpen(false)}
        showSeparator={false}
        title={
          <ModalTitle
            title="Delete document"
            icon={<CancelIcon />}
            titleOrder={2}
          />
        }
      >
        <DeleteDocumentConfirmationForm
          selectedDocument={selectedDocument}
          employerId={employerId}
          onClose={() => setIsDeleteDocumentsModalOpen(false)}
          onSubmit={() => {
            setIsDeleteDocumentsModalOpen(false);
            setSelectedDocument(undefined);
          }}
        />
      </Modal>
    </>
  );
};
