import { useLocalStorage } from '@mantine/hooks';
import _noop from 'lodash/noop';
import { ReactNode, createContext, useContext } from 'react';

export type AppLayoutContextProps = {
  toggleSidebar: () => void;
  isSidebarOpen: boolean;
};

export const AppLayoutContext = createContext<AppLayoutContextProps>({
  toggleSidebar: _noop,
  isSidebarOpen: false,
});

type LoadingOverlayProviderProps = {
  children?: ReactNode;
};

export const AppLayoutContextProvider = ({
  children,
}: LoadingOverlayProviderProps) => {
  const [isSidebarOpen, setIsSidebarOpen] = useLocalStorage<boolean>({
    key: 'sidebar-open',
    defaultValue: true,
  });

  return (
    <AppLayoutContext.Provider
      value={{
        toggleSidebar: () => setIsSidebarOpen(!isSidebarOpen),
        isSidebarOpen,
      }}
    >
      {children}
    </AppLayoutContext.Provider>
  );
};

export function useAppLayout() {
  return useContext(AppLayoutContext);
}
