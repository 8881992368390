/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum EmployeePaymentMethodState {
  CAN_BE_GENERATED = 'CAN_BE_GENERATED',
  AVAILABLE = 'AVAILABLE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  IN_HANDLING_WITH_DETAILS = 'IN_HANDLING_WITH_DETAILS',
  IN_HANDLING_WITHOUT_DETAILS = 'IN_HANDLING_WITHOUT_DETAILS',
}
