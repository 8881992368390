import {
  Applicant,
  FamilyUnit,
  InsuredPeopleDto,
  OnboardingPeriodDto,
  PlanMarket,
} from '@zorro/clients';
import { formatCurrencyEnUs } from '@zorro/shared/formatters';
import {
  VALIDATION_MESSAGES,
  calculateAgeForEnrollment,
} from '@zorro/shared/utils';
import { calculateFamilyUnit } from '@zorro/types';
import * as yup from 'yup';

export const medicalPlanItemSchema = yup.object({
  whoIsEnrolled: yup
    .array()
    .of(yup.string().required())
    .min(1, VALIDATION_MESSAGES.whoIsEnrolledRequired)
    .required(VALIDATION_MESSAGES.whoIsEnrolledRequired),
  planId: yup.string(),
  carrier: yup.string().required(VALIDATION_MESSAGES.carrierRequired),
  planName: yup.string().required(VALIDATION_MESSAGES.planNameRequired),
  premium: yup
    .number()
    .min(0, VALIDATION_MESSAGES.premiumPositive)
    .required(VALIDATION_MESSAGES.premiumRequired),
  isHsaEligible: yup.boolean(),
  maxOutOfPocket: yup.number(),
  benefitsSummaryUrl: yup.string(),
  deductible: yup.number(),
  planMarket: yup
    .mixed<PlanMarket>()
    .oneOf(Object.values(PlanMarket))
    .nullable(),
});

export type MedicalPlanItemFormFields = yup.InferType<
  typeof medicalPlanItemSchema
>;

export type MedicalPlanFormFields = {
  plans: MedicalPlanItemFormFields[];
};

export const medicalPlanFormSchema: yup.ObjectSchema<MedicalPlanFormFields> =
  yup.object({
    plans: yup.array().of(medicalPlanItemSchema).required(),
  });

export const formatExtendedPlanDetails = (
  plans: MedicalPlanItemFormFields[],
  insuredIdToNameMap: Map<string, string>
): string | undefined => {
  return plans.length > 1
    ? plans
        .map(
          ({ premium, whoIsEnrolled }) =>
            `[${formatCurrencyEnUs(premium)}, ${whoIsEnrolled
              .map((id) => insuredIdToNameMap.get(id))
              .join(',')}]`
        )
        .join(' + ')
    : undefined;
};

export const getWhoIsEnrolled = ({
  employee,
  spouse,
  dependents,
}: InsuredPeopleDto): string[] => {
  const whoIsEnrolled: string[] = [];
  whoIsEnrolled.push(employee.id);
  if (spouse) {
    whoIsEnrolled.push(spouse.id);
  }
  dependents.forEach((dependent) => whoIsEnrolled.push(dependent.id));

  return whoIsEnrolled;
};

export const getDefaultWhoIsEnrolled = (
  insured: InsuredPeopleDto,
  familyUnit: FamilyUnit = calculateFamilyUnit(insured)
): string[] => {
  const { employee, spouse, dependents } = insured;

  const employeeId = employee.id;
  const spouseId = spouse?.id;
  const dependentsIds = dependents.map(({ id }) => id);

  switch (familyUnit) {
    case FamilyUnit.EMPLOYEE_ONLY: {
      return [employeeId];
    }
    case FamilyUnit.EMPLOYEE_SPOUSE: {
      return [employeeId, spouseId].filter((id) => id !== undefined);
    }
    case FamilyUnit.EMPLOYEE_CHILD: {
      return [employeeId, ...dependentsIds];
    }
    case FamilyUnit.FAMILY: {
      return [employeeId, spouseId, ...dependentsIds].filter(
        (id) => id !== undefined
      );
    }
    default: {
      return [employeeId];
    }
  }
};

export const getApplicantsFromWhoIsEnrolled = (
  insured: InsuredPeopleDto,
  whoIsEnrolled: string[],
  targetEnrollmentDate: OnboardingPeriodDto['targetEnrollmentDate'],
  email: string
): Applicant[] => {
  const applicants: Applicant[] = [];
  if (whoIsEnrolled.length === 0) return applicants;

  const employeeSelected = whoIsEnrolled.find(
    (id) => id === insured.employee.id
  );

  if (employeeSelected) {
    applicants.push({
      age: calculateAgeForEnrollment(
        insured.employee.dateOfBirth,
        targetEnrollmentDate
      ),
      isSmoker: insured.employee.isSmoker,
      isChild: false,
      dateOfBirth: insured.employee.dateOfBirth,
      lastName: insured.employee.lastName,
      firstName: insured.employee.firstName,
      isEmployee: true,
      email,
    });
  }

  const spouseSelected = whoIsEnrolled.find((id) => id === insured.spouse?.id);

  if (spouseSelected && insured.spouse) {
    applicants.push({
      age: calculateAgeForEnrollment(
        insured.spouse.dateOfBirth,
        targetEnrollmentDate
      ),
      isSmoker: insured.spouse.isSmoker,
      isChild: false,
      dateOfBirth: insured.spouse.dateOfBirth,
      lastName: insured.spouse.lastName,
      firstName: insured.spouse.firstName,
      isEmployee: false,
    });
  }

  insured.dependents.forEach((dependent) => {
    const foundDependent = whoIsEnrolled.find((id) => id === dependent.id);
    if (foundDependent) {
      const age = calculateAgeForEnrollment(
        dependent.dateOfBirth,
        targetEnrollmentDate
      );
      applicants.push({
        age,
        isSmoker: dependent.isSmoker,
        isChild: age < 26,
        dateOfBirth: dependent.dateOfBirth,
        lastName: dependent.lastName,
        firstName: dependent.firstName,
        isEmployee: false,
      });
    }
  });

  return applicants;
};

export const getFamilyUnitFromWhoIsEnrolled = (
  insured: InsuredPeopleDto,
  whoIsEnrolled: Set<string>
): FamilyUnit => {
  const hasSpouse = insured.spouse && whoIsEnrolled.has(insured.spouse.id);

  const hasDependents = insured.dependents.some(({ id }) =>
    whoIsEnrolled.has(id)
  );

  if (!(hasSpouse || hasDependents)) {
    return FamilyUnit.EMPLOYEE_ONLY;
  }
  if (!hasSpouse && hasDependents) {
    return FamilyUnit.EMPLOYEE_CHILD;
  }
  if (hasSpouse && !hasDependents) {
    return FamilyUnit.EMPLOYEE_SPOUSE;
  }
  return FamilyUnit.FAMILY;
};

export const getDefaultMedicalPlanFormFields = (
  whoIsEnrolled: string[],
  planId?: string | null,
  planName?: string | null,
  carrier?: string,
  premium?: number
): MedicalPlanItemFormFields => {
  return {
    whoIsEnrolled,
    planId: planId || '',
    planName: planName || '',
    carrier: carrier || '',
    premium: premium || 0,
  };
};
