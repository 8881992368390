import {
  DatePickerType,
  DatePickerValue,
  MonthPickerInput,
  MonthPickerInputProps,
} from '@mantine/dates';
import { IconCalendarEvent } from '@tabler/icons-react';
import { EN_LOCALE_DATE_FORMAT, parseDate } from '@zorro/shared/formatters';
import { ForwardedRef, forwardRef } from 'react';

import { ZorroIcon } from '../../Icon';
import classNames from '../DateInput/DateInput.module.scss';

export type IMonthPickerInputProps<T extends DatePickerType> =
  MonthPickerInputProps<T>;

// TODO: Add storybook
export const ZorroMonthPickerInput = forwardRef(
  <T extends DatePickerType>(
    {
      placeholder,
      size = 'md',
      clearable = false,
      rightSection,
      value,
      onChange,
      type = 'default',
      ...props
    }: IMonthPickerInputProps<T>,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const handleChange = (newValue: DatePickerValue<T>) => {
      if (type === 'range') {
        const rangeValue = newValue as DatePickerValue<'range'>;
        if (rangeValue[1]) {
          rangeValue[1] = parseDate(rangeValue[1]).endOf('month').toDate();
        }

        onChange?.(rangeValue as DatePickerValue<T>);
      } else {
        onChange?.(newValue);
      }
    };
    return (
      <MonthPickerInput
        placeholder={placeholder}
        size={size}
        valueFormat={EN_LOCALE_DATE_FORMAT}
        clearable={clearable}
        rightSection={rightSection || <ZorroIcon icon={IconCalendarEvent} />}
        classNames={classNames}
        onChange={handleChange}
        value={value}
        type={type}
        ref={ref}
        {...props}
      />
    );
  }
);

ZorroMonthPickerInput.displayName = 'ZorroMonthPickerInput';
