import { Input, InputWrapperProps } from '@mantine/core';
import { ForwardedRef, forwardRef } from 'react';

import classNames from './InputWrapper.module.scss';

export type IInputWrapperProps = InputWrapperProps;

export const ZorroInputWrapper = forwardRef(
  (
    { size = 'sm', ...props }: InputWrapperProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <Input.Wrapper
        ref={ref}
        size={size}
        classNames={classNames}
        styles={(theme) => ({
          root: { borderRadius: 50 },
          label: { fontWeight: 400 },
          required: { color: theme.colors.zorroCoal[9] },
        })}
        {...props}
      />
    );
  }
);

ZorroInputWrapper.displayName = 'ZorroInputWrapper';
