import { ForwardedRef, forwardRef } from 'react';

import { INumberInputProps, ZorroNumberInput } from '../NumberInput';

export type ICurrencyInput = INumberInputProps;

export const ZorroCurrencyInput = forwardRef(
  (
    {
      thousandSeparator = ',',
      prefix = '$',
      decimalScale = 0,
      fixedDecimalScale = true,
      ...props
    }: ICurrencyInput,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <ZorroNumberInput
        thousandSeparator={thousandSeparator}
        fixedDecimalScale={fixedDecimalScale}
        prefix={prefix}
        decimalScale={decimalScale}
        ref={ref}
        {...props}
      />
    );
  }
);

ZorroCurrencyInput.displayName = 'CurrencyInput';
