import { environmentName } from '@zorro/environment';
import {
  SUCCESS_MESSAGES,
  callEndpoint,
  responseErrorToString,
  showErrorNotification,
  showSuccessNotification,
  useImpersonation,
} from '@zorro/shared/utils';
import _noop from 'lodash/noop';

import { useLoadingOverlay } from '../../index';
import {
  getFullStorySessionURL,
  isDebugModeEnabled,
} from '../Analytics/analytics.utils';

type TUseSupportInformationFormConfig = {
  onSuccess?: () => void;
};

export function useSupportInformationForm({
  onSuccess = _noop,
}: TUseSupportInformationFormConfig = {}) {
  const { user, employee, authUser, authEmployee, isImpersonated } =
    useImpersonation();

  const { startLoading, stopLoading } = useLoadingOverlay();

  const supportInformation = {
    environment: environmentName(),
    pageURL: window.location.href,
    sessionURL: getFullStorySessionURL(),
    userDetails: {
      isImpersonated,
      user,
      employee,
      authUser,
      authEmployee,
    },
    isAnalyticsDebugMode: isDebugModeEnabled(),
  };

  async function handleSubmit() {
    startLoading();
    try {
      await callEndpoint({
        method: 'supportControllerReportIssue',
        params: [supportInformation],
      });
      showSuccessNotification({
        message: SUCCESS_MESSAGES.SUPPORT_INFORMATION_SENT_SUCCESS,
      });

      onSuccess();
    } catch (error) {
      showErrorNotification({ message: responseErrorToString(error) });
    }
    stopLoading();
  }

  return { supportInformation, handleSubmit };
}
