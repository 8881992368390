/* eslint-disable unicorn/filename-case */
import { UseEditorOptions, useEditor } from '@tiptap/react';
import { DependencyList } from 'react';

import { richTextEditorExtensions } from './richTextEditor.consts';

type TUseRichTextEditor = UseEditorOptions;

export function useRichTextEditor(
  { extensions, immediatelyRender, ...options }: TUseRichTextEditor,
  deps: DependencyList
) {
  const editor = useEditor(
    {
      extensions: richTextEditorExtensions,
      // 🧠 must be false for SSR enabled backends
      immediatelyRender: false,
      ...options,
    },
    deps
  );

  return { editor };
}
