/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum UserStatus {
  PENDING_INVITATION = 'PENDING_INVITATION',
  PENDING_LOGIN = 'PENDING_LOGIN',
  ACTIVATED = 'ACTIVATED',
  NOT_ACTIVATED = 'NOT_ACTIVATED',
}
