import { EmployeeDto, EnrollmentStatus } from '@zorro/clients';
import { $enum } from 'ts-enum-util';

import { DeadlinePassedTabContent } from './DeadlinePassedTabContent';
import { ElectionSubmittedTabContent } from './ElectionSubmittedTabContent';
import { SendReminderTabContent } from './SendReminderTabContent';

type Props = {
  enrollmentStatus: EnrollmentStatus;
  onboardingPeriodId: string;
  employerId: string;
  employee: EmployeeDto;
};

export const EnrollmentTabContent = ({ enrollmentStatus, ...props }: Props) => {
  return $enum.mapValue(enrollmentStatus).with({
    [EnrollmentStatus.ELECTION_SUBMITTED]: (
      <ElectionSubmittedTabContent
        {...props}
        employeeId={props.employee.id}
        routeButton={{
          route: 'CARRIER_APPLICATION_ENROLLMENT_DETAILS',
          label: 'Start application',
        }}
      />
    ),
    [EnrollmentStatus.CARRIER_APPLICATION_SENT]: (
      <ElectionSubmittedTabContent
        {...props}
        employeeId={props.employee.id}
        routeButton={{
          route: 'ENROLLMENT_CONFIRMATION_PAYMENT_STATUS',
          label: 'Confirm enrollment',
        }}
      />
    ),
    [EnrollmentStatus.ENROLLMENT_CONFIRMED]: (
      <ElectionSubmittedTabContent {...props} employeeId={props.employee.id} />
    ),
    [EnrollmentStatus.ACTIVE_COVERAGE]: (
      <ElectionSubmittedTabContent {...props} employeeId={props.employee.id} />
    ),
    [EnrollmentStatus.COVERAGE_ENDED]: (
      <ElectionSubmittedTabContent {...props} employeeId={props.employee.id} />
    ),
    [EnrollmentStatus.ELECTION_ACTIVE_HAS_NOT_STARTED]: (
      <SendReminderTabContent {...props} />
    ),
    [EnrollmentStatus.ELECTION_ACTIVE_STARTED]: (
      <SendReminderTabContent {...props} />
    ),
    [EnrollmentStatus.DEADLINE_PASSED]: (
      <DeadlinePassedTabContent {...props} employee={props.employee} />
    ),

    [EnrollmentStatus.PENDING_ELECTION_WINDOW]: null,
    [EnrollmentStatus.ELECTION_ACTIVE]: null,
    [EnrollmentStatus.WAIVED_ELECTION]: null,
    [EnrollmentStatus.WAIVED_COVERAGE]: null,

    [EnrollmentStatus.NO_ENROLLMENTS]: null,
  });
};
