import { LockIcon } from '@zorro/shared/assets';
import { MURRIETA_DYNAMIC_ROUTES } from '@zorro/shared/utils';
import {
  Card,
  Group,
  IStepperStepProps,
  Link,
  Stepper,
  Title,
} from '@zorro/zorro-ui-design';

import {
  ManualSubmissionStepType,
  getManualSubmissionStepConfig,
} from './ManualSubmissionUtils';

type StepperLinkProps = IStepperStepProps & {
  manualSubmissionStep: ManualSubmissionStepType;
  onboardingPeriodId: string;
  employerId: string;
  employeeId: string;
};

const StepperLink = ({
  manualSubmissionStep,
  onboardingPeriodId,
  employerId,
  employeeId,
  ...props
}: StepperLinkProps) => {
  const { route, label } = getManualSubmissionStepConfig(manualSubmissionStep);

  if (props.state === 'stepInactive') {
    return <Stepper.Step {...props} label={label} icon={<LockIcon />} />;
  }

  return (
    <Link
      href={MURRIETA_DYNAMIC_ROUTES[route]({
        employerId,
        employeeId,
        onboardingPeriodId,
      })}
      anchorProps={{ underline: 'never' }}
    >
      <Stepper.Step {...props} label={label} isNavigable />
    </Link>
  );
};

type Props = {
  step: ManualSubmissionStepType;
  employerId: string;
  employeeId: string;
  onboardingPeriodId: string;
};

export const ManualSubmissionStepper = ({
  step,
  employerId,
  employeeId,
  onboardingPeriodId,
}: Props) => {
  const { stepIndex } = getManualSubmissionStepConfig(step);

  return (
    <Card withBorder={false} bg="zorroGray.10" style={{ borderRadius: 8 }}>
      <Group>
        <Title>Manual election</Title>
      </Group>
      <Stepper
        styles={{ steps: { maxWidth: '550px' } }}
        allowNextStepsSelect={false}
        active={stepIndex}
        variant="modern"
        wrap={false}
        size="sm"
        mt="lg"
      >
        {Object.values(ManualSubmissionStepType).map((manualSubmissionStep) => (
          <StepperLink
            key={manualSubmissionStep}
            manualSubmissionStep={manualSubmissionStep}
            onboardingPeriodId={onboardingPeriodId}
            employerId={employerId}
            employeeId={employeeId}
          />
        ))}
      </Stepper>
    </Card>
  );
};
