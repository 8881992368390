import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';
import classNames from './Icon.module.scss';

export const IconZorroOnExchange = ({ style, grow }: SVGIconProps) => {
  return (
    <Center style={style} classNames={classNames} data-grow={grow}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
      >
        <path
          d="M2.5 18H17.5M2.5 6.33333V7.16667C2.5 7.82971 2.76339 8.46559 3.23223 8.93443C3.70107 9.40327 4.33696 9.66667 5 9.66667C5.66304 9.66667 6.29893 9.40327 6.76777 8.93443C7.23661 8.46559 7.5 7.82971 7.5 7.16667M2.5 6.33333H17.5M2.5 6.33333L4.16667 3H15.8333L17.5 6.33333M7.5 7.16667V6.33333M7.5 7.16667C7.5 7.82971 7.76339 8.46559 8.23223 8.93443C8.70107 9.40327 9.33696 9.66667 10 9.66667C10.663 9.66667 11.2989 9.40327 11.7678 8.93443C12.2366 8.46559 12.5 7.82971 12.5 7.16667M12.5 7.16667V6.33333M12.5 7.16667C12.5 7.82971 12.7634 8.46559 13.2322 8.93443C13.7011 9.40327 14.337 9.66667 15 9.66667C15.663 9.66667 16.2989 9.40327 16.7678 8.93443C17.2366 8.46559 17.5 7.82971 17.5 7.16667V6.33333M4.16667 18.0001V9.54175M15.8333 18.0001V9.54175M7.5 18V14.6667C7.5 14.2246 7.67559 13.8007 7.98816 13.4882C8.30072 13.1756 8.72464 13 9.16667 13H10.8333C11.2754 13 11.6993 13.1756 12.0118 13.4882C12.3244 13.8007 12.5 14.2246 12.5 14.6667V18"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Center>
  );
};
