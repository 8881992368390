/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum NetworkType {
  HMO = 'HMO',
  POS = 'POS',
  EPO = 'EPO',
  PPO = 'PPO',
  INDEMNITY = 'INDEMNITY',
}
