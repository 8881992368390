import { VALIDATION_MESSAGES } from '@zorro/shared/utils';
import * as yup from 'yup';

export enum InvitationType {
  BY_EMAIL = 'BY_EMAIL',
  BY_LINK = 'BY_LINK',
}

export const sendInviteSchema = yup.object({
  invitationType: yup
    .mixed<InvitationType>()
    .oneOf(
      Object.values(InvitationType),
      VALIDATION_MESSAGES.familyUnitRequired
    )
    .typeError(VALIDATION_MESSAGES.familyUnitRequired)
    .required(VALIDATION_MESSAGES.familyUnitRequired),
  email: yup
    .string()
    .required(VALIDATION_MESSAGES.emailRequired)
    // eslint-disable-next-line require-unicode-regexp
    .matches(/^[^\s@]+@[^\s@][^\s.@]*\.[^\s@]{2,}$/, {
      message: VALIDATION_MESSAGES.emailValid,
    }),
});

export type SendInviteFormFields = yup.InferType<typeof sendInviteSchema>;
