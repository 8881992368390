import { Gender, InsuredSubtype } from '@zorro/clients';
import { DateUtilInstance, getNowAsDate } from '@zorro/shared/formatters';
import {
  VALIDATION_MESSAGES,
  insuredMaxDateOfBirth,
  insuredMinDateOfBirth,
  validateOnlyNumbers,
} from '@zorro/shared/utils';
import { YesNo, insuredSubtypeLabelConfig } from '@zorro/types';
import {
  AddressInput,
  Box,
  DateInput,
  FormErrorMessage,
  Grid,
  InputWrapper,
  PasswordInput,
  Select,
  TabButtonGroup,
  Tabs,
  Text,
  TextInput,
  Tooltip,
} from '@zorro/zorro-ui-design';
import { Fragment } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import * as yup from 'yup';

import {
  INSURED_FORM_ADDRESS_TOOLTIP_LABEL,
  getInsuredBaseSchema,
} from './InsuredFormUtils';

const dependentRelationshipValues = Object.values([
  InsuredSubtype.CHILD,
  InsuredSubtype.OTHER_DEPENDENT,
]);

const insuredTypeOptions = dependentRelationshipValues.map((status) => ({
  label: insuredSubtypeLabelConfig[status],
  value: status,
}));

const getDependentSchema = (
  isFinalizationMode: boolean,
  minDateOfBirth: Date
) =>
  getInsuredBaseSchema(isFinalizationMode).concat(
    yup.object({
      dateOfBirth: yup
        .date()
        .min(minDateOfBirth, VALIDATION_MESSAGES.dependentMaxAgeValid)
        .typeError(VALIDATION_MESSAGES.dateOfBirthRequired)
        .required(VALIDATION_MESSAGES.dateOfBirthRequired),
      ...(isFinalizationMode
        ? {
            subtype: yup
              .mixed<InsuredSubtype>()
              .required(VALIDATION_MESSAGES.relationshipRequired)
              .oneOf(
                dependentRelationshipValues,
                VALIDATION_MESSAGES.relationshipRequired
              ),
          }
        : {
            subtype: yup
              .mixed<InsuredSubtype>()
              .oneOf(
                dependentRelationshipValues,
                VALIDATION_MESSAGES.relationshipRequired
              )
              .nullable(),
          }),
    })
  );

export const getDependentInsuredSchema = (
  isFinalizationMode: boolean,
  minDateOfBirth: Date = getNowAsDate()
) => {
  return yup.object({
    dependents: yup
      .array()
      .of(getDependentSchema(isFinalizationMode, minDateOfBirth))
      .required(),
  });
};

export type InsuredDependentFormFields = yup.InferType<
  ReturnType<typeof getDependentSchema>
>;
export type InsuredDependentsFormFields = yup.InferType<
  ReturnType<typeof getDependentInsuredSchema>
>;

type DependentFormProps = {
  index: number;
  dependentsForm: UseFormReturn<InsuredDependentsFormFields>;
  targetEnrollmentDate: DateUtilInstance;
  isFinalizationMode: boolean;
  isEmployeeMode: boolean;
};

export const DependentForm = ({
  index,
  dependentsForm,
  targetEnrollmentDate,
  isFinalizationMode,
  isEmployeeMode,
}: DependentFormProps) => {
  const { control, formState, watch } = dependentsForm;

  const dependent = watch(`dependents.${index}`);

  const isFemale = dependent?.gender === Gender.FEMALE;

  return (
    <Grid>
      <Grid.Col span={{ sm: 6 }}>
        <Controller
          name={`dependents.${index}.firstName`}
          control={control}
          render={({ field: { ...rest } }) => (
            <TextInput
              {...rest}
              label="First name"
              placeholder="First name"
              required
            />
          )}
        />

        <FormErrorMessage
          errors={formState.errors}
          fieldName={`dependents.${index}.firstName`}
        />
      </Grid.Col>

      <Grid.Col span={{ sm: 6 }}>
        <Controller
          name={`dependents.${index}.lastName`}
          control={control}
          render={({ field: { ...rest } }) => (
            <TextInput
              {...rest}
              label="Last name"
              placeholder="Last name"
              required
            />
          )}
        />

        <FormErrorMessage
          errors={formState.errors}
          fieldName={`dependents.${index}.lastName`}
        />
      </Grid.Col>

      <Grid.Col span={{ sm: 6 }}>
        <Controller
          name={`dependents.${index}.subtype`}
          control={control}
          render={({ field: { ...rest } }) => (
            <Select
              {...rest}
              label="Relationship"
              placeholder="Select relationship"
              data={insuredTypeOptions}
              required={isFinalizationMode}
            />
          )}
        />

        <FormErrorMessage
          errors={formState.errors}
          fieldName={`dependents.${index}.subtype`}
        />
      </Grid.Col>

      <Grid.Col span={{ sm: 6 }}>
        <Controller
          name={`dependents.${index}.dateOfBirth`}
          control={control}
          render={({ field }) => (
            <DateInput
              {...field}
              label="Date of birth"
              defaultLevel="decade"
              minDate={insuredMinDateOfBirth(true, targetEnrollmentDate)}
              maxDate={insuredMaxDateOfBirth(true, targetEnrollmentDate)}
              required
            />
          )}
        />

        <FormErrorMessage
          errors={formState.errors}
          fieldName={`dependents.${index}.dateOfBirth`}
        />
      </Grid.Col>

      <Grid.Col span={{ sm: 6 }}>
        <Controller
          name={`dependents.${index}.gender`}
          control={control}
          render={({ field }) => (
            <InputWrapper label="Gender" required={isFinalizationMode}>
              <TabButtonGroup {...field}>
                <Tabs.List grow>
                  <Tabs.Tab value={Gender.MALE}>Male</Tabs.Tab>
                  <Tabs.Tab value={Gender.FEMALE}>Female</Tabs.Tab>
                </Tabs.List>
              </TabButtonGroup>
            </InputWrapper>
          )}
        />

        <FormErrorMessage
          errors={formState.errors}
          fieldName={`dependents.${index}.gender`}
        />
      </Grid.Col>

      <Grid.Col span={{ sm: 6 }}>
        <Controller
          name={`dependents.${index}.isSmoker`}
          control={control}
          render={({ field: { ...rest } }) => (
            <InputWrapper label="Tobacco User" required={isFinalizationMode}>
              <TabButtonGroup {...rest}>
                <Tabs.List grow>
                  <Tabs.Tab value={YesNo.YES}>Yes</Tabs.Tab>
                  <Tabs.Tab value={YesNo.NO}>No</Tabs.Tab>
                </Tabs.List>
              </TabButtonGroup>
            </InputWrapper>
          )}
        />

        <FormErrorMessage
          errors={formState.errors}
          fieldName={`dependents.${index}.isSmoker`}
        />
      </Grid.Col>

      {isFemale && (
        <Grid.Col span={{ sm: 6 }}>
          <Controller
            name={`dependents.${index}.isPregnant`}
            control={control}
            render={({ field: { ...rest } }) => (
              <InputWrapper label="Pregnant" required={isFinalizationMode}>
                <TabButtonGroup {...rest}>
                  <Tabs.List grow>
                    <Tabs.Tab value={YesNo.YES}>Yes</Tabs.Tab>
                    <Tabs.Tab value={YesNo.NO}>No</Tabs.Tab>
                  </Tabs.List>
                </TabButtonGroup>
              </InputWrapper>
            )}
          />

          <FormErrorMessage
            errors={formState.errors}
            fieldName={`dependents.${index}.isPregnant`}
          />
        </Grid.Col>
      )}

      <Grid.Col span={{ sm: 6 }}>
        <Controller
          name={`dependents.${index}.ssn`}
          control={control}
          render={({ field: { value, ...rest } }) => (
            <PasswordInput
              {...rest}
              label="SSN"
              placeholder="XXXXXXXXX"
              value={value || ''}
              maxLength={9}
              onKeyDown={validateOnlyNumbers}
              required={isFinalizationMode}
            />
          )}
        />

        <FormErrorMessage
          errors={formState.errors}
          fieldName={`dependents.${index}.ssn`}
        />
      </Grid.Col>

      <Grid.Col>
        <Controller
          control={control}
          name={`dependents.${index}.residentialAddress`}
          render={({ field: { value, onBlur, onChange, ...rest } }) => (
            <Tooltip
              label={INSURED_FORM_ADDRESS_TOOLTIP_LABEL}
              disabled={!isEmployeeMode}
              position="top"
              maw={400}
            >
              <Box pos="relative">
                <AddressInput
                  {...rest}
                  onBlur={() => {
                    onChange(value);
                    onBlur();
                  }}
                  notifySubscribers={(newAddress) => onChange(newAddress)}
                  onChange={onChange}
                  label="Residential address"
                  value={value || ''}
                  placeholder="Type residential address"
                  required={isFinalizationMode}
                  disabled={isEmployeeMode}
                />
              </Box>
            </Tooltip>
          )}
        />

        <FormErrorMessage
          errors={formState.errors}
          fieldName={`dependents.${index}.residentialAddress`}
        />
      </Grid.Col>
    </Grid>
  );
};

type DependentsFormProps = {
  dependentsForm: UseFormReturn<InsuredDependentsFormFields>;
  onAppendDependent: () => void;
  targetEnrollmentDate: DateUtilInstance;
  isFinalizationMode: boolean;
  isEmployeeMode: boolean;
};

export const DependentsFormInputs = ({
  dependentsForm,
  isEmployeeMode,
  isFinalizationMode,
  onAppendDependent,
  targetEnrollmentDate,
}: DependentsFormProps) => {
  const dependents = dependentsForm.watch('dependents');

  return (
    <>
      {dependents.map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>
          <DependentForm
            targetEnrollmentDate={targetEnrollmentDate}
            isFinalizationMode={isFinalizationMode}
            isEmployeeMode={isEmployeeMode}
            dependentsForm={dependentsForm}
            index={index}
          />
        </Fragment>
      ))}
      <Box
        w="100%"
        mx="auto"
        opacity={1}
        tabIndex={0}
        p="18px 48px"
        pos="relative"
        bg="zorroWhite.0"
        style={(theme) => ({
          cursor: 'pointer',
          borderRadius: theme.radius.md,
          boxShadow: `inset 0 0 0 1px ${theme.colors.zorroGray[7]}`,
        })}
        onClick={onAppendDependent}
        onKeyDown={(event) => {
          if (event.code === 'Space' || event.code === 'Enter') {
            event.preventDefault();
            onAppendDependent();
          }
        }}
      >
        <Text ta="center" c="zorroIris.9">
          + Add dependent (under 26)
        </Text>
      </Box>
    </>
  );
};
