import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const AssistanceProgramMentalHealthIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_535_1736)">
          <path
            d="M21.3441 2.90915C23.6209 2.26375 26.0493 3.80702 26.4345 6.14194C27.1224 10.3117 27.4428 15.7582 24.295 20.8082C20.799 26.4167 16.4846 27.6529 12.1237 28.3726C10.1351 28.7007 8.1592 27.4781 7.59111 25.5443C6.41591 21.5438 5.60737 16.7588 9.01863 11.2861C12.2456 6.10908 17.145 4.09949 21.3441 2.90915Z"
            fill="#FFD880"
          />
          <path
            d="M20.6667 17.3333C19.429 17.3333 18.242 17.8249 17.3668 18.7001C16.4917 19.5753 16 20.7622 16 21.9999V23.3333C16 24.5709 16.4917 25.7579 17.3668 26.6331C18.242 27.5083 19.429 27.9999 20.6667 27.9999C21.9043 27.9999 23.0913 27.5083 23.9665 26.6331C24.8417 25.7579 25.3333 24.5709 25.3333 23.3333V20.9333"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.3332 17.3333C12.5708 17.3333 13.7578 17.8249 14.633 18.7001C15.5082 19.5753 15.9998 20.7622 15.9998 21.9999V23.3333C15.9998 24.5709 15.5082 25.7579 14.633 26.6331C13.7578 27.5083 12.5708 27.9999 11.3332 27.9999C10.0955 27.9999 8.90851 27.5083 8.03334 26.6331C7.15817 25.7579 6.6665 24.5709 6.6665 23.3333V20.9333"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23.3332 21.3333C24.5708 21.3333 25.7578 20.8417 26.633 19.9665C27.5082 19.0913 27.9998 17.9043 27.9998 16.6667C27.9998 15.429 27.5082 14.242 26.633 13.3668C25.7578 12.4917 24.5708 12 23.3332 12H22.6665"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M25.3333 12.4V8.66667C25.3333 7.42899 24.8417 6.24201 23.9665 5.36684C23.0913 4.49167 21.9043 4 20.6667 4C19.429 4 18.242 4.49167 17.3668 5.36684C16.4917 6.24201 16 7.42899 16 8.66667"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.66667 21.3333C7.42899 21.3333 6.24201 20.8417 5.36684 19.9665C4.49167 19.0913 4 17.9043 4 16.6667C4 15.429 4.49167 14.242 5.36684 13.3668C6.24201 12.4917 7.42899 12 8.66667 12H9.33333"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.6665 12.4V8.66667C6.6665 7.42899 7.15817 6.24201 8.03334 5.36684C8.90851 4.49167 10.0955 4 11.3332 4C12.5708 4 13.7578 4.49167 14.633 5.36684C15.5082 6.24201 15.9998 7.42899 15.9998 8.66667V22"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_535_1736">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
