import { IconSend } from '@tabler/icons-react';
import { Button, Text, TextInput } from '@zorro/zorro-ui-design';
import { Controller, UseFormReturn } from 'react-hook-form';

import { useMonolithMutation } from '../../../hooks';
import { SendInviteFormFields } from './SendInviteUtils';

type Props = {
  sendInviteForm: UseFormReturn<SendInviteFormFields>;
  employerId: string;
};

export const InviteByEmailForm = ({ sendInviteForm, employerId }: Props) => {
  const { mutate: mutateInviteEmployer } = useMonolithMutation({
    method: 'employersControllerInviteEmployerAdmin',
    successMessage: 'Employer invitation email sent',
  });

  const { control, formState, watch } = sendInviteForm;
  const email = watch('email');

  const handleSubmit = async () => {
    try {
      await mutateInviteEmployer([employerId, email, true]);
    } catch {
      /* empty */
    }
  };

  return (
    <Controller
      control={control}
      name="email"
      render={({ field: { ...rest } }) => (
        <TextInput
          {...rest}
          label="Invitation email"
          placeholder="Add invitation email"
          rightSectionWidth="155px"
          size="md"
          radius="4px"
          rightSectionProps={{ style: { marginRight: '2px' } }}
          rightSection={
            <Button
              size="sm"
              variant="filled"
              disabled={!formState.isValid}
              onClick={handleSubmit}
              h="35px"
              radius="4px"
            >
              <IconSend />
              <Text ml="xs" c="white">
                Send invitation
              </Text>
            </Button>
          }
        />
      )}
    />
  );
};
