import { GuyTypingOnLaptop2 } from '@zorro/shared/assets';
import { useRouter } from '@zorro/shared/utils';
import { APP_SHELL_PADDING } from '@zorro/types';
import { Button, Container, Link, Stack, Text } from '@zorro/zorro-ui-design';

import { StepHeading } from '../StepHeading';

type SomethingWentWrongProps = {
  title?: string;
  subtitle?: string;
  shouldShowSupportMessage?: boolean;
  actionButtonText?: string;
  actionButtonAction?: () => void;
};

export const SomethingWentWrong = ({
  title = 'Oops!',
  subtitle = 'Something went wrong',
  shouldShowSupportMessage = true,
  actionButtonText,
  actionButtonAction,
}: SomethingWentWrongProps) => {
  const { reloadPage } = useRouter();

  const handleAction = () => {
    if (actionButtonAction) {
      actionButtonAction();
    } else {
      reloadPage();
    }
  };

  return (
    <Container size="sm" mt={80 - APP_SHELL_PADDING}>
      <Stack align="center">
        <GuyTypingOnLaptop2 />

        <StepHeading title={title} subtitle={subtitle} align="center" />
        {actionButtonText && (
          <Button size="lg" onClick={handleAction}>
            {actionButtonText}
          </Button>
        )}
        {shouldShowSupportMessage && (
          <Text ta="center" mt="3rem" c="zorroGray.4">
            If the problem persists, please contact{' '}
            <Link href="mailto:support@myzorro.co" target="_blank">
              support@myzorro.co
            </Link>
          </Text>
        )}
      </Stack>
    </Container>
  );
};
