import { LoadingOverlay, LoadingOverlayProps } from '@mantine/core';

import { brand } from '../theme';

export type ILoadingOverlayProps = LoadingOverlayProps;

export function ZorroLoadingOverlay({
  overlayProps,
  ...props
}: ILoadingOverlayProps) {
  return (
    <LoadingOverlay
      w="100vw"
      h="100%"
      pos="fixed"
      overlayProps={{
        blur: 2,
        opacity: 0.5,
        color: brand.zorroCoal900,
        ...overlayProps,
      }}
      loaderProps={{ color: brand.zorroIris900 }}
      {...props}
    />
  );
}
