/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum BenefitType {
  MAJOR_MEDICAL = 'MAJOR_MEDICAL',
  SUPPLEMENTAL_ACCIDENT = 'SUPPLEMENTAL_ACCIDENT',
  SUPPLEMENTAL_ACCIDENTAL_DEATH_AND_DISMEMBERMENT = 'SUPPLEMENTAL_ACCIDENTAL_DEATH_AND_DISMEMBERMENT',
  SUPPLEMENTAL_CRITICAL_ILLNESS = 'SUPPLEMENTAL_CRITICAL_ILLNESS',
  SUPPLEMENTAL_DENTAL = 'SUPPLEMENTAL_DENTAL',
  SUPPLEMENTAL_FLEXIBLE_SPENDING_ACCOUNT = 'SUPPLEMENTAL_FLEXIBLE_SPENDING_ACCOUNT',
  SUPPLEMENTAL_HOSPITAL = 'SUPPLEMENTAL_HOSPITAL',
  SUPPLEMENTAL_LIFE = 'SUPPLEMENTAL_LIFE',
  SUPPLEMENTAL_OTHER = 'SUPPLEMENTAL_OTHER',
  SUPPLEMENTAL_VISION = 'SUPPLEMENTAL_VISION',
}
