import { Space, Stack, Text } from '@zorro/zorro-ui-design';

import { ModalFooter } from '../../ModalComponents';
import { useBatchCallEndpoint } from '../../hooks';
import { ErrorsTable } from '../Errors/ErrorsTable';

type SelectedEmployees = {
  onboardingPeriodId?: string;
  firstName: string;
  lastName: string;
};

interface ResetElectionFormProps {
  selectedEmployees: SelectedEmployees[];
  onClose: () => void;
  onSuccess: () => void;
}

export const ResetElectionForm = ({
  selectedEmployees,
  onClose,
  onSuccess,
}: ResetElectionFormProps) => {
  const {
    executeBatchCall: batchDeleteMajorMedicalPlans,
    errors: batchDeleteMajorMedicalPlansErrors,
  } = useBatchCallEndpoint({
    singularItemName: 'election',
    action: 'reset',
    methodName: 'majorMedicalControllerDeleteMajorMedicalPlan',
  });

  const onSubmit = async () => {
    const { errors } = await batchDeleteMajorMedicalPlans(
      selectedEmployees.map(({ firstName, lastName, onboardingPeriodId }) => {
        if (!onboardingPeriodId) {
          throw new Error(
            `${firstName} ${lastName} doesn't have an onboarding period`
          );
        }
        return {
          key: `${firstName} ${lastName}`,
          params: [onboardingPeriodId],
        };
      })
    );
    if (errors.length === 0) {
      onSuccess();
    }
  };

  return (
    <Stack>
      <Text size="lg" w="430px">
        All current plan elections and enrollment data will be deleted
      </Text>

      <Space h="xl" />
      <ModalFooter
        submitText="Reset election"
        submitVariant="primary-red"
        onClose={onClose}
        onSubmit={onSubmit}
      />

      <ErrorsTable
        tableTitle="Employee name"
        errors={batchDeleteMajorMedicalPlansErrors}
        isBulk={selectedEmployees?.length > 1}
      />
    </Stack>
  );
};
