import {
  Drawer,
  DrawerBodyProps,
  DrawerContentProps,
  DrawerProps,
} from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { DRAWER_CONTENT_ZINDEX, DRAWER_OVERLAY_ZINDEX } from '@zorro/types';
import { ReactNode } from 'react';

import { ZorroGroup } from '../Group';
import { ZorroActionIcon } from '../Icon/ActionIcon';
import { ZorroTitle } from '../Title';

export type IDrawerProps = DrawerProps & {
  children?: ReactNode;
  contentProps?: DrawerContentProps;
  bodyProps?: DrawerBodyProps;
};

export function ZorroDrawer({
  opened,
  onClose,
  title,
  children,
  bodyProps,
  contentProps,
  withCloseButton = true,
  position = 'right',
  ...props
}: IDrawerProps) {
  return (
    <Drawer.Root
      position={position}
      onClose={onClose}
      opened={opened}
      size="xl"
      {...props}
    >
      <Drawer.Content
        style={{ boxShadow: 'none', zIndex: DRAWER_CONTENT_ZINDEX }}
        p="40px"
        {...contentProps}
      >
        <Drawer.Title p="0">
          <ZorroGroup justify="space-between" w="100%">
            <ZorroTitle fs="1.5rem" fw={600}>
              {title}
            </ZorroTitle>
            {withCloseButton && (
              <ZorroActionIcon size="xl" onClick={onClose} variant="filled">
                <IconX />
              </ZorroActionIcon>
            )}
          </ZorroGroup>
        </Drawer.Title>
        <Drawer.Body p="0" mt="lg" {...bodyProps}>
          {children}
        </Drawer.Body>
      </Drawer.Content>
      <Drawer.Overlay zIndex={DRAWER_OVERLAY_ZINDEX} />
    </Drawer.Root>
  );
}
