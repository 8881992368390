import { Tabs } from '@zorro/zorro-ui-design';
import { ReactNode } from 'react';

type ITab = {
  icon?: ReactNode;
  title: string;
  content: ReactNode;
  testId?: string;
};

type TabSwitcherProps = {
  tabs: ITab[];
  onTabChange?: () => void;
};

export function TabSwitcher({ tabs, onTabChange, ...props }: TabSwitcherProps) {
  return (
    <Tabs {...props} defaultValue={tabs[0]?.title} onChange={onTabChange}>
      <Tabs.List grow>
        {tabs.map((tab) => {
          return (
            <Tabs.Tab
              key={tab.title}
              leftSection={tab.icon}
              value={tab.title}
              data-testid={tab.testId}
            >
              {tab.title}
            </Tabs.Tab>
          );
        })}
      </Tabs.List>
      {tabs.map((tab) => {
        return (
          <Tabs.Panel key={tab.title} value={tab.title}>
            {tab.content}
          </Tabs.Panel>
        );
      })}
    </Tabs>
  );
}
