import { DonutChart, DonutChartProps } from '@mantine/charts';
import { ForwardedRef, forwardRef } from 'react';

import classNames from './DonutChart.module.scss';

export type IDonutChartProps = DonutChartProps & {
  ref: ForwardedRef<HTMLDivElement>;
};

export const ZorroDonutChart = forwardRef(
  ({ ...props }: IDonutChartProps, ref: ForwardedRef<HTMLDivElement>) => {
    return <DonutChart {...props} ref={ref} classNames={classNames} />;
  }
);

ZorroDonutChart.displayName = 'DonutChart';
