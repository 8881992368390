import { EmploymentStatus, LeaveOfAbsenceDto } from '@zorro/clients';
import { Box, Group, Text, Tooltip } from '@zorro/zorro-ui-design';

import { getEligibilityStatusBadgeConfig } from './EligibilityStatusBadgeUtils';

type Props = {
  employmentStatus: EmploymentStatus;
  eligibleFrom?: string | null;
  eligibleUntil?: string | null;
  leaveOfAbsence: LeaveOfAbsenceDto | null | undefined;
  createdAt: string;
};

export const EligibilityStatusBadgeTooltip = ({
  employmentStatus,
  eligibleFrom,
  eligibleUntil,
  leaveOfAbsence,
  createdAt,
}: Props) => {
  const { label, icon, description } = getEligibilityStatusBadgeConfig(
    employmentStatus,
    { eligibleFrom, eligibleUntil, leaveOfAbsence, createdAt }
  );

  return (
    <Group style={{ borderRadius: 8 }}>
      <Group gap="xs" wrap="nowrap" align="start">
        {icon}
        <Tooltip position="top" label={description}>
          <Box pos="relative">
            <Text size="sm" pos="relative">
              {label}
            </Text>
          </Box>
        </Tooltip>
      </Group>
    </Group>
  );
};
