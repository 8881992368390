import { TitleOrder } from '@mantine/core';
import { Box, Group, ThemeIcon, Title } from '@zorro/zorro-ui-design';
import { ReactNode } from 'react';

type Props = {
  title: string;
  icon: ReactNode;
  bgIcon?: string;
  titleOrder?: TitleOrder;
};

export const ModalTitle = ({ title, icon, bgIcon, titleOrder = 1 }: Props) => {
  return (
    <Group align="center" wrap="nowrap" gap="sm">
      {bgIcon ? (
        <ThemeIcon bg={bgIcon} size={32}>
          {icon}
        </ThemeIcon>
      ) : (
        <Box>{icon}</Box>
      )}
      <Title order={titleOrder}>{title}</Title>
    </Group>
  );
};
