import { ZorroBox } from '../Box';
import { ITextProps, ZorroText } from '../Text';
import { ITooltipProps, ZorroTooltip } from './Tooltip';

type UnderlineTooltipProps = {
  tooltipProps?: Omit<ITooltipProps, 'children' | 'label'>;
  textProps?: Omit<ITextProps, 'children'>;
  tooltipText: string;
  text: string;
};

export const ZorroUnderlineTooltip = ({
  tooltipProps,
  textProps,
  tooltipText,
  text,
}: UnderlineTooltipProps) => {
  return (
    <ZorroTooltip {...tooltipProps} position="top" label={tooltipText} inline>
      <ZorroBox display="inline-block">
        <ZorroText
          {...textProps}
          style={{
            textDecorationLine: 'underline',
            textDecorationStyle: 'dashed',
            textUnderlineOffset: '4px',
          }}
        >
          {text}
        </ZorroText>
      </ZorroBox>
    </ZorroTooltip>
  );
};
