/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum FamilyUnit {
  EMPLOYEE_ONLY = 'EMPLOYEE_ONLY',
  EMPLOYEE_SPOUSE = 'EMPLOYEE_SPOUSE',
  EMPLOYEE_CHILD = 'EMPLOYEE_CHILD',
  FAMILY = 'FAMILY',
}
