import { DatesRangeValue } from '@mantine/dates';
import {
  formatDateEnLocaleRange,
  parseDateUnknown,
} from '@zorro/shared/formatters';
import { Button, DatePicker, Stack, Text } from '@zorro/zorro-ui-design';

import { Filter, FilterRecord } from './FilterUtils';

function filterCallback(
  record: Record<string, unknown>,
  filterBy: string,
  datesRange: DatesRangeValue
) {
  const [startDate, endDate] = datesRange;
  const value = record[filterBy];

  // case: record has no date
  if (!value || typeof value !== 'string') {
    return false;
  }

  // 🧠 the value can be formatted in various ways (ISO, En Locale, etc.)
  const parsedDate = parseDateUnknown(value);

  if (!parsedDate.isValid()) {
    return false;
  }

  // case: startDate and endDate are set
  if (startDate && endDate) {
    return parsedDate.isBetween(startDate, endDate, 'day');
  }

  // case: startDate is set
  if (startDate) {
    return parsedDate.isAfter(startDate);
  }

  // case: endDate is set
  if (endDate) {
    return parsedDate.isBefore(endDate);
  }

  // case: default (no filter)
  return true;
}

type Props<T extends FilterRecord> = {
  filters: Filter<T>[];
  filterBy: string;
  setFilters: (filters: Filter<T>[]) => void;
};

export const DateFilter = <T extends FilterRecord>({
  filters,
  filterBy,
  setFilters,
}: Props<T>) => {
  const dateFilter = filters.find(({ type }) => type === filterBy);

  const datesRange = dateFilter?.value as DatesRangeValue | undefined;
  const [firstDate, secondDate] = datesRange || [];

  return (
    <Stack>
      <DatePicker
        type="range"
        value={datesRange}
        onChange={(newDatesRange) => {
          const otherFilters = filters.filter(({ type }) => type !== filterBy);

          otherFilters.push({
            type: filterBy,
            value: newDatesRange,
            filterCallback: (record) =>
              filterCallback(record, filterBy, newDatesRange),
          });

          setFilters(otherFilters);
        }}
      />

      <Text ta="center">
        {formatDateEnLocaleRange(firstDate as Date, secondDate as Date)}
      </Text>

      <Button
        size="md"
        variant="subtle"
        disabled={!datesRange}
        onClick={() =>
          setFilters(filters.filter(({ type }) => type !== filterBy))
        }
      >
        Clear
      </Button>
    </Stack>
  );
};
