import { EmployerDto } from '@zorro/clients';
import { useMonolithQuery } from '@zorro/shared/utils';
import { RichTextViewer } from '@zorro/zorro-ui-design';

import { getEnrollmentInstructionsContent } from './enrollmentInstructions.utils';

type EnrollmentInstructionsViewerProps = {
  content?: string;
  employerId?: EmployerDto['id'];
};

const EnrollmentInstructionsViewer = ({
  content,
  employerId,
}: EnrollmentInstructionsViewerProps) => {
  const { data: enrollmentInstructions } = useMonolithQuery({
    method: 'enrollmentInstructionsControllerFind',
    params: employerId && [employerId],
  });

  const safeContent = getEnrollmentInstructionsContent(
    content || enrollmentInstructions?.content
  );

  if (!safeContent) {
    return null;
  }

  return <RichTextViewer content={safeContent} />;
};

export { EnrollmentInstructionsViewer };
