import { Group } from '@mantine/core';
import { IconExclamationCircleFilled } from '@tabler/icons-react';
import { getNow, parseDateISO } from '@zorro/shared/formatters';
import { useMonolithQuery } from '@zorro/shared/utils';
import { Button, Icon, Text } from '@zorro/zorro-ui-design';

import { useMonolithMutation } from '../../hooks/useMonolithMutation';

type Props = {
  onboardingPeriodId: string;
};

export const SendReminderTabContent = ({ onboardingPeriodId }: Props) => {
  const { data: onboardingPeriod, isLoading } = useMonolithQuery({
    method: 'onboardingPeriodReportsControllerGenerateOnboardingPeriodReport',
    params: [onboardingPeriodId],
  });

  const { mutate: sendReminder } = useMonolithMutation({
    method: 'onboardingPeriodsControllerSendElectionReminder',
    successMessage: 'Reminder sent',
  });

  if (!onboardingPeriod || isLoading) {
    return null;
  }

  const daysLeft = parseDateISO(onboardingPeriod.onboardingUntil).diff(
    getNow(),
    'day'
  );

  return (
    <Group>
      {daysLeft > 0 && (
        <Group gap="0.25rem">
          <Icon icon={IconExclamationCircleFilled} />
          <Text size="sm" c="zorroGray.4">
            {daysLeft} days left
          </Text>
        </Group>
      )}
      <Button
        size="sm"
        fw={300}
        onClick={() => sendReminder([onboardingPeriodId])}
      >
        Send a reminder
      </Button>
    </Group>
  );
};
