import {
  FullStory,
  isInitialized as isInitializedFullStory,
} from '@fullstory/browser';
import Cookies from 'js-cookie';

const localStorageKey = 'zorroAnalyticsDebug';

export function isDebugModeEnabled(): boolean {
  return Boolean(localStorage.getItem(localStorageKey));
}

export function toggleDebugMode() {
  const isEnabled = localStorage.getItem(localStorageKey);
  if (isEnabled) {
    localStorage.removeItem(localStorageKey);
  } else {
    localStorage.setItem(localStorageKey, 'enabled');
  }
  window.location.reload();
}

export function clearStorage() {
  localStorage.clear();
  const cookies = Cookies.get();
  for (const name in cookies) {
    if (name.startsWith('fs_')) {
      Cookies.remove(name, { path: '/' });
    }
  }
  window.location.reload();
}

export function debug(...logs: string[]) {
  if (isDebugModeEnabled()) {
    const isMultiLine = logs.length > 1;

    // eslint-disable-next-line no-console
    if (isMultiLine) console.groupCollapsed('analytics debug');

    for (const log of logs) {
      // eslint-disable-next-line no-console
      console.log('[ANALYTICS DEBUG]', log);
    }

    // eslint-disable-next-line no-console
    if (isMultiLine) console.groupEnd();
  }
}

export function getFullStorySessionURL() {
  if (isInitializedFullStory()) {
    const sessionURLatTime = FullStory('getSession', { format: 'url.now' });
    if (sessionURLatTime) {
      return sessionURLatTime;
    }
  }
  return 'N/A';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isPrimitiveValue(value: any) {
  return (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean'
  );
}

// @see https://developer.fullstory.com/browser/capture-events/analytics-events/
// @see https://developer.fullstory.com/browser/custom-properties/
export function getFullStoryTrackEventProperties(payload?: object) {
  if (!payload) {
    return {};
  }

  const properties = new Map();

  for (const [key, value] of Object.entries(payload)) {
    // base case: primitive value
    if (isPrimitiveValue(value)) {
      properties.set(key, value);
      continue;
    }

    // array
    if (Array.isArray(value)) {
      const sanitizedArray = [];
      for (const item of value) {
        if (isPrimitiveValue(item)) {
          sanitizedArray.push(item);
          continue;
        }
        sanitizedArray.push(JSON.stringify(item));
      }
      properties.set(key, sanitizedArray);
    }
  }

  return Object.fromEntries(properties);
}
