import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

const IconZorroPaymentSelfPay = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <path
          stroke="#1A1721"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M3.332 13.665h33.333m-30 19.167h26.667a3.333 3.333 0 0 0 3.333-3.333V11.165a3.333 3.333 0 0 0-3.333-3.333H6.665a3.333 3.333 0 0 0-3.333 3.333V29.5a3.333 3.333 0 0 0 3.333 3.333Z"
        />
      </svg>
    </Center>
  );
};

export { IconZorroPaymentSelfPay };
