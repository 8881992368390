import { Divider, DividerProps } from '@mantine/core';

export type IDividerProps = DividerProps;

export function ZorroDivider({
  color = 'zorroGray.2',
  ...props
}: IDividerProps) {
  return (
    <Divider
      color={color}
      styles={(theme) => ({
        label: {
          color: theme.colors.zorroCoal[0],
          fontSize: theme.fontSizes.sm,
          gap: theme.spacing.xl,
        },
      })}
      {...props}
    />
  );
}
