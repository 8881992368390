import { IconBuilding, IconBuildingSkyscraper } from '@tabler/icons-react';
import { YesNo } from '@zorro/types';
import {
  Grid,
  IRadioGroupProps,
  RadioCard,
  RadioGroup,
  brand,
} from '@zorro/zorro-ui-design';
import { ForwardedRef, forwardRef } from 'react';
import { ControllerRenderProps, Path } from 'react-hook-form';

type AleFieldValues = { isApplicableLargeEmployer?: YesNo | null };
type Props<T extends AleFieldValues, U extends Path<T>> = ControllerRenderProps<
  T,
  U
> & {
  radioGroupProps?: Omit<IRadioGroupProps, 'children'>;
};

export const ApplicableLargeEmployerInput = forwardRef(
  <T extends AleFieldValues, U extends Path<T>>(
    { radioGroupProps, disabled, ...field }: Props<T, U>,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <RadioGroup
        {...field}
        {...radioGroupProps}
        ref={ref}
        w="100%"
        display="flex"
        label="Is the company considered an Applicable Large Employer (ALE)?"
        styles={{
          root: { alignItems: 'start', flexDirection: 'column' },
          label: { alignSelf: 'start', marginBottom: '8px' },
        }}
      >
        <Grid align="center">
          <Grid.Col span={{ sm: 6 }}>
            <RadioCard
              value={YesNo.YES}
              iconColor={brand.zorroYolk400}
              icon={<IconBuilding />}
              label="Yes, it's an ALE"
              disabled={disabled}
            />
          </Grid.Col>
          <Grid.Col span={{ sm: 6 }}>
            <RadioCard
              value={YesNo.NO}
              iconColor={brand.zorroFire300}
              icon={<IconBuildingSkyscraper />}
              label="No, it's not an ALE"
              disabled={disabled}
            />
          </Grid.Col>
        </Grid>
      </RadioGroup>
    );
  }
);

ApplicableLargeEmployerInput.displayName = 'ApplicableLargeEmployerInput';
