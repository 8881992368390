import {
  BenefitEnrollmentDto,
  EmployeeDto,
  OnboardingPeriodDto,
} from '@zorro/clients';
import { getDateUtil, parseDateISO } from '@zorro/shared/formatters';

export function getWaiveCoverageEffectiveDates(
  employee?: EmployeeDto,
  onboardingPeriod?: OnboardingPeriodDto,
  benefitEnrollment?: BenefitEnrollmentDto | null
): [Date | null, Date | null] {
  if (benefitEnrollment) {
    const effectiveFrom = parseDateISO(benefitEnrollment.effectiveFrom);
    const effectiveUntil = parseDateISO(benefitEnrollment.effectiveUntil);

    return [effectiveFrom.toDate(), effectiveUntil.toDate()];
  }

  if (!employee || !onboardingPeriod) {
    return [null, null];
  }

  const effectiveFromDataPoints = [
    onboardingPeriod.targetEnrollmentDate,
    onboardingPeriod.enrollmentEffectiveFrom,
    employee.eligibleFrom,
  ].flatMap((dataPoint) => (dataPoint ? [parseDateISO(dataPoint)] : []));

  const effectiveFrom = getDateUtil().max(effectiveFromDataPoints);

  const effectiveUntilDataPoints = [
    onboardingPeriod.enrollmentEffectiveUntil,
    employee.eligibleUntil,
  ].flatMap((dataPoint) => (dataPoint ? [parseDateISO(dataPoint)] : []));

  const effectiveUntil = getDateUtil().min(effectiveUntilDataPoints);

  if (!effectiveFrom || !effectiveUntil) {
    return [null, null];
  }

  return [effectiveFrom.toDate(), effectiveUntil.toDate()];
}
