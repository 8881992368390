import { logger } from './logger';

const createLinkElementForDownload = (
  url: string,
  fileName: string,
  shouldOpenInNewTab: boolean
) => {
  const anchorElement = document.createElement('a');
  anchorElement.setAttribute('href', url);
  anchorElement.setAttribute('download', fileName);
  if (shouldOpenInNewTab) {
    anchorElement.setAttribute('target', '_blank');
  }
  anchorElement.style.visibility = 'hidden';
  document.body.append(anchorElement);
  anchorElement.click();
  anchorElement.remove();
};

export const downloadFile = (
  url: string,
  fileName: string,
  crossOrigin?: boolean | undefined
) => {
  if (crossOrigin) {
    fetch(url)
      .then((res) =>
        // eslint-disable-next-line promise/no-nesting
        res.blob().then((response) => {
          let blob = null;
          blob = new Blob([response]);
          const newUrl = window.URL.createObjectURL(blob);
          createLinkElementForDownload(newUrl, fileName, false);
          return true;
        })
      )
      .catch((error) => logger.error('Error downloading file', error));
  } else {
    createLinkElementForDownload(url, fileName, true);
  }
};
