import { showNotification } from '@mantine/notifications';

import { UNEXPECTED_ERROR_MESSAGE } from './errorUtil';

export type NotificationConfig = {
  message?: string;
  id?: string;
  title?: string;
};

export const showErrorNotification = ({
  message = UNEXPECTED_ERROR_MESSAGE,
  title = 'Oh no!',
  id,
}: NotificationConfig = {}) => {
  showNotification({
    id,
    color: 'red',
    title,
    message,
    autoClose: false,
  });
};

export const showWarningNotification = ({
  message,
  title,
  id,
}: NotificationConfig = {}) => {
  showNotification({
    id,
    color: 'yellow',
    title,
    message,
  });
};

export const showSuccessNotification = ({
  message,
  title = 'Success!',
  id,
}: NotificationConfig = {}) => {
  showNotification({
    id,
    color: 'green',
    title,
    message,
  });
};
