import { JSONContent } from '@tiptap/react';

export function isEmptyEnrollmentInstructions(content?: string): boolean {
  if (!content) {
    return true;
  }

  return content === JSON.stringify({});
}

export function getEnrollmentInstructionsContent(
  content?: string
): JSONContent | null {
  if (!content) {
    return null;
  }

  if (isEmptyEnrollmentInstructions(content)) {
    return null;
  }

  try {
    return JSON.parse(content);
  } catch {
    return null;
  }
}
