import {
  SUCCESS_MESSAGES,
  callEndpoint,
  responseErrorToString,
  showErrorNotification,
  showSuccessNotification,
} from '@zorro/shared/utils';
import { Stack, Text } from '@zorro/zorro-ui-design';
import pluralize from 'pluralize';

import { ModalFooter, useLoadingOverlay } from '../../../index';
import { ActionsMenuSection } from '../employeeActions.types';

type ElectionWindowLaunchFormProps = {
  selectedEmployees: ActionsMenuSection['selectedEmployees'];
  onClose: () => void;
  onSuccess: () => void;
};

const ElectionWindowLaunchForm = ({
  selectedEmployees,
  onClose,
  onSuccess,
}: ElectionWindowLaunchFormProps) => {
  const isSingleEmployee = selectedEmployees.length === 1;

  const { startLoading, stopLoading } = useLoadingOverlay();

  async function handleSubmit() {
    startLoading();
    const onboardingPeriodsIds: string[] = selectedEmployees
      .map((employeeRow) => employeeRow.onboardingPeriodId)
      .filter((onboardingPeriodId) => onboardingPeriodId !== undefined);

    try {
      await Promise.all(
        onboardingPeriodsIds.map(async (onboardingPeriodId) =>
          callEndpoint({
            method: 'onboardingPeriodsControllerSendElectionWindowLaunchEmail',
            params: [onboardingPeriodId],
          })
        )
      );

      showSuccessNotification({
        message: SUCCESS_MESSAGES.getEmailSentSuccessfullyMessage(
          selectedEmployees.length
        ),
      });

      onSuccess();
    } catch (error) {
      showErrorNotification({ message: responseErrorToString(error) });
    }
    stopLoading();
  }

  const text = isSingleEmployee
    ? 'Are you sure you want to send this email to the selected employee?'
    : 'Are you sure you want to send this email to all selected employees?';

  const submitText = `Send ${pluralize('email', selectedEmployees.length)}`;

  return (
    <Stack gap="xxl">
      <Text>{text}</Text>
      <ModalFooter
        onClose={onClose}
        submitVariant="primary"
        submitText={submitText}
        onSubmit={handleSubmit}
      />
    </Stack>
  );
};

export { ElectionWindowLaunchForm };
