import {
  FullStory,
  init as initFullStory,
  isInitialized as isInitializedFullStory,
} from '@fullstory/browser';
import { CORRELATION_ID_HEADER } from '@zorro/clients';
import { isDevelopmentEnvironment } from '@zorro/environment';
import { useImpersonation } from '@zorro/shared/utils';
import axios from 'axios';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { debug, isDebugModeEnabled } from './analytics.utils';
import { useAnalytics } from './useAnalytics';

type TAnalyticsProps = {
  applicationName: 'elena' | 'murrieta' | 'tornado';
};

const Analytics = ({ applicationName }: TAnalyticsProps) => {
  const { authUser, employee, isImpersonated } = useImpersonation();
  const router = useRouter();
  const { sendEvent } = useAnalytics();

  // Network
  useEffect(() => {
    axios.interceptors.request.use(function (config) {
      config.headers[CORRELATION_ID_HEADER] = uuidv4();
      return config;
    });
  }, []);

  // FullStory: Init
  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID &&
      (isDebugModeEnabled() || !isDevelopmentEnvironment())
    ) {
      initFullStory(
        {
          orgId: process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID,
        },
        ({ sessionUrl }) => {
          debug('FullStory initiated');
          debug(`Session URL: ${sessionUrl}`);
        }
      );
    }
  }, []);

  const userId = authUser?.id;
  const tenantId = authUser?.tenantId;
  const userRoles = authUser?.roles.map(({ key }) => key).join(',');

  // FullStory: Identify User
  useEffect(() => {
    if (!isInitializedFullStory() || !userId || !tenantId || !userRoles) {
      return;
    }

    debug('identifyUser');

    FullStory('setIdentity', {
      uid: userId,
    });

    FullStory('setProperties', {
      type: 'user',
      properties: {
        // 🧠 special FS field
        displayName: userId,
        userId,
        tenantId,
        roles: userRoles,
      },
    });
  }, [userId, tenantId, userRoles]);

  const employeeId = employee?.id;
  const employerId = employee?.employerId;

  // FullStory: Identify Employee
  useEffect(() => {
    if (!isInitializedFullStory() || !employeeId || !employerId) {
      return;
    }

    debug('identifyEmployee');

    FullStory('setProperties', {
      type: 'user',
      properties: {
        employeeId,
        employerId,
        isImpersonated,
      },
    });
  }, [employeeId, employerId, isImpersonated]);

  // FullStory: Identify Page
  useEffect(() => {
    function identifyPage() {
      if (!isInitializedFullStory()) {
        return;
      }

      debug('identifyPage');

      // FS requires setting the page properties on each navigation
      FullStory('setProperties', {
        type: 'page',
        properties: {
          // 🧠 special FS property
          pageName: router.pathname,
          application: applicationName,
        },
      });
    }

    identifyPage();

    router.events.on('routeChangeComplete', identifyPage);
    router.events.on('routeChangeError', identifyPage);
    return () => {
      router.events.off('routeChangeComplete', identifyPage);
      router.events.off('routeChangeError', identifyPage);
    };
  }, [router.events, router.pathname, applicationName]);

  // 🧠 Internal Page Tracking
  useEffect(() => {
    sendEvent('page_visit');
  }, [router.pathname, sendEvent]);

  return null;
};

export { Analytics };
