import { VALIDATION_MESSAGES } from '@zorro/shared/utils';
import * as yup from 'yup';

export const allowanceFormSchema = yup.object({
  allowance: yup
    .number()
    .min(0, VALIDATION_MESSAGES.allowanceNotNegative)
    .typeError(VALIDATION_MESSAGES.allowanceRequired)
    .required(VALIDATION_MESSAGES.allowanceRequired),
  employeeMonthlyContribution: yup
    .number()
    .min(0, VALIDATION_MESSAGES.employeeMonthlyContributionNotNegative)
    .typeError(VALIDATION_MESSAGES.employeeMonthlyContributionNotNegative)
    .required(VALIDATION_MESSAGES.employeeMonthlyContributionRequired),
  premium: yup
    .number()
    .min(0, VALIDATION_MESSAGES.premiumPositive)
    .typeError(VALIDATION_MESSAGES.premiumRequired)
    .required(VALIDATION_MESSAGES.premiumRequired),
});

export type AllowanceFormFields = yup.InferType<typeof allowanceFormSchema>;
