import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

const IconZorroBankBuilding = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        width="32"
        height="32"
        viewBox="0 0 32 32"
      >
        <path
          fill="#80E8B6"
          d="M6 14h13a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H6V14Z"
        />
        <path
          stroke="#1A1721"
          d="M4 28h24M4 13.333h24M6.667 8 16 4l9.333 4m-20 5.333V28m21.333-14.667V28m-16-9.333v4m5.334-4v4m5.334-4v4"
        />
      </svg>
    </Center>
  );
};

export { IconZorroBankBuilding };
