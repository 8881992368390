import { WaitingPeriod } from '@zorro/clients';
import { DeepReadonly } from '@zorro/types';

export const waitingPeriodLabelConfig: DeepReadonly<{
  [key in WaitingPeriod]: string;
}> = {
  [WaitingPeriod.NONE]: 'No waiting period',
  [WaitingPeriod.THIRTY_DAYS]: '30 days',
  [WaitingPeriod.SIXTY_DAYS]: '60 days',
  [WaitingPeriod.NINETY_DAYS]: '90 days',
};

export const waitingPeriodToNumberOfDays: DeepReadonly<{
  [key in WaitingPeriod]: number;
}> = {
  [WaitingPeriod.NONE]: 0,
  [WaitingPeriod.THIRTY_DAYS]: 30,
  [WaitingPeriod.SIXTY_DAYS]: 60,
  [WaitingPeriod.NINETY_DAYS]: 90,
};
