import { Group, Text } from '@zorro/zorro-ui-design';
import { ReactNode } from 'react';

type GridRowItemProps = {
  label: string;
  value: string;
  icon?: ReactNode;
};

export const GridRowItem = ({
  icon,
  label,
  value,
  ...props
}: GridRowItemProps) => {
  return (
    <Group gap="lg" wrap="nowrap" align="start" {...props}>
      {icon}
      <Group>
        <Text>
          <Text component="span" fw={600}>
            {label}
          </Text>
          : <Text component="span">{value}</Text>
        </Text>
      </Group>
    </Group>
  );
};
