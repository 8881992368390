import { Carousel, CarouselProps } from '@mantine/carousel';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';

import { ZorroIcon } from '../../Icon';
import { ZorroCarouselSlide } from '../CarouselSlide/CarouselSlide';
import classNames from './Carousel.module.scss';

export type ICarouselProps = CarouselProps;

export const ZorroCarousel = ({
  children,
  withIndicators = true,
  ...props
}: ICarouselProps) => {
  return (
    <Carousel
      classNames={classNames}
      withIndicators={withIndicators}
      nextControlIcon={
        props.nextControlIcon || (
          <ZorroIcon icon={IconChevronRight} aria-label="Go to next slide" />
        )
      }
      previousControlIcon={
        props.previousControlIcon || (
          <ZorroIcon icon={IconChevronLeft} aria-label="Go to previous slide" />
        )
      }
      {...props}
    >
      {children}
    </Carousel>
  );
};

ZorroCarousel.Slide = ZorroCarouselSlide;
