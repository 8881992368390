import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';
import classNames from './Icon.module.scss';

export const IconPremiumRights = ({ style, grow }: SVGIconProps) => {
  return (
    <Center style={style} classNames={classNames} data-grow={grow}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="none"
      >
        <ellipse cx="17.5" cy="16" fill="#FFD880" rx="10.5" ry="12" />
        <path
          stroke="#1A1721"
          d="M16 28c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12Z"
        />
        <path
          stroke="#1A1721"
          d="M19.733 12a2.666 2.666 0 0 0-2.4-1.333h-2.666a2.667 2.667 0 1 0 0 5.333h2.666a2.667 2.667 0 0 1 0 5.333h-2.666a2.667 2.667 0 0 1-2.4-1.333M16 8v2.667m0 10.666V24"
        />
      </svg>
    </Center>
  );
};
