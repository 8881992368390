/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum EmployerStatus {
  PROSPECT = 'PROSPECT',
  DRAFT = 'DRAFT',
  WAITING_FOR_EMPLOYER = 'WAITING_FOR_EMPLOYER',
  NEEDS_REVIEW = 'NEEDS_REVIEW',
  IN_ZORRO_SETUP = 'IN_ZORRO_SETUP',
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
}
