import { AppShell } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { Box } from '@zorro/zorro-ui-design';
import { ReactNode } from 'react';

import { AppLayoutConstants } from '../AppLayoutUtils';

type Props = {
  children: ReactNode;
};

export const AppLayoutMain = ({ children }: Props) => {
  const { height: viewportHeight } = useViewportSize();

  return (
    <AppShell.Main
      style={{
        height: `${viewportHeight - AppLayoutConstants.HEADER_HEIGHT}px`,
      }}
    >
      <Box>
        <Box>
          <Box>{children}</Box>
        </Box>
      </Box>
    </AppShell.Main>
  );
};
