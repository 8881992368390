import * as yup from 'yup';

import { VALIDATION_MESSAGES } from '../validationMessages';

export const emailValidationSchema = yup
  .string()
  .email(VALIDATION_MESSAGES.emailValid)
  // eslint-disable-next-line require-unicode-regexp
  .matches(/^$|^[^\n\r@\u2028\u2029]*@[^\n\r.\u2028\u2029]*\..*$/, {
    message: VALIDATION_MESSAGES.emailValid,
  });
