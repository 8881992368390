import { PayrollCycle } from '@zorro/clients';
import * as yup from 'yup';

export const payrollFormSchema = yup.object().shape({
  payrollCycle: yup
    .mixed<PayrollCycle>()
    .oneOf(Object.values(PayrollCycle), 'Payroll cycle required')
    .required('Payroll cycle required'),
  effectiveOn: yup.date().required('Effective date required'),
});

export type PayrollFormData = yup.InferType<typeof payrollFormSchema>;
