import { EnrollmentStatus } from '@zorro/clients';
import {
  EMPLOYEE_DYNAMIC_ROUTES,
  useDynamicMurrietaRouter,
  useMonolithQuery,
} from '@zorro/shared/utils';
import { Stack, Tabs } from '@zorro/zorro-ui-design';
import { ReactElement } from 'react';
import { $enum } from 'ts-enum-util';

export enum EmployeeLayoutTabType {
  ENROLLMENTS = 'ENROLLMENTS',
  PERSONAL_DETAILS = 'PERSONAL_DETAILS',
  FILES = 'FILES',
  PAYMENTS = 'PAYMENTS',
}

const paymentsTabEnabledEnrollmentStatuses = new Set([
  EnrollmentStatus.ENROLLMENT_CONFIRMED,
  EnrollmentStatus.CARRIER_APPLICATION_SENT,
  EnrollmentStatus.ACTIVE_COVERAGE,
  EnrollmentStatus.COVERAGE_ENDED,
]);

const allEmployeeLayoutTabs = Object.values(EmployeeLayoutTabType);
const getEmployeeLayoutTabConfig = (
  tab: EmployeeLayoutTabType,
  enrollmentStatus: EnrollmentStatus
) => {
  return $enum.mapValue(tab).with<{
    label: string;
    route: keyof typeof EMPLOYEE_DYNAMIC_ROUTES;
    isDisabled: boolean;
  }>({
    [EmployeeLayoutTabType.ENROLLMENTS]: {
      label: 'Enrollment',
      route: 'EMPLOYEE_ENROLLMENT_TAB',
      isDisabled: false,
    },
    [EmployeeLayoutTabType.PERSONAL_DETAILS]: {
      label: 'Personal details',
      route: 'EMPLOYEE_PERSONAL_DETAILS_TAB',
      isDisabled: false,
    },
    [EmployeeLayoutTabType.FILES]: {
      label: 'Files',
      route: 'EMPLOYEE_FILES_TAB',
      isDisabled: false,
    },
    [EmployeeLayoutTabType.PAYMENTS]: {
      label: 'Payments',
      route: 'EMPLOYEE_PAYMENT_TAB',
      isDisabled: !paymentsTabEnabledEnrollmentStatuses.has(enrollmentStatus),
    },
  });
};

type Props = {
  tab: EmployeeLayoutTabType;
  children: ReactElement;
  employerId: string;
  employeeId: string;
  onboardingPeriodId: string;
};

export const EmployeeLayoutTab = ({
  tab,
  employerId,
  employeeId,
  onboardingPeriodId,
  children,
}: Props) => {
  const { navigateToRoute } = useDynamicMurrietaRouter();
  const { data: onboardingPeriod } = useMonolithQuery({
    method: 'onboardingPeriodReportsControllerGenerateOnboardingPeriodReport',
    params: [onboardingPeriodId],
  });

  if (!onboardingPeriod) {
    return null;
  }

  const { enrollmentStatus } = onboardingPeriod;

  return (
    <Stack mb="6rem" gap="xl">
      <Tabs maw="410px" mt="sm" keepMounted={false} value={tab}>
        <Tabs.List grow>
          {allEmployeeLayoutTabs.map((newTab) => {
            const { label, route, isDisabled } = getEmployeeLayoutTabConfig(
              newTab,
              enrollmentStatus
            );

            return (
              <Tabs.Tab
                onClick={() =>
                  navigateToRoute(route, [
                    { employerId, employeeId, onboardingPeriodId },
                  ])
                }
                disabled={isDisabled}
                value={newTab}
                key={newTab}
              >
                {label}
              </Tabs.Tab>
            );
          })}
        </Tabs.List>
      </Tabs>
      {children}
    </Stack>
  );
};
