import { Box, Stack, Title } from '@zorro/zorro-ui-design';

import { ContactsForm, useContactsForm } from '../../ContactsForm';
import { AddEmployerStep } from '../AddEmployerStep';
import {
  AddEmployerStepType,
  HandleStepSubmit,
} from '../AddEmployerStep/AddEmployerUtils';
import FaqSection from '../FaqSection/FaqSection';

type Props = {
  employerId: string;
  isFinalizationMode?: boolean;
  isDon?: boolean;
};

const AddEmployerContactStepFaq = () => {
  return (
    <FaqSection
      links={[{ name: 'Contacts vs. Zorro Admins', href: '#', isDrawer: true }]}
    >
      <Stack>
        <Title size="medium">Why do we ask for contact details?</Title>
        <Box c="zorroGray.9">
          We collect contact details for specific types of communication
          relevant to legal, HR, and finance roles.
        </Box>
        <Title size="medium">What does "Make a Zorro admin" mean?</Title>
        <Box c="zorroGray.9">
          Selecting the "Make a Zorro admin" checkbox grants the contact full
          administrative access to the company account, allowing them to manage
          employee records, payroll, and company reports. Admins have
          comprehensive permissions for company-level tasks once the account is
          set up.
        </Box>
      </Stack>
    </FaqSection>
  );
};

export const AddEmployerContactStep = ({
  employerId,
  isFinalizationMode = false,
  isDon = false,
}: Props) => {
  const { contactsForm, onSubmit, duplicateFieldName } = useContactsForm(
    employerId,
    isFinalizationMode,
    isDon
  );

  const handleStepSubmit: HandleStepSubmit = async () => {
    if (await onSubmit()) {
      return { navigate: true, employerId };
    }

    return { navigate: false };
  };

  const isSubmitDisabled = !contactsForm.formState.isValid;

  return (
    <AddEmployerStep
      isFinalizationMode={isFinalizationMode}
      step={AddEmployerStepType.CONTACTS}
      isSubmitDisabled={isSubmitDisabled}
      title="Add company contacts"
      onSubmit={handleStepSubmit}
      employerId={employerId}
      secondaryLabel="Skip"
      isDon={isDon}
      rightSection={<AddEmployerContactStepFaq />}
    >
      <ContactsForm
        shouldShowOnboardingContacts={isFinalizationMode}
        duplicateFieldName={duplicateFieldName}
        isFinalizationMode={isFinalizationMode}
        contactsForm={contactsForm}
        isDon={isDon}
      />
    </AddEmployerStep>
  );
};
