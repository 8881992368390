import { Icon, RadixMenu, Text, brand } from '@zorro/zorro-ui-design';
import { ElementType, ReactNode } from 'react';

type ActionsSubMenuProps = {
  title: string;
  icon: ElementType;
  children: ReactNode;
};

const sectionIconSize = '1.25rem';

const ActionsSubMenu = ({ title, icon, children }: ActionsSubMenuProps) => {
  return (
    <RadixMenu.Submenu>
      <RadixMenu.Subtrigger
        leftSection={
          <Icon icon={icon} size={sectionIconSize} color={brand.zorroGray800} />
        }
      >
        <Text size="sm" fw={500}>
          {title}
        </Text>
      </RadixMenu.Subtrigger>
      <RadixMenu.Subcontent>{children}</RadixMenu.Subcontent>
    </RadixMenu.Submenu>
  );
};

export { ActionsSubMenu };
