import {
  ApiError,
  ApiRequestOptions,
  MonolithService,
  isSuccess,
} from '@zorro/clients';
import { ExtractClassMethods } from '@zorro/types';
import axios, { HttpStatusCode } from 'axios';

export const MONOLITH_API_ROUTE = '/api/monolith-client';

export type MonolithMethods = ExtractClassMethods<typeof MonolithService>;
export type MonolithMethodNames = keyof MonolithMethods;

export type EndpointCall<T extends MonolithMethodNames = MonolithMethodNames> =
  {
    method: T;
    params: Parameters<MonolithMethods[T]>;
    anonymous?: boolean;
  };

// 🧠 THIS IS TEMPORARY
// @see https://app.clickup.com/t/8695d0ftu

export async function callEndpoint<
  T extends MonolithMethodNames = MonolithMethodNames
>(call: EndpointCall<T>): Promise<ReturnType<MonolithMethods[T]>> {
  try {
    const { data } = await axios.post(MONOLITH_API_ROUTE, call, {
      params: {
        method: call.method,
      },
    });

    return data;
  } catch (error) {
    // @see https://axios-http.com/docs/handling_errors

    let message = error.message || 'unknown error';

    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx

      const { status, data } = error.response;

      if (status === HttpStatusCode.Unauthorized && !call.anonymous) {
        window.location.reload();
        // @ts-expect-error we either location.reload or throw!
        // eslint-disable-next-line consistent-return
        return;
      }

      throw new ApiError(
        {} as unknown as ApiRequestOptions,
        {
          url: MONOLITH_API_ROUTE,
          ok: isSuccess(data.status),
          status,
          statusText: data.statusText,
          body: data.body,
        },
        message
      );
    }

    if (error.request) {
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
      message = `the request was made but no response was received. ${message}`;
    }

    // Something happened in setting up the request that triggered an Error

    throw new ApiError(
      {} as unknown as ApiRequestOptions,
      {
        url: MONOLITH_API_ROUTE,
        ok: false,
        status: 418,
        statusText: "I'm a teapot",
        body: {},
      },
      message
    );
  }
}
