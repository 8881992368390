/* eslint-disable react/boolean-prop-naming */
import { Modal, ModalProps, NativeScrollArea } from '@mantine/core';
import { MODAL_ZINDEX } from '@zorro/types';

import { ZorroDivider } from '../Divider';
import { brand } from '../theme';
import classNames from './Modal.module.scss';

export type IModalProps = Omit<ModalProps, 'opened' | 'padding' | 'shadow'> & {
  dividerColor?: string;
  showSeparator?: boolean;
  opened?: boolean;
  padding?: number;
};

// TODO: Fix ZorroModal with global padding for all components https://app.clickup.com/t/86934xyf9
export function ZorroModal({
  size = 'lg',
  dividerColor = 'zorroGray.6',
  overlayProps = {
    color: brand.zorroCoal900,
    opacity: 0.9,
    blur: 12,
  },
  closeButtonProps = {
    size: 'xs',
  },
  opened = true,
  showSeparator = true,
  padding,
  ...props
}: IModalProps) {
  return (
    <Modal
      size={size}
      shadow="none"
      centered
      transitionProps={{ transition: 'fade' }}
      padding="40px"
      overlayProps={overlayProps}
      classNames={classNames}
      /**
       * INFO: We need to change the scrollAreaComponent value here to use native scrollbars so that
       * components like Datepicker are not cutoff inside the modal
       * See more: https://v6.mantine.dev/core/modal/#native-scrollbars
       */
      scrollAreaComponent={NativeScrollArea}
      closeButtonProps={closeButtonProps}
      opened={opened}
      zIndex={MODAL_ZINDEX}
      {...props}
    >
      {showSeparator && (
        <ZorroDivider
          color={dividerColor}
          mb="lg"
          /**
           * Set to -40 because we have 40 pixel padding
           */
          mx={padding ? -padding : -40}
        />
      )}

      {props.children}
    </Modal>
  );
}
