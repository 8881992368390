import { EmploymentType } from '@zorro/clients';
import { employmentTypeLabelConfig } from '@zorro/types';
import { ISelectProps, Select } from '@zorro/zorro-ui-design';
import { ForwardedRef, forwardRef } from 'react';
import { ControllerRenderProps, Path } from 'react-hook-form';

const employmentTypeOptions = Object.values(EmploymentType).map((status) => ({
  label: employmentTypeLabelConfig[status],
  value: status,
}));

type EmploymentTypeFieldValues = {
  employmentType?: EmploymentType | null;
};
type Props<
  T extends EmploymentTypeFieldValues,
  U extends Path<T>
> = ControllerRenderProps<T, U> & { selectProps?: ISelectProps };

export const EmploymentTypeInput = forwardRef(
  <T extends EmploymentTypeFieldValues, U extends Path<T>>(
    { selectProps, ...field }: Props<T, U>,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <Select
        {...field}
        {...selectProps}
        ref={ref}
        label="Employment type"
        placeholder="Select employment type"
        data={employmentTypeOptions}
      />
    );
  }
);

EmploymentTypeInput.displayName = 'CitizenshipStatusInput';
