import { useRouter } from '@zorro/shared/utils';
import { ReactElement, useEffect, useState } from 'react';

import { MainLink, MainLinkProps } from './MainLink';

interface NavItemsProps {
  items: MainLinkProps[];
}

export function MainLinks({ items }: NavItemsProps): ReactElement {
  const { router } = useRouter();
  const [selectedRoute, setSelectedRoute] = useState<string>();

  useEffect(() => {
    router.beforePopState(() => {
      setSelectedRoute(undefined);

      return true;
    });
  }, [router]);

  return (
    <>
      {items.map((item) => {
        let isCurrentRoute = false;
        if (selectedRoute) {
          isCurrentRoute = item.link === selectedRoute;
        } else {
          isCurrentRoute = item.exactLinkOnly
            ? item.link === router.asPath
            : router.asPath.includes(item.link);
        }

        return (
          <MainLink
            {...item}
            key={item.label}
            onClick={setSelectedRoute}
            isCurrentRoute={isCurrentRoute}
          />
        );
      })}
    </>
  );
}
