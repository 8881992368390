import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';
import classNames from './Icon.module.scss';

export const ContactIcon = ({ style, grow }: SVGIconProps) => {
  return (
    <Center style={style} classNames={classNames} data-grow={grow}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="none"
      >
        <path
          fill="#FFBBAF"
          d="m24.135 17.35-7.431 7.36a1 1 0 0 1-1.407 0L7.8 17.285a6 6 0 0 1 8.204-8.753 5.998 5.998 0 0 1 9.99 4.308 6.001 6.001 0 0 1-1.793 4.451 1.05 1.05 0 0 1-.066.06Z"
        />
        <path
          fill="#141517"
          d="M13 8a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Zm11.031-4.118a1 1 0 0 1 1.217-.72 5 5 0 0 1 0 9.687 1 1 0 0 1-.496-1.938 3 3 0 0 0 0-5.812 1 1 0 0 1-.72-1.217ZM11 20a3 3 0 0 0-3 3v2a1 1 0 1 1-2 0v-2a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v2a1 1 0 1 1-2 0v-2a3 3 0 0 0-3-3h-4Zm10.032-1.1a1 1 0 0 1 1.218-.718A5 5 0 0 1 26 22.994V25a1 1 0 1 1-2 0v-1.997a3 3 0 0 0-2.25-2.885 1 1 0 0 1-.718-1.218Z"
        />
      </svg>
    </Center>
  );
};
