/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum EnrollmentStatus {
  PENDING_ELECTION_WINDOW = 'PENDING_ELECTION_WINDOW',
  ELECTION_ACTIVE = 'ELECTION_ACTIVE',
  ELECTION_ACTIVE_HAS_NOT_STARTED = 'ELECTION_ACTIVE_HAS_NOT_STARTED',
  ELECTION_ACTIVE_STARTED = 'ELECTION_ACTIVE_STARTED',
  ELECTION_SUBMITTED = 'ELECTION_SUBMITTED',
  WAIVED_ELECTION = 'WAIVED_ELECTION',
  DEADLINE_PASSED = 'DEADLINE_PASSED',
  ENROLLMENT_CONFIRMED = 'ENROLLMENT_CONFIRMED',
  CARRIER_APPLICATION_SENT = 'CARRIER_APPLICATION_SENT',
  COVERAGE_ENDED = 'COVERAGE_ENDED',
  ACTIVE_COVERAGE = 'ACTIVE_COVERAGE',
  WAIVED_COVERAGE = 'WAIVED_COVERAGE',
  NO_ENROLLMENTS = 'NO_ENROLLMENTS',
}
