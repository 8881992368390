import { CSSProperties, MantineTheme } from '@mantine/core';
import { IconExternalLink } from '@tabler/icons-react';
import { EnrollmentStatus } from '@zorro/clients';
import {
  IconZorroMessages,
  IconZorroProcess,
  ShoppingBagXIcon,
} from '@zorro/shared/assets';
import {
  ZORRO_UI_DYNAMIC_ROUTES,
  createImpersonationSession,
  useDynamicMurrietaRouter,
  useMonolithQuery,
} from '@zorro/shared/utils';
import {
  Box,
  Card,
  Divider,
  Group,
  Icon,
  Stack,
  Text,
  Tooltip,
  brand,
} from '@zorro/zorro-ui-design';
import { $enum } from 'ts-enum-util';

const getEnrollmentTabButtonsConfig = (enrollmentStatus: EnrollmentStatus) =>
  $enum.mapValue(enrollmentStatus).with<{
    shouldShowEnrollmentTabButtons?: true;
    isImpersonationModeActive?: true;
  }>({
    [EnrollmentStatus.PENDING_ELECTION_WINDOW]: {
      shouldShowEnrollmentTabButtons: true,
    },
    [EnrollmentStatus.ELECTION_ACTIVE]: {
      shouldShowEnrollmentTabButtons: true,
      isImpersonationModeActive: true,
    },
    [EnrollmentStatus.ELECTION_ACTIVE_HAS_NOT_STARTED]: {
      shouldShowEnrollmentTabButtons: true,
      isImpersonationModeActive: true,
    },
    [EnrollmentStatus.ELECTION_ACTIVE_STARTED]: {
      shouldShowEnrollmentTabButtons: true,
      isImpersonationModeActive: true,
    },
    [EnrollmentStatus.ELECTION_SUBMITTED]: {},
    [EnrollmentStatus.WAIVED_ELECTION]: {},
    [EnrollmentStatus.DEADLINE_PASSED]: {
      shouldShowEnrollmentTabButtons: true,
    },
    [EnrollmentStatus.ENROLLMENT_CONFIRMED]: {},
    [EnrollmentStatus.CARRIER_APPLICATION_SENT]: {},
    [EnrollmentStatus.COVERAGE_ENDED]: {},
    [EnrollmentStatus.ACTIVE_COVERAGE]: {},
    [EnrollmentStatus.WAIVED_COVERAGE]: {},
    [EnrollmentStatus.NO_ENROLLMENTS]: {},
  });

const getCardStyles = (
  theme: MantineTheme,
  isActive: boolean = true
): CSSProperties => {
  if (isActive) {
    return { borderColor: brand.zorroGray200, cursor: 'pointer' };
  }

  return {
    background: theme.colors.zorroGray[8],
    color: theme.colors.zorroGray[4],
  };
};

type Props = {
  employerId: string;
  employeeId: string;
  onboardingPeriodId: string;
  onWaiveCoverageClick: () => void;
  enrollmentStatus: EnrollmentStatus;
};

export const EnrollmentTabButtons = ({
  employerId,
  employeeId,
  enrollmentStatus,
  onboardingPeriodId,
  onWaiveCoverageClick,
}: Props) => {
  const { navigateToRoute } = useDynamicMurrietaRouter();

  const { data: onboardingPeriods = [] } = useMonolithQuery({
    method: 'onboardingPeriodsControllerFindMany',
    params: [employeeId],
  });

  const isManualSubmissionActive = !onboardingPeriods.some(
    ({ id, isActive }) => id !== onboardingPeriodId && isActive
  );

  const { shouldShowEnrollmentTabButtons, isImpersonationModeActive = false } =
    getEnrollmentTabButtonsConfig(enrollmentStatus);

  if (!shouldShowEnrollmentTabButtons) {
    return null;
  }

  const impersonateOpacity = isImpersonationModeActive ? 1 : 0.5;
  const manualSubmissionOpacity = isManualSubmissionActive ? 1 : 0.5;

  const handleImpersonationClick = () => {
    if (isImpersonationModeActive) {
      createImpersonationSession(
        employeeId,
        ZORRO_UI_DYNAMIC_ROUTES.PERSONALIZED_ALLOWANCE()
      );
    }
  };

  return (
    <>
      <Divider
        label="Or choose another way to submit "
        labelPosition="center"
        color="zorroGray.2"
        my="xs"
      />

      <Group grow align="stretch">
        <Tooltip
          label="Election must be active to run a joint election session."
          disabled={isImpersonationModeActive}
          position="top"
        >
          <Card
            style={(theme) => getCardStyles(theme, isImpersonationModeActive)}
            onClick={handleImpersonationClick}
          >
            <Stack gap="sm">
              <IconZorroMessages
                style={{ opacity: impersonateOpacity, justifyContent: 'start' }}
              />
              <Box pos="absolute" right="1rem">
                <Icon icon={IconExternalLink} opacity={impersonateOpacity} />
              </Box>
              <Text>Start a live joint session</Text>
            </Stack>
          </Card>
        </Tooltip>
        <Tooltip
          label="Another election is in progress. Complete it before submitting a new one."
          disabled={isManualSubmissionActive}
          position="top"
        >
          <Card
            style={(theme) => getCardStyles(theme, isManualSubmissionActive)}
            onClick={() => {
              if (isManualSubmissionActive) {
                navigateToRoute('MANUAL_SUBMISSION_HOUSEHOLD_INFORMATION', [
                  { employerId, employeeId, onboardingPeriodId },
                ]);
              }
            }}
          >
            <Stack gap="sm">
              <IconZorroProcess
                style={{
                  opacity: manualSubmissionOpacity,
                  justifyContent: 'start',
                }}
              />
              <Text>Start manual election</Text>
            </Stack>
          </Card>
        </Tooltip>
        <Card
          style={(theme) => getCardStyles(theme)}
          onClick={onWaiveCoverageClick}
        >
          <Stack gap="sm">
            <ShoppingBagXIcon style={{ justifyContent: 'start' }} />
            <Text>Waive coverage</Text>
          </Stack>
        </Card>
      </Group>
    </>
  );
};
