import {
  NavigationProgress,
  NavigationProgressProps,
} from '@mantine/nprogress';

import classNames from './NavigationProgress.module.scss';

export const ZorroNavigationProgress = ({
  stepInterval = 250,
  ...props
}: NavigationProgressProps) => {
  return (
    <NavigationProgress
      {...props}
      className={classNames.navigationProgress}
      stepInterval={stepInterval}
    />
  );
};
