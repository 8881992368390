/* eslint-disable no-console,import-x/no-named-as-default-member */
import dayjs, { Dayjs } from 'dayjs';
import dayjsCustomParseFormat from 'dayjs/plugin/customParseFormat';
import dayjsIsBetween from 'dayjs/plugin/isBetween';
import dayjsIsSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import dayjsIsSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import dayjsLocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjsMinMax from 'dayjs/plugin/minMax';
import dayjsTimezone from 'dayjs/plugin/timezone';
import dayjsUTC from 'dayjs/plugin/utc';

import {
  EN_LOCALE_DATE_FORMAT,
  EN_LOCALE_DATE_SHORT_YEAR_FORMAT,
  EN_LOCALE_DATE_TIME_FORMAT,
  ISO_DATE_FORMAT,
  ISO_DATE_TIME_FORMAT,
  MONTH_DAY_EN_LOCALE_DATE_FORMAT,
  NATIVE_DATE_ISO_DATE_TIME_FORMAT,
  PERMISSIVE_EN_LOCALE_DATE_FORMATS_ARRAY,
} from './date.consts';
import { toISOString } from './date.utils';

export function configureDayjs() {
  dayjs.extend(dayjsUTC);
  dayjs.extend(dayjsTimezone);
  dayjs.extend(dayjsLocalizedFormat);
  dayjs.extend(dayjsCustomParseFormat);
  dayjs.extend(dayjsIsSameOrBefore);
  dayjs.extend(dayjsIsSameOrAfter);
  dayjs.extend(dayjsIsBetween);
  dayjs.extend(dayjsMinMax);

  dayjs.locale('en');
  // dayjs.tz.setDefault(TZ_PST8PDT);
}

configureDayjs();

export type DateUtilInstance = Dayjs;

/*
 Basic
 *********************************************************/

export function getDateUtil(): typeof dayjs {
  return dayjs;
}

export function getNow(): DateUtilInstance {
  const dateUtil = getDateUtil();
  return dateUtil();
}

export function getToday(): DateUtilInstance {
  return getNow().startOf('day');
}

export function getNowAsDate(): Date {
  return getNow().toDate();
}

/*
 Parse
 *********************************************************/

export function parseDateEnLocale(date: string): DateUtilInstance {
  const dateUtil = getDateUtil();
  return dateUtil(date, EN_LOCALE_DATE_FORMAT, true);
}

/* ⚠️ use only for parsing input of CSV/Excel files; internally we want to enforce the proper EN Locale format */
export function parseDateEnLocalePermissive(date: string): DateUtilInstance {
  const dateUtil = getDateUtil();
  return dateUtil(date, PERMISSIVE_EN_LOCALE_DATE_FORMATS_ARRAY, true);
}

export function parseDateISO(date: string): DateUtilInstance {
  const dateUtil = getDateUtil();
  return dateUtil(date, ISO_DATE_FORMAT, true);
}

export function parseDateTimeISO(dateTime: string): DateUtilInstance {
  const dateUtil = getDateUtil();

  // we use strict mode to ensure the date time is valid
  const parsedDateTime = dateUtil(
    dateTime,
    [NATIVE_DATE_ISO_DATE_TIME_FORMAT, ISO_DATE_TIME_FORMAT],
    true
  );
  if (!parsedDateTime.isValid()) {
    return parsedDateTime;
  }
  // now that we know the date time is valid,
  // we convert the string to a DateUtil object in local time
  return dateUtil(dateTime);
}

export function parseDate(date: Date): DateUtilInstance {
  const dateUtil = getDateUtil();

  const parsedDate = dateUtil(date);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const isTestRunner = typeof jest !== 'undefined';
  if (!isTestRunner && !parsedDate.isValid()) {
    console.groupCollapsed('ERROR: Date object is invalid');
    console.log('provided', date);
    console.log('parsed as', parsedDate);
    console.groupEnd();
  }

  return parsedDate;
}

export function parseDateUnknown(
  date: Date | DateUtilInstance | string
): DateUtilInstance {
  const dateUtil = getDateUtil();

  let dateString = '';
  switch (true) {
    case typeof date === 'string': {
      dateString = date;
      break;
    }
    case date instanceof Date: {
      dateString = toISOString(date);
      break;
    }
    case dateUtil.isDayjs(date): {
      dateString = date.format();
      break;
    }
  }

  const parsedDate = dateUtil(
    dateString,
    [
      NATIVE_DATE_ISO_DATE_TIME_FORMAT,
      EN_LOCALE_DATE_FORMAT,
      EN_LOCALE_DATE_SHORT_YEAR_FORMAT,
      EN_LOCALE_DATE_TIME_FORMAT,
      ISO_DATE_FORMAT,
      ISO_DATE_TIME_FORMAT,
    ],
    true
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const isTestRunner = typeof jest !== 'undefined';
  if (!isTestRunner && !parsedDate.isValid()) {
    console.groupCollapsed('ERROR: date is invalid');
    console.log('provided', date);
    console.log('parsed as', dateString);
    console.groupEnd();
  }

  return parsedDate;
}

/*
 Getters
 *********************************************************/

export function getFirstDayOfMonthBeforeDate(
  date: Date | DateUtilInstance | string
) {
  const parsedDate = parseDateUnknown(date);
  return parsedDate.date() === 1
    ? parsedDate.subtract(1, 'month')
    : parseDateUnknown(date).startOf('month');
}

export function getFirstDayOfMonthAfterDate(date: string) {
  const parsedDate = parseDateUnknown(date);
  return parsedDate.add(1, 'month').startOf('month');
}

export function getFirstDayOfMonthEqualToOrAfterDate(
  date: Date | DateUtilInstance | string
) {
  const parsedDate = parseDateUnknown(date);
  return parsedDate.date() === 1
    ? parsedDate
    : parsedDate.startOf('month').add(1, 'month');
}

/*
 Display
 *********************************************************/

export function formatDateISO(date: Date | DateUtilInstance | string): string {
  return parseDateUnknown(date).format(ISO_DATE_FORMAT);
}

export function formatDateTimeISO(
  date: Date | DateUtilInstance | string
): string {
  return parseDateUnknown(date).format(ISO_DATE_TIME_FORMAT);
}

export function formatDateEnLocale(
  date: Date | DateUtilInstance | string
): string {
  return parseDateUnknown(date).format(EN_LOCALE_DATE_FORMAT);
}

export function formatDateEnLocaleShortYear(
  date: Date | DateUtilInstance | string
): string {
  return parseDateUnknown(date).format(EN_LOCALE_DATE_SHORT_YEAR_FORMAT);
}

export function formatDateEnLocaleMonthDay(
  date: Date | DateUtilInstance | string
): string {
  return parseDateUnknown(date).format(MONTH_DAY_EN_LOCALE_DATE_FORMAT);
}

export function formatDateTimeEnLocale(
  date: Date | DateUtilInstance | string
): string {
  return parseDateUnknown(date).format(EN_LOCALE_DATE_TIME_FORMAT);
}

export function formatDateEnLocaleRange(
  firstDate?: Date | DateUtilInstance | string,
  secondDate?: Date | DateUtilInstance | string
): string {
  const PLACEHOLDER = '*';
  if (firstDate && secondDate) {
    return `${formatDateEnLocale(firstDate)} - ${formatDateEnLocale(
      secondDate
    )}`;
  }
  if (firstDate) {
    return `${formatDateEnLocale(firstDate)} - ${PLACEHOLDER}`;
  }
  return `${PLACEHOLDER} - ${PLACEHOLDER}`;
}

/*
 Parse + Display
 *********************************************************/

export function getTodayISO(): string {
  return formatDateISO(getNow());
}

export function parseDateISOThenFormatEnLocale(date: string): string {
  return formatDateEnLocale(parseDateISO(date));
}

export function parseDateISOThenFormatEnLocaleShortYear(date: string): string {
  return formatDateEnLocaleShortYear(parseDateISO(date));
}

export function optionallyFormatDateTimeISO(
  value: Date | DateUtilInstance | string | null | undefined
) {
  return value === undefined || value === null
    ? value
    : formatDateTimeISO(value);
}

/*
 Other
 *********************************************************/

// fixme: domain specific functions should reside in their bounded context
export function compareDatesUnboundedIfNull(
  firstDate: Date | DateUtilInstance | string | null | undefined,
  secondDate: Date | DateUtilInstance | string | null | undefined,
  isPeriodBeginning: boolean
): number {
  // this method compares two dates under the logic that null represents negative or positive infinity
  // isPeriodBeginning is a flag that indicates that null is negative infinity, i.e. the earliest date imaginable
  if (!firstDate && !secondDate) {
    return 0;
  }

  if (firstDate === null || firstDate === undefined) {
    return isPeriodBeginning ? -1 : 1;
  }

  if (secondDate === null || secondDate === undefined) {
    return isPeriodBeginning ? 1 : -1;
  }

  return Math.sign(
    parseDateUnknown(firstDate).diff(parseDateUnknown(secondDate))
  );
}

// fixme: domain specific functions should reside in their bounded context
export function dateOfBirthToAgeOnEnrollment(
  dateOfBirthDateISO: string
): number {
  const dob = parseDateISO(dateOfBirthDateISO);
  const startOfNextMonth = getNow().endOf('month').add(1, 'day');
  return startOfNextMonth.diff(dob, 'year');
}

// fixme: domain specific functions should reside in their bounded context
export function earliestAllowedNonDependentBirthDate(): Date {
  return getNow().startOf('day').toDate();
  // return getNow().startOf('day').subtract(18, 'years').toDate(); // TODO: https://app.clickup.com/t/869515rgk
}

// fixme: domain specific functions should reside in their bounded context
export function latestAllowedNonDependentBirthDate(): Date {
  return getNow().startOf('day').subtract(120, 'years').toDate();
}
