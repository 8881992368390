type Props = {
  withTitle?: boolean;
};

export const ZorroLogo = ({ withTitle = true }: Props) => {
  if (!withTitle) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="24"
        fill="none"
      >
        <path
          fill="#8033EF"
          d="M16.997 7.6V5.36a.581.581 0 0 0-.568-.593H9.685c-.358 0-.65-.303-.65-.677V.254c0-.14-.108-.254-.243-.254H4.178C2.563 0 1.254 1.366 1.254 3.05v2.303c0 .14.109.254.243.254h6.557c.593 0 .876.761.436 1.179l-7.435 7.037A3.416 3.416 0 0 0 0 16.303v2.289c0 .327.255.592.568.592h6.786c.358 0 .65.304.65.677v3.885c0 .14.108.254.243.254h4.732c1.57 0 2.84-1.328 2.84-2.962V18.65c0-.14-.109-.254-.243-.254H8.633c-.52 0-.767-.668-.38-1.03l7.692-7.28A3.416 3.416 0 0 0 17 7.607l-.003-.006Z"
        />
      </svg>
    );
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="85" height="24" fill="none">
      <path
        fill="#8033EF"
        d="M16.759 7.738V5.567a.568.568 0 0 0-.56-.574h-6.65a.649.649 0 0 1-.64-.656V.622a.243.243 0 0 0-.24-.246H4.12C2.527.376 1.236 1.7 1.236 3.33v2.231c0 .136.108.246.24.246h6.465c.585 0 .863.738.43 1.142l-7.33 6.817A3.281 3.281 0 0 0 0 16.169v2.216c0 .317.251.574.56.574h6.69c.354 0 .641.294.641.656v3.763c0 .136.108.246.24.246h4.666c1.548 0 2.8-1.286 2.8-2.87v-2.312a.243.243 0 0 0-.24-.246H8.512c-.513 0-.756-.648-.375-.998l7.584-7.051a3.281 3.281 0 0 0 1.04-2.403l-.002-.006Z"
      />
      <path
        fill="#1A1721"
        d="M53.011 11.942c0 4.156-2.982 7.238-7.196 7.238-4.213 0-7.195-3.082-7.195-7.238 0-4.156 2.982-7.21 7.195-7.21 4.214 0 7.196 3.082 7.196 7.21Zm-2.519 0c0-3.344-2.182-5.154-4.68-5.154-2.496 0-4.679 1.81-4.679 5.154 0 3.345 2.183 5.185 4.68 5.185s4.68-1.81 4.68-5.185ZM85.001 11.942c0 4.156-2.982 7.238-7.195 7.238s-7.196-3.082-7.196-7.238c0-4.156 2.983-7.21 7.196-7.21 4.213 0 7.195 3.082 7.195 7.21Zm-2.519 0c0-3.344-2.182-5.154-4.68-5.154-2.496 0-4.679 1.81-4.679 5.154 0 3.345 2.183 5.185 4.68 5.185s4.68-1.81 4.68-5.185ZM61.247 4.99h-2.442c-.86 0-1.556.71-1.556 1.589v.574c0 .215-.168.39-.378.396h-1.677a.355.355 0 0 0-.35.359v10.848c0 .099.076.178.173.178h2.091c.194 0 .35-.161.35-.36V7.883h.004a.389.389 0 0 1 .389-.362h3.396c.193 0 .35-.16.35-.359V5.35a.355.355 0 0 0-.35-.359ZM69.363 4.99h-2.441c-.861 0-1.557.71-1.557 1.589v.574c0 .215-.168.39-.378.396H63.31a.355.355 0 0 0-.35.359v10.848c0 .099.077.178.173.178h2.092c.193 0 .35-.161.35-.36V7.883h.003a.389.389 0 0 1 .389-.362h3.396c.194 0 .35-.16.35-.359V5.35a.355.355 0 0 0-.35-.359ZM36.747 7.964c.312-.379.483-.86.483-1.357V5.352a.352.352 0 0 0-.348-.356H26.017c-.767 0-1.388.636-1.388 1.422v.69a.35.35 0 0 0 .226.33h8.926c.151 0 .223.193.11.297l-9.083 8.25c-.312.379-.483.86-.483 1.357v1.255c0 .195.155.356.348.356h11.12c.766 0 1.387-.636 1.387-1.422v-.704a.352.352 0 0 0-.348-.356h-9.05c-.151 0-.223-.192-.11-.297l9.075-8.213v.003Z"
      />
    </svg>
  );
};
