import { BenefitType } from '@zorro/clients';
import {
  ADandDIcon,
  AccidentIcon,
  AssistanceProgramMentalHealthIcon,
  CriticalIllnessIcon,
  DentalIcon,
  FinancialAndLegalHealthIcon,
  FraudProtectionIcon,
  FsaIcon,
  HospitalIcon,
  LifeIcon,
  OtherIcon,
  PetIcon,
  VisionIcon,
} from '@zorro/shared/assets';
import { OtherCoveragesAflac, OtherCoveragesMetlife } from '@zorro/types';

import { ExtendedSupplementalType } from './enrollmentSummary.types';

export const determineIcon = (supplementalType: ExtendedSupplementalType) => {
  switch (supplementalType) {
    case BenefitType.SUPPLEMENTAL_DENTAL: {
      return <DentalIcon />;
    }
    case BenefitType.SUPPLEMENTAL_VISION: {
      return <VisionIcon />;
    }
    case BenefitType.SUPPLEMENTAL_LIFE: {
      return <LifeIcon />;
    }
    case BenefitType.SUPPLEMENTAL_ACCIDENTAL_DEATH_AND_DISMEMBERMENT: {
      return <ADandDIcon />;
    }
    case BenefitType.SUPPLEMENTAL_CRITICAL_ILLNESS: {
      return <CriticalIllnessIcon />;
    }
    case BenefitType.SUPPLEMENTAL_ACCIDENT: {
      return <AccidentIcon />;
    }
    case BenefitType.SUPPLEMENTAL_HOSPITAL: {
      return <HospitalIcon />;
    }
    case OtherCoveragesMetlife.PET: {
      return <PetIcon />;
    }
    case OtherCoveragesAflac.FRAUD_PROTECTION: {
      return <FraudProtectionIcon />;
    }
    case BenefitType.SUPPLEMENTAL_FLEXIBLE_SPENDING_ACCOUNT: {
      return <FsaIcon />;
    }
    case OtherCoveragesAflac.FINANCIAL_AND_LEGAL_HEALTH: {
      return <FinancialAndLegalHealthIcon />;
    }
    case OtherCoveragesAflac.ASSISTANCE_PROGRAM_FOR_MENTAL_HEALTH: {
      return <AssistanceProgramMentalHealthIcon />;
    }
    case OtherCoveragesAflac.OTHER: {
      return <OtherIcon />;
    }
    default: {
      return null;
    }
  }
};
