import { Group, GroupProps } from '@mantine/core';
import { ForwardedRef, forwardRef } from 'react';

export type IGroupProps = GroupProps & {
  ref: ForwardedRef<HTMLDivElement>;
};

export const ZorroGroup = forwardRef(
  ({ ...props }: IGroupProps, ref: ForwardedRef<HTMLDivElement>) => {
    return <Group {...props} ref={ref} />;
  }
);

ZorroGroup.displayName = 'Group';
