import { Stack } from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { InsuredPeopleDto, OnboardingPeriodDto } from '@zorro/clients';
import { exportEmployeePlanList, useMonolithQuery } from '@zorro/shared/utils';
import {
  ActionIcon,
  Autocomplete,
  Button,
  Collapse,
  CurrencyInput,
  FormErrorMessage,
  Grid,
  Group,
  Icon,
  MultiSelect,
  Space,
  Text,
  TextInput,
} from '@zorro/zorro-ui-design';
import { KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { CarrierInput } from '../CarrierInput';
import { CheckmarkBoxWrapper } from '../InsuredForm/CheckmarkBoxWrapper';
import {
  MedicalPlanFormFields,
  getApplicantsFromWhoIsEnrolled,
} from './MedicalPlansFormUtils';

type Props = {
  medicalPlanForm: UseFormReturn<MedicalPlanFormFields>;
  handleDeleteDependent: (index: number) => void;
  insuredIdToNameMap: Map<string, string>;
  onboardingPeriod?: OnboardingPeriodDto;
  hasMoreThanOnePlan: boolean;
  whoIsEnrolledOptions: string[];
  insured: InsuredPeopleDto;
  isSubmitting: boolean;
  index: number;
  employeeId: string;
};

export const MedicalPlanFormInputs = ({
  handleDeleteDependent,
  insuredIdToNameMap,
  hasMoreThanOnePlan,
  whoIsEnrolledOptions,
  onboardingPeriod,
  medicalPlanForm,
  isSubmitting,
  insured,
  index,
  employeeId,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { data: employee } = useMonolithQuery({
    method: 'employeesControllerFindOne',
    params: [employeeId],
  });

  const { control, watch, formState, setValue } = medicalPlanForm;
  const { isValid, errors } = formState;

  const plan = watch(`plans.${index}`);
  const { whoIsEnrolled, planId } = plan;

  const isFilledOut = isValid && Object.keys(errors).length === 0;

  const applicants =
    onboardingPeriod && employee
      ? getApplicantsFromWhoIsEnrolled(
          insured,
          whoIsEnrolled,
          onboardingPeriod.targetEnrollmentDate,
          employee.email
        )
      : [];

  const { data: planSearchMode } = useMonolithQuery({
    method: 'majorMedicalControllerGetPlanSearchMode',
    params: [employeeId, onboardingPeriod?.targetEnrollmentDate],
  });

  const { data: medicalPlans, isLoading: isLoadingPlans } = useMonolithQuery({
    method: 'majorMedicalControllerSearchMedicalPlansForOnboardingPeriod',
    params: onboardingPeriod &&
      planSearchMode && [
        onboardingPeriod.id,
        { applicants, plansDataProvider: planSearchMode.mode },
      ],
    enabled: applicants.length > 0,
  });

  const [allPlanIdsRecord, carrierNames] = useMemo(
    () => [
      medicalPlans?.reduce<Record<string, string>>(
        (record, { id, name, carrierName }) => {
          record[id] = `${carrierName}, ${name}`;

          return record;
        },
        {}
      ) || {},
      [...new Set<string>(medicalPlans?.map(({ carrierName }) => carrierName))],
    ],
    [medicalPlans]
  );

  useEffect(() => {
    if (medicalPlans) {
      const foundPlan = medicalPlans.find(({ id }) => id === planId);
      if (!foundPlan) {
        return;
      }

      const {
        carrierName = '',
        name,
        premium,
        deductible,
        planMarket,
        hsaEligible,
        maxOutOfPocket,
        benefitsSummaryUrl,
      } = foundPlan;

      setValue(`plans.${index}.carrier`, carrierName);
      setValue(`plans.${index}.planName`, name || '');
      setValue(`plans.${index}.premium`, premium || 0);
      setValue(`plans.${index}.isHsaEligible`, hsaEligible);
      setValue(`plans.${index}.maxOutOfPocket`, maxOutOfPocket);
      setValue(`plans.${index}.benefitsSummaryUrl`, benefitsSummaryUrl);
      setValue(`plans.${index}.deductible`, deductible);
      setValue(`plans.${index}.planMarket`, planMarket);
    }
  }, [medicalPlans, planId, index, setValue]);

  const handleArrowClick = () => {
    setIsOpen((prev) => !prev);
  };

  const handleDownloadPlans = () => {
    if (medicalPlans) {
      exportEmployeePlanList(
        medicalPlans,
        `${insured.employee.firstName} ${insured.employee.lastName}`
      );
    }
  };

  const shouldOpen = isOpen && !isSubmitting;

  return (
    <CheckmarkBoxWrapper
      hasErrors={false}
      isFilledOut={isFilledOut}
      isOpen={shouldOpen}
      w="100%"
    >
      <Group justify="space-between">
        <Text fw="600">Medical plan {index + 1}</Text>
        <ActionIcon
          variant="transparent"
          onClick={handleArrowClick}
          onKeyDown={(event) => {
            if (event.code === 'Space' || event.code === 'Enter') {
              handleArrowClick();
            }
          }}
        >
          {shouldOpen ? (
            <Icon icon={IconChevronUp} aria-label="Collapse employee form" />
          ) : (
            <Icon icon={IconChevronDown} aria-label="Expand employee form" />
          )}
        </ActionIcon>
      </Group>

      <Collapse in={shouldOpen}>
        <Space h="xl" />
        <Grid>
          <Grid.Col span={12}>
            <Controller
              name={`plans.${index}.whoIsEnrolled`}
              control={control}
              render={({ field: { value, ...rest } }) => (
                <MultiSelect
                  {...rest}
                  variant="rectangle"
                  size="md"
                  clearable={false}
                  value={value}
                  data={whoIsEnrolledOptions.map((id) => ({
                    label: insuredIdToNameMap.get(id) || '',
                    value: id,
                  }))}
                  label="Who is enrolled"
                  placeholder="Who is enrolled"
                  required
                />
              )}
            />

            <FormErrorMessage
              fieldName={`plans.${index}.whoIsEnrolled`}
              errors={errors}
            />
          </Grid.Col>

          <Grid.Col>
            <Controller
              name={`plans.${index}.planId`}
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  size="md"
                  placeholder="Type plan ID"
                  disabled={isLoadingPlans}
                  // eslint-disable-next-line id-length
                  labelProps={{ w: '100%' }}
                  label={
                    <Group justify="space-between">
                      <Text size="sm">Plan ID</Text>
                      <Button
                        disabled={!medicalPlans || isLoadingPlans}
                        onClick={handleDownloadPlans}
                        variant="subtle"
                        size="sm"
                        fw={400}
                        p={0}
                      >
                        Download all plans
                      </Button>
                    </Group>
                  }
                  data={Object.keys(allPlanIdsRecord)}
                  renderOption={({ option: { value } }) => (
                    <Stack gap="0.125rem">
                      <Text>{value}</Text>
                      <Text c="zorroGray.4">{allPlanIdsRecord[value]}</Text>
                    </Stack>
                  )}
                />
              )}
            />

            <FormErrorMessage
              fieldName={`plans.${index}.planId`}
              errors={errors}
            />
          </Grid.Col>

          <Grid.Col>
            <CarrierInput
              carriers={carrierNames.length > 0 ? carrierNames : undefined}
              name={`plans.${index}.carrier`}
              control={control}
              size="md"
              shouldShowError
              isEditable
              isRequired
            />
          </Grid.Col>

          <Grid.Col span={6}>
            <Controller
              name={`plans.${index}.planName`}
              control={control}
              render={({ field: { ...rest } }) => (
                <TextInput
                  {...rest}
                  label="Plan name"
                  placeholder="Plan name"
                  required
                />
              )}
            />

            <FormErrorMessage
              fieldName={`plans.${index}.planName`}
              errors={errors}
            />
          </Grid.Col>

          <Grid.Col span={6}>
            <Controller
              name={`plans.${index}.premium`}
              control={control}
              render={({ field: { ...rest } }) => (
                <CurrencyInput
                  {...rest}
                  label="Premium"
                  placeholder="$"
                  decimalScale={2}
                  size="md"
                  required
                />
              )}
            />

            <FormErrorMessage
              fieldName={`plans.${index}.premium`}
              errors={errors}
            />
          </Grid.Col>

          {hasMoreThanOnePlan && (
            <Grid.Col style={{ textAlign: 'right' }}>
              <Button
                variant="subtle"
                p={1}
                onClick={() => handleDeleteDependent(index)}
                onKeyDown={(event: KeyboardEvent<HTMLButtonElement>) => {
                  if (event.code === 'Space' || event.code === 'Enter') {
                    handleDeleteDependent(index);
                  }
                }}
              >
                Delete
              </Button>
            </Grid.Col>
          )}
        </Grid>
      </Collapse>
    </CheckmarkBoxWrapper>
  );
};
