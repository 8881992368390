import { Box, IBoxProps } from '@zorro/zorro-ui-design';

type OutlineCardProps = IBoxProps;

export const OutlineCard = ({
  px = 'xl',
  py = 'xl',
  style,
  ...props
}: OutlineCardProps) => {
  return (
    <Box
      px={px}
      py={py}
      bg="zorroWhite.0"
      style={(theme) => ({
        border: `1px solid ${theme.colors.zorroGray[6]}`,
        borderRadius: theme.radius.md,
        ...style,
      })}
      {...props}
    />
  );
};
