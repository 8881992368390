/* eslint-disable unicorn/filename-case */
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useMonolithQuery } from '@zorro/shared/utils';
import Decimal from 'decimal.js';
import { useEffect } from 'react';

import { useMonolithMutation } from '../hooks';
import {
  PremiumAmountFormFields,
  premiumAmountSchema,
} from './PremiumAmountUtils';

type Props =
  | {
      mode: 'fetch';
      employeeId: string;
      onboardingPeriodId?: string;
    }
  | {
      mode: 'data';
      employeeMonthlyContribution: number;
      premium: number;
      employerMonthlyContribution: number;
    };

export const usePremiumAmountForm = (props: Props) => {
  const { mutate: mutateMajorMedical } = useMonolithMutation({
    method: 'majorMedicalControllerUpsertMajorMedicalPlanByOperations',
  });

  const shouldFetch = props.mode === 'fetch';
  const onboardingPeriodId = shouldFetch ? props.onboardingPeriodId : undefined;
  const employeeId = shouldFetch ? props.employeeId : undefined;

  const { data: onboardingPeriod } = useMonolithQuery({
    method: 'onboardingPeriodsControllerFindOne',
    params: onboardingPeriodId &&
      employeeId && [employeeId, onboardingPeriodId],
    enabled: shouldFetch,
  });
  const { data: benefits } = useMonolithQuery({
    method: 'benefitsControllerGetBenefits',
    params: onboardingPeriodId && [onboardingPeriodId],
    enabled: shouldFetch,
  });

  const majorMedicalPlan =
    props.mode === 'data' ? props : benefits?.majorMedicalBenefit?.plan;

  const premiumAmountForm = useForm<PremiumAmountFormFields>({
    mode: 'all',
    resolver: yupResolver(premiumAmountSchema),
    defaultValues: {
      employeeMonthlyContribution:
        majorMedicalPlan?.employeeMonthlyContribution,
      premium: majorMedicalPlan?.premium,
    },
  });

  const premium = premiumAmountForm.watch('premium');
  const allowance =
    onboardingPeriod?.allowance ??
    majorMedicalPlan?.employerMonthlyContribution ??
    0;
  const employerMonthlyContribution = Math.min(allowance, premium);

  useEffect(() => {
    if (premium) {
      const employeeMonthlyContribution = Math.max(
        new Decimal(premium).sub(employerMonthlyContribution).toNumber(),
        0
      );
      premiumAmountForm.setValue(
        'employeeMonthlyContribution',
        employeeMonthlyContribution
      );
    }
  }, [employerMonthlyContribution, premium, premiumAmountForm]);

  return {
    premiumAmountForm,
    employerMonthlyContribution,
    onSubmit: async () => {
      if (!onboardingPeriodId || !benefits?.majorMedicalBenefit?.plan)
        return false;

      const data = premiumAmountForm.getValues();

      try {
        await mutateMajorMedical([
          onboardingPeriodId,
          true,
          {
            updateManuallyEnteredMajorMedicalPlan: {
              ...benefits?.majorMedicalBenefit?.plan,
              employeeMonthlyContribution: data.employeeMonthlyContribution,
              premium: data.premium,
              allowance,
            },
          },
        ]);

        return true;
      } catch {
        /* empty */
      }
      return false;
    },
  };
};
