import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';

import { RadixMenuContent } from './RadixMenuContent';
import { RadixMenuDivider } from './RadixMenuDivider';
import { RadixMenuItem } from './RadixMenuItem';
import { RadixMenuLabel } from './RadixMenuLabel';
import { RadixMenuSubcontent } from './RadixMenuSubcontent';
import { RadixMenuSubtrigger } from './RadixMenuSubtrigger';
import { RadixMenuTrigger } from './RadixMenuTrigger';

export function RadixMenu(props: DropdownMenuPrimitive.DropdownMenuProps) {
  return <DropdownMenuPrimitive.Root {...props} />;
}

RadixMenu.Trigger = RadixMenuTrigger;
RadixMenu.Item = RadixMenuItem;
RadixMenu.Label = RadixMenuLabel;
RadixMenu.Divider = RadixMenuDivider;
RadixMenu.Content = RadixMenuContent;
RadixMenu.Submenu = DropdownMenuPrimitive.Sub;
RadixMenu.Subtrigger = RadixMenuSubtrigger;
RadixMenu.Subcontent = RadixMenuSubcontent;
