import { Gender } from '@zorro/clients';
import { formatDateEnLocale } from '@zorro/shared/formatters';
import { YesNo, insuredSubtypeLabelConfig } from '@zorro/types';

import { InsuredDependentFormFields } from '../DependentsFormInputs';
import { InsuredSpouseFormFields } from '../SpouseFormInputs';
import { ReadonlyValuesGrid } from './ReadonlyValuesGrid';

type Props = {
  fields: InsuredSpouseFormFields | InsuredDependentFormFields;
};

export const ReadonlyDependentForm = ({ fields }: Props) => {
  const {
    firstName,
    lastName,
    dateOfBirth,
    subtype,
    gender,
    isSmoker,
    isPregnant,
    ssn,
    residentialAddress,
  } = fields;

  return (
    <ReadonlyValuesGrid
      items={[
        { id: 'employee-info', title: 'Employee info' },
        {
          id: 'firstName',
          label: 'First name',
          value: firstName,
        },
        {
          id: 'lastName',
          label: 'Last name',
          value: lastName,
        },
        {
          id: 'dateOfBirth',
          label: 'Date of birth',
          value: formatDateEnLocale(dateOfBirth),
        },
        {
          id: 'relationship',
          label: 'Relationship',
          value: subtype && insuredSubtypeLabelConfig[subtype],
        },
        {
          id: 'gender',
          label: 'Gender',
          value: gender === Gender.MALE ? 'Male' : 'Female',
        },
        {
          id: 'smoker',
          label: 'Smoker',
          value: isSmoker === YesNo.YES ? 'Yes' : 'No',
        },
        {
          id: 'pregnant',
          label: 'Pregnant',
          value: isPregnant === YesNo.YES ? 'Yes' : 'No',
        },
        {
          id: 'ssn',
          label: 'SSN',
          value: ssn,
          isSensitive: true,
        },
        {
          id: 'residential-address',
          label: 'Residential address',
          value: residentialAddress,
          span: 12,
        },
      ]}
    />
  );
};
