/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AnticipatedCareLevel {
  VERY_LOW = 'VERY_LOW',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  VERY_HIGH = 'VERY_HIGH',
  ESSENTIAL = 'ESSENTIAL',
  MODERATE = 'MODERATE',
  ABOVE_AVERAGE = 'ABOVE_AVERAGE',
  EXTENSIVE = 'EXTENSIVE',
}
