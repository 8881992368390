import { Accordion, AccordionProps } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';

import { ZorroIcon } from '../Icon';
import classNames from './Accordion.module.scss';
import { ZorroAccordionControl } from './AccordionControl';
import { ZorroAccordionItem } from './AccordionItem';
import { ZorroAccordionPanel } from './AccordionPanel';

type Props<T extends boolean> = AccordionProps<T>;

export const ZorroAccordion = <T extends boolean>({
  children,
  ...props
}: Props<T>) => {
  return (
    <Accordion
      chevron={<ZorroIcon icon={IconChevronDown} />}
      chevronSize={24}
      classNames={classNames}
      {...props}
    >
      {children}
    </Accordion>
  );
};

ZorroAccordion.Item = ZorroAccordionItem;
ZorroAccordion.Control = ZorroAccordionControl;
ZorroAccordion.Panel = ZorroAccordionPanel;
