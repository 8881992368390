import { KeyboardEvent } from 'react';

export function handleKeyDown<T>(
  event: KeyboardEvent<T>,
  onKeyDown: () => void
): void {
  if (event.code === 'Space' || event.code === 'Enter') {
    event.preventDefault();
    onKeyDown();
  }
}
