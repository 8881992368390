import pluralize from 'pluralize';

/* eslint perfectionist/sort-objects: "error" */
export const SUCCESS_MESSAGES = {
  AGENCY_DELETE_SUCCESS_MESSAGE: 'Agency deleted successfully!',
  AGENCY_UPDATE_SUCCESS_MESSAGE: 'Agency updated successfully!',
  BENEFIT_RESET_SUCCESS_MESSAGE: 'Benefit reset successfully!',
  COMPANY_EMAIL_UPDATE_SUCCESS_MESSAGE: 'Company email updated successfully!',
  EMPLOYEE_ADD_SUCCESS_MESSAGE: 'Employee added successfully!',
  EMPLOYEE_HEALTH_PLAN_UPDATE_SUCCESS_MESSAGE:
    'Employee health plan updated successfully!',
  EMPLOYEE_INFO_UPDATE_SUCCESS_MESSAGE: 'Employee info updated successfully!',
  EMPLOYEE_TERMINATED_SUCCESSFULLY_MESSAGE: 'Employee terminated successfully!',
  EMPLOYER_LOGO_WAS_UPLOADED_MESSAGE: 'Employer logo was uploaded!',
  FAMILY_MEMBER_ADD_SUCCESS_MESSAGE: 'Family member was added successfully!',
  FAMILY_MEMBER_REMOVE_SUCCESS_MESSAGE:
    'Family member was removed successfully!',
  getAgencyCreatedMessage: (agencyName: string) =>
    `New agency "${agencyName}" was created.`,
  getEmailSentSuccessfullyMessage: (count: number) =>
    `${pluralize('Email', count)} sent successfully!`,
  getUserCreatedMessage: (firstName: string, lastName: string) =>
    `New user ${firstName} ${lastName} was created.`,
  PERSONAL_DETAILS_UPDATE_SUCCESS_MESSAGE:
    'Personal details updated successfully!',
  QUOTE_CREATION_SUCCESS_MESSAGE: 'Quote created successfully!',
  QUOTE_DELETE_SUCCESS_MESSAGE: 'Quote deleted successfully!',
  SAVED_SUCCESSFULLY_MESSAGE: 'Saved successfully!',
  SET_EMPLOYER_TO_PROSPECT_SUCCESS_MESSAGE: 'Set employer to prospect',
  SUPPORT_INFORMATION_SENT_SUCCESS: 'Support information was sent successfully',
  USER_UPDATE_SUCCESS_MESSAGE: 'User updated.',
};
