import { Group } from '@mantine/core';
import { Dropzone, DropzoneProps } from '@mantine/dropzone';

import classNames from './Dropzone.module.scss';

export type IDropzoneProps = DropzoneProps & {
  minHeight?: number;
};

export function ZorroDropzone({
  children,
  disabled,
  minHeight = 140,
  ...props
}: IDropzoneProps) {
  return (
    <Dropzone
      classNames={classNames}
      data-disabled={disabled}
      disabled={disabled}
      {...props}
      bg="white"
    >
      <Group
        align="center"
        gap="xl"
        mih={minHeight}
        style={{ pointerEvents: 'none' }}
      >
        {children}
      </Group>
    </Dropzone>
  );
}
