import { CompanySetupForm, useCompanySetupForm } from '../../CompanySetupForm';
import {
  CompanySetupTabType,
  CompanySetupTabWrapper,
} from '../CompanySetupTabWrapper';

const isFinalizationMode = true;

type Props = {
  employerId: string;
};

export const CompanySetupTab = ({ employerId }: Props) => {
  const { companySetupForm, onSubmit } = useCompanySetupForm(
    employerId,
    isFinalizationMode
  );
  const { formState } = companySetupForm;

  return (
    <CompanySetupTabWrapper
      onCancel={() => companySetupForm.reset()}
      tab={CompanySetupTabType.COMPANY_SETUP}
      employerId={employerId}
      formState={formState}
      onSubmit={onSubmit}
    >
      <CompanySetupForm
        isFinalizationMode={isFinalizationMode}
        companySetupForm={companySetupForm}
      />
    </CompanySetupTabWrapper>
  );
};
