import { ZorroSetupForm, useZorroSetupForm } from '../../ZorroSetupForm';
import { AddEmployerStep } from '../AddEmployerStep';
import {
  AddEmployerStepType,
  HandleStepSubmit,
} from '../AddEmployerStep/AddEmployerUtils';

type Props = {
  employerId: string;
  isDon?: boolean;
};

const isFinalizationMode = true;

export const AddEmployerZorroSetupStep = ({
  employerId,
  isDon = false,
}: Props) => {
  const { zorroSetupForm, allAgencies, onSubmit } = useZorroSetupForm({
    employerId,
    isFinalizationMode,
  });

  const handleStepSubmit: HandleStepSubmit = async () => {
    if (await onSubmit()) {
      return { navigate: true, employerId };
    }

    return { navigate: false };
  };

  const isSubmitDisabled = !zorroSetupForm.formState.isValid;

  return (
    <AddEmployerStep
      subtitle="will not be included in the employer invitation"
      step={AddEmployerStepType.ZORRO_SETUP}
      isSubmitDisabled={isSubmitDisabled}
      title="Add Zorro setup info"
      onSubmit={handleStepSubmit}
      isFinalizationMode={false}
      employerId={employerId}
      secondaryLabel="Skip"
      isDon={isDon}
    >
      <ZorroSetupForm
        zorroSetupForm={zorroSetupForm}
        allAgencies={allAgencies}
        isFinalizationMode={isFinalizationMode}
      />
    </AddEmployerStep>
  );
};
