import { getCarrierLogoUrl } from '@zorro/shared/utils';
import { Text } from '@zorro/zorro-ui-design';
import Image from 'next/image';
import { useState } from 'react';

type CarrierLogoNextProps = typeof Image.defaultProps & {
  plan: {
    logoUrl?: string | undefined;
    carrierName: string;
  };
};

export const CarrierLogoNext = ({ plan, ...props }: CarrierLogoNextProps) => {
  const [hasImageError, setHasImageError] = useState(false);

  const hasDimensions = !!props.width || !!props.height;
  const src = hasImageError
    ? plan.logoUrl
    : getCarrierLogoUrl(plan.carrierName);

  if (!src) {
    return (
      <Text fw={600} style={{ whiteSpace: 'break-spaces' }}>
        {plan.carrierName}
      </Text>
    );
  }

  return (
    <Image
      src={src}
      alt={plan?.carrierName || 'carrier'}
      fill={!hasDimensions}
      style={{
        objectFit: 'contain',
      }}
      onError={() => {
        setHasImageError(true);
      }}
      {...props}
    />
  );
};
