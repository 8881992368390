/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Plan metal grouping (e.g. platinum, gold, silver, etc)
 */
export enum TierLevel {
  PLATINUM = 'platinum',
  GOLD = 'gold',
  SILVER = 'silver',
  EXPANDED_BRONZE = 'expanded_bronze',
  BRONZE = 'bronze',
  CATASTROPHIC = 'catastrophic',
  NONE = 'none',
}
