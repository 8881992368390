import { yupResolver } from '@hookform/resolvers/yup';
import {
  VALIDATION_MESSAGES,
  useForm,
  useMonolithQuery,
} from '@zorro/shared/utils';
import { FormErrorMessage, TextInput } from '@zorro/zorro-ui-design';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

import { FormFooter } from '../../../FormFooter';
import { useMonolithMutation } from '../../../hooks';

const idFromEmployerSchema = yup.object({
  idFromEmployer: yup.string().required(VALIDATION_MESSAGES.employeeID),
});

type IdFromEmployerFormFields = yup.InferType<typeof idFromEmployerSchema>;

type Props = {
  employeeId: string;
  onClose: () => void;
};

export const EditIdFromEmployerForm = ({ employeeId, onClose }: Props) => {
  const { tryMutate: mutateEmployeeByAdmin } = useMonolithMutation({
    method: 'employeesControllerUpdateByAdmin',
  });

  const { data: employee } = useMonolithQuery({
    method: 'employeesControllerFindOne',
    params: [employeeId],
  });

  const { control, getValues, formState } = useForm<IdFromEmployerFormFields>({
    mode: 'all',
    resolver: yupResolver(idFromEmployerSchema),
    defaultValues: {
      idFromEmployer: employee?.idFromEmployer || '',
    },
  });

  const { isValid, errors } = formState;

  const handleSubmit = async () => {
    const { idFromEmployer } = getValues();
    const mutateResult = await mutateEmployeeByAdmin([
      employeeId,
      { idFromEmployer },
    ]);

    if (mutateResult.isOk()) {
      onClose();
    }
  };

  return (
    <>
      <Controller
        name="idFromEmployer"
        control={control}
        render={({ field }) => (
          <TextInput
            {...field}
            placeholder="Type employee id"
            label="Employee ID"
            required
          />
        )}
      />

      <FormErrorMessage fieldName="idFromEmployer" errors={errors} />

      <FormFooter
        primaryLabel="Save"
        primaryButtonProps={{
          onClick: handleSubmit,
          disabled: !isValid,
        }}
        secondaryLabel="Cancel"
        secondaryButtonProps={{ onClick: () => onClose() }}
      />
    </>
  );
};
