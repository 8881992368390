import { useAuth } from '@frontegg/nextjs';
import Intercom from '@intercom/messenger-js-sdk';
import { parseDate } from '@zorro/shared/formatters';
import { useMonolithQuery } from '@zorro/shared/utils';
import { useEffect } from 'react';

export const IntercomWidget = () => {
  const { user, isAuthenticated } = useAuth();

  const { data, isLoading } = useMonolithQuery({
    method: 'intercomControllerGenerateHash',
    params: user && [{ email: user.email }],
  });

  useEffect(() => {
    if (
      !isAuthenticated ||
      !user ||
      isLoading ||
      !data?.hash ||
      !process.env.NEXT_PUBLIC_INTERCOM_ID
    ) {
      return;
    }

    const createdAt = user.createdAt
      ? parseDate(user.createdAt).unix()
      : undefined;

    Intercom({
      app_id: process.env.NEXT_PUBLIC_INTERCOM_ID,
      email: user.email,
      name: user.name,
      created_at: createdAt,
      user_hash: data.hash,
    });
  }, [isAuthenticated, user, isLoading, data]);

  return null;
};
