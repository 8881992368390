import { AppShell } from '@mantine/core';
import { ZorroLogo } from '@zorro/shared/assets';
import { useMobileDetector } from '@zorro/shared/utils';
import { Center, Divider, Drawer, Stack } from '@zorro/zorro-ui-design';
import { ReactNode } from 'react';

import { useAppLayout } from './AppLayoutContext';

type Props = {
  children: ReactNode;
};

export const AppLayoutSidebar = ({ children }: Props) => {
  const { isSidebarOpen, toggleSidebar } = useAppLayout();
  const { isMobile } = useMobileDetector();

  if (isMobile) {
    return (
      <Drawer
        styles={{ inner: { width: '20rem' } }}
        contentProps={{ px: 'sm' }}
        bodyProps={{ mt: 0 }}
        onClose={toggleSidebar}
        opened={isSidebarOpen}
        title={
          <Center pl="lg">
            <ZorroLogo />
          </Center>
        }
        position="left"
      >
        <Divider mt="sm" />
        <Stack mt="sm">{children}</Stack>
      </Drawer>
    );
  }

  return (
    <AppShell.Navbar bg="zorroGray.10">
      <Stack mt="sm" ml="sm" mr="0.25rem">
        {children}
      </Stack>
    </AppShell.Navbar>
  );
};
