import { Slider, SliderProps } from '@mantine/core';
import { forwardRef } from 'react';

import classNames from './Slider.module.scss';

export type ISliderProps = SliderProps;

export const ZorroSlider = forwardRef<HTMLDivElement, ISliderProps>(
  (props, ref) => <Slider ref={ref} classNames={classNames} {...props} />
);

ZorroSlider.displayName = 'ZorroSlider';
