export function getFullName<
  T extends { firstName?: string | null; lastName?: string | null }
>(dto: T): string {
  const first = dto.firstName ?? '';
  const last = dto.lastName ?? '';
  return `${first} ${last}`.trim();
}

export function getFullNameOrThrow<
  T extends { firstName?: string | null; lastName?: string | null }
>(dto: T): string {
  if (!dto.firstName && !dto.lastName) {
    throw new Error(
      `Either first or last name are missing: ${JSON.stringify(dto)}`
    );
  }
  const first = dto.firstName ?? '';
  const last = dto.lastName ?? '';
  return `${first} ${last}`.trim();
}
