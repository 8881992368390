import { formatDateISO, getNow } from '@zorro/shared/formatters';

import { exportToCsv } from './exportToCsv';

const fields = [
  { value: 'id' },
  { value: 'name' },
  { value: 'carrierName' },
  { value: 'premium' },
  { value: 'hsaEligible' },
  { value: 'deductible' },
  { value: 'maxOutOfPocket' },
  { value: 'benefitsSummaryUrl' },
  { value: 'familyDrugDeductible' },
  { value: 'familyDrugMoop' },
  { value: 'familyMedicalDeductible' },
  { value: 'familyMedicalMoop' },
  { value: 'gated' },
  { value: 'individualDrugDeductible' },
  { value: 'individualDrugMoop' },
  { value: 'individualMedicalDeductible' },
  { value: 'individualMedicalMoop' },
  { value: 'level' },
  { value: 'planMarket' },
  { value: 'logoUrl' },
  { value: 'planType' },
  { value: 'providers' },
  { value: 'drugCoverages' },
  { value: 'primaryCarePhysician' },
  { value: 'specialist' },
  { value: 'emergencyRoom' },
  { value: 'ambulance' },
  { value: 'urgentCare' },
  { value: 'labTest' },
  { value: 'imagingCenter' },
  { value: 'imagingPhysician' },
  { value: 'outpatientFacility' },
  { value: 'outpatientPhysician' },
  { value: 'outpatientAmbulatoryCareCenter' },
  { value: 'inpatientFacility' },
  { value: 'inpatientPhysician' },
  { value: 'genericDrugs' },
  { value: 'preferredBrandDrugs' },
  { value: 'nonPreferredBrandDrugs' },
  { value: 'specialtyDrugs' },
  { value: 'prenatalPostnatalCare' },
  { value: 'inpatientBirth' },
  { value: 'inpatientBirthPhysician' },
  { value: 'inpatientMentalHealth' },
  { value: 'outpatientMentalHealth' },
  { value: 'isRecommended' },
  { value: 'aicScore' },
  { value: 'preferenceScore' },
];

export function exportEmployeePlanList(
  data: unknown[],
  employeeFullName: string,
  employerName: string = ''
) {
  const today = formatDateISO(getNow());
  const fileName = `plans_${employerName}_${employeeFullName}_${today}.csv`;

  return exportToCsv(data, fileName, fields);
}
