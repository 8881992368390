import { MantineStyleProp } from '@mantine/core';

import { ZorroCenter } from '../../Center';

type Props = {
  style?: MantineStyleProp;
};

export const EmptyIcon = ({ style }: Props) => {
  return (
    <ZorroCenter style={style}>
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1356_17894)">
          <circle cx="20.125" cy="37.625" r="16.625" fill="#F0E6FF" />
          <path
            d="M30.2936 20.9487C39.5219 20.524 46.6666 17.5747 46.6666 14C46.6666 10.1337 38.3086 7 27.9999 7C24.1243 7 20.5239 7.44333 17.5396 8.20167M11.0459 11.067C9.94692 11.9583 9.33325 12.9523 9.33325 14C9.33325 16.8467 13.8693 19.299 20.3793 20.3933"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.33325 14V28C9.33325 31.8663 17.6913 35 27.9999 35C30.3006 35 32.5033 34.8437 34.5379 34.5567M41.9743 32.6433C44.8933 31.4067 46.6666 29.778 46.6666 28V14"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.33325 28V42C9.33325 45.8663 17.6913 49 27.9999 49C35.5063 49 41.9789 47.3387 44.9423 44.94M46.6666 37.3333V28"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7 7L49 49"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1356_17894">
            <rect width="56" height="56" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </ZorroCenter>
  );
};
