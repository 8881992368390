import { useRoles } from '@zorro/shared/utils';
import Head from 'next/head';

import { InsuredFormInputs, useInsuredForm } from '../../InsuredForm';
import {
  ManualSubmissionStep,
  ManualSubmissionStepSubmit,
  ManualSubmissionStepType,
} from '../ManualSubmissionStep';

const isEmployeeMode = false;

type Props = {
  employerId: string;
  employeeId: string;
  onboardingPeriodId: string;
  isDon?: boolean;
};

export const ManualSubmissionHouseholdInformationStep = ({
  employerId,
  employeeId,
  onboardingPeriodId,
  isDon = false,
}: Props) => {
  const { isZorroOperations } = useRoles();
  const isFinalizationMode = !isZorroOperations;
  const insuredForm = useInsuredForm({
    isFinalizationMode,
    isEmployeeMode,
    onboardingPeriodId,
    employeeId,
  });

  const handleStepSubmit: ManualSubmissionStepSubmit = async () => {
    return { navigate: await insuredForm.onSubmit() };
  };

  return (
    <>
      <Head>
        <title>Household Information | Submission</title>
      </Head>
      <ManualSubmissionStep
        title="Provide or confirm full details of the employee and other applicants for this election"
        step={ManualSubmissionStepType.HOUSEHOLD_INFORMATION}
        submitLabel="Confirm household information"
        onboardingPeriodId={onboardingPeriodId}
        onSubmit={handleStepSubmit}
        employerId={employerId}
        employeeId={employeeId}
        isDon={isDon}
      >
        <InsuredFormInputs
          isFinalizationMode={isFinalizationMode}
          isEmployeeMode={isEmployeeMode}
          insuredForm={insuredForm}
          employerId={employerId}
        />
      </ManualSubmissionStep>
    </>
  );
};
