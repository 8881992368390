import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const LifeIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_4829_18941)">
          <path
            d="M16.0212 4C18.5986 6.78466 24.2503 7.95574 27.688 7.76364C28.1885 9.84281 27.9843 11.0479 27.781 13.2008C27.5776 15.3536 27.022 17.4346 26.1471 19.3201C25.2722 21.2056 24.0959 22.857 22.688 24.1763C21.2802 25.4956 19.6695 26.4559 17.9518 27C16.234 26.4559 14.6233 25.4956 13.2155 24.1763C11.8076 22.857 10.6313 21.2056 9.75639 19.3201C8.88149 17.4346 8.32588 15.3536 8.12257 13.2008C7.91926 11.0479 7.93428 9.42463 8.43478 7.34545C12.1503 6.64848 12.7133 6.50909 16.0212 4Z"
            fill="#80E8B6"
          />
          <path
            d="M16 4C19.1189 6.75543 23.1896 8.19009 27.3496 8C27.9552 10.0574 28.1405 12.2153 27.8945 14.3456C27.6485 16.4759 26.9761 18.535 25.9174 20.4007C24.8586 22.2664 23.4351 23.9005 21.7315 25.206C20.0278 26.5114 18.0787 27.4616 16 28C13.9213 27.4616 11.9722 26.5114 10.2685 25.206C8.56486 23.9005 7.14135 22.2664 6.08262 20.4007C5.02389 18.535 4.35153 16.4759 4.1055 14.3456C3.85947 12.2153 4.04479 10.0574 4.65045 8C8.81043 8.19009 12.8811 6.75543 16 4"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11 16H21"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 11V21"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_4829_18941">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
