import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const TableIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 9C3 6.79086 4.79086 5 7 5H14V26H7C4.79086 26 3 24.2091 3 22V9Z"
          fill="#FFBBAF"
        />
        <rect
          x="3.75"
          y="5.75"
          width="24.5"
          height="20.5"
          rx="3.25"
          stroke="#1A1721"
          strokeWidth="1.5"
        />
        <rect x="8" y="6" width="1.5" height="20" fill="#1A1721" />
        <rect x="13" y="6" width="1.5" height="20" fill="#1A1721" />
        <rect
          x="29"
          y="11"
          width="1"
          height="26"
          transform="rotate(90 29 11)"
          fill="#1A1721"
        />
        <rect
          x="29"
          y="16"
          width="1"
          height="26"
          transform="rotate(90 29 16)"
          fill="#1A1721"
        />
      </svg>
    </Center>
  );
};
