import { PlanMarket } from '@zorro/clients';
import { useRoles } from '@zorro/shared/utils';
import {
  Box,
  Card,
  Flex,
  Grid,
  Link,
  Stack,
  Text,
} from '@zorro/zorro-ui-design';

import { CarrierLogoNext } from '../CarrierLogoNext';
import { PlanMarketBadgePageView } from '../PlanMarketEnrollment';
import { MajorMedicalPlanCardSkeleton } from './MajorMedicalPlanCardSkeleton';

export type MajorMedicalPlanCardProps = {
  carrierName?: string;
  name?: string;
  externalID?: string;
  planMarket?: PlanMarket;
  benefitsSummaryUrl?: string;
  shouldShowRenewalTag?: boolean;
  isLoading?: boolean;
};

export const MajorMedicalPlanCard = ({
  carrierName,
  name,
  externalID,
  planMarket,
  benefitsSummaryUrl,
  shouldShowRenewalTag,
  isLoading,
}: MajorMedicalPlanCardProps) => {
  const roles = useRoles();

  if (isLoading) {
    return <MajorMedicalPlanCardSkeleton />;
  }

  return (
    <Card
      style={(theme) => ({
        borderColor: theme.colors.zorroGray[2],
      })}
      pos="relative"
    >
      <Grid align="center">
        <Grid.Col span={{ base: 12, md: 3.4 }} pos="relative">
          <Stack h="100%" justify="center">
            <CarrierLogoNext
              plan={{ carrierName: carrierName || '' }}
              width={160}
              height={65}
            />
          </Stack>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 5.6 }}>
          <Stack gap="xs">
            <Text>{name}</Text>
            <Flex gap="xs" wrap="nowrap">
              <Text c="zorroGray.4">Plan ID: {externalID}</Text>
              {planMarket && roles.isManagement && (
                <PlanMarketBadgePageView planMarket={planMarket} />
              )}
            </Flex>
          </Stack>
        </Grid.Col>
        {benefitsSummaryUrl && (
          <Grid.Col span={{ base: 12, md: 3 }}>
            <Flex justify="end" w="100%">
              <Link href={benefitsSummaryUrl} target="_blank">
                View plan details
              </Link>
            </Flex>
          </Grid.Col>
        )}
      </Grid>
      {shouldShowRenewalTag && (
        <Box
          pos="absolute"
          bg="zorroGray.8"
          px="md"
          pb="0.125rem"
          right={0}
          top={0}
          style={{ borderBottomLeftRadius: 16 }}
        >
          <Text size="sm">Plan renewal</Text>
        </Box>
      )}
    </Card>
  );
};
