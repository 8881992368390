import { BenefitStatus } from '@zorro/clients';
import { Stack } from '@zorro/zorro-ui-design';

import { EmployeeRow } from '../../EmployeesDatatable';
import { FormFooter } from '../../FormFooter';
import { InformationBoxComponent } from '../../InformationBox';
import { useBatchCallEndpoint } from '../../hooks';
import { ErrorsTable } from '../Errors/ErrorsTable';

type FlagAsApplicationSentFormProps = {
  selectedEmployees: EmployeeRow[];
  onClose: () => void;
  onSuccess: () => void;
};

const FlagAsApplicationSentForm = ({
  selectedEmployees,
  onClose,
  onSuccess,
}: FlagAsApplicationSentFormProps) => {
  const { executeBatchCall, errors } = useBatchCallEndpoint({
    methodName: 'benefitEnrollmentsControllerPatch',
    singularItemName: 'employee',
    action: 'updated',
    batchSize: 5,
  });

  async function handleSubmit() {
    const batchItems = selectedEmployees.map(
      ({ fullName, medicalBenefitEnrollment }) => ({
        key: fullName,
        params: [
          medicalBenefitEnrollment?.benefitId as string, // modal is only available when there are benefit enrollments
          { status: BenefitStatus.APPLICATION_SENT },
        ] as const,
      })
    );
    await executeBatchCall(batchItems, {
      onSuccess,
    });
  }

  return (
    <>
      <Stack>
        <InformationBoxComponent
          title="Please note"
          items={[
            "It's recommended to submit applications through individual employee pages where all details can be verified",
            "When flagging in bulk, application-related fields remain at default values. It's your responsibility to confirm/update premium amounts, collect application evidence, and update payment status on the individual enrollment pages",
          ]}
        />

        <ErrorsTable
          tableTitle="Employee name"
          errors={errors}
          isBulk={selectedEmployees?.length > 1}
        />
      </Stack>

      <FormFooter
        primaryLabel="Flag as application sent"
        primaryButtonProps={{ onClick: handleSubmit }}
        secondaryLabel="Cancel"
        secondaryButtonProps={{
          onClick: onClose,
        }}
      />
    </>
  );
};

export { FlagAsApplicationSentForm };
