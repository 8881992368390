import {
  FullStory,
  isInitialized as isInitializedFullStory,
} from '@fullstory/browser';
import { formatDateTimeISO, getNow } from '@zorro/shared/formatters';
import {
  callEndpoint,
  getIpAddress,
  useImpersonation,
} from '@zorro/shared/utils';
import { useCallback } from 'react';

import { getFullStoryTrackEventProperties } from './analytics.utils';

export function useAnalytics() {
  const { authUser, employee, isImpersonated, sessionId } = useImpersonation();

  const userId = authUser?.id;
  const employeeId = employee?.id;
  const employerId = employee?.employerId;

  const sendEvent = useCallback(
    async (name: string, payload?: object) => {
      if (!userId) {
        return;
      }

      const sentAt = formatDateTimeISO(getNow());

      if (isInitializedFullStory()) {
        FullStory('trackEvent', {
          name,
          properties: getFullStoryTrackEventProperties(payload),
        });
      }

      const ip = await getIpAddress();

      // 🧠 When analysing events, `user_id` is always the authenticated user,
      //    the `employee_id` depends on impersonation.
      //    The `isImpersonated` flag lets you know whether the
      //    employee belongs to the user or not.

      await callEndpoint({
        method: 'eventsControllerCollect',
        params: [
          [
            {
              name,
              userId,
              pageUrl: window.location.href,
              userAgent: navigator.userAgent,
              referer: document.referrer,
              sessionId,
              ip,
              employerId,
              employeeId,
              isImpersonated,
              payload,
              sentAt,
            },
          ],
        ],
      });
    },
    [userId, sessionId, employerId, employeeId, isImpersonated]
  );

  return { sendEvent };
}
