import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';

import classNames from './RadixMenu.module.scss';

export const RadixMenuLabel = (
  props: DropdownMenuPrimitive.DropdownMenuLabelProps
) => {
  return (
    <DropdownMenuPrimitive.Label {...props} className={classNames.label} />
  );
};
