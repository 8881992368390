import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';

import classNames from './RadixMenu.module.scss';

export const RadixMenuSubcontent = ({
  alignOffset = -6,
  ...props
}: DropdownMenuPrimitive.DropdownMenuSubContentProps) => {
  return (
    <DropdownMenuPrimitive.Portal>
      <DropdownMenuPrimitive.SubContent
        {...props}
        alignOffset={alignOffset}
        className={classNames.content}
      />
    </DropdownMenuPrimitive.Portal>
  );
};
