import { Textarea, TextareaProps } from '@mantine/core';
import { forwardRef } from 'react';

import { ZorroBox } from '../Box';
import { ZorroText } from '../Text/Text';
import classNames from './TextArea.module.scss';

export type ITextareaProps = Omit<TextareaProps, 'value'> & {
  value: string;
};
export const ZorroTextarea = forwardRef<HTMLTextAreaElement, ITextareaProps>(
  ({ value, maxLength, ...props }, ref) => {
    return (
      <ZorroBox pos="relative">
        <Textarea
          classNames={classNames}
          maxLength={maxLength}
          value={value}
          ref={ref}
          {...props}
        />
        {maxLength && (
          <ZorroText
            c="zorroGray.6"
            right="0.75rem"
            pos="absolute"
            bottom="1rem"
            fw={400}
          >
            {value?.length || 0}/{maxLength}
          </ZorroText>
        )}
      </ZorroBox>
    );
  }
);

ZorroTextarea.displayName = 'ZorroTextarea';
