import { SelfReportType } from '@zorro/clients';
import { selfReportTypeLabelConfig } from '@zorro/types';
import { FormErrorMessage, Select } from '@zorro/zorro-ui-design';
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';

const selectSelfReportTypeOptions = Object.values(SelfReportType).map(
  (status) => ({
    label: selfReportTypeLabelConfig[status],
    value: status,
  })
);

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName>;

export function SelfReportTypeInput<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({ ...formProps }: Props<TFieldValues, TName>) {
  const {
    field,
    formState: { errors },
  } = useController(formProps);

  return (
    <>
      <Select
        {...field}
        label="Self report type"
        placeholder="Self report type"
        data={selectSelfReportTypeOptions}
      />

      <FormErrorMessage fieldName={formProps.name} errors={errors} />
    </>
  );
}
