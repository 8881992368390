import { EmployeeDto, GetEmployerDto } from '@zorro/clients';
import {
  DON_DYNAMIC_ROUTES,
  MURRIETA_DYNAMIC_ROUTES,
  useMonolithQuery,
} from '@zorro/shared/utils';
import { Breadcrumbs } from '@zorro/zorro-ui-design';

const getBreadcrumbsItems = (
  employer: GetEmployerDto,
  employee: EmployeeDto,
  onboardingPeriodId: string,
  isDon: boolean
) => [
  {
    title: employer.name,
    href: isDon
      ? DON_DYNAMIC_ROUTES.COMPANY()
      : MURRIETA_DYNAMIC_ROUTES.COMPANY_OVERVIEW(employer.id),
  },
  {
    title: 'All employees',
    href: isDon
      ? DON_DYNAMIC_ROUTES.EMPLOYEES()
      : MURRIETA_DYNAMIC_ROUTES.EMPLOYEES({ employerId: employer.id }),
  },
  {
    title: `${employee.firstName} ${employee.lastName}`,
    href: MURRIETA_DYNAMIC_ROUTES.EMPLOYEE_PAGE({
      employerId: employer.id,
      employeeId: employee.id,
    }),
  },
  {
    title: 'Manual election',
    href: MURRIETA_DYNAMIC_ROUTES.MANUAL_SUBMISSION_HOUSEHOLD_INFORMATION({
      employerId: employer.id,
      employeeId: employee.id,
      onboardingPeriodId,
    }),
  },
];

type Props = {
  employerId: string;
  employeeId: string;
  onboardingPeriodId: string;
  isDon?: boolean;
};

export const ManualSubmissionBreadcrumbs = ({
  employerId,
  employeeId,
  onboardingPeriodId,
  isDon = false,
}: Props) => {
  const { data: employer, isLoading: isLoadingEmployer } = useMonolithQuery({
    method: 'employersControllerFindOne',
    params: [employerId],
  });
  const { data: employee, isLoading: isLoadingEmployee } = useMonolithQuery({
    method: 'employeesControllerFindOne',
    params: [employeeId],
  });

  if (isLoadingEmployer || isLoadingEmployee || !employer || !employee) {
    return null;
  }

  return (
    <Breadcrumbs
      items={getBreadcrumbsItems(employer, employee, onboardingPeriodId, isDon)}
      my="2rem"
    />
  );
};
