import { IconInfoCircle } from '@tabler/icons-react';
import {
  EmployeeDto,
  EmployeePaymentMethodDetailsDto,
  EmployeePaymentMethodState,
  EmployeePaymentMethodType,
  OnboardingPeriodDto,
} from '@zorro/clients';
import { useMonolithQuery } from '@zorro/shared/utils';
import {
  Flex,
  Group,
  Icon,
  Loader,
  Switch,
  Text,
  Title,
} from '@zorro/zorro-ui-design';
import { ChangeEvent, useState } from 'react';

import {
  PAYMENT_METHOD_CREDIT_CARD_CONTAINER_WIDTH,
  PAYMENT_METHOD_DIRECT_ACH_CONTAINER_WIDTH,
} from '../paymentsDesign.consts';
import { InHandlingBadge } from './Badges/InHandlingBadge';
import { NotAvailableBadge } from './Badges/NotAvailableBadge';
import { CreditCardSkeleton } from './CreditCardSkeleton';
import { PaymentMethodCreditCard } from './PaymentMethodCreditCard';
import { PaymentMethodDirectACH } from './PaymentMethodDirectAch';
import { PaymentMethodNotice } from './PaymentMethodNotice';

type EmployeePaymentMethodDetailsProps = {
  employeeId: EmployeeDto['id'];
  onboardingPeriodId: OnboardingPeriodDto['id'];
  isPaymentsTab?: boolean;
  isElena?: boolean;
};

const EmployeePaymentMethodDetails = ({
  employeeId,
  onboardingPeriodId,
  isPaymentsTab = false,
  isElena = false,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
EmployeePaymentMethodDetailsProps) => {
  const [showDetails, setShowDetails] = useState(false);

  const { data: paymentMethodDetails, isLoading } = useMonolithQuery({
    method: 'paymentsControllerEmployeePaymentMethodDetails',
    params: [employeeId, { onboardingPeriodId }],
  });

  if (isLoading) {
    return (
      <Flex justify="center" align="center" direction="column" gap="xs">
        <Loader />
        <Text fw={500}>Loading employee payment details...</Text>
      </Flex>
    );
  }

  const fallbackPaymentMethodDetails: EmployeePaymentMethodDetailsDto = {
    type: EmployeePaymentMethodType.ZORRO_PAY,
    state: EmployeePaymentMethodState.NOT_AVAILABLE,
  };

  const data = paymentMethodDetails ?? fallbackPaymentMethodDetails;

  if (data.type === EmployeePaymentMethodType.SELF_PAY) {
    return null;
  }

  const handleShowDetailsSwitch = (event: ChangeEvent<HTMLInputElement>) => {
    setShowDetails(event.currentTarget.checked);
  };

  let isPaymentDetailsShown = true;
  let badgeText = '';
  let badgeComponent = null;

  switch (data.state) {
    case EmployeePaymentMethodState.AVAILABLE: {
      break;
    }
    case EmployeePaymentMethodState.IN_HANDLING_WITHOUT_DETAILS: {
      isPaymentDetailsShown = false;
      badgeComponent = <InHandlingBadge />;
      badgeText =
        'This payment method needs to be manually generated by Zorro. You’ll be notified once it’s ready.';

      if (isPaymentsTab) {
        badgeText = 'This payment method is being handled by Zorro.';
      }
      break;
    }
    case EmployeePaymentMethodState.IN_HANDLING_WITH_DETAILS: {
      badgeText =
        'This payment method needs to be manually generated by Zorro. You’ll be notified once it’s ready.';

      if (isPaymentsTab) {
        const paymentMethodName = data.creditCard ? 'card' : 'payment account';
        badgeText = `This ${paymentMethodName} is being updated by Zorro due to recent changes in the enrollment details.`;
      }

      if (isElena) {
        isPaymentDetailsShown = false;
      }
      break;
    }
    case EmployeePaymentMethodState.NOT_AVAILABLE:
    case EmployeePaymentMethodState.CAN_BE_GENERATED: {
      isPaymentDetailsShown = false;

      if (isPaymentsTab) {
        badgeComponent = <NotAvailableBadge />;
        badgeText =
          'No payment method available for this enrollment at this point.';
      }
      break;
    }
  }

  if (!isPaymentDetailsShown) {
    return (
      <>
        <Group
          align="center"
          justify="space-between"
          style={{ width: PAYMENT_METHOD_CREDIT_CARD_CONTAINER_WIDTH }}
        >
          <Title order={3} size="sm">
            Payment method details
          </Title>
        </Group>
        <CreditCardSkeleton badgeComponent={badgeComponent} />
        {badgeText && (
          <Group gap="xs" wrap="nowrap">
            <Icon icon={IconInfoCircle} />
            <Text size="sm">{badgeText}</Text>
          </Group>
        )}
      </>
    );
  }

  if (data.creditCard) {
    const { billingAddress } = data.creditCard;

    return (
      <>
        <Group
          align="center"
          justify="space-between"
          style={{ maxWidth: PAYMENT_METHOD_CREDIT_CARD_CONTAINER_WIDTH }}
        >
          <Title order={3} size="sm">
            Card details
          </Title>
          <Switch
            label="Show card details"
            size="xs"
            checked={showDetails}
            onChange={handleShowDetailsSwitch}
          />
        </Group>
        <PaymentMethodCreditCard {...data.creditCard} isMasked={!showDetails} />
        <Group gap="xs">
          <Text size="sm" fw={500}>
            Billing address:
          </Text>
          <Text size="sm">{billingAddress}</Text>
        </Group>
        {badgeText && (
          <Group gap="xs" wrap="nowrap">
            <Icon icon={IconInfoCircle} />
            <Text size="sm">{badgeText}</Text>
          </Group>
        )}
        {isElena && <PaymentMethodNotice />}
      </>
    );
  }

  if (data.directACH) {
    return (
      <>
        <Group
          align="center"
          justify="space-between"
          style={{ width: PAYMENT_METHOD_DIRECT_ACH_CONTAINER_WIDTH }}
        >
          <Title order={3} size="sm">
            Your payment account
          </Title>
          <Switch
            label="Show account details"
            size="xs"
            checked={showDetails}
            onChange={handleShowDetailsSwitch}
          />
        </Group>
        <PaymentMethodDirectACH {...data.directACH} isMasked={!showDetails} />
        {badgeText && (
          <Group gap="xs" wrap="nowrap">
            <Icon icon={IconInfoCircle} />
            <Text size="sm">{badgeText}</Text>
          </Group>
        )}
        {isElena && <PaymentMethodNotice />}
      </>
    );
  }

  return null;
};

export { EmployeePaymentMethodDetails };
