import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { ContactType } from '@zorro/clients';
import {
  callEndpoint,
  logger,
  responseErrorToString,
  showErrorNotification,
  useForm,
  useMonolithQuery,
} from '@zorro/shared/utils';
import { UseFormReturn } from 'react-hook-form';

import {
  ContactsFormFields,
  getContactsSchemaObject,
  getDefaultContact,
  mapContactItemToEmployerContactDto,
  mapEmployerContactDtoToContactItem,
} from './ContactsFormUtils';

type UseContactsForm = (
  employerId: string | undefined,
  isFinalizationMode: boolean,
  isDon?: boolean
) => {
  contactsForm: UseFormReturn<ContactsFormFields>;
  duplicateFieldName: keyof ContactsFormFields;
  onSubmit: () => Promise<boolean>;
};

export const useContactsForm: UseContactsForm = (
  employerId,
  isFinalizationMode,
  isDon = false
) => {
  const queryClient = useQueryClient();
  const { data: contacts } = useMonolithQuery({
    method: 'employersControllerGetEmployerContactsByType',
    params: employerId && [employerId],
  });

  const contactsForm = useForm<ContactsFormFields>({
    mode: 'all',
    resolver: yupResolver(getContactsSchemaObject(isFinalizationMode, isDon)),
    defaultValues: {
      onboardingContacts: contacts?.onboarding?.length
        ? mapEmployerContactDtoToContactItem(contacts?.onboarding)
        : [getDefaultContact()],
      legalContacts: contacts?.legal?.length
        ? mapEmployerContactDtoToContactItem(contacts?.legal)
        : [getDefaultContact()],
      hrContacts: contacts?.hr?.length
        ? mapEmployerContactDtoToContactItem(contacts?.hr)
        : [getDefaultContact()],
      financeContacts: contacts?.finance?.length
        ? mapEmployerContactDtoToContactItem(contacts?.finance)
        : [getDefaultContact()],
    },
  });
  const duplicateFieldName = isFinalizationMode
    ? 'onboardingContacts'
    : 'legalContacts';
  return {
    contactsForm,
    duplicateFieldName,
    onSubmit: async (): Promise<boolean> => {
      const data = contactsForm.getValues();
      const contacts = [
        ...mapContactItemToEmployerContactDto(
          data,
          ContactType.ONBOARDING,
          duplicateFieldName
        ),
        ...mapContactItemToEmployerContactDto(
          data,
          ContactType.LEGAL,
          duplicateFieldName
        ),
        ...mapContactItemToEmployerContactDto(
          data,
          ContactType.HR,
          duplicateFieldName
        ),
        ...mapContactItemToEmployerContactDto(
          data,
          ContactType.FINANCE,
          duplicateFieldName
        ),
      ];

      try {
        if (employerId) {
          await callEndpoint({
            method: 'employersControllerUpsertEmployerContacts',
            params: [employerId, contacts],
          });
        }

        await queryClient.invalidateQueries();
        return true;
      } catch (error) {
        logger.error(error);
        showErrorNotification({ message: responseErrorToString(error) });
      }

      return false;
    },
  };
};
