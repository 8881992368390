import { Grid, Skeleton, Stack } from '@zorro/zorro-ui-design';

export const EmployeeSidebarItemsSkeleton = () => {
  return (
    <Stack>
      <Grid gutter="xs" align="center" justify="center">
        <Grid.Col span={2}>
          <Skeleton h="1.5rem" w="1.5rem" />
        </Grid.Col>
        <Grid.Col span={10}>
          <Skeleton h="1.25rem" />
        </Grid.Col>
      </Grid>
      <Grid gutter="xs">
        <Grid.Col span={2} offset={1}>
          <Skeleton h="1.5rem" w="1.5rem" />
        </Grid.Col>
        <Grid.Col span={9}>
          <Skeleton h="1.25rem" />
        </Grid.Col>
      </Grid>
      <Grid gutter="xs">
        <Grid.Col span={2} offset={1}>
          <Skeleton h="1.5rem" w="1.5rem" />
        </Grid.Col>
        <Grid.Col span={9}>
          <Skeleton h="1.25rem" />
        </Grid.Col>
      </Grid>
      <Grid gutter="xs">
        <Grid.Col span={2}>
          <Skeleton h="1.5rem" w="1.5rem" />
        </Grid.Col>
        <Grid.Col span={10}>
          <Skeleton h="1.25rem" />
        </Grid.Col>
      </Grid>
    </Stack>
  );
};
