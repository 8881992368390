/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum MetalLevel {
  CATASTROPHIC = 'CATASTROPHIC',
  BRONZE = 'BRONZE',
  EXPANDED_BRONZE = 'EXPANDED_BRONZE',
  SILVER = 'SILVER',
  GOLD = 'GOLD',
  PLATINUM = 'PLATINUM',
}
