import {
  IconBrandTelegram,
  IconCircleCheck,
  IconCircleX,
  IconClockHour3,
  IconShoppingBag,
  IconShoppingBagCheck,
  IconShoppingBagExclamation,
  IconShoppingBagX,
  IconWritingSign,
} from '@tabler/icons-react';
import { IconNotApplicable } from '@zorro/shared/assets';
import { Box, Icon, brand } from '@zorro/zorro-ui-design';

interface StatusIconProps {
  size?: number;
}

export function PendingElectionWindowIcon({ size = 36 }: StatusIconProps) {
  return (
    <Icon
      icon={IconShoppingBag}
      color={brand.zorroGray500}
      strokeWidth={2.5}
      style={{
        borderRadius: '50%',
        borderColor: brand.zorroGray500,
        backgroundColor: brand.zorroGray900,
        padding: '5px',
        borderWidth: '0.8px',
        borderStyle: 'dashed',
      }}
      size={size}
    />
  );
}

export function ElectionActiveHasNotStartedIcon({
  size = 36,
}: StatusIconProps) {
  return (
    <Icon
      icon={IconShoppingBagExclamation}
      color={brand.zorroCoal900}
      style={{
        borderRadius: '50%',
        backgroundColor: brand.zorroYolk200,
        padding: '5px',
      }}
      size={size}
    />
  );
}

export function ElectionActiveStartedIcon({ size = 36 }: StatusIconProps) {
  return (
    <Icon
      icon={IconShoppingBag}
      color={brand.zorroCoal900}
      style={{
        borderRadius: '50%',
        backgroundColor: brand.zorroYolk200,
        padding: '5px',
      }}
      size={size}
    />
  );
}

export function ElectionSubmittedIcon({ size = 36 }: StatusIconProps) {
  return (
    <Icon
      icon={IconShoppingBagCheck}
      color={brand.zorroCoal900}
      style={{
        borderRadius: '50%',
        backgroundColor: brand.primaryButtonSelected,
        padding: '5px',
      }}
      size={size}
    />
  );
}

export function WaivedElectionIcon({ size = 36 }: StatusIconProps) {
  const iconSize = Math.floor(size * (16 / 26));

  return (
    <div
      style={{
        width: size,
        height: size,
        borderRadius: '50%',
        backgroundColor: brand.zorroGray200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Icon
        icon={IconShoppingBagX}
        color={brand.zorroCoal900}
        size={iconSize}
      />
    </div>
  );
}

export function DeadlinePassedIcon({ size = 36 }: StatusIconProps) {
  return (
    <Icon
      icon={IconClockHour3}
      color={brand.zorroCoal900}
      style={{
        borderRadius: '50%',
        backgroundColor: brand.zorroFire300,
        padding: '5px',
      }}
      size={size}
    />
  );
}

export function EnrollmentConfirmedIcon({ size = 36 }: StatusIconProps) {
  return (
    <Icon
      icon={IconWritingSign}
      color={brand.zorroCoal900}
      style={{
        borderRadius: '50%',
        backgroundColor: brand.zorroGreen300,
        padding: '5px',
      }}
      size={size}
    />
  );
}

export function CarrierApplicationSentIcon({ size = 36 }: StatusIconProps) {
  return (
    <Icon
      icon={IconBrandTelegram}
      color={brand.zorroCoal900}
      style={{
        borderRadius: '50%',
        backgroundColor: brand.zorroSky100,
        padding: '5px',
      }}
      size={size}
    />
  );
}

export function CoverageEndedIcon({ size = 36 }: StatusIconProps) {
  return DeadlinePassedIcon({ size });
}

export function ActiveCoverageIcon({ size = 36 }: StatusIconProps) {
  return (
    <Icon
      icon={IconCircleCheck}
      color={brand.zorroCoal900}
      style={{
        borderRadius: '50%',
        backgroundColor: brand.zorroGreen300,
        padding: '5px',
      }}
      size={size}
    />
  );
}

export function WaivedCoverageIcon({ size = 36 }: StatusIconProps) {
  return (
    <Icon
      icon={IconCircleX}
      color={brand.zorroCoal900}
      style={{
        borderRadius: '50%',
        backgroundColor: brand.zorroGray200,
        padding: '5px',
      }}
      size={size}
    />
  );
}

export function NoEnrollmentsIcon() {
  return (
    <Box
      style={{
        borderRadius: '50%',
        backgroundColor: brand.zorroGray700,
        padding: '8px',
      }}
    >
      <IconNotApplicable />
    </Box>
  );
}
