import { MantineTheme } from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { useRouter } from '@zorro/shared/utils';
import {
  Collapse,
  Flex,
  Group,
  Icon,
  Text,
  ThemeIcon,
  UnstyledButton,
} from '@zorro/zorro-ui-design';
import Link from 'next/link';
import { Fragment, ReactNode, useEffect, useState } from 'react';

import { MainLinkDropdownContent } from './MainLinkDropdownContent';

export type MainLinkDropdownProps = {
  icon: ReactNode;
  label: string;
  subpages: {
    link: string;
    label: string;
    exactLinkOnly?: boolean;
  }[];
};

type Props = MainLinkDropdownProps & {
  isCurrentRoute: boolean;
  onClick: (link: string) => void;
};

export const MainLinkDropdown = ({
  isCurrentRoute,
  onClick,
  icon,
  label,
  subpages,
}: Props) => {
  const { router } = useRouter();
  const [isOpen, setIsOpen] = useState<boolean>(isCurrentRoute);
  const [hoveredLink, setHoveredLink] = useState<string>();

  useEffect(() => {
    if (!isCurrentRoute) {
      setIsOpen(false);
    }
  }, [isCurrentRoute, router.pathname]);

  return (
    <>
      <UnstyledButton
        pl="xs"
        pr="md"
        py="md"
        styles={(theme) => ({
          root: {
            color: theme.colors.zorroCoal[9],
            ...(isCurrentRoute && {
              backgroundColor: theme.colors.zorroIris[1],
              borderLeft: `3px solid ${theme.colors.zorroIris[9]}`,
            }),
          },
        })}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Group justify="space-between">
          <Flex align="center">
            <ThemeIcon mr="xs" style={{ background: 'transparent' }}>
              {icon}
            </ThemeIcon>
            <Text size="md">{label}</Text>
          </Flex>

          <Icon icon={isOpen ? IconChevronUp : IconChevronDown} />
        </Group>
      </UnstyledButton>
      <Collapse in={isOpen}>
        {subpages.map((page) => {
          const isCurrentSubPage = page.link === router.asPath;

          return (
            <Fragment key={page.link}>
              <Link href={page.link}>
                <UnstyledButton
                  onClick={() => onClick(page.link)}
                  onMouseEnter={() => setHoveredLink(page.link)}
                  onMouseLeave={() => setHoveredLink('')}
                  style={(theme: MantineTheme) => ({
                    width: '100%',
                    paddingLeft: 32,
                    paddingRight: theme.spacing.lg,
                    paddingTop: theme.spacing.sm,
                    paddingBottom: theme.spacing.sm,
                  })}
                >
                  <Group justify="space-between">
                    <MainLinkDropdownContent
                      routeLabel={page.label}
                      isHovering={page.link === hoveredLink}
                      isCurrentSubPage={isCurrentSubPage}
                    />
                  </Group>
                </UnstyledButton>
              </Link>
            </Fragment>
          );
        })}
      </Collapse>
    </>
  );
};
