import { IconEye, IconEyeOff } from '@tabler/icons-react';
import {
  ActionIcon,
  Grid,
  Group,
  Icon,
  Stack,
  Text,
  Title,
  brand,
} from '@zorro/zorro-ui-design';
import { ReactNode, useState } from 'react';

/* eslint-disable react/destructuring-assignment */
export type ReadonlyValueProps =
  | {
      id: string;
      label: string;
      value?: ReactNode | string | null;
      span?: number;
      isSensitive?: boolean;
    }
  | {
      id: string;
      title: string;
    };

const ReadonlyValue = (props: ReadonlyValueProps) => {
  const [isHidden, setIsHidden] = useState<boolean>(true);

  if ('title' in props) {
    return (
      <Grid.Col>
        <Title size="h5" fw={600}>
          {props.title}
        </Title>
      </Grid.Col>
    );
  }

  const { label, value, span = 6, isSensitive = false } = props;

  return (
    <Grid.Col span={span}>
      <Stack gap="xs">
        <Text size="sm" fw={400} c="zorroGray.4">
          {label}
        </Text>

        <Group gap="xs">
          {value ? (
            <Text style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
              {isSensitive && isHidden ? '*********' : value}
            </Text>
          ) : (
            <Text>-</Text>
          )}
          {isSensitive && value && (
            <ActionIcon
              variant="transparent"
              onClick={() => setIsHidden(!isHidden)}
            >
              <Icon
                icon={isHidden ? IconEye : IconEyeOff}
                color={brand.zorroGray400}
                width={20}
              />
            </ActionIcon>
          )}
        </Group>
      </Stack>
    </Grid.Col>
  );
};

type Props = {
  items: ReadonlyValueProps[];
};

export const ReadonlyValuesGrid = ({ items }: Props) => {
  return (
    <Grid>
      {items.map((item) => (
        <ReadonlyValue key={item.id} {...item} />
      ))}
    </Grid>
  );
};
