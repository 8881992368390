/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum BandType {
  _1_YEARS = '1_years',
  _5_YEARS = '5_years',
  _10_YEARS = '10_years',
  CHILD = 'child',
}
