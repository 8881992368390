export const EditColorIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <g clipPath="url(#clip0_1327_14382)">
        <rect x="6" y="10" width="23" height="23" rx="4" fill="#FFEFCC" />
        <path
          d="M11.6665 11.6667H9.99984C9.11578 11.6667 8.26794 12.0179 7.64281 12.6431C7.01769 13.2682 6.6665 14.116 6.6665 15.0001V30.0001C6.6665 30.8841 7.01769 31.732 7.64281 32.3571C8.26794 32.9822 9.11578 33.3334 9.99984 33.3334H24.9998C25.8839 33.3334 26.7317 32.9822 27.3569 32.3571C27.982 31.732 28.3332 30.8841 28.3332 30.0001V28.3334"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.975 10.9749C34.6314 10.3185 35.0002 9.42824 35.0002 8.49993C35.0002 7.57163 34.6314 6.68135 33.975 6.02493C33.3186 5.36852 32.4283 4.99976 31.5 4.99976C30.5717 4.99976 29.6814 5.36852 29.025 6.02493L15 19.9999V24.9999H20L33.975 10.9749Z"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.6665 8.33325L31.6665 13.3333"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1327_14382">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
