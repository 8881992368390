import { CompanySetupForm, useCompanySetupForm } from '../../CompanySetupForm';
import { AddEmployerStep } from '../AddEmployerStep';
import {
  AddEmployerStepType,
  HandleStepSubmit,
} from '../AddEmployerStep/AddEmployerUtils';
import FaqSection from '../FaqSection/FaqSection';

type Props = {
  employerId: string;
  isFinalizationMode?: boolean;
  isDon?: boolean;
};

const links = [
  {
    name: 'Is this company an ALE?',
    href: 'https://www.irs.gov/affordable-care-act/employers/determining-if-an-employer-is-an-applicable-large-employer',
    isDrawer: false,
  },
];

export const AddEmployerCompanySetupStep = ({
  employerId,
  isFinalizationMode = false,
  isDon = false,
}: Props) => {
  const { companySetupForm, onSubmit } = useCompanySetupForm(
    employerId,
    isFinalizationMode
  );

  const handleStepSubmit: HandleStepSubmit = async () => {
    if (await onSubmit()) {
      return { navigate: true, employerId };
    }

    return { navigate: false };
  };

  const isSubmitDisabled = !companySetupForm.formState.isValid;

  return (
    <AddEmployerStep
      isFinalizationMode={isFinalizationMode}
      step={AddEmployerStepType.COMPANY_SETUP}
      isSubmitDisabled={isSubmitDisabled}
      title="Add company setup info"
      onSubmit={handleStepSubmit}
      employerId={employerId}
      secondaryLabel="Skip"
      isDon={isDon}
      rightSection={<FaqSection links={links} />}
    >
      <CompanySetupForm
        isFinalizationMode={isFinalizationMode}
        companySetupForm={companySetupForm}
      />
    </AddEmployerStep>
  );
};
