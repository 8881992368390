import { ContactsForm, useContactsForm } from '../../ContactsForm';
import {
  CompanySetupTabType,
  CompanySetupTabWrapper,
} from '../CompanySetupTabWrapper';

const isFinalizationMode = true;

type Props = {
  employerId: string;
};

export const ContactsSetupTab = ({ employerId }: Props) => {
  const { contactsForm, onSubmit } = useContactsForm(
    employerId,
    isFinalizationMode
  );
  const { formState } = contactsForm;

  return (
    <CompanySetupTabWrapper
      onCancel={() => contactsForm.reset()}
      tab={CompanySetupTabType.CONTACTS}
      employerId={employerId}
      formState={formState}
      onSubmit={onSubmit}
    >
      <ContactsForm
        isFinalizationMode={isFinalizationMode}
        contactsForm={contactsForm}
        shouldShowOnboardingContacts
        shouldHideMakeAdmin
      />
    </CompanySetupTabWrapper>
  );
};
