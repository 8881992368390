import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const FinancialAndLegalHealthIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_535_1745)">
          <path
            d="M16.8693 23.447C13.867 27.0826 8.87379 27.9164 5.71676 25.3093C2.55972 22.7022 2.43433 17.6414 5.43668 14.0058C8.43903 10.3701 17.0847 5.11349 20.2417 7.72061C23.3987 10.3277 19.8717 19.8114 16.8693 23.447Z"
            fill="#66D8F2"
          />
          <path
            d="M9.3335 26.6667H22.6668"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 8.00008L16 6.66675L24 8.00008"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 4V26.6667"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 16L8 8L4 16C4 17.0609 4.42143 18.0783 5.17157 18.8284C5.92172 19.5786 6.93913 20 8 20C9.06087 20 10.0783 19.5786 10.8284 18.8284C11.5786 18.0783 12 17.0609 12 16Z"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28 16L24 8L20 16C20 17.0609 20.4214 18.0783 21.1716 18.8284C21.9217 19.5786 22.9391 20 24 20C25.0609 20 26.0783 19.5786 26.8284 18.8284C27.5786 18.0783 28 17.0609 28 16Z"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_535_1745">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
