import { yupResolver } from '@hookform/resolvers/yup';
import {
  SUCCESS_MESSAGES,
  VALIDATION_MESSAGES,
  useForm,
  useMonolithQuery,
} from '@zorro/shared/utils';
import { FormErrorMessage, Text, Textarea } from '@zorro/zorro-ui-design';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

import { FormFooter } from '../FormFooter';
import { useMonolithMutation } from '../hooks';

const notesFormSchema = yup.object({
  notes: yup.string().required(VALIDATION_MESSAGES.notesRequired),
});

type EmployeeInfoFormFields = yup.InferType<typeof notesFormSchema>;

type Props = {
  employeeId: string;
};

export const NotesForm = ({ employeeId }: Props) => {
  const { tryMutate: mutateEmployeeInfo } = useMonolithMutation({
    method: 'employeesControllerUpdateEmployeeInfo',
    successMessage: SUCCESS_MESSAGES.EMPLOYEE_INFO_UPDATE_SUCCESS_MESSAGE,
    shouldShowLoadingOverlay: false,
  });
  const { data: employee } = useMonolithQuery({
    method: 'employeesControllerGetEmployeeInfo',
    params: [employeeId],
  });

  const { getValues, control, formState, reset } =
    useForm<EmployeeInfoFormFields>({
      mode: 'all',
      resolver: yupResolver(notesFormSchema),
      defaultValues: { notes: employee?.notes || '' },
    });

  const { isValid, isDirty, errors } = formState;

  const handleSubmit = async () => {
    const { notes } = getValues();

    await mutateEmployeeInfo([employeeId, { notes }]);
  };

  return (
    <>
      <Controller
        control={control}
        name="notes"
        render={({ field }) => (
          <Textarea
            {...field}
            label={
              <Text fw={600} mb="lg">
                General notes
                <Text c="zorroGray.4" ml="0.25rem">
                  (visible by agents only)
                </Text>
              </Text>
            }
            placeholder="Add your note here"
            minRows={8}
            autosize
          />
        )}
      />
      <FormErrorMessage fieldName="notes" errors={errors} />

      <FormFooter
        primaryLabel="Save"
        primaryButtonProps={{
          onClick: handleSubmit,
          disabled: !isValid || !isDirty,
        }}
        secondaryLabel={isDirty ? 'Cancel' : undefined}
        secondaryButtonProps={{
          onClick: () => reset(),
          disabled: !isDirty,
        }}
      />
    </>
  );
};
