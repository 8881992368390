import { CSSProperties } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import {
  formatCurrencyEnUs,
  singleLineTruncatedTextWithEllipsisParent,
} from '@zorro/shared/formatters';
import { Box, Group, Icon, Text, Tooltip } from '@zorro/zorro-ui-design';
import { memo } from 'react';

type CostPerMonthProps = {
  amount: number;
  isSmall?: boolean;
  justify?: CSSProperties['justifyContent'];
  hideDecimals?: boolean;
  totalFees?: number;
};

export const CostPerMonth = memo(
  ({
    amount,
    isSmall,
    totalFees,
    justify = 'start',
    hideDecimals = false,
    ...props
  }: CostPerMonthProps) => {
    return (
      <Group align="baseline" justify={justify} gap={0} {...props}>
        <Text size={isSmall ? '16px' : '24px'} fw="600">
          {formatCurrencyEnUs(amount, {
            minimumFractionDigits: hideDecimals ? 0 : 2,
            maximumFractionDigits: hideDecimals ? 0 : undefined,
          })}
        </Text>
        <Text size={isSmall ? '14px' : '16px'} mr={2}>
          /mo
        </Text>
        {totalFees && (
          <Tooltip
            position="top"
            label={`Including monthly fees of ${formatCurrencyEnUs(totalFees)}`}
            hidden={false}
          >
            <Box
              style={{
                ...singleLineTruncatedTextWithEllipsisParent,
                display: 'inline',
                width: 'auto',
              }}
            >
              <Icon
                icon={IconInfoCircle}
                height={16}
                width={18}
                style={{ verticalAlign: 'top' }}
              />
            </Box>
          </Tooltip>
        )}
      </Group>
    );
  }
);

CostPerMonth.displayName = 'CostPerMonth';
