import { IconClockHour3 } from '@tabler/icons-react';
import { EnrollmentActivity, SubmissionType } from '@zorro/clients';
import { getFullName } from '@zorro/shared/utils';
import {
  Box,
  Card,
  Grid,
  Group,
  Icon,
  Text,
  Tooltip,
  brand,
} from '@zorro/zorro-ui-design';
import { $enum } from 'ts-enum-util';

import { UserAvatar } from '../UserAvatar';

type Props = {
  timeStamp: string;
  activity: EnrollmentActivity;
  performedByEmail: string;
  performedByAvatarColor: string;
  submissionType?: SubmissionType | null;
  performedByFirstName?: string | null;
  performedByLastName?: string | null;
};

const COVERAGE_WAIVER_SUBMITTED = 'Coverage waiver submitted';
const ELECTION_SUBMITTED = 'Election submitted';

export const getRouteForEmployerStatus = (
  activity: EnrollmentActivity,
  submissionType?: SubmissionType | null
): string => {
  const submittedMessagesBySubmissionType = (
    submissionType?: SubmissionType | null
  ) => {
    return $enum.mapValue(submissionType).with<string>({
      [SubmissionType.BY_EMPLOYEE]: 'Election submitted by employee',
      [SubmissionType.BY_OPERATOR]:
        'Election submitted on behalf of the employee',
      [SubmissionType.JOINT_SESSION]:
        'Election submitted during a joint live session',
      [SubmissionType.SELF_REPORTED]: 'Election self reported by employee',
      [SubmissionType.AUTOMATIC]: ELECTION_SUBMITTED,
      [$enum.handleUnexpected]: ELECTION_SUBMITTED,
      [$enum.handleNull]: ELECTION_SUBMITTED,
      [$enum.handleUndefined]: ELECTION_SUBMITTED,
    });
  };

  const waivedMessagesBySubmissionType = (
    submissionType?: SubmissionType | null
  ) => {
    return $enum.mapValue(submissionType).with<string>({
      [SubmissionType.BY_EMPLOYEE]: 'Coverage waiver submitted by employee',
      [SubmissionType.BY_OPERATOR]:
        'Coverage waiver submitted on behalf of the employee',
      [SubmissionType.JOINT_SESSION]:
        'Coverage waiver submitted during a joint live session',
      [SubmissionType.SELF_REPORTED]: COVERAGE_WAIVER_SUBMITTED,
      [SubmissionType.AUTOMATIC]: COVERAGE_WAIVER_SUBMITTED,
      [$enum.handleUnexpected]: COVERAGE_WAIVER_SUBMITTED,
      [$enum.handleNull]: COVERAGE_WAIVER_SUBMITTED,
      [$enum.handleUndefined]: COVERAGE_WAIVER_SUBMITTED,
    });
  };

  return $enum.mapValue(activity).with<string>({
    [EnrollmentActivity.RESET]: 'Enrollment reset to initial state',
    [EnrollmentActivity.SUBMITTED]:
      submittedMessagesBySubmissionType(submissionType),
    [EnrollmentActivity.WAIVED]: waivedMessagesBySubmissionType(submissionType),
    [EnrollmentActivity.WAIVER_UPDATE]: 'Coverage waiver details updated',
    [EnrollmentActivity.ENROLLMENT_CONFIRMATION_REVERTED]:
      'Enrollment confirmation reverted',
    [EnrollmentActivity.ENROLLMENT_CONFIRMED]: 'Enrollment confirmed',
    [EnrollmentActivity.APPLICATION_REVERTED]: 'Application reverted',
    [EnrollmentActivity.APPLICATION_SENT]: 'Application sent to carrier',
    [EnrollmentActivity.ENROLLMNET_DETAILS_UPDATE]:
      'Enrollment details updated',
    [EnrollmentActivity.ELECTION_DETAILS_UPDATE]: 'Election details updated',
    [EnrollmentActivity.PREMIUM_ADJUSTMENT]: 'Premium adjusted',
    [$enum.handleUnexpected]: '',
  });
};

export const EnrollmentActivityLogEntry = ({
  timeStamp,
  activity,
  submissionType,
  performedByEmail,
  performedByFirstName,
  performedByLastName,
  performedByAvatarColor,
}: Props) => {
  const fullName = getFullName({
    firstName: performedByFirstName,
    lastName: performedByLastName,
  });
  return (
    <Card
      p="10.5px 38.5px 10.5px 38.5"
      style={{ borderColor: brand.zorroGray200 }}
    >
      <Grid align="center">
        <Grid.Col span={3}>
          <Group>
            <Icon icon={IconClockHour3} />
            <Text size="sm">{timeStamp}</Text>
          </Group>
        </Grid.Col>
        <Grid.Col span={6}>
          <Text fw={600} size="sm">
            {getRouteForEmployerStatus(activity, submissionType)}
          </Text>
        </Grid.Col>
        <Grid.Col span={3}>
          <Group gap={8}>
            {(performedByFirstName || performedByLastName) && (
              <UserAvatar
                firstName={performedByFirstName ?? ''}
                lastName={performedByLastName ?? ''}
                color={performedByAvatarColor}
                size={32}
                textSize={11}
                textColor={brand.zorroCoal900}
              />
            )}
            <Tooltip label={performedByEmail} maw={500} position="top">
              <Box pos="relative">
                <Text size="sm">{fullName}</Text>
              </Box>
            </Tooltip>
          </Group>
        </Grid.Col>
      </Grid>
    </Card>
  );
};
