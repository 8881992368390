import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const DentalIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="24" cy="7" r="6" fill="#66D8F2" />
        <path
          d="M5.40495 5.94982C4.55537 6.92306 4.03404 8.15079 4.00201 9.48061C3.94826 11.709 4.98313 14.478 6.09507 17.144"
          stroke="black"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.1625 10.3696C28.1617 4.75693 22.2304 3.81576 21.1475 3.77801C19.8985 3.73448 18.7273 4.02925 17.7311 4.57143C16.3092 5.29169 14.8874 5.29169 13.4656 4.57143C12.4693 4.02925 11.2981 3.73448 10.0492 3.77801C9.04482 3.81304 6.56233 4.61902 5.39307 5.95819"
          stroke="black"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.1167 17.184C6.74586 18.733 8.98523 24.3521 9.19674 25.6327C9.41024 26.9251 9.82381 27.6873 10.9122 27.6873C11.6454 27.6873 12.2717 26.8682 12.7414 25.5698C13.1042 24.5669 13.6802 22.5888 13.9937 21.5953C14.5403 19.8631 16.6579 19.8631 17.2045 21.5953C17.518 22.5888 18.0939 24.5669 18.4568 25.5698C18.9264 26.8682 19.5528 27.6873 20.286 27.6873C21.3744 27.6873 21.788 26.9251 22.0015 25.6327C22.7012 21.3965 26.7354 15.16 27.1616 10.441"
          stroke="black"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Center>
  );
};
