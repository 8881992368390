import { IconAlertTriangle, IconMail, IconRepeat } from '@tabler/icons-react';
import { GetEmployerDto, OpenEnrollmentPeriodDto } from '@zorro/clients';
import { EditColorIcon } from '@zorro/shared/assets';
import { Drawer, Icon, Modal, brand } from '@zorro/zorro-ui-design';

import {
  AccountInvitationForm,
  AddEnrollmentRecordForm,
  ChangeElectionWindowForm,
  ChangeEmployeeClassForm,
  ChangeShoppingStatusForm,
  ConfirmEnrollmentForm,
  DeleteEmployeeForm,
  EditEmployeeRoleForm,
  ElectionDeadlineReminderForm,
  ElectionSummaryForm,
  ElectionWindowLaunchForm,
  EmployeeLeaveOfAbsenceForm,
  FlagAsApplicationSentForm,
  ResetElectionForm,
  TerminateEmployeeForm,
  UpdateEligibilityForm,
  WaiveCoverageForm,
  WaiverConfirmationForm,
} from '.';
import {
  CreateEmployeeForm,
  UploadRosterForm,
} from '../EmployeeManagementActions';
import { EmployeeRow } from '../EmployeesDatatable';
import { ModalTitle } from '../ModalComponents';
import { EnrollmentConfirmationForm } from './EmailCommunications/EnrollmentConfirmationForm';
import { getEmployeeNameOrEmployeesCount } from './actionsMenu.utils';
import { EmployeeActionsOverlays } from './employeeActions.consts';

type ActionsMenuOverlaysProps = {
  enrollmentPeriods: OpenEnrollmentPeriodDto[];
  selectedEnrollmentPeriodId: string | null;
  selectedEmployees: EmployeeRow[];
  resetSelectedEmployees: () => void;
  employer: GetEmployerDto;
  overlayId: EmployeeActionsOverlays;
  onClose: () => void;
};

const ActionsMenuOverlays = ({
  enrollmentPeriods,
  selectedEnrollmentPeriodId,
  selectedEmployees,
  resetSelectedEmployees,
  employer,
  overlayId,
  onClose,
}: ActionsMenuOverlaysProps) => {
  function resetSelectedEmployeesAndClose() {
    resetSelectedEmployees();
    onClose();
  }

  return (
    <>
      <Drawer
        opened={overlayId === EmployeeActionsOverlays.UPLOAD_ROSTER}
        title="Upload roster"
        onClose={onClose}
      >
        <UploadRosterForm employer={employer} onSuccess={onClose} />
      </Drawer>
      <Drawer
        opened={overlayId === EmployeeActionsOverlays.ADD_EMPLOYEE}
        title="Add a new employee"
        onClose={onClose}
      >
        <CreateEmployeeForm
          employer={employer}
          enrollmentPeriods={enrollmentPeriods}
          onSuccess={onClose}
        />
      </Drawer>
      <Drawer
        opened={overlayId === EmployeeActionsOverlays.UPDATE_ELIGIBILITY}
        title="Update eligibility"
        onClose={onClose}
      >
        <UpdateEligibilityForm
          selectedEmployees={selectedEmployees}
          onSuccess={onClose}
        />
      </Drawer>
      <Drawer
        opened={overlayId === EmployeeActionsOverlays.ADD_ENROLLMENT_RECORD}
        title="Add enrollment record"
        onClose={onClose}
      >
        <AddEnrollmentRecordForm
          selectedEmployees={selectedEmployees}
          employerId={employer.id}
          onSuccess={onClose}
        />
      </Drawer>
      <Drawer
        opened={overlayId === EmployeeActionsOverlays.CHANGE_ELECTION_WINDOW}
        title="Change election window"
        onClose={onClose}
      >
        <ChangeElectionWindowForm
          selectedEmployees={selectedEmployees}
          planYearId={selectedEnrollmentPeriodId || ''}
          onSuccess={onClose}
        />
      </Drawer>
      <Drawer
        opened={overlayId === EmployeeActionsOverlays.CHANGE_EMPLOYEE_ROLE}
        onClose={onClose}
        title={EmployeeActionsOverlays.CHANGE_EMPLOYEE_ROLE}
      >
        <EditEmployeeRoleForm
          selectedEmployees={selectedEmployees}
          onSuccess={onClose}
        />
      </Drawer>
      <Drawer
        opened={overlayId === EmployeeActionsOverlays.CHANGE_EMPLOYEE_CLASS}
        onClose={onClose}
        title={EmployeeActionsOverlays.CHANGE_EMPLOYEE_CLASS}
      >
        <ChangeEmployeeClassForm
          selectedEmployees={selectedEmployees}
          employerId={employer.id}
          onClose={onClose}
          onSuccess={onClose}
        />
      </Drawer>
      <Drawer
        opened={overlayId === EmployeeActionsOverlays.SINGLE_TERMINATE_EMPLOYEE}
        onClose={onClose}
        title={EmployeeActionsOverlays.SINGLE_TERMINATE_EMPLOYEE}
      >
        <TerminateEmployeeForm
          selectedEmployees={selectedEmployees}
          onSuccess={resetSelectedEmployeesAndClose}
        />
      </Drawer>
      <Drawer
        opened={
          overlayId === EmployeeActionsOverlays.SINGLE_SET_LEAVE_OF_ABSENCE
        }
        onClose={onClose}
        title={EmployeeActionsOverlays.SINGLE_SET_LEAVE_OF_ABSENCE}
      >
        <EmployeeLeaveOfAbsenceForm
          selectedEmployees={selectedEmployees}
          onSuccess={onClose}
        />
      </Drawer>
      <Drawer
        opened={
          overlayId === EmployeeActionsOverlays.BULK_FLAG_AS_APPLICATION_SENT
        }
        onClose={onClose}
        title={EmployeeActionsOverlays.BULK_FLAG_AS_APPLICATION_SENT}
      >
        <FlagAsApplicationSentForm
          selectedEmployees={selectedEmployees}
          onClose={onClose}
          onSuccess={onClose}
        />
      </Drawer>
      <Drawer
        opened={overlayId === EmployeeActionsOverlays.BULK_CONFIRM_ENROLLMENT}
        onClose={onClose}
        title={EmployeeActionsOverlays.BULK_CONFIRM_ENROLLMENT}
      >
        <ConfirmEnrollmentForm
          selectedEmployees={selectedEmployees}
          onClose={onClose}
          onSuccess={onClose}
        />
      </Drawer>
      <Drawer
        opened={overlayId === EmployeeActionsOverlays.WAIVE_COVERAGE}
        onClose={onClose}
        title={EmployeeActionsOverlays.WAIVE_COVERAGE}
      >
        <WaiveCoverageForm
          selectedEmployees={selectedEmployees}
          onClose={onClose}
          onSuccess={onClose}
        />
      </Drawer>

      <Modal
        onClose={onClose}
        opened={overlayId === EmployeeActionsOverlays.RESET_ELECTION}
        showSeparator={false}
        size="lg"
        title={
          <ModalTitle
            bgIcon={brand.zorroFire500}
            title="Reset election?"
            icon={<IconRepeat color={brand.zorroCoal900} />}
          />
        }
      >
        <ResetElectionForm
          selectedEmployees={selectedEmployees}
          onClose={onClose}
          onSuccess={onClose}
        />
      </Modal>
      <Modal
        size="md"
        opened={overlayId === EmployeeActionsOverlays.CHANGE_SHOPPING_STATUS}
        onClose={onClose}
        title={
          <ModalTitle
            title="Change Shopping Status"
            icon={<EditColorIcon />}
            titleOrder={2}
          />
        }
      >
        <ChangeShoppingStatusForm
          selectedEmployees={selectedEmployees}
          onClose={onClose}
          onSuccess={onClose}
        />
      </Modal>
      <Modal
        onClose={onClose}
        opened={overlayId === EmployeeActionsOverlays.DELETE_EMPLOYEE}
        size="55rem"
        title={
          <ModalTitle
            title="Confirm deleting employees"
            icon={
              <Icon
                icon={IconAlertTriangle}
                color={brand.zorroFire500}
                size={32}
              />
            }
            titleOrder={2}
          />
        }
      >
        <DeleteEmployeeForm
          selectedEmployees={selectedEmployees}
          onClose={onClose}
          onSuccess={resetSelectedEmployeesAndClose}
        />
      </Modal>
      <Modal
        opened={overlayId === EmployeeActionsOverlays.EMAIL_ACCOUNT_INVITATION}
        onClose={onClose}
        title={
          <ModalTitle
            title={`Send account invitation email to ${getEmployeeNameOrEmployeesCount(
              selectedEmployees
            )}?`}
            bgIcon={brand.zorroPlum100}
            icon={<IconMail color={brand.zorroCoal900} />}
            titleOrder={2}
          />
        }
        showSeparator={false}
        size="lg"
      >
        <AccountInvitationForm
          selectedEmployees={selectedEmployees}
          onClose={onClose}
          onSuccess={onClose}
        />
      </Modal>
      <Modal
        opened={
          overlayId === EmployeeActionsOverlays.EMAIL_ELECTION_WINDOW_LAUNCH
        }
        onClose={onClose}
        title={
          <ModalTitle
            title={`Send election window launch email to ${getEmployeeNameOrEmployeesCount(
              selectedEmployees
            )}?`}
            bgIcon={brand.zorroPlum100}
            icon={<IconMail color={brand.zorroCoal900} />}
            titleOrder={2}
          />
        }
        showSeparator={false}
        size="lg"
      >
        <ElectionWindowLaunchForm
          selectedEmployees={selectedEmployees}
          onClose={onClose}
          onSuccess={onClose}
        />
      </Modal>
      <Modal
        opened={
          overlayId === EmployeeActionsOverlays.EMAIL_ELECTION_DEADLINE_REMINDER
        }
        onClose={onClose}
        title={
          <ModalTitle
            title={`Send election deadline reminder email to ${getEmployeeNameOrEmployeesCount(
              selectedEmployees
            )}?`}
            bgIcon={brand.zorroPlum100}
            icon={<IconMail color={brand.zorroCoal900} />}
            titleOrder={2}
          />
        }
        showSeparator={false}
        size="lg"
      >
        <ElectionDeadlineReminderForm
          selectedEmployees={selectedEmployees}
          onClose={onClose}
          onSuccess={onClose}
        />
      </Modal>
      <Modal
        opened={overlayId === EmployeeActionsOverlays.EMAIL_ELECTION_SUMMARY}
        onClose={onClose}
        title={
          <ModalTitle
            title={`Send election summary email to ${getEmployeeNameOrEmployeesCount(
              selectedEmployees
            )}?`}
            bgIcon={brand.zorroPlum100}
            icon={<IconMail color={brand.zorroCoal900} />}
            titleOrder={2}
          />
        }
        showSeparator={false}
        size="lg"
      >
        <ElectionSummaryForm
          selectedEmployees={selectedEmployees}
          onClose={onClose}
          onSuccess={onClose}
        />
      </Modal>
      <Modal
        opened={
          overlayId === EmployeeActionsOverlays.EMAIL_ENROLLMENT_CONFIRMATION
        }
        onClose={onClose}
        title={
          <ModalTitle
            title={`Send enrollment confirmation email to ${getEmployeeNameOrEmployeesCount(
              selectedEmployees
            )}?`}
            bgIcon={brand.zorroPlum100}
            icon={<IconMail color={brand.zorroCoal900} />}
            titleOrder={2}
          />
        }
        showSeparator={false}
        size="lg"
      >
        <EnrollmentConfirmationForm
          selectedEmployees={selectedEmployees}
          onClose={onClose}
          onSuccess={onClose}
        />
      </Modal>
      <Modal
        opened={overlayId === EmployeeActionsOverlays.EMAIL_WAIVER_CONFIRMATION}
        onClose={onClose}
        title={
          <ModalTitle
            title={`Send waiver confirmation email to ${getEmployeeNameOrEmployeesCount(
              selectedEmployees
            )}?`}
            bgIcon={brand.zorroPlum100}
            icon={<IconMail color={brand.zorroCoal900} />}
            titleOrder={2}
          />
        }
        showSeparator={false}
        size="lg"
      >
        <WaiverConfirmationForm
          selectedEmployees={selectedEmployees}
          onClose={onClose}
          onSuccess={onClose}
        />
      </Modal>
    </>
  );
};

export { ActionsMenuOverlays };
