import { MIME_TYPES } from '@mantine/dropzone';
import { IconInfoCircle } from '@tabler/icons-react';
import { OEPPaymentMethod } from '@zorro/clients';
import {
  IconZorroPaymentCard,
  IconZorroPaymentSelfPay,
  TableIcon,
} from '@zorro/shared/assets';
import { EN_LOCALE_DATE_FORMAT } from '@zorro/shared/formatters';
import { oepPaymentMethodLabelConfig } from '@zorro/types';
import {
  Alert,
  CurrencyInput,
  DateInput,
  DateRangeInput,
  FormErrorMessage,
  Grid,
  Group,
  LabelWithTooltip,
  Link,
  MonthPickerInput,
  RadioCard,
  RadioGroup,
  Text,
  Title,
  brand,
} from '@zorro/zorro-ui-design';
import { Control, Controller, UseFormReturn } from 'react-hook-form';

import { FileUploader } from '../FileUploader';
import { AllowanceModelManagement } from './AllowanceModelManagement';
import { PlanYearSetupFormFields } from './PlanYearSetupUtils';

type Props = {
  planYearSetupForm: UseFormReturn<PlanYearSetupFormFields>;
  isFinalizationMode?: boolean;
};

export const PlanYearSetupFormInputs = ({
  isFinalizationMode = false,
  planYearSetupForm,
}: Props) => {
  const { formState, control, setValue, watch } = planYearSetupForm;
  const { errors } = formState;

  const allowanceModel = watch('allowanceModel');
  const paymentMethod = watch('paymentMethod');

  function handleClickDeleteAllowanceModel() {
    setValue('allowanceModel', undefined, {
      shouldValidate: true,
    });
  }

  return (
    <Grid>
      <Grid.Col span={6}>
        <Controller
          control={control}
          name="effectiveDates"
          render={({ field: { ref: _ref, ...rest } }) => (
            <MonthPickerInput
              {...rest}
              placeholder="Set coverage period dates"
              type="range"
              size="md"
              allowSingleDateInRange
              valueFormat={EN_LOCALE_DATE_FORMAT}
              label={
                <LabelWithTooltip
                  label="Coverage period"
                  tooltipLabel="The coverage period for the plan year"
                  isRequired
                />
              }
            />
          )}
        />
      </Grid.Col>

      <Grid.Col span={6}>
        <Controller
          control={control}
          name="oepDates"
          render={({ field: { ref: _ref, ...rest } }) => (
            <DateRangeInput
              {...rest}
              placeholder="Set open enrollment period dates"
              type="range"
              size="md"
              allowSingleDateInRange
              label={
                <LabelWithTooltip
                  label="Election window"
                  tooltipLabel="The dates when elections will be open for employees during the open enrollment"
                  isRequired
                />
              }
            />
          )}
        />
      </Grid.Col>

      <Grid.Col>
        {!allowanceModel && (
          <FileUploader
            control={control as unknown as Control}
            name="allowanceModel"
            label="Allowance Model"
            topRightContent={
              <Link
                href="/files/sample_allowance_model.csv"
                anchorProps={{
                  size: 'sm',
                }}
                download
              >
                Download sample
              </Link>
            }
            isLoading={false}
            onDrop={(files: File[], onBlur, onChange) => {
              onChange(files[0]);
              onBlur();
            }}
            accept={[MIME_TYPES.csv]}
            isMultiple={false}
            isDisabled={!!allowanceModel}
            zoneLabelText="Or drag it here"
            buttonText="Upload Allowance Model"
            isRequired={isFinalizationMode}
          >
            <TableIcon />
          </FileUploader>
        )}
        <AllowanceModelManagement
          allowanceModel={allowanceModel}
          onClickDelete={handleClickDeleteAllowanceModel}
        />
        <FormErrorMessage fieldName="allowanceModel" errors={errors} />
      </Grid.Col>

      <Grid.Col mt="lg">
        <Title size="sm" fw={600}>
          Charges
        </Title>
        <Text c="zorroGray.4" mt="0.25rem">
          Set fees and broker commissions according to the fees table and your
          agreement with Zorro
        </Text>
      </Grid.Col>

      <Grid.Col span={{ sm: 6 }}>
        <Controller
          control={control}
          name="totalPEPMFees"
          render={({ field }) => (
            <CurrencyInput
              {...field}
              label="Total PEPM fees"
              size="md"
              required={isFinalizationMode}
            />
          )}
        />

        <FormErrorMessage fieldName="totalPEPMFees" errors={errors} />
      </Grid.Col>
      <Grid.Col span={{ sm: 6 }}>
        <Controller
          control={control}
          name="pepmBrokerCommission"
          render={({ field: { ...rest } }) => (
            <CurrencyInput
              {...rest}
              size="md"
              label={
                <LabelWithTooltip
                  label="PEPM broker commission"
                  tooltipLabel="Broker commission portion of the total PEPM fees"
                  isRequired={isFinalizationMode}
                />
              }
            />
          )}
        />

        <FormErrorMessage fieldName="pepmBrokerCommission" errors={errors} />
      </Grid.Col>
      <Grid.Col span={{ sm: 6 }}>
        <Controller
          control={control}
          name="monthlyPlatformFee"
          render={({ field: { ...rest } }) => (
            <CurrencyInput
              {...rest}
              label="Monthly platform fee"
              size="md"
              required={isFinalizationMode}
            />
          )}
        />

        <FormErrorMessage fieldName="monthlyPlatformFee" errors={errors} />
      </Grid.Col>

      <Grid.Col span={{ sm: 6 }}>
        <Controller
          control={control}
          name="initialSetupFee"
          render={({ field: { ...rest } }) => (
            <CurrencyInput
              {...rest}
              label="Initial setup fee"
              size="md"
              required={isFinalizationMode}
            />
          )}
        />

        <FormErrorMessage fieldName="initialSetupFee" errors={errors} />
      </Grid.Col>

      <Grid.Col>
        <Alert
          variant="light"
          bg="zorroSky.1"
          mt="md"
          style={(theme) => ({
            border: `1px solid ${theme.colors.zorroSky[5]}`,
          })}
        >
          <Group gap="xs">
            <IconInfoCircle />
            <Text fw={500}>All fees exclude sales tax</Text>
          </Group>
        </Alert>
      </Grid.Col>

      <Grid.Col mt="lg">
        <Title size="sm" fw={600}>
          Payments
        </Title>
        <Text c="zorroGray.4" mt="0.25rem">
          “Zorro Pay” should be the form of payment in the majority of cases.
          Choose “Self-pay” only if you’ve aligned with Zorro about this
          employer setup.
        </Text>
      </Grid.Col>

      <Grid.Col>
        <Controller
          control={control}
          name="paymentMethod"
          render={({ field: { ...rest } }) => (
            <RadioGroup
              {...rest}
              display="flex"
              variant="vertical"
              label="Payment method"
              required
            >
              <Group wrap="nowrap">
                <RadioCard
                  label={
                    oepPaymentMethodLabelConfig[OEPPaymentMethod.ZORRO_PAY]
                  }
                  icon={<IconZorroPaymentCard />}
                  iconColor={brand.zorroIris400}
                  value={OEPPaymentMethod.ZORRO_PAY}
                />
                <RadioCard
                  label={oepPaymentMethodLabelConfig[OEPPaymentMethod.SELF_PAY]}
                  value={OEPPaymentMethod.SELF_PAY}
                  icon={<IconZorroPaymentSelfPay />}
                  iconColor={brand.zorroYolk400}
                />
              </Group>
            </RadioGroup>
          )}
        />

        <FormErrorMessage fieldName="paymentMethod" errors={errors} />
      </Grid.Col>

      {paymentMethod === OEPPaymentMethod.ZORRO_PAY && (
        <>
          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="initialDrawAmount"
              render={({ field: { ...rest } }) => (
                <CurrencyInput
                  {...rest}
                  size="md"
                  label={
                    <LabelWithTooltip
                      label="Initial draw amount"
                      tooltipLabel="The total amount drawn from the employer's account before enrollment to ensure sufficient funds for all employee payments"
                      isRequired={isFinalizationMode}
                    />
                  }
                />
              )}
            />

            <FormErrorMessage fieldName="initialDrawAmount" errors={errors} />
          </Grid.Col>
          <Grid.Col span={6}>
            <Controller
              control={control}
              name="initialDrawDate"
              render={({ field: { ...rest } }) => (
                <DateInput {...rest} label="Initial draw date" size="md" />
              )}
            />

            <FormErrorMessage fieldName="initialDrawDate" errors={errors} />
          </Grid.Col>
          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="reserveAmount"
              render={({ field: { ...rest } }) => (
                <CurrencyInput
                  {...rest}
                  size="md"
                  label={
                    <LabelWithTooltip
                      label="Reserve amount"
                      tooltipLabel="A reserve kept in the account to ensure enough funds for new employees and enrollment changes during the plan year"
                    />
                  }
                />
              )}
            />

            <FormErrorMessage fieldName="initialDrawDate" errors={errors} />
          </Grid.Col>
        </>
      )}
    </Grid>
  );
};
