import { BenefitStatus } from '@zorro/clients';
import { Space, Text } from '@zorro/zorro-ui-design';

import { ModalFooter } from '../ModalComponents/ModalFooter';
import { useMonolithMutation } from '../hooks/useMonolithMutation';

interface UndoEnrollmentConfirmationFormProps {
  benefitEnrollmentId: string;
  onClose: () => void;
}

export const UndoEnrollmentConfirmationForm = ({
  benefitEnrollmentId,
  onClose,
}: UndoEnrollmentConfirmationFormProps) => {
  const { mutate: changeBenefitEnrollmentStatus } = useMonolithMutation({
    method: 'benefitEnrollmentsControllerPatch',
  });

  const onSubmit = async () => {
    await changeBenefitEnrollmentStatus([
      benefitEnrollmentId,
      { status: BenefitStatus.APPLICATION_SENT },
    ]);
    onClose();
  };

  return (
    <>
      <Text size="lg">
        The enrollment will be set back to application sent status
      </Text>

      <Space h="xl" />
      <ModalFooter
        submitText="Change status"
        onSubmit={onSubmit}
        onClose={onClose}
        submitVariant="primary"
      />
    </>
  );
};
