import { DependentIcon, EmployeeIcon, SpouseIcon } from '@zorro/shared/assets';

type IconFromGenderProps = {
  label: string;
};

export const IconFromGender = ({ label }: IconFromGenderProps) => {
  if (label === 'Spouse') {
    return <SpouseIcon />;
  } else if (label.includes('Dependent')) {
    return <DependentIcon />;
  }

  return <EmployeeIcon />;
};
