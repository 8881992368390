import { IconUser } from '@tabler/icons-react';
import { useRoles } from '@zorro/shared/utils';
import { RadixMenu } from '@zorro/zorro-ui-design';

import { ActionsSubMenu } from '../ActionsSubMenu';
import { EmployeeActionsOverlays } from '../employeeActions.consts';
import { ActionsMenuSection } from '../employeeActions.types';

const EmployeeAdministrationSection = ({
  setOverlayId,
  selectedEmployees,
}: ActionsMenuSection) => {
  const { isZorroOperations } = useRoles();

  const isBulk = selectedEmployees.length > 1;

  return (
    <ActionsSubMenu title="Employee administration" icon={IconUser}>
      {!isBulk && (
        <>
          <RadixMenu.Item
            onClick={() =>
              setOverlayId(EmployeeActionsOverlays.SINGLE_TERMINATE_EMPLOYEE)
            }
          >
            {EmployeeActionsOverlays.SINGLE_TERMINATE_EMPLOYEE}
          </RadixMenu.Item>
          <RadixMenu.Item
            onClick={() =>
              setOverlayId(EmployeeActionsOverlays.SINGLE_SET_LEAVE_OF_ABSENCE)
            }
          >
            {EmployeeActionsOverlays.SINGLE_SET_LEAVE_OF_ABSENCE}
          </RadixMenu.Item>
        </>
      )}
      {isZorroOperations && (
        <RadixMenu.Item
          onClick={() =>
            setOverlayId(EmployeeActionsOverlays.UPDATE_ELIGIBILITY)
          }
        >
          {EmployeeActionsOverlays.UPDATE_ELIGIBILITY}
        </RadixMenu.Item>
      )}
      <RadixMenu.Item
        onClick={() =>
          setOverlayId(EmployeeActionsOverlays.CHANGE_EMPLOYEE_CLASS)
        }
      >
        {EmployeeActionsOverlays.CHANGE_EMPLOYEE_CLASS}
      </RadixMenu.Item>
    </ActionsSubMenu>
  );
};

export { EmployeeAdministrationSection };
