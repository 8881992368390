import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const PetIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_4832_18699)">
          <circle cx="24" cy="12" r="8" fill="#FF8972" />
          <path
            d="M13.3335 19.9992L20.7815 12.5526C20.4714 12.2425 20.2425 11.8608 20.1151 11.4412C19.9878 11.0216 19.9658 10.577 20.0513 10.1469C20.1368 9.7168 20.327 9.31441 20.6051 8.97537C20.8833 8.63633 21.2407 8.37111 21.6458 8.20321C22.0509 8.03531 22.4912 7.9699 22.9276 8.01278C23.364 8.05566 23.7831 8.2055 24.1478 8.44904C24.5125 8.69258 24.8115 9.02229 25.0183 9.40898C25.2251 9.79566 25.3334 10.2274 25.3335 10.6659C25.7721 10.6656 26.2041 10.7735 26.591 10.98C26.978 11.1866 27.308 11.4854 27.5519 11.8499C27.7958 12.2145 27.946 12.6337 27.9891 13.0702C28.0323 13.5067 27.9671 13.9471 27.7994 14.3524C27.6316 14.7577 27.3665 15.1153 27.0275 15.3936C26.6885 15.672 26.286 15.8624 25.8558 15.948C25.4256 16.0336 24.9809 16.0117 24.5612 15.8844C24.1415 15.757 23.7596 15.5281 23.4495 15.2179L18.6668 19.9992"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 17.3326L11.2186 12.5526C11.5287 12.2425 11.7576 11.8608 11.885 11.4412C12.0124 11.0216 12.0343 10.577 11.9488 10.1469C11.8633 9.7168 11.6731 9.31441 11.395 8.97537C11.1169 8.63633 10.7594 8.37111 10.3543 8.20321C9.94921 8.03531 9.50895 7.9699 9.07253 8.01278C8.63611 8.05566 8.217 8.2055 7.85232 8.44904C7.48764 8.69258 7.18865 9.02229 6.98183 9.40898C6.77501 9.79566 6.66674 10.2274 6.66662 10.6659C6.22798 10.6656 5.79605 10.7735 5.40908 10.98C5.02211 11.1866 4.69207 11.4854 4.4482 11.8499C4.20433 12.2145 4.05415 12.6337 4.01099 13.0702C3.96783 13.5067 4.033 13.9471 4.20075 14.3524C4.36849 14.7577 4.63362 15.1153 4.97264 15.3936C5.31166 15.672 5.7141 15.8624 6.1443 15.948C6.5745 16.0336 7.01918 16.0117 7.43892 15.8844C7.85866 15.757 8.2405 15.5281 8.55062 15.2179L13.3333 19.9992"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4 26.6667H28C27.7667 24.4387 27.9387 22.2067 25.3333 20H6.66667C4.88933 21.3333 4 23.556 4 26.6667Z"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_4832_18699">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
