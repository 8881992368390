/*
 @see https://listings.pcisecuritystandards.org/documents/PCI_DSS-QRG-v3_2_1.pdf
 */

// alternative symbol: ●
const MASKING_SYMBOL = '●';

export function getCardNumberSkeleton(): string {
  const ccDigitGroups = MASKING_SYMBOL.repeat(16).match(/.{1,4}/gu);
  if (!ccDigitGroups) {
    return '';
  }
  return ccDigitGroups.join(' ');
}

export function getFormattedCardNumber(
  cardNumber: string,
  isMasked: boolean = true
): string {
  const ccDigitGroups = cardNumber.match(/.{1,4}/gu);
  if (!ccDigitGroups) {
    return '';
  }
  if (isMasked) {
    const lastGroup = ccDigitGroups.pop();
    const groups = ccDigitGroups.map((group) =>
      group.replaceAll(/./gu, MASKING_SYMBOL)
    );
    return [...groups, lastGroup].join(' ');
  }
  return ccDigitGroups.join(' ');
}

export function getFormattedCVV(
  cvcNumber: string,
  isMasked: boolean = true
): string {
  if (isMasked) {
    return cvcNumber.replaceAll(/./gu, MASKING_SYMBOL);
  }
  return cvcNumber;
}

export function getSensitivePaymentData(
  data: string,
  isMasked: boolean = true
): string {
  if (isMasked) {
    return data.replaceAll(/./gu, MASKING_SYMBOL);
  }
  return data;
}
