import { EmployeeDto, GetEmployerDto } from '@zorro/clients';
import {
  DON_DYNAMIC_ROUTES,
  EMPLOYEE_DYNAMIC_ROUTES,
  MURRIETA_DYNAMIC_ROUTES,
  useMonolithQuery,
} from '@zorro/shared/utils';
import { Breadcrumbs } from '@zorro/zorro-ui-design';

import { EmployeeLayoutBreadcrumbsSkeleton } from './EmployeeLayoutBreadcrumbsSkeleton';

const getBreadcrumbsItems = (
  employer: GetEmployerDto,
  employee: EmployeeDto,
  isDon: boolean
) => [
  {
    title: employer.name,
    href: isDon
      ? DON_DYNAMIC_ROUTES.COMPANY()
      : MURRIETA_DYNAMIC_ROUTES.COMPANY_OVERVIEW(employer.id),
  },
  {
    title: 'All employees',
    href: isDon
      ? DON_DYNAMIC_ROUTES.EMPLOYEES()
      : MURRIETA_DYNAMIC_ROUTES.EMPLOYEES({ employerId: employer.id }),
  },
  {
    title: `${employee.firstName} ${employee.lastName}`,
    href: EMPLOYEE_DYNAMIC_ROUTES.EMPLOYEE_PAGE({
      employerId: employer.id,
      employeeId: employee.id,
    }),
  },
];

type Props = {
  employerId: string;
  employeeId: string;
  isDon: boolean;
};

export const EmployeeLayoutBreadcrumbs = ({
  employerId,
  employeeId,
  isDon,
}: Props) => {
  const { data: employer, isLoading: isLoadingEmployer } = useMonolithQuery({
    method: 'employersControllerFindOne',
    params: [employerId],
  });
  const { data: employee, isLoading: isLoadingEmployee } = useMonolithQuery({
    method: 'employeesControllerFindOne',
    params: [employeeId],
  });

  if (isLoadingEmployer || isLoadingEmployee || !employer || !employee) {
    return <EmployeeLayoutBreadcrumbsSkeleton />;
  }

  return <Breadcrumbs items={getBreadcrumbsItems(employer, employee, isDon)} />;
};
