import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const HealthPlanIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_51_19708)">
          <path d="M7 7H21V28H7V7Z" fill="#80E8B6" />
          <path
            d="M12.0013 6.66699H9.33464C8.62739 6.66699 7.94911 6.94794 7.44902 7.44804C6.94892 7.94814 6.66797 8.62641 6.66797 9.33366V25.3337C6.66797 26.0409 6.94892 26.7192 7.44902 27.2193C7.94911 27.7194 8.62739 28.0003 9.33464 28.0003H22.668C23.3752 28.0003 24.0535 27.7194 24.5536 27.2193C25.0537 26.7192 25.3346 26.0409 25.3346 25.3337V9.33366C25.3346 8.62641 25.0537 7.94814 24.5536 7.44804C24.0535 6.94794 23.3752 6.66699 22.668 6.66699H20.0013"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.3333 4H14.6667C13.1939 4 12 5.19391 12 6.66667C12 8.13943 13.1939 9.33333 14.6667 9.33333H17.3333C18.8061 9.33333 20 8.13943 20 6.66667C20 5.19391 18.8061 4 17.3333 4Z"
            fill="white"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.332 18.667H18.6654"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 16V21.3333"
            stroke="#1A1721"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_51_19708">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
