import { capitalizeStartOfSentence } from '@zorro/shared/formatters';

import { UNEXPECTED_ERROR_MESSAGE, responseErrorToString } from './errorUtil';
import { logger } from './logger';
import {
  showErrorNotification,
  showSuccessNotification,
  showWarningNotification,
} from './notifications';

// eslint-disable-next-line sonarjs/cognitive-complexity
export async function handleBatchNotifications(
  promiseResults: (
    | PromiseSettledResult<unknown>
    | PromiseSettledResult<void>
  )[],
  entityName: { singular: string; plural?: string },
  actionPastTense = 'updated'
) {
  if (promiseResults.length === 0) {
    showErrorNotification({
      message: UNEXPECTED_ERROR_MESSAGE,
    });
    return;
  }
  try {
    const successCount = promiseResults.filter(
      ({ status }) => status === 'fulfilled'
    )?.length;
    const rejectedCount = promiseResults.length - successCount;
    const entityNamePlural = entityName.plural || `${entityName.singular}s`;

    if (promiseResults.length === 1) {
      // single action
      if (successCount === 1) {
        showSuccessNotification({
          message: `${capitalizeStartOfSentence(
            entityName.singular
          )} ${actionPastTense} successfully!`,
        });
      } else {
        const { reason } = promiseResults[0] as PromiseRejectedResult;
        showErrorNotification({ message: responseErrorToString(reason) });
      }
    } // multiple actions
    else if (rejectedCount === 0) {
      showSuccessNotification({
        message: `${successCount} ${entityNamePlural} ${actionPastTense} successfully!`,
      });
    } else if (rejectedCount === promiseResults.length) {
      // all failed
      showErrorNotification({
        message: `${successCount} ${entityNamePlural} ${actionPastTense}. ${rejectedCount} ${entityNamePlural} had errors`,
      });
    } else {
      // partial success
      showWarningNotification({
        message: `${capitalizeStartOfSentence(
          actionPastTense
        )} ${successCount} ${entityNamePlural}. ${rejectedCount} ${entityNamePlural} had errors`,
      });
    }
  } catch (error) {
    logger.error(error);

    const errorMessage = responseErrorToString(error);

    const errorObject = errorMessage
      ? {
          message: errorMessage,
        }
      : {};

    showErrorNotification(errorObject);
  }
}
