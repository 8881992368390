import { GetObjectCommand, GetObjectCommandInput } from '@aws-sdk/client-s3';
import {
  PresignedPostOptions,
  createPresignedPost,
} from '@aws-sdk/s3-presigned-post';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import {
  BenefitDocumentType,
  CreateBenefitDocumentDto,
  s3Client,
} from '@zorro/clients';
import axios from 'axios';
import type { NextApiRequest, NextApiResponse } from 'next';
import { v4 as uuidv4 } from 'uuid';

import { BENEFIT_DOCUMENTS_BUCKET, expires } from './bucketUtils';
import { downloadFile } from './downloadFile';
import { logger } from './logger';
import { extractFileKeyFromUrl } from './urlUtils';

const handleCreatePresignedPost = async (
  req: NextApiRequest,
  res: NextApiResponse
) => {
  // INFO: use uuid in upload path to avoid overwriting files with the same filename
  const uploadKey = `${uuidv4()}/${req.query.file}`;
  const bucketParams: PresignedPostOptions = {
    Bucket: BENEFIT_DOCUMENTS_BUCKET,
    Key: uploadKey,
    Fields: {
      'Content-Type': req.query.fileType as string,
    },
    Expires: expires,
  };

  const data = await createPresignedPost(s3Client, bucketParams);
  res.status(200).json({ ...data });
};

const handleGetSignedUrl = async (
  req: NextApiRequest,
  res: NextApiResponse
) => {
  const key = req.query.key as string | undefined | null;

  if (!key) {
    res.status(500).json({ error: `Invalid key: ${key}` });
    return;
  }

  const bucketParams: GetObjectCommandInput = {
    Bucket: BENEFIT_DOCUMENTS_BUCKET,
    Key: key,
  };

  const command = new GetObjectCommand(bucketParams);
  const url = await getSignedUrl(s3Client, command, { expiresIn: expires });
  res.status(200).json({ url });
};

export const benefitDocumentHandler = async (
  req: NextApiRequest,
  res: NextApiResponse
) => {
  try {
    const operationType = req.query.operationType;
    switch (operationType) {
      case 'createPresignedPost': {
        await handleCreatePresignedPost(req, res);
        break;
      }
      case 'getSignedUrl': {
        await handleGetSignedUrl(req, res);
        break;
      }
      default: {
        res
          .status(500)
          .json({ error: `Invalid operationType: ${operationType}` });
      }
    }
  } catch (error) {
    logger.error(error);

    res
      .status(500)
      .json({ error: `Failed to sign employer document operation: ${error}` });
  }
};

export const createBenefitDocument = async (
  file: File,
  onboardingPeriodId: string,
  isSharedWithEmployee: boolean,
  type: BenefitDocumentType
): Promise<CreateBenefitDocumentDto> => {
  const fileName = encodeURIComponent(file.name.replaceAll(' ', '_'));
  const fileType = encodeURIComponent(file.type);
  const res = await axios.get(
    `/api/benefit-documents?operationType=createPresignedPost&file=${fileName}&fileType=${fileType}`
  );
  const { url, fields } = await res.data;
  const formData = new FormData();

  Object.entries({ ...fields, file }).forEach(([key, value]) => {
    formData.append(key, value as string);
  });
  await axios.post(url, formData);
  return {
    documentUrl: url + fields.key,
    onboardingPeriodId,
    fileName,
    isSharedWithEmployee,
    type,
  };
};

export const downloadBenefitDocument = async (
  documentUrl: string,
  fileName: string
) => {
  const key = extractFileKeyFromUrl(documentUrl);

  const res = await axios.get(
    `/api/benefit-documents?operationType=getSignedUrl&key=${key}`
  );

  downloadFile(res.data.url, fileName);
};
