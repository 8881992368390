import { useRouter } from '@zorro/shared/utils';
import { ReactElement } from 'react';

import {
  CollapsibleMainLink,
  CollapsibleMainLinkProps,
} from './CollapsibleMainLink';

export interface CollapsibleSubpageProps {
  order: number;
  label: string;
  link: string;
  parentLabel: string;
  isHsaEligible?: boolean;
}

interface CollapsibleMainLinksProps {
  items: CollapsibleMainLinkProps[];
}

export function CollapsibleMainLinks({
  items,
}: CollapsibleMainLinksProps): ReactElement {
  const { router } = useRouter();

  let currentSubPage: CollapsibleSubpageProps | undefined = undefined;

  const currentParentPage = items.find((item) => {
    currentSubPage = item.subpages.find((subpage) => {
      const url = new URL(
        subpage.link,
        process.env.NEXT_PUBLIC_ZORRO_UI_BASE_URL
      );
      return url.pathname === router.pathname;
    });

    return currentSubPage;
  });

  return (
    <>
      {items.map((item) => {
        const isParentPageOrderSmallerThanCurrent =
          currentParentPage && currentParentPage.order < item.order;

        return (
          <CollapsibleMainLink
            key={item.label}
            order={item.order}
            icon={item.icon}
            label={item.label}
            subpages={item.subpages}
            currentSubPage={currentSubPage}
            isParentPageOrderSmallerThanCurrent={
              isParentPageOrderSmallerThanCurrent
            }
          />
        );
      })}
    </>
  );
}
