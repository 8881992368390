import { yupResolver } from '@hookform/resolvers/yup';
import { screamingSnakeCaseToText, useForm } from '@zorro/shared/utils';
import {
  FormErrorMessage,
  Group,
  RadioCard,
  RadioGroup,
  Stack,
} from '@zorro/zorro-ui-design';
import { Controller } from 'react-hook-form';

import { EmployeeRow } from '../../EmployeesDatatable';
import { FormFooter } from '../../FormFooter';
import { InformationBoxComponent } from '../../InformationBox';
import { PlanMarketConfig } from '../../PlanMarketEnrollment';
import { useBatchCallEndpoint } from '../../hooks';
import { ErrorsTable } from '../Errors/ErrorsTable';
import {
  ConfirmEnrollmentFormFields,
  confirmEnrollmentSchema,
  planMarketEnrollmentValues,
} from './confirmEnrollmentForm.utils';

type ConfirmEnrollmentFormProps = {
  selectedEmployees: EmployeeRow[];
  onClose: () => void;
  onSuccess: () => void;
};

export const ConfirmEnrollmentForm = ({
  selectedEmployees,
  onClose,
  onSuccess,
}: ConfirmEnrollmentFormProps) => {
  const {
    control,
    formState: { errors, isValid },
    getValues,
  } = useForm<ConfirmEnrollmentFormFields>({
    mode: 'all',
    resolver: yupResolver(confirmEnrollmentSchema),
  });

  const { executeBatchCall, errors: bulkErrors } = useBatchCallEndpoint({
    methodName: 'majorMedicalControllerConfirmEnrollment',
    singularItemName: 'employee',
    action: 'updated',
    batchSize: 5,
  });

  async function handleSubmit() {
    const { planMarket: planMarketFormValue } = getValues();
    const planMarket =
      planMarketFormValue === 'NOT_APPLICABLE' ? null : planMarketFormValue;
    const batchItems = selectedEmployees.map(
      ({ fullName, medicalBenefitEnrollment }) => ({
        key: fullName,
        params: [
          medicalBenefitEnrollment?.benefitId as string, // modal is only available for submitted employees
          { planMarket },
        ] as const,
      })
    );

    await executeBatchCall(batchItems, {
      onSuccess,
    });
  }

  return (
    <>
      <Stack>
        <Stack>
          <InformationBoxComponent
            title="Please note"
            items={[
              "It's recommended to confirm enrollments via individual employee pages where all details can be verified",
              "When confirming in bulk, enrollment fields will remain at their default values. It's your responsibility to confirm/update the coverage period, collect enrollment evidence, update member ID, and track payment status on individual pages",
              "If there are any changes between an employee's election and the final confirmation (e.g. premium, tax advantage, or coverage dates), ensure the employee is informed",
            ]}
          />
          <Controller
            name="planMarket"
            control={control}
            render={({ field: { value, ...rest } }) => (
              <RadioGroup
                {...rest}
                value={value}
                display="flex"
                label="Enrollment type"
                required
                styles={{
                  root: { alignItems: 'start', flexDirection: 'column' },
                  label: { alignSelf: 'start' },
                }}
              >
                <Group wrap="nowrap" gap="sm">
                  {planMarketEnrollmentValues.map((radioValue) => {
                    const { color, icon: IconComponent } =
                      PlanMarketConfig[radioValue];
                    const label = screamingSnakeCaseToText(radioValue);

                    return (
                      <RadioCard
                        icon={<IconComponent />}
                        iconColor={color}
                        key={radioValue}
                        value={radioValue}
                        checked={radioValue === value}
                        label={label}
                        style={{ flexGrow: 1 }}
                      />
                    );
                  })}
                </Group>
              </RadioGroup>
            )}
          />
          <FormErrorMessage fieldName="planMarket" errors={errors} />
        </Stack>

        <ErrorsTable
          tableTitle="Employee name"
          errors={bulkErrors}
          isBulk={selectedEmployees?.length > 1}
        />
      </Stack>

      <FormFooter
        primaryLabel="Confirm enrollment"
        primaryButtonProps={{ onClick: handleSubmit, disabled: !isValid }}
        secondaryLabel="Cancel"
        secondaryButtonProps={{
          onClick: onClose,
        }}
      />
    </>
  );
};
