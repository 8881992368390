/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum SubmissionType {
  BY_EMPLOYEE = 'BY_EMPLOYEE',
  JOINT_SESSION = 'JOINT_SESSION',
  BY_OPERATOR = 'BY_OPERATOR',
  SELF_REPORTED = 'SELF_REPORTED',
  AUTOMATIC = 'AUTOMATIC',
}
