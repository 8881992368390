import { IconTrash } from '@tabler/icons-react';
import { AddIcon } from '@zorro/shared/assets';
import { useRoles } from '@zorro/shared/utils';
import {
  ActionIcon,
  Button,
  Card,
  Checkbox,
  Divider,
  FormErrorMessage,
  Grid,
  Group,
  Icon,
  PhoneInput,
  Stack,
  Text,
  TextInput,
  brand,
} from '@zorro/zorro-ui-design';
import { Fragment, ReactElement } from 'react';
import { Controller, UseFormReturn, useFieldArray } from 'react-hook-form';

import { ContactsFormFields, getDefaultContact } from './ContactsFormUtils';

type Props = {
  icon: ReactElement;
  title: string;
  subtitle: string;
  contactTitle: string;
  fieldName: keyof ContactsFormFields;
  duplicateFieldName?: keyof ContactsFormFields;
  contactsForm: UseFormReturn<ContactsFormFields>;
  isFinalizationMode?: boolean;
  shouldHideMakeAdmin?: boolean;
  duplicateModeCheckbox?: { label: string };
  isMultiSelect?: boolean;
};

export const ContactsFormSection = ({
  icon,
  title,
  subtitle,
  contactTitle,
  fieldName,
  duplicateFieldName,
  contactsForm,
  isFinalizationMode,
  shouldHideMakeAdmin = false,
  isMultiSelect = false,
  duplicateModeCheckbox,
}: Props) => {
  const { isAgentWithoutSupervisor: isReadonlyMode } = useRoles();
  const { control, formState, watch } = contactsForm;
  const { errors } = formState;

  const { isDuplicateMode } = watch(`${fieldName}.0`);
  const name =
    isDuplicateMode && duplicateFieldName ? duplicateFieldName : fieldName;
  const fields = watch(name);
  const { append, remove } = useFieldArray({
    control,
    name,
  });

  const isDisabled = isReadonlyMode || isDuplicateMode;

  return (
    <Card
      style={(theme) => ({ borderColor: theme.colors.zorroGray[1] })}
      radius="md"
      p={0}
    >
      <Group
        align="start"
        bg="zorroGray.10"
        wrap="nowrap"
        py="lg"
        px="xl"
        gap={8}
      >
        {icon}

        <Stack gap={0}>
          <Text fw={600}>
            {title} {isFinalizationMode ? '*' : ''}
          </Text>
          <Text c="zorroGray.9">{subtitle}</Text>
        </Stack>
      </Group>
      <Stack key={isDuplicateMode?.toString()} py="lg" px="xl">
        {fields.map((_, index) => {
          const shouldShowRequired = isFinalizationMode && index === 0;
          const showDuplicateCheckbox = duplicateModeCheckbox && index === 0;

          return (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={index.toString()}>
              <Group justify="space-between" wrap="nowrap">
                <Text fw="600" style={{ whiteSpace: 'nowrap' }}>
                  {contactTitle} {index + 1}
                </Text>
                {index > 0 && (
                  <>
                    <Divider w="100%" color="zorroGray.1" />
                    <ActionIcon
                      variant="filled"
                      onClick={() => remove(index)}
                      size="md"
                    >
                      <Icon
                        icon={IconTrash}
                        color={brand.zorroGray800}
                        size={20}
                      />
                    </ActionIcon>
                  </>
                )}
                {showDuplicateCheckbox && (
                  <Controller
                    control={control}
                    name={`${fieldName}.${index}.isDuplicateMode`}
                    render={({ field: { value, ...rest } }) => (
                      <Checkbox
                        {...rest}
                        isChecked={value || false}
                        label={duplicateModeCheckbox.label}
                        shouldHaveBorder={false}
                        size="sm"
                      />
                    )}
                  />
                )}
              </Group>
              <Grid>
                <Grid.Col span={6}>
                  <Controller
                    control={control}
                    name={`${name}.${index}.firstName`}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        disabled={isDisabled}
                        required={shouldShowRequired}
                        placeholder="Type first name"
                        label="First name"
                        size="md"
                      />
                    )}
                  />

                  <FormErrorMessage
                    fieldName={`${name}.${index}.firstName`}
                    errors={errors}
                  />
                </Grid.Col>

                <Grid.Col span={6}>
                  <Controller
                    control={control}
                    name={`${name}.${index}.lastName`}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        disabled={isDisabled}
                        required={shouldShowRequired}
                        placeholder="Type last name"
                        label="Last name"
                        size="md"
                      />
                    )}
                  />

                  <FormErrorMessage
                    fieldName={`${name}.${index}.lastName`}
                    errors={errors}
                  />
                </Grid.Col>

                <Grid.Col span={6}>
                  <Controller
                    control={control}
                    name={`${name}.${index}.phone`}
                    render={({ field }) => (
                      <PhoneInput
                        {...field}
                        control={control}
                        disabled={isDisabled}
                        required={shouldShowRequired}
                        placeholder="(212) 456-7890"
                        international={false}
                        label="Phone number"
                        size="md"
                      />
                    )}
                  />

                  <FormErrorMessage
                    fieldName={`${name}.${index}.phone`}
                    errors={errors}
                  />
                </Grid.Col>

                <Grid.Col span={6}>
                  <Controller
                    control={control}
                    name={`${name}.${index}.email`}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        disabled={isDisabled}
                        required={shouldShowRequired}
                        placeholder="Type email address"
                        label="Email address"
                        size="md"
                      />
                    )}
                  />

                  <FormErrorMessage
                    fieldName={`${name}.${index}.email`}
                    errors={errors}
                  />
                </Grid.Col>

                <Grid.Col mb="md">
                  <Group justify="space-between">
                    {!shouldHideMakeAdmin && (
                      <Controller
                        control={control}
                        name={`${name}.${index}.isAdmin`}
                        render={({ field: { value, ...rest } }) => (
                          <Checkbox
                            {...rest}
                            disabled={isDisabled}
                            isChecked={value || false}
                            label="Make a Zorro admin"
                            shouldHaveBorder={false}
                            size="sm"
                          />
                        )}
                      />
                    )}

                    <FormErrorMessage
                      fieldName={`${name}.${index}.isAdmin`}
                      errors={errors}
                    />
                    {isMultiSelect &&
                      index === fields.length - 1 &&
                      !isDuplicateMode && (
                        <Button
                          onClick={() => append(getDefaultContact())}
                          disabled={isReadonlyMode}
                          variant="subtle"
                          size="sm"
                          p={0}
                        >
                          <AddIcon />
                        </Button>
                      )}
                  </Group>
                </Grid.Col>
              </Grid>
            </Fragment>
          );
        })}
      </Stack>
    </Card>
  );
};
