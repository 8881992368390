import { SUCCESS_MESSAGES, showSuccessNotification } from '@zorro/shared/utils';
import { Button, Stack } from '@zorro/zorro-ui-design';

import { useLoadingOverlay } from '../LoadingOverlayContext';
import { PlanYearSetupFormInputs } from './PlanYearSetupFormInputs';
import { usePlanYearSetupForm } from './usePlanYearSetupForm';

const isFinalizationMode = true;

type Props = {
  employerId: string;
  onSuccess: () => void;
  isEdit: boolean;
  openEnrollmentPeriodId?: string;
};

export const PlanYearSetupForm = ({
  employerId,
  isEdit,
  openEnrollmentPeriodId,
  onSuccess,
}: Props) => {
  const { startLoading, stopLoading } = useLoadingOverlay();
  const { planYearSetupForm, onSubmit } = usePlanYearSetupForm({
    selectedOpenEnrollmentPeriodId: openEnrollmentPeriodId,
    isFinalizationMode,
    employerId,
  });

  const { isValid, isDirty } = planYearSetupForm.formState || {};
  const isSubmitDisabled = isEdit ? !isValid || !isDirty : !isValid;

  const handleSubmit = async () => {
    startLoading();
    if (await onSubmit()) {
      showSuccessNotification({
        message: SUCCESS_MESSAGES.SAVED_SUCCESSFULLY_MESSAGE,
      });
      onSuccess();
    }
    stopLoading();
  };

  return (
    <Stack>
      <PlanYearSetupFormInputs
        planYearSetupForm={planYearSetupForm}
        isFinalizationMode
      />
      <Button
        disabled={isSubmitDisabled}
        onClick={handleSubmit}
        size="md"
        m="auto"
        mt="lg"
      >
        Submit
      </Button>
    </Stack>
  );
};
