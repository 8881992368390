import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const OtherIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_535_1726"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="32"
          height="32"
        >
          <rect width="32" height="32" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_535_1726)">
          <path
            d="M24 17.0007C24 23.9043 20.4043 29.0007 14.6053 29.0007C8.80627 29.0007 3 23.9043 3 17.0007C3 10.0972 8.80627 4.00073 14.6053 4.00073C20.4043 4.00073 24 10.0972 24 17.0007Z"
            fill="#E8E8E8"
          />
          <circle
            cx="15.9998"
            cy="16.0001"
            r="12.5833"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
          <g clipPath="url(#clip0_535_1726)">
            <path
              d="M10.0002 16.6666C10.3684 16.6666 10.6668 16.3681 10.6668 15.9999C10.6668 15.6317 10.3684 15.3333 10.0002 15.3333C9.63197 15.3333 9.3335 15.6317 9.3335 15.9999C9.3335 16.3681 9.63197 16.6666 10.0002 16.6666Z"
              fill="#1A1721"
              stroke="#1A1721"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.0002 16.6666C16.3684 16.6666 16.6668 16.3681 16.6668 15.9999C16.6668 15.6317 16.3684 15.3333 16.0002 15.3333C15.632 15.3333 15.3335 15.6317 15.3335 15.9999C15.3335 16.3681 15.632 16.6666 16.0002 16.6666Z"
              fill="#1A1721"
              stroke="#1A1721"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.0002 16.6666C22.3684 16.6666 22.6668 16.3681 22.6668 15.9999C22.6668 15.6317 22.3684 15.3333 22.0002 15.3333C21.632 15.3333 21.3335 15.6317 21.3335 15.9999C21.3335 16.3681 21.632 16.6666 22.0002 16.6666Z"
              fill="#1A1721"
              stroke="#1A1721"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_535_1726">
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(8 8)"
            />
          </clipPath>
        </defs>
      </svg>
    </Center>
  );
};
