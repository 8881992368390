/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum BusinessEntityType {
  C_CORPORATION = 'C_CORPORATION',
  S_CORPORATION = 'S_CORPORATION',
  NON_PROFIT_ORGANIZATION = 'NON_PROFIT_ORGANIZATION',
  PARTNERSHIP = 'PARTNERSHIP',
  LIMITED_LIABILITY_CORPORATION = 'LIMITED_LIABILITY_CORPORATION',
  LIMITED_LIABILITY_PARTNERSHIP = 'LIMITED_LIABILITY_PARTNERSHIP',
  SOLE_PROPRIETORSHIP = 'SOLE_PROPRIETORSHIP',
  UNION = 'UNION',
  GOVERNMENT_AGENCY = 'GOVERNMENT_AGENCY',
  OTHER = 'OTHER',
}
