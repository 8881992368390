import { Center } from '@zorro/zorro-ui-design';
import React from 'react';

import { SVGIconProps } from '../types';
import classNames from './Icon.module.scss';

export const IconNotApplicable = ({ style, grow }: SVGIconProps) => {
  return (
    <Center style={style} classNames={classNames} data-grow={grow}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
      >
        <path
          d="M10.3333 5V15M13.8333 13.5V8.25C13.8333 7.78587 14.0176 7.34075 14.3458 7.01256C14.674 6.68437 15.1191 6.5 15.5833 6.5C16.0474 6.5 16.4925 6.68437 16.8207 7.01256C17.1489 7.34075 17.3333 7.78587 17.3333 8.25V13.5M13.8333 10.875H17.3333"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.24988 13.3332V6.6665L6.99988 13.3332V6.6665"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Center>
  );
};
