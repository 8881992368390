/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum DocumentType {
  PLAN = 'PLAN',
  ADMIN = 'ADMIN',
  COMPLIANCE = 'COMPLIANCE',
  FINANCE = 'FINANCE',
}
