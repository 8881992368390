import { PayrollCycle } from '@zorro/clients';
import { DeepReadonly } from '@zorro/types';

export const payrollCycleLabelConfig: DeepReadonly<{
  [key in PayrollCycle]: string;
}> = {
  [PayrollCycle.MONTHLY]: 'Monthly (12 cycles)',
  [PayrollCycle.SEMI_MONTHLY]: 'Semi monthly (24 cycles)',
  [PayrollCycle.BIWEEKLY]: 'Bi weekly (26 cycles)',
  [PayrollCycle.WEEKLY]: 'Weekly (52 cycles)',
};
