import { DatePicker, DatePickerProps, DatePickerType } from '@mantine/dates';

import classNames from '../DateInput/DateInput.module.scss';

export type IDatePickerProps<T extends DatePickerType> = DatePickerProps<T>;

export const ZorroDatePicker = <T extends DatePickerType>({
  size = 'xl',
  ...props
}: IDatePickerProps<T>) => {
  return <DatePicker classNames={classNames} size={size} {...props} />;
};
