import { useViewportSize } from '@mantine/hooks';
import { useMobileDetector } from '@zorro/shared/utils';
import { NAVBAR_WIDTH_EXPANDED } from '@zorro/types';
import { Button, Flex, Grid, Paper, Stack } from '@zorro/zorro-ui-design';
import { MouseEventHandler, ReactNode } from 'react';

const SHOPPING_BOTTOM_BAR_PADDING = 85;
const SHOPPING_BOTTOM_BAR_MIN_HEIGHT = 120;

type ShoppingBottomBarProps = {
  actionText: ReactNode | string;
  handleSkipAction: MouseEventHandler<HTMLButtonElement>;
  handleNextAction: MouseEventHandler<HTMLButtonElement>;
  isNextStepDisabled: boolean;
  isWaiveLoading?: boolean;
  isNextLoading?: boolean;
  infoText?: ReactNode;
};

export const ShoppingBottomBar = ({
  actionText,
  handleSkipAction,
  handleNextAction,
  isNextStepDisabled,
  isWaiveLoading,
  isNextLoading,
  infoText,
}: ShoppingBottomBarProps) => {
  const { isMobile } = useMobileDetector();
  const { width } = useViewportSize();

  const isNavbarExpanded = width > 1400;

  return (
    <Paper
      py={{ base: 'md', sm: undefined }}
      pr={{ base: 'md', lg: SHOPPING_BOTTOM_BAR_PADDING }}
      pl={{
        base: 'md',
        sm: isNavbarExpanded
          ? NAVBAR_WIDTH_EXPANDED + SHOPPING_BOTTOM_BAR_PADDING
          : SHOPPING_BOTTOM_BAR_PADDING,
      }}
      style={(theme) => ({
        position: 'fixed',
        right: 0,
        bottom: 0,
        width: '100%',
        // paddingLeft: marginLeftBasedOnScreenWidth,
        minHeight: SHOPPING_BOTTOM_BAR_MIN_HEIGHT,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 0,
        borderTop: `1px solid ${theme.colors.zorroGray[6]}`,
        // INFO: a minimum of 100 is necessary to overlap the sidebar
        zIndex: 100,
      })}
      className="excluded-from-print"
    >
      <Grid align="center" w="100%">
        {!isMobile && (
          <Grid.Col span={{ sm: 6, md: 8 }}>
            <Stack gap="xs">
              {actionText}
              {infoText}
            </Stack>
          </Grid.Col>
        )}
        <Grid.Col span={{ sm: 6, md: 4 }}>
          <Flex justify="end" direction="row" gap="md">
            <Button
              variant="secondary"
              loading={isWaiveLoading}
              onClick={handleSkipAction}
              size="lg"
            >
              Waive
            </Button>
            <Button
              onClick={handleNextAction}
              loading={isNextLoading}
              disabled={isNextStepDisabled}
              size="lg"
            >
              Confirm
            </Button>
          </Flex>
        </Grid.Col>
      </Grid>
    </Paper>
  );
};
