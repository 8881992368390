import { Popover, ScrollArea } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import {
  Box,
  Group,
  Icon,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@zorro/zorro-ui-design';
import { UseFormReturn } from 'react-hook-form';

import { CardOption } from './CardOption';
import classNames from './CardSelector.module.scss';
import { SelectedCardOption } from './SelectedCardOption';
import {
  CardSelectorOptionalProps,
  TCardSelectorOption,
} from './cardSelector.types';

type CardSelectorRequiredProps = {
  selectData: TCardSelectorOption[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any>;
  fieldName: string;
};

export type CardSelectorEditModeProps = CardSelectorOptionalProps &
  CardSelectorRequiredProps;

export const CardSelectorEditMode = (props: CardSelectorEditModeProps) => {
  const [opened, { close, toggle }] = useDisclosure(false);

  const { form, fieldName, selectData, required, label, tooltipLabel } = props;

  const { setValue, watch } = form;

  const selectedValue = watch(fieldName);

  const option = selectData.find(({ value }) => value === selectedValue);

  if (!option) {
    return null;
  }

  const options = selectData.map((item) => (
    <CardOption
      key={item.value}
      item={item}
      isSelected={item.value === selectedValue}
      onClick={() => {
        setValue(fieldName, item.value, {
          shouldDirty: true,
        });
        close();
      }}
    />
  ));

  return (
    <Stack gap="xs">
      <Group gap="xs" align="center">
        <Text size="sm">
          {label}
          {required && '*'}
        </Text>
        {tooltipLabel && (
          <Tooltip label={tooltipLabel}>
            <Box pos="relative" h="lg">
              <Icon icon={IconInfoCircle} size="1.25rem" />
            </Box>
          </Tooltip>
        )}
      </Group>

      <Group>
        <Popover
          opened={opened}
          width="target"
          position="bottom"
          shadow="md"
          onClose={close}
          classNames={classNames}
        >
          <Popover.Target>
            <Box flex="1 0 auto">
              <SelectedCardOption
                {...option}
                opened={opened}
                onClick={toggle}
              />
            </Box>
          </Popover.Target>
          <Popover.Dropdown>
            <ScrollArea.Autosize mah="7rem">{options}</ScrollArea.Autosize>
          </Popover.Dropdown>
        </Popover>
      </Group>
    </Stack>
  );
};
