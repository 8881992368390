import { IconChevronRight } from '@tabler/icons-react';
import { Group, Skeleton, brand } from '@zorro/zorro-ui-design';

export const EmployeePageBreadcrumbsSkeleton = () => {
  return (
    <Group gap="xs">
      <Skeleton h={18} width="100px" />
      <IconChevronRight height="1.25rem" color={brand.zorroGray400} />
      <Skeleton h={18} width="100px" />
      <IconChevronRight height="1.25rem" color={brand.zorroGray400} />
      <Skeleton h={18} width="100px" />
    </Group>
  );
};
