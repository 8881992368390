/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PlanYearEmployeeRenewalReportDto = {
  employeeId: string;
  employerId: string;
  planYear: number;
  targetOnboardingPeriodId: string;
  renewalStatus: PlanYearEmployeeRenewalReportDto.renewalStatus;
};
export namespace PlanYearEmployeeRenewalReportDto {
  export enum renewalStatus {
    NOT_APPLICABLE = 'NOT_APPLICABLE',
    WAIVED = 'WAIVED',
    RENEWED = 'RENEWED',
    CHANGED = 'CHANGED',
  }
}
