import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';
import classNames from './Icon.module.scss';

export const IconPersonPurple = ({ style, grow }: SVGIconProps) => {
  return (
    <Center style={style} classNames={classNames} data-grow={grow}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="none"
      >
        <circle cx="13" cy="21" r="8" fill="#CDABFF" />
        <path
          stroke="#1A1721"
          d="M16 15.667A5.333 5.333 0 1 0 16 5a5.333 5.333 0 0 0 0 10.667ZM8 29v-2.667A5.333 5.333 0 0 1 13.333 21h5.334A5.333 5.333 0 0 1 24 26.333V29"
        />
      </svg>
    </Center>
  );
};
