import { Center } from '@zorro/zorro-ui-design';

export const InviteByLinkDecoration = () => {
  return (
    <Center h="100%">
      <svg
        width="228"
        height="373"
        viewBox="0 0 228 373"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M228 371.792H0" stroke="#3C0789" strokeWidth="1.5" />
        <path
          d="M87.2365 201.55C87.2365 201.55 67.2061 270.587 58.5957 353.809L52.3354 357.964L79.6626 370.961L114.583 275.312L120.22 198.067"
          fill="#3C0789"
        />
        <path
          d="M95.4922 200.734L134.518 198.067L141.614 200.95L144.949 370.96H108.701L112.534 364.109L95.4922 200.734V200.734Z"
          fill="#E0E3FE"
        />
        <path
          d="M103.705 125.12C103.705 129.872 106.956 134.009 111.582 135.143L121.385 137.546"
          stroke="#3C0789"
          strokeWidth="1.5"
        />
        <path
          d="M113.691 123.899C113.952 123.899 114.163 123.688 114.163 123.428C114.163 123.168 113.952 122.957 113.691 122.957C113.43 122.957 113.219 123.168 113.219 123.428C113.219 123.688 113.43 123.899 113.691 123.899Z"
          fill="#3C0789"
        />
        <path
          d="M106.432 123.899C106.693 123.899 106.904 123.688 106.904 123.428C106.904 123.168 106.693 122.957 106.432 122.957C106.171 122.957 105.959 123.168 105.959 123.428C105.959 123.688 106.171 123.899 106.432 123.899Z"
          fill="#3C0789"
        />
        <path
          d="M108.561 126.243V118.976H105.768"
          stroke="#3C0789"
          strokeWidth="1.5"
        />
        <path d="M114.583 118.975H110.933" stroke="#3C0789" strokeWidth="1.5" />
        <path
          d="M120.22 122.698V125.222C121.584 125.222 122.691 124.118 122.691 122.757C122.691 122.681 122.687 122.607 122.681 122.533C122.542 121.002 120.22 121.161 120.22 122.698Z"
          fill="#E0E3FE"
        />
        <path
          d="M120.22 125.222C121.584 125.222 122.691 124.118 122.691 122.757"
          stroke="#3C0789"
          strokeWidth="1.5"
        />
        <path
          d="M110.557 127.092C111.196 127.731 112.232 127.731 112.871 127.092"
          stroke="#8033EF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M108.561 126.248L110.157 126.64"
          stroke="#3C0789"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M87.2368 163.039V201.55L141.929 214.96V172.068C141.929 156.86 131.52 143.616 116.715 139.986H116.715C109.614 138.245 102.106 139.857 96.3519 144.357C90.5979 148.857 87.2368 155.745 87.2368 163.039V163.039Z"
          fill="#8033EF"
        />
        <path
          d="M112.534 364.109L95.4922 200.734"
          stroke="#3C0789"
          strokeWidth="1.5"
        />
        <path
          d="M103.33 123.428L97.9141 120.466C97.9141 120.466 105.83 97.8578 122.078 107.832C138.327 117.806 121.385 129.193 121.385 129.193"
          stroke="#3C0789"
          strokeWidth="1.5"
        />
        <path
          d="M102.942 119.525C102.942 119.525 113.693 117.796 117.437 112.554C117.437 112.554 115.914 120.145 120.427 121.996"
          stroke="#3C0789"
          strokeWidth="1.5"
        />
        <path
          d="M129.124 145.974C129.124 145.974 147.659 158.976 155.658 177.125C155.658 177.125 152.825 143.74 156.825 138.254C158.936 135.358 161.325 139.29 161.325 139.29C161.325 139.29 171.824 134.929 172.157 136.592C172.49 138.254 166.324 144.571 166.324 144.571C166.324 144.571 175.323 185.897 168.324 197.7C161.325 209.503 125.828 185.964 125.828 185.964"
          stroke="#3C0789"
          strokeWidth="1.5"
        />
        <path
          d="M129.652 188.363C129.652 188.363 129.727 204.571 141.929 211.466V195.076L129.652 188.363Z"
          fill="#3C0789"
        />
        <path
          d="M96.5769 144.186C96.5769 144.186 80.1106 156.359 76.9998 168.613C76.9998 168.613 71.8892 135.448 69.0006 136.214C66.1119 136.979 65.4453 139.289 65.4453 139.289C65.4453 139.289 56.3351 134.535 55.0019 136.201C53.6687 137.866 63.0011 146.954 63.0011 146.954C63.0011 146.954 57.3555 186.587 63.8899 191.949C77.6664 203.253 87.2366 187.072 87.2366 187.072"
          stroke="#3C0789"
          strokeWidth="1.5"
        />
        <rect
          x="42.0352"
          y="32.9648"
          width="39.9999"
          height="39.956"
          rx="8"
          fill="#8033EF"
        />
        <path
          d="M59.5349 55.4408L64.5349 50.4463M61.2016 47.9493L61.5874 47.5031C62.3689 46.7226 63.4289 46.2841 64.534 46.2842C65.6391 46.2843 66.699 46.7229 67.4804 47.5035C68.2617 48.2842 68.7007 49.3429 68.7006 50.4468C68.7005 51.5507 68.2614 52.6094 67.4799 53.39L67.0349 53.7762M62.8683 57.938L62.5374 58.3825C61.7468 59.1635 60.6798 59.6015 59.5679 59.6015C58.456 59.6015 57.3889 59.1635 56.5983 58.3825C56.2086 57.9976 55.8992 57.5393 55.688 57.0341C55.4769 56.529 55.3682 55.987 55.3682 55.4395C55.3682 54.8921 55.4769 54.3501 55.688 53.8449C55.8992 53.3397 56.2086 52.8814 56.5983 52.4965L57.0349 52.1111"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="94.0352"
          width="39.9999"
          height="39.956"
          rx="8"
          fill="#3C0789"
        />
        <path
          d="M110.035 16.9818H118.035M110.035 20.9774H116.035M120.035 11.9873C120.831 11.9873 121.594 12.303 122.156 12.865C122.719 13.427 123.035 14.1892 123.035 14.984V22.9752C123.035 23.77 122.719 24.5322 122.156 25.0942C121.594 25.6562 120.831 25.9719 120.035 25.9719H115.035L110.035 28.9686V25.9719H108.035C107.24 25.9719 106.476 25.6562 105.914 25.0942C105.351 24.5322 105.035 23.77 105.035 22.9752V14.984C105.035 14.1892 105.351 13.427 105.914 12.865C106.476 12.303 107.24 11.9873 108.035 11.9873H120.035Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="146.035"
          y="32.9648"
          width="39.9999"
          height="39.956"
          rx="8"
          fill="#00BEE9"
        />
        <path
          d="M157.035 47.948C157.035 47.4181 157.246 46.91 157.621 46.5353C157.996 46.1607 158.505 45.9502 159.035 45.9502H173.035C173.566 45.9502 174.074 46.1607 174.449 46.5353C174.824 46.91 175.035 47.4181 175.035 47.948M157.035 47.948V57.937C157.035 58.4669 157.246 58.975 157.621 59.3497C157.996 59.7243 158.505 59.9348 159.035 59.9348H173.035C173.566 59.9348 174.074 59.7243 174.449 59.3497C174.824 58.975 175.035 58.4669 175.035 57.937V47.948M157.035 47.948L166.035 53.9414L175.035 47.948"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Center>
  );
};
