import { Stack, Text } from '@zorro/zorro-ui-design';

import { ModalFooter } from '../../ModalComponents';
import { useMonolithMutation } from '../../hooks';

type Props = {
  onClose: () => void;
  onSubmit?: () => void;
  onboardingPeriodId?: string;
};

export const DeleteEnrollmentRecord = ({
  onClose,
  onSubmit,
  onboardingPeriodId,
}: Props) => {
  const { tryMutate: mutateOnboardingPeriodDelete } = useMonolithMutation({
    successMessage: 'Deleted enrollment record successfully.',
    method: 'onboardingPeriodsControllerDelete',
  });

  if (!onboardingPeriodId) {
    return null;
  }

  const handleEnrollmentDelete = async () => {
    const mutationResult = await mutateOnboardingPeriodDelete([
      onboardingPeriodId,
    ]);

    if (mutationResult.isOk()) {
      onSubmit?.();
    }

    onClose();
  };

  return (
    <Stack gap="xl">
      <Text>
        Are you sure you want to delete this enrollment record?
        <br /> This action cannot be undone
      </Text>
      <ModalFooter
        onClose={onClose}
        onSubmit={handleEnrollmentDelete}
        submitVariant="primary-red"
        submitText="Delete"
      />
    </Stack>
  );
};
