import { CitizenshipStatus } from '@zorro/clients';
import { citizenshipStatusLabelConfig } from '@zorro/types';
import { ISelectProps, Select } from '@zorro/zorro-ui-design';
import { ForwardedRef, forwardRef } from 'react';
import { ControllerRenderProps, Path } from 'react-hook-form';

const citizenshipStatusOptions = Object.values(CitizenshipStatus).map(
  (status) => ({
    label: citizenshipStatusLabelConfig[status],
    value: status,
  })
);

type CitizenshipStatusFieldValues = {
  citizenshipStatus?: CitizenshipStatus | null;
};
type Props<
  T extends CitizenshipStatusFieldValues,
  U extends Path<T>
> = ControllerRenderProps<T, U> & { selectProps?: ISelectProps };

export const CitizenshipStatusInput = forwardRef(
  <T extends CitizenshipStatusFieldValues, U extends Path<T>>(
    { selectProps, ...field }: Props<T, U>,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <Select
        {...field}
        {...selectProps}
        ref={ref}
        label="Citizenship status"
        placeholder="Select citizenship status"
        data={citizenshipStatusOptions}
      />
    );
  }
);

CitizenshipStatusInput.displayName = 'CitizenshipStatusInput';
