import { CsvFileIcon } from '@zorro/shared/assets';
import { useMonolithQuery } from '@zorro/shared/utils';
import { Box, Icon, Skeleton } from '@zorro/zorro-ui-design';

import { exportAllowanceModelToCsv } from '../../PlanYearSetupForm/PlanYearSetupUtils';
import { NO_DATA_PLACEHOLDER } from '../planYearTable.consts';

type AllowanceModelCellProps = {
  employerId: string;
  employerName: string;
  year: string;
};

const AllowanceModelCell = ({
  employerId,
  employerName,
  year,
}: AllowanceModelCellProps) => {
  const { data: allowanceModel, isLoading: isLoadingAllowanceModel } =
    useMonolithQuery({
      method: 'allowanceModelControllerSearch',
      params: [employerId, { year: Number(year) }],
    });

  if (isLoadingAllowanceModel) {
    return <Skeleton height={26} />;
  }

  if (!allowanceModel) {
    return NO_DATA_PLACEHOLDER;
  }

  return (
    <Box
      onClick={() => exportAllowanceModelToCsv(allowanceModel, employerName)}
      style={{ cursor: 'pointer' }}
    >
      <Icon icon={CsvFileIcon} />
    </Box>
  );
};

export { AllowanceModelCell };
