import { Button, ButtonProps } from '@mantine/core';
import { ComponentPropsWithoutRef, MouseEventHandler, forwardRef } from 'react';

import classNames from './Button.module.scss';

export type IButtonProps = ButtonProps &
  ComponentPropsWithoutRef<'button'> & {
    onClick?: MouseEventHandler<HTMLButtonElement>;
    disableHover?: boolean;
  };

export const ZorroButton = forwardRef<HTMLButtonElement, IButtonProps>(
  (
    {
      variant = 'primary',
      size = 'xl',
      radius = 'md',
      disableHover = false,
      ...props
    },
    ref
  ) => {
    return (
      <Button
        type="button"
        ref={ref}
        size={size}
        radius={radius}
        variant={variant}
        classNames={classNames}
        data-disable-hover={disableHover}
        {...props}
      />
    );
  }
);

ZorroButton.displayName = 'ZorroButton';
