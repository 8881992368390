import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';
import classNames from './Icon.module.scss';

export const WomanWritingOnTablet = ({ style, grow }: SVGIconProps) => {
  return (
    <Center style={style} classNames={classNames} data-grow={grow}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 190 242" fill="none">
        <mask
          id="a"
          width="190"
          height="242"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'luminance' }}
        >
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M0 0h190v242H0V0Z"
            clipRule="evenodd"
          />
        </mask>
        <g mask="url(#a)">
          <path
            fill="#C5C9F0"
            d="M163.251 108.871c-.294.085-4.045 1.176-5.521 3.775a3.47 3.47 0 0 0-3.14-1.988h-44.056a3.461 3.461 0 0 0-3.378 2.685l-8.676 37.199a18.819 18.819 0 0 0-1.787-.244l-20.474-5.109a3.639 3.639 0 0 0-4.316 2.314 3.45 3.45 0 0 0-.2 1.193 3.649 3.649 0 0 0 3.392 3.64l9.682.623c-9.326 4.39-20.671 12.751-30.141 20.433-9.214-14.426-.568-28.85 5.936-36.618-.838-8.561-5.084-28.438-26.266-27.897 7.297-9.358 16.396-17.379 27.671-22.747 38.62 42.806 82.802 7.809 83.74 7.056a77.706 77.706 0 0 1 17.534 15.691M159.238 231.669v7.397H31.698v-10.478c4.969.294 10.196-.882 14.95-3.026 6.018-2.74 11.216-6.997 15.661-11.854 4.572-5.027 7.083-11.998 9.996-18.06 4.375-9.129 10.17-15.632 19.366-18.387l-.588 2.529a3.475 3.475 0 0 0 3.378 4.26h44.277c-.882 7.644-4.602 49.392 20.5 47.628"
          />
          <path
            fill="#3C0789"
            d="M138.758 37.126a48.904 48.904 0 0 1-1.535 11.722 9.721 9.721 0 0 1-7.679 7.23c-.073-2.67-1.208-5.013-2.94-6.469.158-2.26.136-4.529-.067-6.785a56.722 56.722 0 0 0-.483-4.13 15.29 15.29 0 0 0-.485-2.103 7.53 7.53 0 0 0-2.058-1.561c-2.725-1.535-8.335-3.166-18.727-.767a12.444 12.444 0 0 0-4.687 2.172.336.336 0 0 0-.082.07c-.938.865-2.132 1.959-3.367 2.526-1.278.588-2.613.612-3.733-.779-.03-.03-.044-.056-.07-.085-.589-.767-1.15-1.764-2.188-1.961-2.188-.412-3.252 1.764-3.528 3.593-.241 1.69.256 3.18.982 4.6a11.842 11.842 0 0 0 2.352 3.096c.243.236.467.492.667.765-.091.016-.18.04-.267.073a4.894 4.894 0 0 0-.588.242c-2.646 1.19-4.53 4.174-4.572 7.667 0 .109-4.437.044-4.998 0-2.164-.2-4.295-.823-5.818-2.455-4.322-4.63-4.302-11.501-2.526-16.876 1.682-5.1 3.822-10.08 6.627-14.67C85.065 12.31 94.493 4.983 106.43 4.325a39.553 39.553 0 0 1 8.558.5c1.337.21 2.66.505 3.96.881 12.169 5.31 19.892 17.346 19.822 31.42"
          />
          <path
            fill="#00BEE9"
            d="M131.711 150.345c-1.438 3.69-4.86 5.801-7.644 4.704-2.784-1.096-3.878-4.953-2.443-8.643 1.435-3.69 4.86-5.804 7.644-4.704 2.784 1.099 3.881 4.957 2.443 8.646"
          />
          <g stroke="#3C0789" strokeWidth="1.73">
            <path d="M139.108 183.297s-7.809 54.434 23.426 48.401c31.064-6.006 42.821-132.382-47.096-150.557M65.978 130.892s-25.201 21.253-11.621 42.509" />
            <path d="M139.675 168.177c-.432.026-1.049-.262-1.47-.377a43.043 43.043 0 0 1-1.584-.452 28.836 28.836 0 0 1-8.547-4.328c-.588-.421-1.634-.691-2.352-.415a2.376 2.376 0 0 0-1.529 1.873c-.22 1.517.85 3.002 2.017 3.975 1.385 1.158 2.823 2.226 4.501 2.919a31.442 31.442 0 0 0 4.998 1.538c.774.179 1.559.294 2.326.491" />
            <path d="M149.348 153.45c.318 1.676.55 3.416.391 5.119-.132 1.44-.65 3.122-2.252 3.472-.305.08-.621.109-.935.085-6.033-.356-11.004-2.44-16.681-6.544-5.678-4.105-7.677 5.348-2.491 7.023M127.598 169.594a4.764 4.764 0 0 1-.397-.368c-2.825-2.954-3.337 2.981-.37 4.728l.382.226c.2.085.414.185.626.294.1.027.212.071.315.1 2.681 1.176 5.536 1.929 8.346 2.696-2.781-.908-5.553-1.802-8.346-2.696-.241-.1-.471-.2-.712-.314a1.238 1.238 0 0 0-.229-.071.317.317 0 0 0-.312.029.438.438 0 0 0-.141.212c-.456 1.094-.085 2.473.497 3.452.611 1.034 1.661 1.831 2.696 2.399 2.784 1.561 6.021 2.484 9.146 3.037a6.37 6.37 0 0 0 1.764.114M166.074 190.168s-9.582-16.329-10.649-30.526c-.653-8.788-3.234-10.649-5.08-10.775" />
            <path d="m147.487 162.038 1.864-8.588.994-4.586 7.367-34.004a3.47 3.47 0 0 0-3.392-4.204h-44.059a3.462 3.462 0 0 0-3.378 2.684l-8.676 37.203-6.333 27.145-.588 2.543a3.478 3.478 0 0 0 3.381 4.257h44.273" />
          </g>
          <path
            fill="#00BEE9"
            d="M92.862 37.273s2.331-9.887 15.497-11.198c13.165-1.312 16.464 8.526 16.464 8.526l-.415.99s-17.308-6.944-26.866 2.967c0 0-3.442 1.561-4.68-1.279"
          />
          <g stroke="#3C0789" strokeWidth="1.73">
            <path d="m96.413 150.298-20.477-5.115a3.628 3.628 0 1 0-1.117 7.141l9.681.638" />
            <path
              strokeLinecap="round"
              d="M145.453 93.171s-44.727 36.233-83.752-7.041M60.298 136.769c-.837-8.562-5.08-28.454-26.28-27.898"
            />
            <path d="M162.984 108.865s-3.996 1.1-5.527 3.787M.67 239.507h189.897" />
          </g>
          <path
            fill="#00D16C"
            d="M126.604 49.621c1.791 1.505 2.94 3.963 2.94 6.744 0 4.616-3.207 8.35-7.17 8.35a6.042 6.042 0 0 1-1.332-.156c4.616-3.528 5.55-14.938 5.55-14.938"
          />
          <path
            stroke="#3C0789"
            strokeWidth="1.73"
            d="M100.494 68.252a17.552 17.552 0 0 0 14.406.556c12.989-5.425 12.492-21.413 11.143-30.103a16.54 16.54 0 0 0-.459-2.088c-1.491-4.968-5.38-9.017-10.505-10.166-9.925-2.243-19 3.384-22.767 10.96"
          />
          <path
            stroke="#3C0789"
            strokeWidth="1.73"
            d="M125.708 36.773s-.042-.059-.127-.156c-.882-.993-5.989-5.735-20.771-2.328a12.403 12.403 0 0 0-4.683 2.173.38.38 0 0 0-.089.07"
          />
          <path
            stroke="#3C0789"
            strokeWidth="1.73"
            d="M86.306 60.858h-7.312c-5.277 0-9.461-4.834-11.28-9.37-2.559-6.38-.921-12.91 1.38-19.11 1.93-5.19 4.382-10.231 7.607-14.744 7.22-10.099 18.122-15.82 30.652-14.762 1.111.094 2.218.231 3.319.412 16.826 2.728 28.171 16.796 28.086 33.833a48.924 48.924 0 0 1-1.534 11.73 9.753 9.753 0 0 1-7.683 7.23"
          />
          <path
            stroke="#3C0789"
            strokeWidth="1.73"
            d="M91.136 48.26a6.277 6.277 0 0 0-.667-.767 11.782 11.782 0 0 1-2.352-3.093c-.727-1.42-1.223-2.911-.982-4.602.27-1.816 1.334-4.004 3.528-3.592 1.038.2 1.59 1.193 2.187 1.96 2.27 2.94 5.395-.014 7.312-1.79"
          />
          <path
            fill="#00BEE9"
            fillRule="evenodd"
            d="M126.604 49.621c1.791 1.505 2.94 3.963 2.94 6.744 0 4.616-3.207 8.35-7.17 8.35a6.048 6.048 0 0 1-1.332-.156"
            clipRule="evenodd"
          />
          <path
            stroke="#3C0789"
            strokeWidth="1.73"
            d="M126.604 49.621c1.791 1.505 2.94 3.963 2.94 6.744 0 4.616-3.207 8.35-7.17 8.35a6.048 6.048 0 0 1-1.332-.156M111.26 55.286c1.606 1.08 5.483 1.964 4.704-1.234-.935-3.79-2.193-11.887 5.513-12.504 0 0 3.393.144 5.068 1.296M114.162 61.622s-3.881 2.058-5.803-2.043"
          />
          <path
            fill="#3C0789"
            d="M106.292 47.084c-.15 1.302.358 2.428 1.138 2.52.779.09 1.531-.883 1.684-2.194.153-1.311-.361-2.425-1.138-2.516-.776-.091-1.534.882-1.684 2.19ZM118.928 47.851c-.15 1.3.359 2.429 1.138 2.52.779.09 1.531-.882 1.681-2.194.15-1.31-.358-2.428-1.135-2.516-.776-.088-1.534.882-1.684 2.19Z"
          />
          <g stroke="#3C0789" strokeWidth="1.73">
            <path d="M111.26 42.38s-2.375-3.663-7.758-.188M115.435 68.567v20.32M35.097 190.168s8.376-7.938 19.254-16.758c9.47-7.682 20.827-16.058 30.155-20.445a47.144 47.144 0 0 1 3.478-1.47 19.036 19.036 0 0 1 10.223-.935" />
            <path
              strokeLinecap="round"
              d="M91.874 177.688c-9.2 2.754-14.994 9.258-19.366 18.386-2.907 6.063-5.424 13.033-9.996 18.061-4.442 4.854-9.64 9.114-15.658 11.854-10.92 4.968-24.364 4.586-31.458-6.218-4.798-7.327-6.686-16.429-7.644-24.99-1.647-14.88 3.551-56.822 26.266-85.913 7.297-9.358 16.4-17.378 27.671-22.747 7.912-3.757 16.855-6.215 26.992-6.912l2.84-14.65"
            />
            <path d="M159.441 232.163v7.344M31.901 229.54v9.967" />
          </g>
          <path
            fill="#00BEE9"
            d="M100.024 56.365a8.99 8.99 0 0 1-2.2 6.007 6.144 6.144 0 0 1-2.584.567c-3.96 0-7.17-3.733-7.17-8.35a8.97 8.97 0 0 1 2.202-6.003 6.113 6.113 0 0 1 2.584-.57c3.96 0 7.168 3.737 7.168 8.35Z"
          />
          <ellipse
            cx="92.862"
            cy="56.365"
            stroke="#3C0789"
            strokeWidth="1.73"
            rx="7.162"
            ry="8.35"
          />
        </g>
      </svg>
    </Center>
  );
};
