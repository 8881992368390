import { VALIDATION_MESSAGES } from '@zorro/shared/utils';
import * as yup from 'yup';

export const premiumAmountSchema = yup.object({
  employeeMonthlyContribution: yup
    .number()
    .min(0, VALIDATION_MESSAGES.employeeMonthlyContributionNotNegative)
    .typeError(VALIDATION_MESSAGES.employeeMonthlyContributionNotNegative)
    .required(VALIDATION_MESSAGES.employeeMonthlyContributionRequired),
  premium: yup
    .number()
    .min(0, VALIDATION_MESSAGES.premiumPositive)
    .typeError(VALIDATION_MESSAGES.premiumRequired)
    .required(VALIDATION_MESSAGES.premiumRequired),
});

export type PremiumAmountFormFields = yup.InferType<typeof premiumAmountSchema>;
