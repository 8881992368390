import { nprogress } from '@mantine/nprogress';
import { useEffect } from 'react';

import { useRouter } from './useRouter';

const handleStartProgress = () => nprogress.start();
const handleCompleteProgress = () => nprogress.complete();

export const useAppProgressBar = () => {
  const { router } = useRouter();

  useEffect(() => {
    router.events.on('routeChangeStart', handleStartProgress);
    router.events.on('routeChangeError', handleCompleteProgress);
    router.events.on('routeChangeComplete', handleCompleteProgress);
    return () => {
      router.events.off('routeChangeStart', handleStartProgress);
      router.events.off('routeChangeError', handleCompleteProgress);
      router.events.off('routeChangeComplete', handleCompleteProgress);
    };
  }, []);
};
