/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum BenefitDocumentType {
  SELF_REPORT = 'SELF_REPORT',
  APPLICATION = 'APPLICATION',
  ENROLLMENT = 'ENROLLMENT',
  WAIVE = 'WAIVE',
  CONFIRMATION = 'CONFIRMATION',
  OTHER = 'OTHER',
}
