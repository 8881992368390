import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { IconChevronRight } from '@tabler/icons-react';
import { ReactElement } from 'react';

import { ZorroGroup } from '../Group';
import { ZorroIcon } from '../Icon';
import { brand } from '../theme';
import classNames from './RadixMenu.module.scss';

type Props = DropdownMenuPrimitive.DropdownMenuSubTriggerProps & {
  leftSection?: ReactElement;
};

export const RadixMenuSubtrigger = ({
  children,
  leftSection,
  ...props
}: Props) => {
  return (
    <DropdownMenuPrimitive.SubTrigger
      {...props}
      className={classNames.subTrigger}
    >
      <ZorroGroup justify="space-between" w="100%">
        <ZorroGroup gap="xs">
          {leftSection}
          {children}
        </ZorroGroup>
        <ZorroIcon
          icon={IconChevronRight}
          color={brand.zorroGray800}
          size="1rem"
        />
      </ZorroGroup>
    </DropdownMenuPrimitive.SubTrigger>
  );
};
