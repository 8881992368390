import {
  IconCirclePlus,
  IconNotebook,
  IconWritingSign,
} from '@tabler/icons-react';
import {
  useDynamicMurrietaRouter,
  useMonolithQuery,
  useRoles,
} from '@zorro/shared/utils';
import { Icon, Stack } from '@zorro/zorro-ui-design';
import { ReactNode, useMemo, useState } from 'react';

import { AppLayout } from '../AppLayout';
import {
  EmployeeLayoutAction,
  EmployeeLayoutActions,
} from './EmployeeLayoutActions';
import { EmployeeLayoutBreadcrumbs } from './EmployeeLayoutBreadcrumbs';
import { EmployeeLayoutHeader } from './EmployeeLayoutHeader/EmployeeLayoutHeader';
import {
  EmployeeLayoutSidebar,
  EmployeeLayoutSidebarType,
  SidebarItemProps,
} from './EmployeeLayoutSidebar/EmployeeLayoutSidebar';
import { EmployeeLayoutSidebarSubitemProps } from './EmployeeLayoutSidebar/EmployeeLayoutSidebarSubitem';
import {
  mapOnboardingPeriodToEmployeeSidebarSubitem,
  sortOnboardingPeriodsForEmployeePageSidebar,
} from './EmployeeLayoutUtils';

type Props = {
  page: EmployeeLayoutSidebarType;
  employerId: string;
  employeeId: string;
  onboardingPeriodId?: string;
  children: ReactNode;
  isDon?: boolean;
};

export const EmployeeLayout = ({
  page,
  employerId,
  employeeId,
  onboardingPeriodId,
  children,
  isDon = false,
}: Props) => {
  const { isEmployerAdmin } = useRoles();
  const [action, setAction] = useState<EmployeeLayoutAction>();
  const { navigateToRoute } = useDynamicMurrietaRouter();
  const {
    data: onboardingPeriods = [],
    isLoading: isOnboardingPeriodsLoading,
  } = useMonolithQuery({
    method: 'onboardingPeriodReportsControllerGenerateEmployeeOnboardingReport',
    params: [employeeId],
  });
  const { data: employee } = useMonolithQuery({
    method: 'employeesControllerFindOne',
    params: [employeeId],
  });

  const enrollmentSubitems: EmployeeLayoutSidebarSubitemProps[] =
    useMemo(() => {
      const subitems =
        onboardingPeriods.length > 0
          ? onboardingPeriods
              .sort(sortOnboardingPeriodsForEmployeePageSidebar)
              .map((onboardingPeriod) => {
                const handleClick = () =>
                  navigateToRoute('EMPLOYEE_ENROLLMENT_TAB', [
                    {
                      employerId,
                      employeeId,
                      onboardingPeriodId: onboardingPeriod.id,
                    },
                  ]);

                return mapOnboardingPeriodToEmployeeSidebarSubitem({
                  currentOnboardingPeriodId: onboardingPeriodId,
                  onClick: handleClick,
                  onboardingPeriod,
                  employee,
                });
              })
          : [
              {
                id: 'no-enrollments',
                label: 'No enrollments yet',
                isChecked: page === EmployeeLayoutSidebarType.ENROLLMENTS,
                onClick: () =>
                  navigateToRoute('EMPLOYEE_NO_ENROLLMENTS', [
                    { employerId, employeeId },
                  ]),
              },
            ];

      if (!isEmployerAdmin) {
        subitems.push({
          id: 'new-enrollment',
          label: 'New enrollment',
          onClick: () => setAction(EmployeeLayoutAction.ADD_ENROLLMENT),
          leftContent: <Icon icon={IconCirclePlus} />,
        });
      }

      return subitems;
    }, [
      onboardingPeriods,
      page,
      isEmployerAdmin,
      onboardingPeriodId,
      employee,
      navigateToRoute,
      employerId,
      employeeId,
    ]);

  const selectedOnboardingPeriod = onboardingPeriods.find(
    ({ id }) => onboardingPeriodId === id
  );

  const sideBarItems: SidebarItemProps[] = [
    {
      page: EmployeeLayoutSidebarType.ENROLLMENTS,
      label: 'Enrollments',
      icon: IconWritingSign,
      subitems: enrollmentSubitems,
    },
  ];

  if (!isEmployerAdmin) {
    sideBarItems.push({
      page: EmployeeLayoutSidebarType.GENERAL_NOTES,
      label: 'General notes',
      icon: IconNotebook,
      onClick: () =>
        navigateToRoute('EMPLOYEE_GENERAL_NOTES', [{ employeeId, employerId }]),
      subitems: [],
    });
  }

  return (
    <AppLayout>
      <AppLayout.Sidebar>
        <EmployeeLayoutSidebar
          isOnboardingPeriodsLoading={isOnboardingPeriodsLoading}
          employerId={employerId}
          employeeId={employeeId}
          setAction={setAction}
          items={sideBarItems}
          page={page}
        />
      </AppLayout.Sidebar>
      <AppLayout.Main>
        <Stack>
          <EmployeeLayoutBreadcrumbs
            employerId={employerId}
            employeeId={employeeId}
            isDon={isDon}
          />
          <EmployeeLayoutHeader
            isOnboardingPeriodsLoading={isOnboardingPeriodsLoading}
            onboardingPeriodReport={selectedOnboardingPeriod}
            setAction={setAction}
            employee={employee}
          />
          {children}
        </Stack>
      </AppLayout.Main>
      <EmployeeLayoutActions
        onboardingPeriodId={onboardingPeriodId}
        employeeId={employeeId}
        employerId={employerId}
        setAction={setAction}
        action={action}
      />
    </AppLayout>
  );
};
