/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum DrugCoverageTier {
  GENERIC = 'generic',
  SPECIALTY = 'specialty',
  BRAND = 'brand',
  PREFERRED_BRAND = 'preferredBrand',
  PREFERRED_GENERIC = 'preferredGeneric',
  PREFERRED_SPECIALTY = 'preferredSpecialty',
  NON_PREFERRED_BRAND = 'nonPreferredBrand',
  NON_PREFERRED_GENERIC = 'nonPreferredGeneric',
  NON_PREFERRED_SPECIALTY = 'nonPreferredSpecialty',
  NOT_COVERED = 'notCovered',
  NOT_LISTED = 'notListed',
  NON_LISTED = 'nonListed',
  NO_COVERAGE = 'noCoverage',
}
