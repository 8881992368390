import { EmployeePaymentMethodType, PaymentStatus } from '@zorro/clients';
import { formatToFixedFloat } from '@zorro/shared/formatters';
import { Group, Icon, Text, Tooltip } from '@zorro/zorro-ui-design';

import {
  PaymentMethodCombinedPayIcon,
  PaymentMethodSelfPayIcon,
  PaymentMethodZorroPayIcon,
  PaymentStatusFalseAgentIcon,
  PaymentStatusFalseEmployeeIcon,
  PaymentStatusTrueAgentIcon,
  PaymentStatusTrueEmployeeIcon,
  PaymentStatusUnknownEmployeeIcon,
} from './PaymentBadgesIcons';

const paymentMethodToProps = {
  [EmployeePaymentMethodType.ZORRO_PAY]: {
    label: 'Zorro Pay',
    icon: PaymentMethodZorroPayIcon,
  },
  [EmployeePaymentMethodType.SELF_PAY]: {
    label: 'Self Pay',
    icon: PaymentMethodSelfPayIcon,
  },

  [EmployeePaymentMethodType.COMBINED]: {
    label: 'Combined',
    icon: PaymentMethodCombinedPayIcon,
  },
};

interface PaymentMethodBadgeProps {
  paymentMethod: EmployeePaymentMethodType;
  premium: number;
  selfPayAmount: number;
}

const tooltipCombinedText = (zorroPay: number, selfPay: number) =>
  `$${formatToFixedFloat(zorroPay)} through Zorro Pay and $${formatToFixedFloat(
    selfPay
  )} through Self-pay`;

export const PaymentMethodBadge = ({
  paymentMethod,
  premium,
  selfPayAmount,
}: PaymentMethodBadgeProps) => {
  const zorroPay = premium - selfPayAmount;
  const tooltipText =
    paymentMethod === EmployeePaymentMethodType.COMBINED
      ? tooltipCombinedText(zorroPay ?? 0, selfPayAmount ?? 0)
      : null;

  return (
    <Tooltip label={tooltipText} position="top" disabled={!tooltipText}>
      <Group
        wrap="nowrap"
        bg="zorroGray.10"
        gap="xs"
        pr="md"
        w="max-content"
        style={(theme) => ({
          borderRadius: `${theme.radius.lg}`,
        })}
      >
        <Icon icon={paymentMethodToProps[paymentMethod].icon} />
        <Text size="sm">{paymentMethodToProps[paymentMethod].label}</Text>
      </Group>
    </Tooltip>
  );
};

interface PaymentStatusBadgeProps {
  status: PaymentStatus;
}

export const paymentStatusToProps = {
  [PaymentStatus.TRUE]: {
    label: 'Set up by agent',
    icon: PaymentStatusTrueAgentIcon,
  },
  [PaymentStatus.TRUE_BY_EMPLOYEE]: {
    label: 'Set up by employee',
    icon: PaymentStatusTrueEmployeeIcon,
  },
  [PaymentStatus.FALSE]: {
    label: 'Not set up - waiting for agent',
    icon: PaymentStatusFalseAgentIcon,
  },
  [PaymentStatus.FALSE_BY_EMPLOYEE]: {
    label: 'Not set up - waiting for employee',
    icon: PaymentStatusFalseEmployeeIcon,
  },
  [PaymentStatus.UNKNOWN_BY_EMPLOYEE]: {
    label: 'Awaiting employee report',
    icon: PaymentStatusUnknownEmployeeIcon,
  },
};

export const InitialPaymentStatusBadge = ({
  status,
}: PaymentStatusBadgeProps) => {
  return (
    <Group
      wrap="nowrap"
      bg="zorroGray.10"
      gap="xs"
      pr="md"
      w="max-content"
      style={(theme) => ({
        borderRadius: `${theme.radius.lg}`,
      })}
    >
      <Icon icon={paymentStatusToProps[status].icon} />
      <Text size="sm">{paymentStatusToProps[status].label}</Text>
    </Group>
  );
};
