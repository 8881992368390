import { Grid, Text } from '@zorro/zorro-ui-design';
import { ReactNode } from 'react';

import { IconWithTitle } from './IconWithTitle';

type NoEmployeeContributionProps = {
  title: string;
  icon?: ReactNode;
};

export const NoEmployeeContribution = ({
  title,
  icon,
}: NoEmployeeContributionProps) => {
  return (
    <>
      <Grid.Col span={6}>
        <IconWithTitle icon={icon} title={title} />
      </Grid.Col>

      <Grid.Col span={6}>
        <Text>Carrier(s) will be in touch to provide pricing</Text>
      </Grid.Col>
    </>
  );
};
