import { EmployeePaymentMethodType } from '@zorro/clients';
import { formatCurrencyEnUs } from '@zorro/shared/formatters';
import { useMonolithQuery } from '@zorro/shared/utils';
import { Divider, Group, Text, Title, brand } from '@zorro/zorro-ui-design';

type PaymentAmountDistributionProps = {
  employeeId: string;
  onboardingPeriodId: string;
};

const PaymentAmountDistribution = ({
  employeeId,
  onboardingPeriodId,
}: PaymentAmountDistributionProps) => {
  const { data, isLoading } = useMonolithQuery({
    method: 'paymentsControllerEmployeePaymentMethodDetails',
    params: [employeeId, { onboardingPeriodId }],
  });

  if (isLoading || !data || data.type !== EmployeePaymentMethodType.COMBINED) {
    return null;
  }

  const zorroPayText =
    data?.associatedBenefits?.majorMedical.premiumAmount &&
    data?.associatedBenefits?.majorMedical.selfPayAmount
      ? formatCurrencyEnUs(
          data.associatedBenefits.majorMedical.premiumAmount -
            data.associatedBenefits.majorMedical.selfPayAmount
        )
      : 'N/A';
  const selfPayText = data?.associatedBenefits?.majorMedical.selfPayAmount
    ? formatCurrencyEnUs(data.associatedBenefits.majorMedical.selfPayAmount)
    : 'N/A';

  return (
    <>
      <Title order={3} size="sm">
        Payment amount distribution
      </Title>
      <Group gap="lg">
        <Group gap="xs">
          <Text>Zorro Pay:</Text>
          <Text fw="bold">{zorroPayText}</Text>
        </Group>
        <Group gap="xs">
          <Text>Self-pay:</Text>
          <Text fw="bold">{selfPayText}</Text>
        </Group>
      </Group>
      <Divider color={brand.zorroGray200} />
    </>
  );
};

export { PaymentAmountDistribution };
