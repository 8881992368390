import { MantineTheme } from '@mantine/core';
import {
  EmployeeReportRowDto,
  EmploymentStatus,
  ExtendedBenefitStatus,
  FamilyUnit,
  GetEmployerDto,
  OpenEnrollmentPeriodDto,
  UserStatus,
} from '@zorro/clients';
import { formatCurrencyEnUs, formatDateISO } from '@zorro/shared/formatters';
import {
  MURRIETA_DYNAMIC_ROUTES,
  isDefined,
  screamingSnakeCaseToText,
  screamingSnakeCaseToTitle,
} from '@zorro/shared/utils';
import {
  EmployeesReportView,
  employerFamilyUnitLabelConfig,
  recordsPerPage,
} from '@zorro/types';
import { Box, Group, Link, Text, brand } from '@zorro/zorro-ui-design';
import { DataTableSortStatus } from 'mantine-datatable';
import { useEffect, useState } from 'react';

import { AgentStatusBadge, agentBadgeConfig } from '../AgentStatusBadge';
import { EligibilityStatusBadgeTooltip } from '../EligibilityStatusBadge';
import {
  eligibleLabel,
  getEligibilityStatusLabel,
} from '../EligibilityStatusBadge/EligibilityStatusBadgeUtils';
import { ActionsMenu, EmployeeActionsOverlays } from '../EmployeeActions';
import { EmployeeManagementActionsMenu } from '../EmployeeManagementActions';
import { EnrollmentStatusBadge } from '../EnrollmentStatus';
import { Filter, SelectFilter } from '../Filters';
import {
  InitialPaymentStatusBadge,
  PaymentMethodBadge,
  paymentStatusToProps,
} from '../Payments';
import { PlanMarketBadgeTableView } from '../PlanMarketEnrollment';
import { QualifyingLifeEventLabel } from '../QualifyingLifeEventLabel';
import { SearchableDataTable } from '../SearchableDataTable';
import { UserAvatar } from '../UserAvatar';
import {
  EmployeesViewToDataMap,
  PaginatedEmployeeReportsViewToDataMap,
} from './employeesDatatable.types';
import {
  ColumnId,
  EmployeeRow,
  downloadEmployees,
  employeesDataToFormattedRows,
  getAvailableViews,
  getFilteredSortedSearchedRecords,
} from './employeesDatatable.utils';

export function renderCellValue(cellValue: string) {
  return <Text size="sm">{cellValue || '-'}</Text>;
}

type Props = {
  employer: GetEmployerDto;
  employeesViewToDataMap: PaginatedEmployeeReportsViewToDataMap;
  currentView: EmployeesReportView;
  setCurrentView: (view: EmployeesReportView) => void;
  hiddenColumnIds?: ColumnId[];
  selectedEnrollmentPeriod: OpenEnrollmentPeriodDto | null;
  isSelectable?: boolean;
  selectedEmployees?: EmployeeRow[];
  setSelectedEmployeeIds?: (employees: string[]) => void;
  setOverlayId: (overlayId: EmployeeActionsOverlays) => void;
};

type EmployeeRowKey = keyof EmployeeRow;

/* eslint-disable sonarjs/cognitive-complexity */
export const EmployeesDatatable = ({
  employer,
  employeesViewToDataMap,
  currentView,
  setCurrentView,
  hiddenColumnIds = [],
  selectedEnrollmentPeriod,
  isSelectable,
  selectedEmployees,
  setSelectedEmployeeIds,
  setOverlayId,
}: Props) => {
  const [searchValue, setSearchValue] = useState('');
  const [filters, setFilters] = useState<Filter<EmployeeRow>[]>([]);
  const [page, setPage] = useState(1);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: 'fullName',
    direction: 'asc',
  });

  function shouldHideColumnId(id: ColumnId) {
    return hiddenColumnIds.includes(id);
  }

  const filteredEmployeesViewToDataMap: EmployeesViewToDataMap = new Map();

  employeesViewToDataMap.forEach(({ data, isLoading }, view) => {
    const filteredData = getFilteredSortedSearchedRecords(
      data,
      filters,
      sortStatus,
      searchValue
    );

    filteredEmployeesViewToDataMap.set(view, { isLoading, data: filteredData });
  });

  const availableViews = getAvailableViews(
    filteredEmployeesViewToDataMap,
    selectedEnrollmentPeriod
  );

  useEffect(() => {
    const isCurrentViewAvailable = availableViews.some(
      ({ value }) => currentView === value
    );
    if (!isCurrentViewAvailable) {
      setCurrentView(EmployeesReportView.ALL);
    }
  }, [availableViews, currentView, setCurrentView]);

  const createSelectOptions = <K extends EmployeeRowKey>(
    property: K,
    formatToLabel: (value: NonNullable<EmployeeRow[K]>) => string = String
  ) => {
    const result: { value: string; label: string }[] = [
      { value: '', label: '-' },
    ];

    const unfilteredEmployeeReportRows =
      employeesViewToDataMap.get(currentView)?.data || [];

    const unfilteredEmployeeRows = employeesDataToFormattedRows(
      unfilteredEmployeeReportRows
    );

    const filteredOptions = [
      ...new Set(unfilteredEmployeeRows.map((row) => row[property])),
    ]
      .filter(Boolean)
      .map((option) => ({
        value: String(option),
        label: formatToLabel(option as NonNullable<EmployeeRow[K]>),
      }));
    result.push(...filteredOptions);

    return result;
  };

  const handleFilterChange = (value: Filter<EmployeeRow>[]) => {
    setFilters(value);
    setPage(1);
    setSelectedEmployeeIds?.([]);
  };

  const handleSearchValueChange = (value: string) => {
    setSearchValue(value);
    setPage(1);
    setSelectedEmployeeIds?.([]);
  };

  const handlePageChange = (page: number) => {
    setPage(page);
    setSelectedEmployeeIds?.([]);
  };

  const { data: viewedRecords = [], isLoading: isLoadingViewedRecords } =
    filteredEmployeesViewToDataMap.get(currentView) || {};

  const pageRecords = viewedRecords.slice(
    (page - 1) * recordsPerPage,
    page * recordsPerPage
  );

  return (
    <SearchableDataTable
      records={pageRecords}
      totalRecords={viewedRecords.length}
      searchValue={searchValue}
      componentsLeftToDownloadButton={
        <Group gap="xl">
          <ActionsMenu
            setOverlayId={setOverlayId}
            selectedEmployees={selectedEmployees || []}
          />
          <EmployeeManagementActionsMenu
            setOverlayId={setOverlayId}
            isDisabled={isLoadingViewedRecords}
          />
        </Group>
      }
      onSearchChange={handleSearchValueChange}
      onDownload={() =>
        downloadEmployees(viewedRecords, employer.name, hiddenColumnIds)
      }
      availableViews={availableViews}
      currentView={currentView}
      setCurrentView={setCurrentView as (view: string) => void}
      highlightOnHover
      pinFirstColumn
      isSelectable={isSelectable}
      grow
      groups={[
        {
          id: ColumnId.EMPLOYEE_DETAILS,
          title: (
            <Box
              style={{
                textAlign: 'center',
                width: '100%',
              }}
            >
              Employee details
            </Box>
          ),
          columns: [
            {
              accessor: ColumnId.FULL_NAME,
              sortable: true,
              width: '240px',
              render: ({
                onboardingPeriodId = '',
                ...employee
              }: EmployeeReportRowDto) => (
                <Group gap="xs" wrap="nowrap" align="center">
                  <UserAvatar
                    firstName={employee.firstName}
                    lastName={employee.lastName}
                    color={brand.primaryButtonSelected}
                    size={26}
                    textSize={13}
                  />
                  <Link
                    href={
                      onboardingPeriodId
                        ? MURRIETA_DYNAMIC_ROUTES.EMPLOYEE_ENROLLMENT_TAB({
                            employerId: employer.id,
                            employeeId: employee.id,
                            onboardingPeriodId,
                          })
                        : MURRIETA_DYNAMIC_ROUTES.EMPLOYEE_PAGE({
                            employerId: employer.id,
                            employeeId: employee.id,
                          })
                    }
                    prefetch={false}
                    anchorProps={{ underline: 'never', size: 'sm' }}
                  >
                    {employee.fullName}
                  </Link>
                </Group>
              ),
              hidden: shouldHideColumnId(ColumnId.FULL_NAME),
            },
            {
              accessor: ColumnId.EMAIL,
              sortable: true,
              hidden: shouldHideColumnId(ColumnId.EMAIL),
            },
            {
              accessor: ColumnId.INVITATION_STATUS,
              sortable: true,
              filtering: filters.some(
                (filter) => filter.type === ColumnId.INVITATION_STATUS
              ),
              filter: (
                <Group p="md">
                  <SelectFilter
                    title="Invitation status:"
                    selectOptions={createSelectOptions(
                      'invitationStatus',
                      (status: EmployeeRow[ColumnId.INVITATION_STATUS]) => {
                        return agentBadgeConfig[status as UserStatus].label;
                      }
                    )}
                    filterBy="invitationStatus"
                    setFilters={handleFilterChange}
                    filters={filters}
                  />
                </Group>
              ),
              render: ({ invitationStatus }) => (
                <AgentStatusBadge status={invitationStatus} />
              ),
            },
            {
              accessor: ColumnId.AGE,
              sortable: true,
              hidden: shouldHideColumnId(ColumnId.AGE),
            },
            {
              accessor: ColumnId.STATE,
              sortable: true,
              filtering: filters.some((filter) => filter.type === 'state'),
              filter: (
                <Group p="md">
                  <SelectFilter
                    title="State:"
                    selectOptions={createSelectOptions('state')}
                    filterBy="state"
                    setFilters={handleFilterChange}
                    filters={filters}
                  />
                </Group>
              ),
            },
            {
              accessor: ColumnId.CLASS,
              sortable: true,
              hidden: shouldHideColumnId(ColumnId.CLASS),
              filtering: filters.some((filter) => filter.type === 'class'),
              filter: (
                <Group p="md">
                  <SelectFilter
                    title="Class:"
                    selectOptions={createSelectOptions('class')}
                    setFilters={handleFilterChange}
                    filters={filters}
                    filterBy="class"
                  />
                </Group>
              ),
            },
            {
              accessor: ColumnId.BENEFIT_ELIGIBILITY,
              sortable: true,
              filtering: filters.some(
                (filter) => filter.type === ColumnId.BENEFIT_ELIGIBILITY
              ),
              filter: (
                <Group p="md">
                  <SelectFilter
                    title="Benefit Eligibility:"
                    selectOptions={createSelectOptions(
                      'benefitEligibility',
                      (status) => {
                        const label = getEligibilityStatusLabel(status);
                        if (label === eligibleLabel) {
                          switch (status) {
                            case EmploymentStatus.ELIGIBLE_EMPLOYED: {
                              return 'Eligible for benefits';
                            }

                            case EmploymentStatus.ELIGIBLE_UPCOMING_TERMINATION: {
                              return 'Eligible for benefits - ending';
                            }

                            case EmploymentStatus.ELIGIBLE_UPCOMING_LEAVE: {
                              return 'Eligible for benefits - upcoming leave';
                            }
                          }
                        }
                        return label;
                      }
                    )}
                    filterBy="benefitEligibility"
                    setFilters={handleFilterChange}
                    filters={filters}
                  />
                </Group>
              ),
              hidden: shouldHideColumnId(ColumnId.BENEFIT_ELIGIBILITY),
              width: '20rem',
              render: ({
                eligibleFrom,
                eligibleUntil,
                benefitEligibility,
                leaveOfAbsence,
                createdAt,
              }) => {
                return benefitEligibility ? (
                  <EligibilityStatusBadgeTooltip
                    eligibleFrom={eligibleFrom}
                    eligibleUntil={eligibleUntil}
                    employmentStatus={benefitEligibility}
                    leaveOfAbsence={leaveOfAbsence}
                    createdAt={createdAt}
                  />
                ) : (
                  '-'
                );
              },
            },
            {
              accessor: ColumnId.ROLE,
              filtering: filters.some((filter) => filter.type === 'role'),
              sortable: true,
              filter: (
                <Group p="md">
                  <SelectFilter
                    title="Role:"
                    selectOptions={createSelectOptions('role')}
                    setFilters={handleFilterChange}
                    filters={filters}
                    filterBy="role"
                  />
                </Group>
              ),
              hidden: shouldHideColumnId(ColumnId.ROLE),
            },
          ],
          style: (theme: MantineTheme) => ({
            backgroundColor: theme.colors.zorroGray50,
          }),
        },
        {
          id: 'enrollment',
          title: (
            <Box
              style={{
                textAlign: 'center',
                width: '100%',
              }}
            >
              Enrollment
            </Box>
          ),
          columns: [
            {
              accessor: ColumnId.ENROLLMENT_STATUS,
              title: 'Status',
              render: ({ enrollmentStatus }) => (
                <EnrollmentStatusBadge
                  enrollmentStatus={enrollmentStatus}
                  iconSize={32}
                />
              ),
              sortable: true,
              filtering: filters.some(
                (filter) => filter.type === 'enrollmentStatusLabel'
              ),
              filter: (
                <Group p="md">
                  <SelectFilter
                    title="Enrollment status:"
                    selectOptions={createSelectOptions('enrollmentStatusLabel')}
                    filterBy="enrollmentStatusLabel"
                    setFilters={handleFilterChange}
                    filters={filters}
                  />
                </Group>
              ),
              hidden: shouldHideColumnId(ColumnId.ENROLLMENT_STATUS),
            },
            {
              accessor: ColumnId.ELECTION_START,
              title: 'Election start',
              sortable: true,
              hidden: shouldHideColumnId(ColumnId.ELECTION_START),
              cellsStyle: () => ({
                paddingLeft: '1rem',
                paddingRight: '1.5rem',
              }),
              render: ({ electionStart }) => renderCellValue(electionStart),
            },
            {
              accessor: ColumnId.ELECTION_END,
              title: 'Election end',
              sortable: true,
              hidden: shouldHideColumnId(ColumnId.ELECTION_END),
              cellsStyle: () => ({
                paddingLeft: '1rem',
                paddingRight: '1.5rem',
              }),
              render: ({ electionEnd }) => renderCellValue(electionEnd),
            },
            {
              accessor: ColumnId.COVERAGE_START,
              title: 'Coverage start',
              sortable: true,
              hidden: shouldHideColumnId(ColumnId.COVERAGE_START),
              render: ({ coverageStart }) => renderCellValue(coverageStart),
            },
            {
              accessor: ColumnId.COVERAGE_END,
              title: 'Coverage end',
              sortable: true,
              hidden: shouldHideColumnId(ColumnId.COVERAGE_END),
              render: ({ coverageEnd }) => renderCellValue(coverageEnd),
            },
            {
              accessor: ColumnId.ENROLLMENT_TYPE,
              title: 'Type',
              sortable: true,
              width: '9.125rem',
              hidden: shouldHideColumnId(ColumnId.ENROLLMENT_TYPE),
              cellsStyle: () => ({ paddingLeft: '1rem', paddingRight: '1rem' }),
              filtering: filters.some(
                (filter) => filter.type === 'enrollmentType'
              ),
              filter: (
                <Group p="md">
                  <SelectFilter
                    title="Enrollment type:"
                    selectOptions={createSelectOptions('enrollmentType')}
                    filterBy="enrollmentType"
                    setFilters={handleFilterChange}
                    filters={filters}
                  />
                </Group>
              ),
              render: ({ enrollmentType }) => renderCellValue(enrollmentType),
            },
            {
              accessor: ColumnId.QLE,
              title: 'QLE',
              width: '240px',
              sortable: true,
              filtering: filters.some(
                (filter) => filter.type === ColumnId.QLE_TYPE
              ),
              filter: (
                <Group p="md">
                  <SelectFilter
                    title="QLE:"
                    selectOptions={createSelectOptions(
                      ColumnId.QLE_TYPE,
                      (value) => screamingSnakeCaseToTitle(value)
                    )}
                    filterBy={ColumnId.QLE_TYPE}
                    setFilters={handleFilterChange}
                    filters={filters}
                  />
                </Group>
              ),
              render: ({ qle }) =>
                qle ? (
                  <QualifyingLifeEventLabel
                    qualifyingLifeEvent={qle}
                    shouldShowTitle={false}
                  />
                ) : (
                  '-'
                ),
            },
            {
              accessor: ColumnId.ENROLLMENT_SUBMISSION_TYPE,
              title: 'Submission type',
              sortable: true,
              hidden: shouldHideColumnId(ColumnId.ENROLLMENT_SUBMISSION_TYPE),
              filtering: filters.some(
                (filter) => filter.type === ColumnId.ENROLLMENT_SUBMISSION_TYPE
              ),
              filter: (
                <Group p="md">
                  <SelectFilter
                    title="Enrollment submission type:"
                    selectOptions={createSelectOptions(
                      'enrollmentSubmissionType'
                    )}
                    filterBy="enrollmentSubmissionType"
                    setFilters={handleFilterChange}
                    filters={filters}
                  />
                </Group>
              ),
              render: ({ enrollmentSubmissionType }) =>
                renderCellValue(enrollmentSubmissionType),
            },
            {
              accessor: ColumnId.ENROLLMENT_SUBMISSION_DATE,
              title: 'Submission date',
              sortable: true,
              hidden: shouldHideColumnId(ColumnId.ENROLLMENT_SUBMISSION_DATE),
              render: ({ enrollmentSubmissionDate }) =>
                enrollmentSubmissionDate
                  ? renderCellValue(formatDateISO(enrollmentSubmissionDate))
                  : '-',
            },
            {
              accessor: ColumnId.ENROLLMENT_COMBINED_PLAN,
              title: 'Combined plan',
              sortable: true,
              hidden: shouldHideColumnId(ColumnId.ENROLLMENT_COMBINED_PLAN),
              filtering: filters.some(
                (filter) => filter.type === ColumnId.ENROLLMENT_COMBINED_PLAN
              ),
              filter: (
                <Group p="md">
                  <SelectFilter
                    title="Combined plan type:"
                    selectOptions={createSelectOptions(
                      'enrollmentIsCombinedPlan'
                    )}
                    filterBy="enrollmentIsCombinedPlan"
                    setFilters={setFilters}
                    filters={filters}
                  />
                </Group>
              ),
              render: ({ enrollmentIsCombinedPlan }) =>
                renderCellValue(enrollmentIsCombinedPlan),
            },
            {
              accessor: ColumnId.PAYMENT_METHOD,
              title: 'Payment method',
              sortable: true,
              filtering: filters.some(
                (filter) => filter.type === ColumnId.PAYMENT_METHOD
              ),
              filter: (
                <Group p="md">
                  <SelectFilter
                    title="Payment method:"
                    selectOptions={createSelectOptions(
                      ColumnId.PAYMENT_METHOD,
                      (label) => screamingSnakeCaseToTitle(label)
                    )}
                    filterBy={ColumnId.PAYMENT_METHOD}
                    setFilters={handleFilterChange}
                    filters={filters}
                  />
                </Group>
              ),
              hidden: shouldHideColumnId(ColumnId.PAYMENT_METHOD),
              render: ({ paymentMethod, medicalPremium, selfPayAmount }) =>
                paymentMethod ? (
                  <PaymentMethodBadge
                    paymentMethod={paymentMethod}
                    premium={medicalPremium}
                    selfPayAmount={selfPayAmount}
                  />
                ) : (
                  '-'
                ),
            },
            {
              accessor: ColumnId.INITIAL_PREMIUM_PAYMENT_STATUS,
              title: 'Initial payment status',
              sortable: true,
              filtering: filters.some(
                (filter) =>
                  filter.type === ColumnId.INITIAL_PREMIUM_PAYMENT_STATUS
              ),
              filter: (
                <Group p="md">
                  <SelectFilter
                    title="Initial payment status:"
                    selectOptions={createSelectOptions(
                      ColumnId.INITIAL_PREMIUM_PAYMENT_STATUS,
                      (status) => paymentStatusToProps[status].label
                    )}
                    filterBy={ColumnId.INITIAL_PREMIUM_PAYMENT_STATUS}
                    setFilters={handleFilterChange}
                    filters={filters}
                  />
                </Group>
              ),
              hidden: shouldHideColumnId(
                ColumnId.INITIAL_PREMIUM_PAYMENT_STATUS
              ),
              render: ({ initialPremiumPaymentStatus }) =>
                initialPremiumPaymentStatus ? (
                  <InitialPaymentStatusBadge
                    status={initialPremiumPaymentStatus}
                  />
                ) : (
                  '-'
                ),
            },
            {
              accessor: ColumnId.AUTO_PAY_STATUS,
              title: 'Auto-pay status',
              sortable: true,
              filtering: filters.some(
                (filter) => filter.type === ColumnId.AUTO_PAY_STATUS
              ),
              filter: (
                <Group p="md">
                  <SelectFilter
                    title="Auto-pay status:"
                    selectOptions={createSelectOptions(
                      ColumnId.AUTO_PAY_STATUS,
                      (status) => paymentStatusToProps[status].label
                    )}
                    filterBy={ColumnId.AUTO_PAY_STATUS}
                    setFilters={handleFilterChange}
                    filters={filters}
                  />
                </Group>
              ),
              hidden: shouldHideColumnId(ColumnId.AUTO_PAY_STATUS),
              render: ({ autoPayStatus }) =>
                autoPayStatus ? (
                  <InitialPaymentStatusBadge status={autoPayStatus} />
                ) : (
                  '-'
                ),
            },
            {
              accessor: ColumnId.MEDICAL_CARRIER,
              title: 'Carrier',
              width: '240px',
              hidden: shouldHideColumnId(ColumnId.MEDICAL_CARRIER),
              sortable: true,
              render: ({ medicalCarrier }) => renderCellValue(medicalCarrier),
              filtering: filters.some(
                (filter) => filter.type === 'medicalCarrier'
              ),
              filter: (
                <Group p="md">
                  <SelectFilter
                    title="Carrier name"
                    selectOptions={createSelectOptions('medicalCarrier')}
                    filterBy="medicalCarrier"
                    setFilters={handleFilterChange}
                    filters={filters}
                  />
                </Group>
              ),
            },
            {
              accessor: ColumnId.MEDICAL_PLAN_NAME,
              title: 'Plan name',
              width: '240px',
              hidden: shouldHideColumnId(ColumnId.MEDICAL_PLAN_NAME),
              cellsStyle: () => ({ padding: '0 1rem' }),
              sortable: true,
              render: ({ medicalPlanName }) => renderCellValue(medicalPlanName),
            },
            {
              accessor: ColumnId.MEDICAL_PLAN_ID,
              title: 'Plan ID',
              width: '240px',
              hidden: shouldHideColumnId(ColumnId.MEDICAL_PLAN_ID),
              sortable: true,
              render: ({ medicalPlanId }) => renderCellValue(medicalPlanId),
            },
            {
              accessor: ColumnId.MEDICAL_PLAN_MARKET,
              title: 'Plan market',
              hidden: shouldHideColumnId(ColumnId.MEDICAL_PLAN_MARKET),
              sortable: true,
              filtering: filters.some(
                (filter) => filter.type === ColumnId.MEDICAL_PLAN_MARKET
              ),
              filter: (
                <Group p="md">
                  <SelectFilter
                    title="Plan market:"
                    selectOptions={createSelectOptions(
                      ColumnId.MEDICAL_PLAN_MARKET,
                      (market) => screamingSnakeCaseToText(market)
                    )}
                    filterBy={ColumnId.MEDICAL_PLAN_MARKET}
                    setFilters={handleFilterChange}
                    filters={filters}
                  />
                </Group>
              ),
              render: ({ medicalPlanMarket, medicalStatus }) => {
                return medicalPlanMarket &&
                  medicalStatus !== ExtendedBenefitStatus.WAIVED ? (
                  <PlanMarketBadgeTableView planMarket={medicalPlanMarket} />
                ) : (
                  '-'
                );
              },
            },
            {
              accessor: ColumnId.MEDICAL_FAMILY_UNIT,
              title: 'Family unit',
              cellsStyle: () => ({ padding: '0 1rem' }),
              sortable: true,
              filtering: filters.some(
                (filter) => filter.type === ColumnId.MEDICAL_FAMILY_UNIT
              ),
              filter: (
                <Group p="md">
                  <SelectFilter
                    title="Family unit:"
                    selectOptions={createSelectOptions(
                      ColumnId.MEDICAL_FAMILY_UNIT,
                      (unit) => employerFamilyUnitLabelConfig[unit]
                    )}
                    filterBy={ColumnId.MEDICAL_FAMILY_UNIT}
                    setFilters={handleFilterChange}
                    filters={filters}
                  />
                </Group>
              ),
              hidden: shouldHideColumnId(ColumnId.MEDICAL_FAMILY_UNIT),
              render: ({ medicalFamilyUnit }) =>
                renderCellValue(
                  employerFamilyUnitLabelConfig[medicalFamilyUnit as FamilyUnit]
                ),
            },
            {
              accessor: ColumnId.DEPENDENT_COUNT,
              title: 'Children',
              sortable: true,
              filtering: filters.some(
                (filter) => filter.type === ColumnId.DEPENDENT_COUNT
              ),
              filter: (
                <Group p="md">
                  <SelectFilter
                    title="Dependent count:"
                    selectOptions={createSelectOptions(
                      ColumnId.DEPENDENT_COUNT
                    )}
                    filterBy={ColumnId.DEPENDENT_COUNT}
                    setFilters={handleFilterChange}
                    filters={filters}
                  />
                </Group>
              ),
              hidden: shouldHideColumnId(ColumnId.DEPENDENT_COUNT),
              render: ({ dependentCount }) =>
                dependentCount !== null && dependentCount !== undefined
                  ? renderCellValue(dependentCount)
                  : '-',
            },
            {
              accessor: ColumnId.IS_HSA_ELIGIBLE,
              title: 'HSA eligible',
              sortable: true,
              filtering: filters.some(
                (filter) => filter.type === ColumnId.IS_HSA_ELIGIBLE
              ),
              filter: (
                <Group p="md">
                  <SelectFilter
                    title="HSA eligible:"
                    selectOptions={createSelectOptions(
                      ColumnId.IS_HSA_ELIGIBLE
                    )}
                    filterBy={ColumnId.IS_HSA_ELIGIBLE}
                    setFilters={handleFilterChange}
                    filters={filters}
                  />
                </Group>
              ),
              hidden: shouldHideColumnId(ColumnId.IS_HSA_ELIGIBLE),
              render: ({ isHsaEligible }) => renderCellValue(isHsaEligible),
            },
            {
              accessor: ColumnId.MEDICAL_PREMIUM,
              title: 'Premium',
              hidden: shouldHideColumnId(ColumnId.MEDICAL_PREMIUM),
              sortable: true,
              render: ({ medicalPremium }) =>
                isDefined(medicalPremium)
                  ? formatCurrencyEnUs(medicalPremium)
                  : '-',
            },
            {
              accessor: ColumnId.MEDICAL_EMPLOYER_CONTRIBUTION,
              title: 'Employer contribution',
              hidden: shouldHideColumnId(
                ColumnId.MEDICAL_EMPLOYER_CONTRIBUTION
              ),
              sortable: true,
              render: ({ medicalEmployerContribution }) =>
                isDefined(medicalEmployerContribution)
                  ? formatCurrencyEnUs(medicalEmployerContribution)
                  : '-',
            },
            {
              accessor: ColumnId.MEDICAL_EMPLOYEE_CONTRIBUTION,
              title: 'Employee contribution',
              hidden: shouldHideColumnId(
                ColumnId.MEDICAL_EMPLOYEE_CONTRIBUTION
              ),
              sortable: true,
              render: ({ medicalEmployeeContribution }) =>
                isDefined(medicalEmployeeContribution)
                  ? formatCurrencyEnUs(medicalEmployeeContribution)
                  : '-',
            },
            {
              accessor: ColumnId.ALLOWANCE,
              title: 'Allowance',
              sortable: true,
              hidden: shouldHideColumnId(ColumnId.ALLOWANCE),
              render: ({ allowance }) =>
                renderCellValue(allowance ? `$${allowance}` : '-'),
            },
          ],
          style: (theme: MantineTheme) => ({
            backgroundColor: theme.colors.zorroGray50,
          }),
        },
        {
          id: ColumnId.ZORRO_ID,
          title: '',
          columns: [
            {
              accessor: ColumnId.ZORRO_ID,
              title: 'Zorro ID',
              width: '8rem',
              sortable: true,
              hidden: shouldHideColumnId(ColumnId.ZORRO_ID),
              render: ({ id }) => id.slice(0, 8),
            },
            {
              accessor: ColumnId.ID_FROM_EMPLOYER,
              title: 'Employee ID',
              width: '20.125rem',
              sortable: true,
              cellsStyle: () => ({ padding: '0.75rem' }),
              hidden:
                shouldHideColumnId(ColumnId.ID_FROM_EMPLOYER) ||
                pageRecords.every((record) => !record.idFromEmployer),
            },
          ],
          style: {
            borderBottom: 'none !important',
          },
        },
      ]}
      selectedRecords={selectedEmployees}
      onSelectedRecordsChange={(selectedRecords: EmployeeRow[]) =>
        setSelectedEmployeeIds?.(selectedRecords.map((record) => record.id))
      }
      recordsPerPage={recordsPerPage}
      page={page}
      onPageChange={handlePageChange}
      sortStatus={sortStatus}
      onSortStatusChange={setSortStatus}
      filtersCount={filters.length}
      clearFilters={() => setFilters([])}
      isLoading={isLoadingViewedRecords}
    />
  );
};
