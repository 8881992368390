import { getToday } from '@zorro/shared/formatters';
import { useMonolithQuery } from '@zorro/shared/utils';
import { Space, Stack, Text, Title } from '@zorro/zorro-ui-design';

import { FullPageLoader } from '../FullPageLoader';
import { CompanyCoverageOverview } from './CompanyCoverageOverview';
import CompanyEnrollmentOverview from './CompanyEnrollmentOverview';

interface CompanySummaryProps {
  employerId: string;
}

export const CompanyOverview = ({ employerId }: CompanySummaryProps) => {
  const { data: companyOverviewReport, isLoading } = useMonolithQuery({
    method: 'employerReportsControllerGenerateCompanyOverviewReport',
    params: [employerId],
  });

  if (isLoading) {
    return <FullPageLoader />;
  }

  const formattedToday = getToday().format('dddd, MMMM D');

  return (
    <Stack>
      <Text c="zorroGray.4">{formattedToday}</Text>
      {companyOverviewReport && (
        <>
          <Title size="24px">
            {companyOverviewReport.companyName} overview
          </Title>
          <Space h="md" />
          <CompanyCoverageOverview
            eligibleEmployeesCount={
              companyOverviewReport.eligibleEmployeesCount
            }
            currentCoverageCountByStatus={
              companyOverviewReport.currentCoverageCountByStatus
            }
          />
          <Space h="md" />
          {companyOverviewReport.openEnrollmentYear && (
            <CompanyEnrollmentOverview
              title="open enrollment"
              enrollmentYear={companyOverviewReport.openEnrollmentYear}
              enrollmentCounts={
                companyOverviewReport.openEnrollmentCountByStatus
              }
            />
          )}
          {companyOverviewReport.currentYear && (
            <CompanyEnrollmentOverview
              title="enrollment activities"
              enrollmentYear={companyOverviewReport.currentYear}
              enrollmentCounts={
                companyOverviewReport.currentYearEnrollmentCountByStatus
              }
            />
          )}
        </>
      )}
    </Stack>
  );
};
