import {
  getNow,
  parseDateEnLocale,
  parseDateISO,
} from '@zorro/shared/formatters';

export const calculateAge = (dateOfBirth: string): number => {
  return getNow().diff(parseDateISO(dateOfBirth), 'year');
};

export const calculateAgeForEnrollment = (
  dateOfBirth: string,
  targetEnrollmentDate: string
): number => {
  return parseDateISO(targetEnrollmentDate).diff(
    parseDateISO(dateOfBirth),
    'year'
  );
};

export const DEFAULT_ADULT_DATE = '01/01/1990';

export function getAdultDefaultDate(): Date {
  return parseDateEnLocale(DEFAULT_ADULT_DATE).toDate();
}
