/* eslint-disable id-length */
import { Title, TitleProps } from '@mantine/core';

export interface ITitleProps extends TitleProps {
  letterSpacing?: string;
}

export const ZorroTitle = ({
  c = 'zorroCoal.9',
  fw = 500,
  letterSpacing = '-0.02em',
  ...props
}: ITitleProps) => {
  return <Title c={c} fw={fw} style={{ letterSpacing }} {...props} />;
};
