import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import {
  callEndpoint,
  convertFromYesNo,
  convertToYesNoEnum,
  isDefined,
  logger,
  responseErrorToString,
  showErrorNotification,
  useForm,
  useMonolithQuery,
} from '@zorro/shared/utils';
import { YesNo } from '@zorro/types';

import {
  CompanySetupFormFields,
  getCompanySetupSchemaObject,
} from './CompanySetupUtils';

export const useCompanySetupForm = (
  employerId: string | undefined,
  isFinalizationMode: boolean
) => {
  const queryClient = useQueryClient();
  const { data: employer } = useMonolithQuery({
    method: 'employersControllerFindOne',
    params: employerId && [employerId],
  });

  const companySetupForm = useForm<CompanySetupFormFields>({
    mode: 'all',
    resolver: yupResolver(getCompanySetupSchemaObject(isFinalizationMode)),
    defaultValues: {
      payrollCycle: employer?.payrollCycle,
      waitingPeriod: employer?.waitingPeriod,
      isApplicableLargeEmployer: isDefined(employer?.isApplicableLargeEmployer)
        ? convertToYesNoEnum(employer?.isApplicableLargeEmployer)
        : YesNo.NO,
    },
  });

  return {
    companySetupForm,
    onSubmit: async () => {
      const data = companySetupForm.getValues();

      try {
        if (employerId) {
          await callEndpoint({
            method: 'employersControllerUpdate',
            params: [
              employerId,
              {
                payrollCycle: data.payrollCycle,
                waitingPeriod: data.waitingPeriod,
                isApplicableLargeEmployer: data.isApplicableLargeEmployer
                  ? convertFromYesNo(data.isApplicableLargeEmployer)
                  : null,
                enrollmentTeamIds: employer?.enrollmentTeamIds || [],
                zorroPartnerId: employer?.zorroPartnerId || null,
                producerId: employer?.producerId || null,
                isProspect: false,
              },
            ],
          });

          await queryClient.invalidateQueries();
          return true;
        }
      } catch (error) {
        logger.error(error);
        showErrorNotification({ message: responseErrorToString(error) });
      }

      return false;
    },
  };
};
