import { IconChevronDown } from '@tabler/icons-react';
import { useRoles } from '@zorro/shared/utils';
import { Button, RadixMenu } from '@zorro/zorro-ui-design';

import { AccountManagementSection } from './AccountManagement/AccountManagementSection';
import { EmailCommunicationsSection } from './EmailCommunications/EmailCommunicationsSection';
import { EmployeeAdministrationSection } from './EmployeeAdministration/EmployeeAdministrationSection';
import { EnrollmentActionsSection } from './EnrollmentActions/EnrollmentActionsSection';
import { ActionsMenuSection } from './employeeActions.types';

type ActionMenuProps = {
  selectedEmployees: ActionsMenuSection['selectedEmployees'];
  setOverlayId: ActionsMenuSection['setOverlayId'];
};

export function ActionsMenu({
  selectedEmployees,
  setOverlayId,
}: ActionMenuProps) {
  const { isManagement } = useRoles();

  const selectedEmployeesCount = selectedEmployees.length;

  return (
    <RadixMenu>
      <RadixMenu.Trigger disabled={selectedEmployeesCount === 0}>
        <Button
          disabled={selectedEmployeesCount === 0}
          variant="primary"
          size="xs"
          fw={400}
          fz="sm"
          rightSection={
            <IconChevronDown width="1.1875rem" height="1.1875rem" />
          }
        >
          Actions
        </Button>
      </RadixMenu.Trigger>

      <RadixMenu.Content align="end">
        <EmailCommunicationsSection
          setOverlayId={setOverlayId}
          selectedEmployees={selectedEmployees}
        />
        {isManagement && (
          <EnrollmentActionsSection
            setOverlayId={setOverlayId}
            selectedEmployees={selectedEmployees}
          />
        )}
        <EmployeeAdministrationSection
          setOverlayId={setOverlayId}
          selectedEmployees={selectedEmployees}
        />
        <AccountManagementSection
          setOverlayId={setOverlayId}
          selectedEmployees={selectedEmployees}
        />
      </RadixMenu.Content>
    </RadixMenu>
  );
}
