import { UploadedGenericFile } from '@zorro/shared/assets';
import { singleLineTruncatedTextWithEllipsis } from '@zorro/shared/formatters';
import { Group, Text } from '@zorro/zorro-ui-design';

import classNames from './FileUploadDisplay.module.scss';

type Props = {
  fileName: string;
  onClick?: () => void;
};

export const FileUploadDisplay = ({ fileName, onClick }: Props) => {
  const format = fileName.includes('.') && `${fileName.split('.').pop()}`;
  const name = fileName.replace(`.${format}`, '');

  return (
    <Group
      display="flex"
      wrap="nowrap"
      w="100%"
      style={{ overflow: 'hidden', marginRight: '10px' }}
    >
      <UploadedGenericFile />
      <Group classNames={classNames} onClick={onClick}>
        <Text style={singleLineTruncatedTextWithEllipsis}>
          {format ? `${name}.` : name}
        </Text>
        <Text>{format}</Text>
      </Group>
    </Group>
  );
};
