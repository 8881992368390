import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

const IconZorroPaymentCombined = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        width="40"
        height="40"
        viewBox="0 0 40 40"
      >
        <path
          stroke="#1A1721"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M13.333 11.667 20 5m0 0 6.667 6.667M20 5v8.99a11.229 11.229 0 0 1-5 9.343 11.23 11.23 0 0 0-5 9.344V35m20 0v.017m0-5v.016m-1.667-5v.017m-5-3.334v.017"
        />
      </svg>
    </Center>
  );
};

export { IconZorroPaymentCombined };
