import {
  DateUtilInstance,
  getNow,
  getNowAsDate,
} from '@zorro/shared/formatters';

export function deleteInsuredAgeOnOEPPlanStart<
  T extends {
    dependents: { [x: string]: unknown; ageOnOEPPlanStart?: number | null }[];
    employee: { [x: string]: unknown; ageOnOEPPlanStart?: number | null };
    spouse?: { [x: string]: unknown; ageOnOEPPlanStart?: number | null };
  }
>(insured: T) {
  insured.dependents.forEach((dependent) => delete dependent.ageOnOEPPlanStart);
  delete insured.employee?.ageOnOEPPlanStart;
  delete insured.spouse?.ageOnOEPPlanStart;
}

function maxAllowedAdultBirthDate(
  targetDate: DateUtilInstance
): DateUtilInstance {
  return targetDate.startOf('day').subtract(18, 'years');
}

function minAllowedDependentBirthDate(
  targetDate: DateUtilInstance
): DateUtilInstance {
  return targetDate.startOf('day').subtract(26, 'years');
}

export function insuredMinDateOfBirth(
  isDependent: boolean,
  targetEnrollmentDate: DateUtilInstance | null
): Date | undefined {
  const targetDate = targetEnrollmentDate || getNow();
  return isDependent
    ? minAllowedDependentBirthDate(targetDate).toDate()
    : undefined;
}

export function insuredMaxDateOfBirth(
  isDependent: boolean,
  targetEnrollmentDate: DateUtilInstance | null
): Date {
  const targetDate = targetEnrollmentDate || getNow();
  return isDependent
    ? getNowAsDate()
    : maxAllowedAdultBirthDate(targetDate).toDate();
}
