import { Gender, InsuredSubtype } from '@zorro/clients';
import { DateUtilInstance } from '@zorro/shared/formatters';
import {
  VALIDATION_MESSAGES,
  getAdultDefaultDate,
  insuredMaxDateOfBirth,
  insuredMinDateOfBirth,
  validateOnlyNumbers,
} from '@zorro/shared/utils';
import { YesNo, insuredSubtypeLabelConfig } from '@zorro/types';
import {
  AddressInput,
  Box,
  DateInput,
  FormErrorMessage,
  Grid,
  Group,
  InputWrapper,
  PasswordInput,
  Select,
  TabButtonGroup,
  Tabs,
  Text,
  TextInput,
  Tooltip,
} from '@zorro/zorro-ui-design';
import { Controller, UseFormReturn } from 'react-hook-form';
import * as yup from 'yup';

import {
  INSURED_FORM_ADDRESS_TOOLTIP_LABEL,
  getInsuredBaseSchema,
} from './InsuredFormUtils';

const spouseRelationshipValues = Object.values([
  InsuredSubtype.SPOUSE,
  InsuredSubtype.DOMESTIC_PARTNER,
]);

const insuredTypeOptions = spouseRelationshipValues.map((status) => ({
  label: insuredSubtypeLabelConfig[status],
  value: status,
}));

export const getSpouseInsuredSchema = (isFinalizationMode: boolean) =>
  getInsuredBaseSchema(isFinalizationMode).concat(
    yup.object({
      ...(isFinalizationMode
        ? {
            subtype: yup
              .mixed<InsuredSubtype>()
              .required(VALIDATION_MESSAGES.relationshipRequired)
              .oneOf(
                spouseRelationshipValues,
                VALIDATION_MESSAGES.relationshipRequired
              ),
          }
        : {
            subtype: yup
              .mixed<InsuredSubtype>()
              .oneOf(
                spouseRelationshipValues,
                VALIDATION_MESSAGES.relationshipRequired
              )
              .nullable(),
          }),
    })
  );

export type InsuredSpouseFormFields = yup.InferType<
  ReturnType<typeof getSpouseInsuredSchema>
>;

type Props = {
  setIsSpouseActive: (value: boolean) => void;
  isSpouseActive: boolean;
  spouseForm: UseFormReturn<InsuredSpouseFormFields>;
  targetEnrollmentDate: DateUtilInstance;
  isFinalizationMode: boolean;
  isEmployeeMode: boolean;
};

export const SpouseFormInputs = ({
  isEmployeeMode,
  isFinalizationMode,
  setIsSpouseActive,
  isSpouseActive,
  spouseForm,
  targetEnrollmentDate,
}: Props) => {
  const { formState, control, watch } = spouseForm;
  const { errors } = formState;
  const isFemale = watch('gender') === Gender.FEMALE;

  return (
    <Group>
      {isSpouseActive ? (
        <Grid>
          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="firstName"
              render={({ field: { ...rest } }) => (
                <TextInput
                  {...rest}
                  label="First name"
                  placeholder="First name"
                  required
                />
              )}
            />

            <FormErrorMessage errors={errors} fieldName="firstName" />
          </Grid.Col>

          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="lastName"
              render={({ field: { ...rest } }) => (
                <TextInput
                  {...rest}
                  label="Last name"
                  placeholder="Last name"
                  required
                />
              )}
            />

            <FormErrorMessage errors={errors} fieldName="lastName" />
          </Grid.Col>

          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="subtype"
              render={({ field: { ...rest } }) => (
                <Select
                  {...rest}
                  label="Relationship"
                  placeholder="Select relationship"
                  data={insuredTypeOptions}
                  required={isFinalizationMode}
                />
              )}
            />

            <FormErrorMessage errors={errors} fieldName="subtype" />
          </Grid.Col>

          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="dateOfBirth"
              render={({ field: { ...rest } }) => (
                <DateInput
                  {...rest}
                  label="Date of birth"
                  defaultLevel="decade"
                  defaultDate={getAdultDefaultDate()}
                  minDate={insuredMinDateOfBirth(false, targetEnrollmentDate)}
                  maxDate={insuredMaxDateOfBirth(false, targetEnrollmentDate)}
                  required
                />
              )}
            />

            <FormErrorMessage errors={errors} fieldName="dateOfBirth" />
          </Grid.Col>

          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="gender"
              render={({ field: { ...rest } }) => (
                <InputWrapper label="Gender" required={isFinalizationMode}>
                  <TabButtonGroup {...rest}>
                    <Tabs.List grow>
                      <Tabs.Tab value={Gender.MALE}>Male</Tabs.Tab>
                      <Tabs.Tab value={Gender.FEMALE}>Female</Tabs.Tab>
                    </Tabs.List>
                  </TabButtonGroup>
                </InputWrapper>
              )}
            />

            <FormErrorMessage errors={errors} fieldName="gender" />
          </Grid.Col>

          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="isSmoker"
              render={({ field: { ...rest } }) => (
                <InputWrapper
                  label="Tobacco User"
                  required={isFinalizationMode}
                >
                  <TabButtonGroup {...rest}>
                    <Tabs.List grow>
                      <Tabs.Tab value={YesNo.YES}>Yes</Tabs.Tab>
                      <Tabs.Tab value={YesNo.NO}>No</Tabs.Tab>
                    </Tabs.List>
                  </TabButtonGroup>
                </InputWrapper>
              )}
            />

            <FormErrorMessage errors={errors} fieldName="isSmoker" />
          </Grid.Col>

          {isFemale && (
            <Grid.Col span={{ sm: 6 }}>
              <Controller
                control={control}
                name="isPregnant"
                render={({ field: { ...rest } }) => (
                  <InputWrapper label="Pregnant" required={isFinalizationMode}>
                    <TabButtonGroup {...rest}>
                      <Tabs.List grow>
                        <Tabs.Tab value={YesNo.YES}>Yes</Tabs.Tab>
                        <Tabs.Tab value={YesNo.NO}>No</Tabs.Tab>
                      </Tabs.List>
                    </TabButtonGroup>
                  </InputWrapper>
                )}
              />

              <FormErrorMessage errors={errors} fieldName="isPregnant" />
            </Grid.Col>
          )}

          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="ssn"
              render={({ field: { value, ...rest } }) => (
                <PasswordInput
                  {...rest}
                  label="SSN"
                  placeholder="XXXXXXXXX"
                  value={value || ''}
                  maxLength={9}
                  onKeyDown={validateOnlyNumbers}
                  required={isFinalizationMode}
                />
              )}
            />

            <FormErrorMessage errors={errors} fieldName="ssn" />
          </Grid.Col>

          <Grid.Col>
            <Controller
              control={control}
              name="residentialAddress"
              render={({ field: { value, onBlur, onChange, ...rest } }) => (
                <Tooltip
                  label={INSURED_FORM_ADDRESS_TOOLTIP_LABEL}
                  disabled={!isEmployeeMode}
                  position="top"
                  maw={400}
                >
                  <Box pos="relative">
                    <AddressInput
                      {...rest}
                      onBlur={() => {
                        onChange(value);
                        onBlur();
                      }}
                      notifySubscribers={(newAddress) => onChange(newAddress)}
                      onChange={onChange}
                      label="Residential address"
                      value={value || ''}
                      placeholder="Type residential address"
                      required={isFinalizationMode}
                      disabled={isEmployeeMode}
                    />
                  </Box>
                </Tooltip>
              )}
            />

            <FormErrorMessage errors={errors} fieldName="residentialAddress" />
          </Grid.Col>
        </Grid>
      ) : (
        <Text ta="center" c="zorroIris.9">
          + Add spouse / domestic partner
        </Text>
      )}
    </Group>
  );
};
