import { AddIcon } from '@zorro/shared/assets';
import { MultiSelectData } from '@zorro/types';
import {
  ActionIcon,
  IMultiSelectProps,
  MultiSelect,
} from '@zorro/zorro-ui-design';
import { forwardRef } from 'react';

interface MultiSelectWithAddProps extends IMultiSelectProps {
  data: MultiSelectData[];
  showAddButton?: boolean;
  onIconClick?: () => void;
}

export const MultiSelectWithAdd = forwardRef<
  HTMLInputElement,
  MultiSelectWithAddProps
>(
  (
    {
      nothingFoundMessage = 'No results found',
      clearable = false,
      searchable = true,
      onIconClick,
      showAddButton,
      ...props
    },
    ref
  ) => {
    return (
      <>
        <MultiSelect
          ref={ref}
          size="sm"
          clearable={clearable}
          searchable={searchable}
          nothingFoundMessage={nothingFoundMessage}
          // TODO: edit to class modules
          // styles={(theme) => ({
          //   label: {
          //     fontSize: theme.fontSizes.md,
          //     fontWeight: 400,
          //     marginBottom: theme.spacing.sm,
          //     marginTop: theme.spacing.lg,
          //   },
          //   input: {
          //     ...(showAddButton && { border: 'none', padding: 0 }),
          //     borderColor: theme.colors.zorroGray600,
          //     borderRadius: 12,
          //     paddingRight: `calc(${theme.spacing.xl} * 2)`,
          //   },
          //   item: {
          //     '&:hover': {
          //       backgroundColor: theme.fn.rgba(
          //         // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          //         theme.colors.zorroIris400?.[0],
          //         0.2
          //       ),
          //     },
          //   },
          // })}
          {...props}
        />

        {showAddButton && (
          <ActionIcon
            onClick={onIconClick}
            mt="xs"
            aria-label="Add new selection"
          >
            <AddIcon />
          </ActionIcon>
        )}
      </>
    );
  }
);

MultiSelectWithAdd.displayName = 'MultiSelectWithAdd';
