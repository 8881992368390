import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const ThreeDotsIcon = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="4"
        viewBox="0 0 14 4"
        fill="none"
      >
        <path
          d="M7.00016 1.33325C6.82335 1.33325 6.65378 1.40349 6.52876 1.52851C6.40373 1.65354 6.3335 1.82311 6.3335 1.99992C6.3335 2.17673 6.40373 2.3463 6.52876 2.47132C6.65378 2.59635 6.82335 2.66659 7.00016 2.66659C7.17697 2.66659 7.34654 2.59635 7.47157 2.47132C7.59659 2.3463 7.66683 2.17673 7.66683 1.99992C7.66683 1.82311 7.59659 1.65354 7.47157 1.52851C7.34654 1.40349 7.17697 1.33325 7.00016 1.33325Z"
          stroke="#76747A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.3335 1.33325C2.15668 1.33325 1.98712 1.40349 1.86209 1.52851C1.73707 1.65354 1.66683 1.82311 1.66683 1.99992C1.66683 2.17673 1.73707 2.3463 1.86209 2.47132C1.98711 2.59635 2.15668 2.66659 2.3335 2.66659C2.51031 2.66659 2.67988 2.59635 2.8049 2.47132C2.92993 2.3463 3.00016 2.17673 3.00016 1.99992C3.00016 1.82311 2.92993 1.65354 2.8049 1.52851C2.67988 1.40349 2.51031 1.33325 2.3335 1.33325Z"
          stroke="#76747A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.6668 1.33325C11.49 1.33325 11.3204 1.40349 11.1954 1.52851C11.0704 1.65354 11.0002 1.82311 11.0002 1.99992C11.0002 2.17673 11.0704 2.3463 11.1954 2.47132C11.3204 2.59635 11.49 2.66659 11.6668 2.66659C11.8436 2.66659 12.0132 2.59635 12.1382 2.47132C12.2633 2.3463 12.3335 2.17673 12.3335 1.99992C12.3335 1.82311 12.2633 1.65354 12.1382 1.52851C12.0132 1.40349 11.8436 1.33325 11.6668 1.33325Z"
          stroke="#76747A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Center>
  );
};
